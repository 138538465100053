import api from '../api'

export interface AggregatedDateReportTotalsData {
  date: string
  gasImport?: number
  electricityImport: number
  electricityExport: number
  peakElectricityImport: number
  peakElectricityExport: number
  solarGeneration: number
  batteryCharge: number
  batteryTotalCharge: number
  batteryTotalDischarge: number
}

export interface AggregatedDateReportTimeSeriesData {
  time: string
  value: number
}
export interface AggregatedDateReportData {
  totals: [AggregatedDateReportTotalsData]
  electricityImportTimeseries: [AggregatedDateReportTimeSeriesData]
  electricityExportTimeseries: [AggregatedDateReportTimeSeriesData]
  gasImportTimeseries: [AggregatedDateReportTimeSeriesData]
  electricityImportPowerTimeseries: [AggregatedDateReportTimeSeriesData]
  electricityExportPowerTimeseries: [AggregatedDateReportTimeSeriesData]
  solarGenerationTimeseries:[AggregatedDateReportTimeSeriesData]
  electricityConsumptionTimeseries:[AggregatedDateReportTimeSeriesData]
  batteryChargeTimeseries:[AggregatedDateReportTimeSeriesData]
  energyConsumptionTimeseries:[AggregatedDateReportTimeSeriesData]
}

export function electricityConsumptionSum(item: AggregatedDateReportTotalsData): number {
  return (
    item.electricityImport -
    item.electricityExport +
    item.solarGeneration -
    item.batteryTotalCharge +
    item.batteryTotalDischarge
  )
}

export type AggregationLevel = 'day' | 'month'

export interface YearReportData {
  electricityImport: number
  electricityExport: number
}

export class ReportingService {
  getAggregatedReportData = async (
    accessToken: string,
    aggregationLevel: AggregationLevel,
    startDate: string | undefined,
    endDate: string | undefined,
  ): Promise<AggregatedDateReportData | void> => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      let queryParams = ''
      if (startDate && endDate) {
        queryParams = `?startDate=${startDate}&endDate=${endDate}`
      }
      const response = await api.get(`/reporting/aggregated/${aggregationLevel}${queryParams}`, config)
      return response.data
    } catch (err) {
      console.error(err)
    }
  }

  getYearReportData = async (accessToken: string): Promise<YearReportData | void> => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      const response = await api.get('/reporting/year', config)
      return response.data
    } catch (err) {}
  }

  getFinancialReportData = async (accessToken: string): Promise<FinancialReportData | void> => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
      const response = await api.get('/reporting/financial', config)
      return response.data
    } catch (err) {}
  }

  getCommunityReportData = async (
    accessToken: string,
    communityId: number,
    period: string,
    startDate: string,
    endDate: string,
  ) => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    }
    const res = await api.get(`/reporting/aggregated/community/${communityId}/${period}`, config)
    const data = res.data
    const absValues: number[] = []
    if ('consumption' in data) {
      data.consumption.map((dataPoint: any) => {
        absValues.push(Math.abs(dataPoint.value))
      })
    }
    if ('production' in data) {
      data.production.map((dataPoint: any) => {
        absValues.push(Math.abs(dataPoint.value))
      })
    }
    return { ...data, max: Math.max(...absValues) }
  }

  getCommunitiesReportData = async (accessToken: string,
    period: string, startDate: string, endDate: string) => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        startDate: startDate,
        endDate: endDate
      }
    }
    const res = await api.get(`/reporting/aggregated/communities/${period}`, config)
    const data = res.data
    const absValues: number[] = []
    if ('consumption' in data) {
      data.consumption.map((dataPoint: any) => {
        absValues.push(Math.abs(dataPoint.value))
      })
    }
    if ('production' in data) {
      data.production.map((dataPoint: any) => {
        absValues.push(Math.abs(dataPoint.value))
      })
    }
    return { ...data, max: Math.max(...absValues) }
  }
}
