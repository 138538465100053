import * as React from 'react'
import { Grid, Table, Container, Form, Button, Divider, Dimmer, Image } from 'semantic-ui-react'
import AuthContext from '../../contexts/auth'
import { ReportingService, AggregatedDateReportData, electricityConsumptionSum } from '../../services/reporting'
import { ReportConnectionRow, ReportHeader } from './generic'
import { TimeSeriesBarGraph } from './graphs'
import UserContext from '../../contexts/user'
import { useContext } from 'react'
import * as loadingSymbol from '../../assets/loading.png'
import {
  electricityImportColor,
  electricityExportPowerColor,
  gasImportColor,
  solarProductionColor,
  totalEnergyConsumption,
  sumElectricityImportColor
} from '../../util/graph_data'

const moment = require('moment')

const MonthReport: React.FC = () => {
  const [reportingData, setReportingData] = React.useState<AggregatedDateReportData | undefined>()
  const [isPrintMode, setIsPrintMode] = React.useState<boolean>(false)
  const reportingService = new ReportingService()
  const reportElement = React.useRef<HTMLDivElement>(null)
  const userContext = useContext(UserContext)
  const authContext = React.useContext(AuthContext)
  const [defaultStartDate, setDefaultStartDate] = React.useState('')
  const [defaultEndDate, setDefaultEndDate] = React.useState('')
  const [loading, setLoading] = React.useState<boolean>(true)

  const fetchData = async () => {
    const token = authContext?.accessToken!
    const result = await reportingService.getAggregatedReportData(token, 'month', defaultStartDate, defaultEndDate)
    setReportingData(result)
  }
  React.useEffect(() => {
    fetchData()
  }, [])
  const handleStartDate = (e: any) => {
    setDefaultStartDate(e.target.value)
  }
  const handleEndDate = (e: any) => {
    setDefaultEndDate(e.target.value)
  }

  const onBeforePrint = () => {
    setIsPrintMode(true)
  }
  const onAfterPrint = () => {
    setIsPrintMode(false)
  }
  React.useEffect(() => {
    if (reportingData?.totals && userContext?.profile) {
      setLoading(false)
    }
  })
  React.useEffect(() => {
    if (!defaultStartDate && !defaultEndDate && reportingData && reportingData.totals) {
      for (let i = 0; i < reportingData.totals.length; i++) {
        if (i === 0) {
          setDefaultStartDate(moment(reportingData.totals[i].date).format('YYYY-MM-DD'))
        }
        if (i === reportingData.totals.length - 1) {
          setDefaultEndDate(moment(reportingData.totals[i].date).format('YYYY-MM-DD'))
        }
      }
    }
  }, [reportingData])

  return (
    <>
      <div className="Loader" style={{ display: loading ? 'block' : 'none' }}>
        <Dimmer active>
          <Image src={loadingSymbol.default} className="rotate" />
        </Dimmer>
      </div>
      <Container
        className="report-workspace"
        id="month-report-container"
        style={{ display: loading ? 'none' : 'block' }}
      >
        <div className="report-container" ref={reportElement}>
          <ReportHeader
            title="MAAND"
            elementRef={reportElement.current}
            onBeforePrint={onBeforePrint}
            onAfterPrint={onAfterPrint}
          />
          <ReportConnectionRow></ReportConnectionRow>
          <Grid>
            {/* Date selection */}
            <Grid.Row>
              <Grid.Column style={{ paddingLeft: '10px' }} width="3">
                <div className="flex1-column">
                  <strong className="date-selection"> Startdatum </strong>
                  <input type="date" className="startDate" onChange={handleStartDate} defaultValue={defaultStartDate} />
                </div>
              </Grid.Column>
              <Grid.Column width="3">
                <div className="flex1-column">
                  <strong className="date-selection">Einddatum</strong>
                  <input type="date" className="endDate" onChange={handleEndDate} defaultValue={defaultEndDate} />
                </div>
              </Grid.Column>
              {!isPrintMode && (
                <div className="flex1-row flex-center-vertical m-y-medium">
                  <Button className="generate-report" content="Genereer rapport" onClick={fetchData} />
                </div>
              )}
            </Grid.Row>

            {/* Data table */}
            <Grid.Row style={{ paddingTop: '10px' }}>
              <Grid.Column style={{ paddingLeft: '10px' }}>
                <Table stackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                      <Table.HeaderCell>Elektriciteit consumptie uit het net</Table.HeaderCell>
                      <Table.HeaderCell>Elektriciteit teruglevering</Table.HeaderCell>
                      <Table.HeaderCell>Opbrengst zonnepanelen</Table.HeaderCell>
                      <Table.HeaderCell>Totaal elektriciteit consumptie</Table.HeaderCell>
                      <Table.HeaderCell>Piek consumptie</Table.HeaderCell>
                      <Table.HeaderCell>Piek teruglevering</Table.HeaderCell>
                      <Table.HeaderCell>Gas consumptie</Table.HeaderCell>
                      <Table.HeaderCell>Totale energie consumptie</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {reportingData &&
                      reportingData.totals &&
                      reportingData.totals.map(function (item, index) {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell width="2" className="padding-zero" style={{ fontSize: '14px' }}>
                              {moment(item.date).format('MMMM YYYY')}
                            </Table.Cell>
                            <Table.Cell style={{ fontSize: '14px' }} textAlign="center">
                              {item.electricityImport.toFixed(2).replace('.', ',')} kWh
                            </Table.Cell>
                            <Table.Cell style={{ fontSize: '14px' }} textAlign="center" width="2">
                              {item.electricityExport.toFixed(2).replace('.', ',')} kWh
                            </Table.Cell>
                            <Table.Cell style={{ fontSize: '14px' }} textAlign="center">
                              {item.solarGeneration.toFixed(2).replace('.', ',')} kWh
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {electricityConsumptionSum(item).toFixed(2).replace('.', ',')} kWh
                            </Table.Cell>
                            <Table.Cell style={{ fontSize: '14px' }} textAlign="center">
                              {item.peakElectricityImport.toFixed(2).replace('.', ',')} kW
                            </Table.Cell>
                            <Table.Cell style={{ fontSize: '14px' }} textAlign="center">
                              {item.peakElectricityExport.toFixed(2).replace('.', ',')} kW
                            </Table.Cell>
                            <Table.Cell style={{ fontSize: '14px' }} textAlign="center">
                              {item.gasImport.toFixed(2).replace('.', ',')} m³
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {(electricityConsumptionSum(item) + (item.gasImport || 0) * 9.769)
                                .toFixed(2)
                                .replace('.', ',')}{' '}
                              kWh
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            {reportingData && reportingData.electricityImportTimeseries.length > 0 && (
              <Grid.Row className="page-together" style={{ marginTop: '10px' }}>
                <Grid.Column className="graph-container">
                  <Grid.Row style={{ paddingTop: '10px' }}>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                       Elektriciteitsconsumptie uit het net (kWh per dag)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      {/* {reportingData && reportingData.electricityImportTimeseries && ( */}
                      <TimeSeriesBarGraph
                        timeSeriesData={reportingData.electricityImportTimeseries}
                        color={electricityImportColor}
                        title={'kWh'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
            {reportingData && reportingData.electricityExportTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                        Teruglevering aan het elektriciteitsnet (kWh per dag)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      {/* {reportingData && reportingData.electricityExportTimeseries && ( */}
                      <TimeSeriesBarGraph
                        timeSeriesData={reportingData.electricityExportTimeseries}
                        color={electricityExportPowerColor}
                        title={'kWh'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <Grid className="page-together">
            {reportingData && reportingData.solarGenerationTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Zonne-opwek (kWh per dag)</strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesBarGraph
                        timeSeriesData={reportingData.solarGenerationTimeseries}
                        color={solarProductionColor}
                        title={'kWh'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
            {reportingData && reportingData.electricityImportTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                        Totaal elektriciteit consumptie (kWh per dag)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesBarGraph
                        timeSeriesData={reportingData.electricityImportTimeseries}
                        color={sumElectricityImportColor}
                        title={'kWh'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <Grid className="page-together">
            {reportingData && reportingData.gasImportTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Gasconsumptie (m³ per dag)</strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      {/* {reportingData && reportingData.gasImportTimeseries && ( */}
                      <TimeSeriesBarGraph
                        timeSeriesData={reportingData.gasImportTimeseries}
                        color={gasImportColor}
                        title={'m³'}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
            {reportingData && reportingData.energyConsumptionTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                        Totaal energieverbruik (elektriciteit + gas)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      {/* {reportingData && reportingData.energyConsumptionTimeseries && ( */}
                      <TimeSeriesBarGraph
                        timeSeriesData={reportingData.energyConsumptionTimeseries}
                        color={totalEnergyConsumption}
                        title={'kWh'}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <Divider />
          <div className="flex1-row flex1-end">©2021 Lyv B.V. Onder voorbehoud van fouten.</div>
        </div>
      </Container>
    </>
  )
}
export default MonthReport
