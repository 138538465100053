import * as React from 'react'
import { Tab, Modal } from 'semantic-ui-react'
import { Monitor, Assets, Date, AddDevice } from '..'

const Home: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const panes = [
    {
      menuItem: 'Inzicht',
      render: () => (
        <Tab.Pane>
          <Monitor />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Apparaten',
      render: () => (
        <Tab.Pane>
          <Assets />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        id: 'Date',
        content: <Date />,
        position: 'right'
      }
    }
  ]
  return (
    <>
      <Tab panes={panes} className="home-menu-container" />
    </>
  )
}
export default Home
