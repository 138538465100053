import React, { useState, useEffect, useRef, useContext } from 'react'
import DataContext from './contexts/data'

export const useInterval = (callback: any, delay: number) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick () {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
// export const callBack = () => {
//   const dataContext = useContext(DataContext)
//   const summary = dataContext?.aggregate?.summaryAggregate
//  return const wantedGraphicData = [
//     { x: 1, y: summary?.fromSolar },
//     { x: 2, y: summary?.fromBattery },
//     { x: 3, y: summary?.fromGrid }
//   ]
// }
