import * as React from 'react'
import { Form, Confirm, Dropdown, Message } from 'semantic-ui-react'
import { useState } from 'react'
import DataContext from '../../../contexts/data'
import { IElectricityContract, IElectricityContractCreate } from '../../../interfaces/contracts'
import {
  contractOptions,
  confirmationOptions,
  typeTariff
} from '../../../util/options'
import { electricityTariffType, electricityTariffTypeDutch, clientType, toolTipText } from '~/enums'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import UserContext from '../../../contexts/user'
import NumberFormat from 'react-number-format'
const moment = require('moment')

const EnergySupplyFlow: React.FC = () => {
  const dataContext = React.useContext(DataContext)
  const userContext = React.useContext(UserContext)
  const [formData, setFormData] = useState<any>({})
  const [currentContract, setCurrentContract] = useState<IElectricityContract>(dataContext?.contract?.electricity!)
  const [energyRetailerOptions, setEnergyRetailerOptions] = useState<any[]>([])
  const [selectedContractType, setSelectedContractType] = useState<any>(
    currentContract.isDefaultContract ? 'Standaard' : 'Werkelijk'
  )
  const [change, setChange] = useState<boolean>(false)
  const [obligationInput, setObligationInput] = useState('obligation-input')
  const [doubleTariff, setDoubleTariff] = useState<boolean>(false)
  const [disabledForm, setDisabledForm] = useState<boolean>(true)
  const [selectedRetailer, setSelectedRetailer] = useState(dataContext?.contract.electricity.retailer?.id)
  const [selectedTariffType, setSelectedTariffType] = useState(
    dataContext?.contract.electricity.tariffType === 'single' ? 'Enkeltarief' : 'Dubbeltarief'
  )
  const [selectedTariffTypeToBackend, setSelectedTariffTypeToBackend] = useState(
    dataContext?.contract.electricity.tariffType
  )
  const [failureMessage, setFailureMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const [isTaxReductionApplicable, setIsTaxReductionApplicable] = useState<boolean>(true)
  const handleInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.replace(',', '.') })
  }
  const close = () => {
    setOpenPopUp(false)
  }
  const onSubmit = async (e: any) => {
    e.preventDefault()
    const payload: IElectricityContractCreate = {
      ...formData,
      tariffType: selectedTariffTypeToBackend!,
      retailerId: selectedRetailer,
      isTaxReductionApplicable
    }
    setDisabledForm(true)
    setObligationInput('obligation-input')
    setChange(false)
    try {
      await dataContext?.contract?.createElectricityContract(payload)
      setSuccessMessage('De elektriciteitsgegevens zijn succesvol verwerkt.')
    } catch (err) {
      setFailureMessage('Klant heeft reeds actieve, niet standaard, gas en elektriciteitsgegevens.')
    }
  }
  const onEnergyRetailerSelected = (e: any, data: any) => {
    setSelectedRetailer(data.value)
  }

  const onContractChange = (e: any, data: any) => {
    if (data.value === 'Werkelijk') {
      setChange(true)
      setDisabledForm(false)
      setObligationInput('')
    } else {
      setDisabledForm(true)
      setObligationInput('obligation-input')
      setChange(false)
    }
    setSelectedContractType(data.value)
  }
  const inclusiveOrExclusive = () => {
    if (userContext?.profile?.customerType === clientType.Business) {
      return toolTipText.Exclusive
    } else if (userContext?.profile?.customerType === clientType.Private) {
      return toolTipText.Inclusive
    }
  }
  const onTariffTypeSelected = (e: any, data: any) => {
    if (data.value === electricityTariffTypeDutch.Double) {
      setSelectedTariffType('Dubbeltarief')
      setSelectedTariffTypeToBackend('double')
      setDoubleTariff(true)
    } else if (data.value === electricityTariffTypeDutch.Single) {
      setSelectedTariffType('Enkeltarief')
      setSelectedTariffTypeToBackend('single')
      setDoubleTariff(false)
    }
  }
  const onChangeConfirm = () => {
    setChange(true)
    setDisabledForm(false)
    setObligationInput('')
  }
  setTimeout(() => {
    setSuccessMessage(null)
    setFailureMessage(null)
  }, 6000)
  React.useEffect(() => {
    const result = dataContext?.contract.energyRetailers.map((value) => {
      return { key: value.id, text: value.displayName, value: value.id }
    })
    setEnergyRetailerOptions(result || [])
  }, [dataContext?.contract.energyRetailers])
  // React.useEffect(() => {
  //   const result = dataContext?.contract.tariffTypes.map((value) => {
  //     return { key: value, text: value, value }
  //   })
  //   setTariffTypeOptions(result || [])
  // }, [dataContext?.contract.tariffTypes])

  React.useEffect(() => {
    if (dataContext?.contract.defaultElectricity) {
      setCurrentContract(dataContext.contract.defaultElectricity)
    }
  }, [dataContext?.contract.defaultElectricity])

  React.useEffect(() => {
    setFormData({
      tariffHighPerKwh: currentContract.tariffHighPerKwh.toFixed(2),
      tariffLowPerKwh: currentContract.tariffLowPerKwh.toFixed(2),
      tariffFlatPerKwh: currentContract.tariffFlatPerKwh?.toFixed(2),
      feedInTariffPerKwh: currentContract.feedInTariffPerKwh.toFixed(2),
      fixedFeePerYear: currentContract.fixedFeePerYear.toFixed(2),
      startDateTime: moment(currentContract.startDateTime).format('DD-MM-YYYY'),
      endDateTime: moment(currentContract.endDateTime).format('DD-MM-YYYY')
    })
    const contractType = currentContract.isDefaultContract ? 'Standaard' : 'Werkelijk'
    setSelectedContractType(contractType)
  }, [currentContract])

  React.useEffect(() => {
    if (dataContext?.contract.electricity) {
      setCurrentContract(dataContext.contract.electricity)
    }
  }, [dataContext?.contract.electricity])
  return (
    <div className="energy-supply-container">
      <div className="energy-form-container">
        <hr style={{ marginTop: '10px' }} />
        <Form onSubmit={onSubmit} className="form">
          <div className="energy-supply-details flex1-column">
            <div className="flex1-row">
              <p className="energy-supply-detail-names">Contract gegevens</p>
              <Dropdown
                name="contract"
                onChange={onContractChange}
                options={contractOptions}
                value={selectedContractType}
                selection
                fluid
                style={{ marginLeft: '8px' }}
              />
              <div className="tooltip">
                <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                <div className="tooltiptext">
                  Werkerlijk: Selecteren als u de werkelijke waarde van uw <br />
                  energiecontract wilt gebruiken. Dit geeft het meest nauwkeurige resultaat.
                  <br />
                  Standaard: Selecteert u als u niet weet wat u voor stroom en gas <br />
                  betaald. Het syteem rekent dan met standaard waarde. Let op! <br />
                  De overzichten komen dan niet overeen met wat u <br />
                  werkelijk betaald.
                </div>
              </div>
            </div>
            <div className="flex1-row" style={{ marginTop: '10px' }}>
              <p className="energy-supply-detail-names">Leverancier</p>
              <Dropdown
                name="supplier"
                placeholder="Details"
                onChange={onEnergyRetailerSelected}
                value={selectedRetailer}
                options={energyRetailerOptions}
                disabled={disabledForm}
                className={obligationInput}
                selection
                fluid
                style={{ marginLeft: '6px', marginRight: '30px' }}
              />
            </div>
            <div className="flex1-row">
              <p className="energy-supply-detail-names">Type tarief</p>
              <Dropdown
                name="rateType"
                placeholder="DubbelTarief"
                onChange={onTariffTypeSelected}
                value={selectedTariffType}
                options={typeTariff}
                disabled={disabledForm}
                className={obligationInput}
                selection
                fluid
                style={{ marginLeft: '6px', marginRight: '30px' }}
              />
            </div>
            {!doubleTariff && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Tarief</p>
                </div>
                <NumberFormat
                  value={formData.tariffFlatPerKwh}
                  onChange={handleInputChange}
                  name="tariffFlatPerKwh"
                  className={`form-item ${obligationInput} number-format`}
                  disabled={disabledForm}
                  style={{ marginRight: '5px', paddingLeft: '15px' }}
                  decimalSeparator={','}
                  allowedDecimalSeparators={[',']}
                  isNumericString={true}
                />
                <span style={{ marginLeft: '-54px', paddingTop: '4px', opacity: '0.9' }}>€/kWh</span>
                <div className="tooltip">
                  <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                  <div className="tooltiptext" style={{ height: '50px', bottom: '-100%' }}>
                    {inclusiveOrExclusive()}
                  </div>
                </div>
              </div>
            )}
            {doubleTariff && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Hoogtarief</p>
                </div>
                <NumberFormat
                  value={formData.tariffHighPerKwh}
                  name="tariffHighPerKwh"
                  onChange={handleInputChange}
                  className={`form-item ${obligationInput} number-format`}
                  disabled={disabledForm}
                  style={{ marginRight: '5px' }}
                  decimalSeparator={','}
                  allowedDecimalSeparators={[',']}
                  isNumericString={true}
                />
                <span style={{ marginLeft: '-56px', paddingTop: '4px', opacity: '0.9' }}>€/kWh</span>
                <div className="tooltip">
                  <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                  <div className="tooltiptext" style={{ height: '50px', bottom: '-100%' }}>
                    {inclusiveOrExclusive()}
                  </div>
                </div>
              </div>
            )}
            {doubleTariff && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Laagtarief</p>
                </div>
                <NumberFormat
                  value={formData.tariffLowPerKwh}
                  onChange={handleInputChange}
                  name="tariffLowPerKwh"
                  className={`form-item ${obligationInput} number-format`}
                  disabled={disabledForm}
                  style={{ marginRight: '5px' }}
                  decimalSeparator={','}
                  allowedDecimalSeparators={[',']}
                  isNumericString={true}
                />
                <span style={{ marginLeft: '-56px', paddingTop: '4px', opacity: '0.9' }}>€/kWh</span>
                <div className="tooltip">
                  <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                  <div className="tooltiptext" style={{ height: '50px', bottom: '-100%' }}>
                    {inclusiveOrExclusive()}
                  </div>
                </div>
              </div>
            )}
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Teruglevertarief</p>
              </div>
              <NumberFormat
                value={formData.feedInTariffPerKwh}
                onChange={handleInputChange}
                name="feedInTariffPerKwh"
                className={`form-item ${obligationInput} number-format`}
                disabled={disabledForm}
                style={{ marginRight: '5px' }}
                decimalSeparator={','}
                allowedDecimalSeparators={[',']}
                isNumericString={true}
              />
              <span style={{ marginLeft: '-56px', paddingTop: '4px', opacity: '0.9' }}>€/kWh</span>
              <div className="tooltip">
                <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                <div className="tooltiptext" style={{ height: '50px', bottom: '-100%' }}>
                  {inclusiveOrExclusive()}
                </div>
              </div>
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Vastrecht</p>
              </div>
              <NumberFormat
                value={formData.fixedFeePerYear}
                onChange={handleInputChange}
                name="fixedFeePerYear"
                className={`form-item ${obligationInput} number-format`}
                disabled={disabledForm}
                style={{ marginRight: '5px' }}
                decimalSeparator={','}
                allowedDecimalSeparators={[',']}
                isNumericString={true}
              />
              <span style={{ marginLeft: '-46px', paddingTop: '4px', opacity: '0.9' }}>€/jaar</span>
              <div className="tooltip">
                <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                <div className="tooltiptext" style={{ height: '50px', bottom: '-100%' }}>
                  {inclusiveOrExclusive()}
                </div>
              </div>
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Start contract</p>
              </div>
              <input
                value={formData.startDateTime}
                onChange={handleInputChange}
                name="startDateTime"
                className={`form-item ${obligationInput}`}
                disabled={disabledForm}
                style={{ marginLeft: '0px', marginRight: '30px' }}
              />
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Einde contract</p>
              </div>
              <input
                value={formData.endDateTime}
                onChange={handleInputChange}
                name="endDateTime"
                className={`form-item ${obligationInput}`}
                disabled={disabledForm}
                style={{ marginLeft: '0px', marginRight: '30px' }}
              />
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Heffingskorting</p>
              </div>
              <Dropdown
                name="isTaxReductionApplicable"
                options={confirmationOptions}
                value={isTaxReductionApplicable}
                className={obligationInput}
                selection
                fluid
                disabled={disabledForm}
                onChange={(e, data: any) => {
                  setIsTaxReductionApplicable(data.value)
                }}
                style={{ marginLeft: '6px', marginRight: '30px' }}
              />
            </div>

            <div className="flex1-row">
              <div className="energy-supply-detail-names"></div>
              {failureMessage && <Message color="red">{failureMessage}</Message>}
              {successMessage && <Message color="green">{successMessage}</Message>}
            </div>
            <div className="flex1-row flex1-end" style={{ marginRight: '30px' }}>
              {selectedContractType === 'Werkelijk' && !change && (
                <button className="btn-blue" onClick={onChangeConfirm}>
                  Wijzig
                </button>
              )}
              {change && (
                <button className="btn-blue" type="submit">
                  Pas toe
                </button>
              )}
              <Confirm
                content="
                Wil je de data pastoe?"
                cancelButton="Nee"
                confirmButton="Ja"
                open={openPopUp}
                onCancel={close}
                onConfirm={close}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}
export default EnergySupplyFlow
