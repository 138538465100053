import * as React from 'react'
import { useContext } from 'react'
import { AiOutlineSync } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import * as arrowIcon from '../../../assets/Arrow.svg'
import * as blueUpArrow from '../../../assets/blue_up_arrow.png'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import * as electricitySymbol from '../../../assets/Electricity.svg'
import { darkGreyColorScale, lightGreyColorScale, yellowColorScale } from '../../../util/graph_data'
import { VictoryPie, VictoryLabel, VictoryGroup, VictoryArea, VictoryAxis, VictoryChart, VictoryStack } from 'victory'
import * as upArrow from '../../../assets/up_arrow.png'
import { tickCount } from '../../../util/helper'
import { Image } from 'semantic-ui-react'
import './electricity.scss'
import { IElectricityAggregate } from '../../../interfaces/aggregate'
import AuthContext from '../../../contexts/auth'
import DataContext from '../../../contexts/data'
import UsageService from '../../../services/usage'
const moment = require('moment')

interface IProps {
  setTileExpanded: (value: string) => void
  tileExpanded: string
}
const Electricity: React.FC<IProps> = (props: IProps) => {
  const [expand, setExpand] = React.useState(false)
  const [value, setValue] = React.useState<string | undefined>(moment(new Date()).format('YYYY-MM-DD'))
  const [arrowUp, setArrowUp] = React.useState(false)
  const [electricityTickCount, setElectricityTickcount] = React.useState<number>()
  const [usageData, setUsageData] = React.useState([])
  const [forecastData, setForecastData] = React.useState([])
  const authContext = React.useContext(AuthContext)
  const dataContext = useContext(DataContext)
  const [electricity, setElectricity] = React.useState<IElectricityAggregate | undefined>(
    dataContext?.aggregate?.electricityAggregate
  )
  const usageService = new UsageService()
  const detailsExpand = () => {
    setExpand(!expand)
  }
  const closeGraph = () => {
    setArrowUp(true)
    props.setTileExpanded('')
  }
  const handleDate = (e: any) => {
    setValue(e.target.value)
    setArrowUp(false)
  }
  const rightArrowClicked = async (e: any) => {
    props.setTileExpanded('electricity')
  }
  const fetchData = async () => {
    const token = authContext?.accessToken!
    const results = await usageService.getElectricityUsage(token, value)
    const forecast = await usageService.getElectricityUsage(token, value, true)
    setUsageData(results)
    setForecastData(forecast)
  }

  React.useEffect(() => {
    fetchData()
  }, [value])
  React.useEffect(() => {
    setElectricity(dataContext?.aggregate?.electricityAggregate)
  }, [dataContext?.aggregate?.electricityAggregate])

  const startAngle = 180
  let actualEndAngle = 180
  let predictedEndAngle = 180
  if (Math.abs(electricity?.actualEnergy!) >= Math.abs(electricity?.predictedEnergy!)) {
    actualEndAngle = electricity?.actualEnergy! > 0 ? 495 : -135
    const percentageOfPredictedEnergy =
      (100 * Math.abs(electricity?.predictedEnergy!)) / Math.abs(electricity?.actualEnergy!)
    predictedEndAngle = (percentageOfPredictedEnergy / 100) * actualEndAngle + 180
  } else {
    predictedEndAngle = electricity?.predictedEnergy! > 0 ? 495 : -135
    const percentageOfActualEnergy =
      (100 * Math.abs(electricity?.actualEnergy!)) / Math.abs(electricity?.predictedEnergy!)
    actualEndAngle = (percentageOfActualEnergy / 100) * predictedEndAngle + 180
  }
  React.useEffect(() => {
    setElectricityTickcount(tickCount(usageData))
  }, [usageData])

  const isAllZero: boolean = usageData.every((item: any) => item.value === 0)
  return (
    <>
      <div className="grid-container">
        <div className="flex1-row space-between">
          <strong className="title">Elektriciteit</strong>
          <div className="refresh-icon">
            <IconContext.Provider
              value={{
                style: { verticalAlign: 'middle', fill: 'rgba(0,183,213)' }
              }}
            >
              <div>
                <AiOutlineSync />
              </div>
            </IconContext.Provider>
          </div>
        </div>
        <hr />
        {(!!electricity?.actualEnergy || !!electricity?.predictedEnergy) && (
          <div className="flex1-row flex1-end bold-font">
            {electricity?.instantPower.toString().replace('.', ',')} kW
          </div>
        )}
        <div className="flex1-column">
          {!electricity?.actualEnergy && !electricity?.predictedEnergy && (
            <div className="flex-center-h-v">
              <div>Geen data beschikbaar</div>
            </div>
          )}
          <div className="electricity-graph-positioning">
            {(!!electricity?.actualEnergy || !!electricity?.predictedEnergy) && (
              <div className={props.tileExpanded ? 'expanded-tile' : 'normal-tile'}>
                <VictoryGroup height={180}>
                  <VictoryPie
                    startAngle={startAngle}
                    endAngle={predictedEndAngle}
                    colorScale={lightGreyColorScale}
                    standalone={false}
                    labels={() => null}
                    data={[{ y: 1 }]}
                    radius={70}
                    innerRadius={60}
                    style={{ labels: { fontSize: 16, fill: 'white' } }}
                  />
                  <VictoryPie
                    startAngle={startAngle}
                    endAngle={actualEndAngle}
                    colorScale={yellowColorScale}
                    standalone={false}
                    labels={() => null}
                    data={[{ y: 1 }]}
                    radius={90}
                    innerRadius={73}
                    style={{ labels: { fontSize: 16, fill: 'white' } }}
                  />
                  <VictoryLabel
                    style={{ fill: 'rgba(251,187,33)', fontSize: '12px', textAlign: 'start' }}
                    x={171}
                    y={105}
                    text={
                      electricity?.actualEnergy !== 0
                        ? `${electricity?.actualEnergy.toFixed(2).toString().replace('.', ',')} kWh`
                        : `${electricity?.actualEnergy.toString().replace('.', ',')} kWh`
                    }
                    direction="ltr"
                  />
                  <VictoryLabel
                    style={{ fill: 'rgba(251,187,33)', fontSize: '14px', textAlign: 'start' }}
                    x={180}
                    y={85}
                    text={
                      electricity?.actualCost !== 0
                        ? `€ ${electricity?.actualCost.toFixed(2).toString().replace('.', ',')}`
                        : `€ ${electricity?.actualCost.toString().replace('.', ',')}`
                    }
                  />
                  <VictoryLabel
                    style={{ fill: 'rgba(204,204,204)', fontSize: '14px', textAlign: 'start' }}
                    x={227}
                    y={85}
                    text={
                      electricity?.predictedCost !== 0
                        ? `€ ${electricity?.predictedCost.toFixed(2).toString().replace('.', ',')}`
                        : `€ ${electricity?.predictedCost.toString().replace('.', ',')}`
                    }
                    direction="ltr"
                  />
                  <VictoryLabel
                    style={{ fill: 'rgba(204,204,204)', fontSize: '12px', textAlign: 'start' }}
                    x={229}
                    y={105}
                    text={
                      electricity?.predictedEnergy !== 0
                        ? `${electricity?.predictedEnergy.toFixed(2).toString().replace('.', ',')} kWh`
                        : `${electricity?.predictedEnergy.toString().replace('.', ',')} kWh`
                    }
                  />
                </VictoryGroup>
              </div>
            )}
            {(!!electricity?.actualEnergy || !!electricity?.predictedEnergy) && (
              <Image
                src={electricitySymbol.default}
                className={props.tileExpanded ? 'electricity-symbol modified' : 'electricity-symbol'}
              />
            )}
          </div>
          <div className="flex1-row space-between flex-center-vertical">
            {(!!electricity?.actualEnergy || !!electricity?.predictedEnergy) && (
              <div className="flex1-row flex1-center full-width">
                <div className="flex1-row flex1-center" style={{ width: '95%' }}>
                  <div
                    className={
                      props.tileExpanded
                        ? 'actual flex1-row flex1-end modified-tile'
                        : 'actual flex1-row flex1-end normal-tile'
                    }
                  >
                    Actueel
                  </div>
                  <div
                    className={
                      props.tileExpanded
                        ? 'expected flex1-row flex1-start modified-tile'
                        : 'expected flex1-row flex1-start normal-tile'
                    }
                    style={{ marginLeft: '15px' }}
                  >
                    Voorspeld
                  </div>
                </div>
                {props.tileExpanded && <div className="flex1-row flex1-end" style={{ width: '5%' }}></div>}
                {!props.tileExpanded && (
                  <div className="flex1-row flex1-end">
                    <Image src={arrowIcon.default} className="right-arrow-icon" onClick={rightArrowClicked} />
                  </div>
                )}
              </div>
            )}
          </div>
          {props.tileExpanded && (
            <div>
              {(!!electricity?.actualEnergy || !!electricity?.predictedEnergy) && (
                <div className="flex1-row space-between" style={{ marginTop: '10px' }}>
                  <strong>Elektriciteit historie</strong>
                  <div className="fle1-row flex-center-vertical" onClick={detailsExpand}>
                    <strong className="detail-txt select-period">Selecteer periode</strong>
                    <input type="date" className="input-date" style={{ opacity: '0' }} onChange={handleDate}></input>
                    {expand && <Image src={blueUpArrow.default} style={{ marginBottom: '2px' }} />}
                    {!expand && <Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
                  </div>
                </div>
              )}
              <div>
                {value && (
                  <div style={{ marginTop: '10px' }}>
                    <div style={{ fontSize: '14px' }}> {value && moment(value).format('DD MMMM YYYY')}</div>
                    <div className="monitor-graph-container m-t-medium">
                      <VictoryChart
                        height={80}
                        scale={{ x: 'time' }}
                        padding={{ top: 5, bottom: 20, right: 10, left: 26 }}
                      >
                        {usageData && isAllZero ? (
                          <VictoryAxis
                            dependentAxis={true}
                            style={{
                              tickLabels: { fontSize: '5px', padding: 3 }
                            }}
                            tickFormat={() => ''}
                          />
                        ) : (
                          <VictoryAxis
                            dependentAxis={true}
                            crossAxis={false}
                            style={{
                              tickLabels: { fontSize: '5px', padding: 3 }
                            }}
                            tickFormat={(t) => t}
                          />
                        )}
                        {usageData && isAllZero ? (
                          <VictoryAxis tickFormat={() => ''} />
                        ) : (
                          <VictoryAxis
                            style={{
                              axis: { stroke: 'black' },
                              grid: { stroke: lightGreyColorScale, width: '2px' },
                              tickLabels: { fontSize: '5px', padding: -52 }
                            }}
                            tickFormat={(time) => {
                              return moment(time).format('HH:mm')
                            }}
                            tickLabelComponent={<VictoryLabel dx={6} />}
                            tickCount={electricityTickCount}
                          // tickCount={6}
                          />
                        )}
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: '#FBBB21' }}
                          x={150}
                          y={75}
                          text="Actueel"
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: darkGreyColorScale }}
                          // x={180}
                          x={electricity?.actualCost === 0 ? 170 : 180}
                          y={75}
                          text={
                            electricity?.actualCost !== 0
                              ? `€ ${electricity?.actualCost.toFixed(2).toString().replace('.', ',')}`
                              : `€ ${electricity?.actualCost.toString().replace('.', ',')}`
                          }
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: lightGreyColorScale }}
                          x={electricity?.actualEnergy === 0 ? 215 : 225}
                          y={75}
                          text={
                            electricity?.actualEnergy !== 0
                              ? `| ${electricity?.actualEnergy.toFixed(2).toString().replace('.', ',')} kWh`
                              : `| ${electricity?.actualEnergy.toString().replace('.', ',')} kWh`
                          }
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: lightGreyColorScale }}
                          x={275}
                          y={75}
                          text="Voorspeld"
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: darkGreyColorScale }}
                          x={electricity?.predictedCost === 0 ? 290 : 325}
                          // x={325}
                          y={75}
                          text={
                            electricity?.predictedCost !== 0
                              ? `€ ${electricity?.predictedCost.toFixed(2).toString().replace('.', ',')}`
                              : `€ ${electricity?.predictedCost.toString().replace('.', ',')}`
                          }
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: lightGreyColorScale }}
                          x={electricity?.predictedEnergy === 0 ? 320 : 375}
                          // x={375}
                          y={75}
                          text={
                            electricity?.predictedEnergy !== 0
                              ? `| ${electricity?.predictedEnergy.toFixed(2).toString().replace('.', ',')} kWh`
                              : `| ${electricity?.predictedEnergy.toString().replace('.', ',')} kWh`
                          }
                        />
                        <VictoryStack>
                          {usageData.length > 1 && (
                            <VictoryArea
                              interpolation="cardinal"
                              style={{ data: { fill: '#FBBB21', opacity: '0.6' } }}
                              data={
                                usageData &&
                                usageData.map((data: any) => {
                                  return {
                                    x: data.time,
                                    y: data.value
                                  }
                                })
                              }
                            />
                          )}
                        </VictoryStack>
                      </VictoryChart>
                    </div>
                    <div className="flex1-row flex1-end">
                      <Image src={upArrow.default} onClick={closeGraph} className="monitor-arrow-up" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default Electricity
