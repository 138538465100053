import api from '../api'
import {
  IUserBuildingDetailsUpdate,
  IUserCustomerDetailsUpdate,
  IUserSettingsUpdate,
  IUserCustomerDetail,
  IUserBuildingDetail,
  IUserSettings,
  IUserSubscription
} from '../interfaces/user'

export default class UserService {
  getUserProfile = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get('/me/', config)
    return response.data
  }

  fetchUsers = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get('/subusers', config)
    return response.data
  }

  getUserToken = async (accessToken: string, userId: number) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get(`/auth/${userId}`, config)
    return response.data
  }

  updateUser = async (token: string, userId: number, data: any) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const { firstName, lastName, street, streetNumber, postcode, city } = data
    const response = await api.put(
      `/subusers/${userId}`,
      { firstName, lastName, street, streetNumber, postcode, city },
      config
    )
    return response.data
  }

  suspendUser = async (token: string, userId: number) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.post(`/subusers/${userId}/suspend`, null, config)
    return response.data
  }

  unSuspendUser = async (token: string, userId: number) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.delete(`/subusers/${userId}/suspend`, config)
    return response.data
  }

  resendUserInvite = async (token: string, email: string) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.post('/subusers/invite', { email }, config)
    return response.data
  }

  deleteUser = async (token: string, userId: number) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.delete(`/subusers/${userId}`, config)
    return response.data
  }

  updateCustomerDetails = async (token: string, data: IUserCustomerDetailsUpdate) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.put('/subusers/customer', data, config)
    return response.data
  }

  updateSettingsDetails = async (token: string, data: IUserSettingsUpdate) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.put('/subusers/settings', data, config)
    return response.data
  }

  updateBuildingDetails = async (token: string, data: IUserBuildingDetailsUpdate) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.put('/subusers/building', data, config)
    return response.data
  }

  getUserCustomerDetails = async (token: string): Promise<IUserCustomerDetail> => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.get('/subusers/customer', config)
    return response.data
  }

  getUserBuildingDetails = async (token: string): Promise<IUserBuildingDetail> => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.get('/subusers/building', config)
    return response.data
  }

  getUserSettings = async (token: string): Promise<IUserSettings> => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.get('/subusers/settings', config)
    return response.data
  }

  getUserSubscription = async (token: string): Promise<IUserSubscription> => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.get('/subusers/subscription', config)
    return response.data
  }

  getUserSubscriptionDetail = async (token: string, subuserId: number): Promise<IUserSubscription> => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.get(`/subusers/${subuserId}/subscription`, config)
    return response.data
  }
}
