import * as React from 'react'
import { Checkbox, Image, Button, Divider } from 'semantic-ui-react'
import * as helper from '../../../util/helper'
import './assets.scss'
import './device_list'

interface IDeviceLibraryGeneric {
  imageLink: string
  description: string
  groupLabel: string
}
// DeviceLibrary can be used in the devicelist to add a device to it.
const DeviceLibraryGeneric = (props: IDeviceLibraryGeneric) => {
  const imageSize = () => {
    if (props.description === helper.deviceDescriptionAssetMapping.gas_meter) {
      return 'gas-meter m-l-medium'
    } else if (
      props.description === helper.deviceDescriptionAssetMapping.solar_plant ||
      props.description === helper.deviceDescriptionAssetMapping.solar_plant_sma
    ) {
      return ''
    } else {
      return 'device-icon-size'
    }
  }
  return (
    <>
      <div className="flex1-row space-between flex-center-vertical">
        <div className="flex1-row flex-center-vertical">
          <div className="column1">{props && <Image src={props.imageLink} className={imageSize()} />}</div>
          <div className="flex1-cloumn column2">
            <strong className="description flex1-row flex1-start">{props && props.description}</strong>
            <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
              {props && props.groupLabel}
            </div>
          </div>
          <Button
            content="Toevoegen"
            className="add-device-btn deleteunavailable"
            data-tooltip="Niet beschikbaar"
          ></Button>
        </div>
        <Checkbox />
      </div>
      <Divider />
    </>
  )
}
export default DeviceLibraryGeneric
