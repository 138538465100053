import './inline-field.scss'
import * as React from 'react'
import { Form } from 'semantic-ui-react'

interface IInlineTextInputFieldProps{
    label: string
    placeholder: string
    value: string
    name?: string
    required?: boolean
    disabled?: boolean
    type?: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => any
}

const InlineTextInputField = (props: IInlineTextInputFieldProps) => {
  const pClassName = props.required ? 'input-label required-field' : 'input-label'
  return (<div className="flex1-row">
    <p className={pClassName}>{props.label}</p>
    <Form.Field className="flex1-row">
      <input
        placeholder={props.placeholder}
        required={props.required}
        className="input-item"
        name={props.name}
        value={props.value}
        type={props.type}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </Form.Field>
  </div>)
}

export default InlineTextInputField
