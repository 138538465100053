import api from '../api'

export default class OnboardService {
  inviteUser = async (accessToken: string, data: any, invite: boolean) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const inviteQuery = invite ? 'true' : 'false'
    const url = `/subusers?invite=${inviteQuery}`
    const response = await api.post(url, data, config)
    return response.data
  }

  validateToken = async (token: string) => {
    const response = await api.get(`/auth/validate?token=${token}`)
    const result = response.data
    return result
  }

  setUserPassword = async (token: string, password: string) => {
    const response = await api.post(`/set-password?token=${token}`, {
      password
    })
    return response.data
  }

  verifyAccount = async (token: string) => {
    const response = await api.post(`/auth/verify-account?token=${token}`)
    return response.data
  }

  setupGateway = async (token: string, serialNumber: string) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.post(
      '/gateways/pair',
      {
        serialnr: serialNumber
      },
      config
    )
    return response.data
  }

  updateCustomerDetails = async (token: string, data: any) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.put('/me/', data, config)
    return response.data
  }

  setOnboardingComplete = async (token: string) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.put('/me/onboarding-complete', null, config)
    return response.data
  }

  setupMainMeterDevice = async (token: string) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.post('/subusers/setup/main-meter', null, config)
    return response.data
  }

  setupMainMeterLibrary = async (token: string, libraryId: number, commParams: any) => {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const response = await api.post(`/subusers/setup/main-meter/library/${libraryId}`, commParams, config)
    return response.data
  }
}
