import * as React from 'react'
import { Grid, Message, Checkbox, Image, Popup, Button } from 'semantic-ui-react'
import * as loadingSymbol from '../../../assets/loading.png'
import * as step1info from '../../../assets/step1.png'
import * as step2info from '../../../assets/step2.png'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { formatSerialNumber } from '../../../util/helper'
import { useState, useContext } from 'react'
import * as deviceService from '~/services/device'
import { SmartControllerApplianceType } from '~/enums'
import * as constants from '~/constants'
import AuthContext from '~/contexts/auth'
import UserContext from '~/contexts/user'
import { IDevice } from '~/interfaces/device'

interface GenericSmartControllerProps {
  library: any
  data: any
  selectedDevice: IDevice | null
  setSelectedDevice: (value: IDevice | null) => void
  setDeviceAddedSuccessfully: (value: string) => void
  deviceAddedSuccessfully: string
}

const genericSmartController = (props: GenericSmartControllerProps) => {
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)

  const [isLoading, setLoading] = useState<boolean>(false)
  const [scannedSerialNumbers, setScanSerialNumbers] = useState<any[]>([])
  const [selectedSerialnumber, setSelectedSerialNumber] = useState<string | null>(null)
  const [isConnected, setConnected] = useState<boolean>(false)
  const [deviceConnectedSuccesfully, setDeviceConnectedSuccessfully] = React.useState<string>('')
  const [isDeviceConnectedFailure, setIsDeviceConnectedFailure] = useState<boolean>(false)
  const [deviceConnectedFailure, setDeviceConnectedFailure] = React.useState<string>('')
  const [serialNumberNotFound, setSerialNumberNotFound] = React.useState<boolean>(false)
  const [isDeviceAddedFailure, setIsDeviceAddedFailure] = useState<boolean>(false)
  const [deviceAddedFailure, setDeviceAddedFailure] = React.useState<string>('')
  const [selectedConnectDevice, setSelectedConnectDevice] = React.useState<boolean>(false)
  const [isFillRequiredFields, setIsFillRequiredFields] = useState<boolean>(true)
  const [fillRequiredFields, setfillRequiredFields] = React.useState<string>('')

  const scanDevices = async () => {
    userContext?.socket?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      const eventType = data.eventType
      if (eventType === constants.ScanSmartControllerDeviceResponse) {
        if (data === null) {
          setSerialNumberNotFound(true)
        }
        if (data.status === 'success') {
          const serialNumbers: any[] = data.data.filter((value: any) => {
            return value.status.toLowerCase() === 'new' || value.status.toLowerCase() === 'connected'
          })
          setScanSerialNumbers(serialNumbers.map((val: any) => val.serial_no))
          const allAddedDeviceTrue: boolean = serialNumbers.every((val) => val.status === 'added')
          allAddedDeviceTrue && setSerialNumberNotFound(true)
          setLoading(false)
        } else {
          setSerialNumberNotFound(true)
          setLoading(false)
        }
      }
    })
    setLoading(true)
    try {
      await deviceService.scanSmartControllerDevices(authContext?.accessToken!, props.library.libraryId)
    } catch (error) {
      setLoading(false)
    }
  }

  const connectDevice = async () => {
    setSelectedConnectDevice(true)
    setIsDeviceConnectedFailure(false)
    setConnected(false)
    userContext?.socket?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      const eventType = data.eventType
      if (eventType === constants.ConnectSmartControllerDeviceResponse) {
        if (data.status === 'success') {
          setDeviceConnectedSuccessfully('Succesvol verbonden')
          setConnected(true)
        } else {
          setDeviceConnectedFailure('Kan geen verbinding maken met slimme schakelaar')
          setIsDeviceConnectedFailure(true)
        }
      }
    })

    try {
      await deviceService.connectSmartControllerDevice(
        authContext?.accessToken!,
        props.library.libraryId,
        selectedSerialnumber!
      )
    } catch (error) {
      console.error(error)
    }
  }
  const addDeviceSubmit = () => {

    if ((!props.data.powerRating || !props.data.boilerCapacity) && props.library.application === 'boiler') {
      setfillRequiredFields('Zou je alsjeblieft in de vereiste velden kunnen invullen?')
      setIsFillRequiredFields(false)
    } else if (!props.data.powerRating && props.library.application === 'space_heater') {
      setfillRequiredFields('Zou je alsjeblieft in de vereiste velden kunnen invullen?')
      setIsFillRequiredFields(false)
    } else if (!props.data.applianceType && props.library.application === 'generic') {
      setfillRequiredFields('Zou je alsjeblieft in de vereiste velden kunnen invullen?')
      setIsFillRequiredFields(false)
    } else {
      setfillRequiredFields('')
      setIsFillRequiredFields(true)
    }

    if (isFillRequiredFields && props.library.application === 'boiler') {
      addDevice(props.data)
    } else if (isFillRequiredFields && props.library.application === 'space_heater') {
      const data = { ...props.data }
      delete data.boilerCapacity
      addDevice(data)
    } else if (isFillRequiredFields && props.library.application === 'generic') {
      const data = { ...props.data }
      delete data.powerRating
      delete data.boilerCapacity
      addDevice(data)
    } else {
    }
  }

  const addDevice = async (data1: any) => {
    userContext?.socket?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      if (data === null) {
        setDeviceAddedFailure('Kan slimme schakelaar niet toevoegen')
        setIsDeviceAddedFailure(true)
      }
      const eventType = data.eventType
      if (eventType === constants.AddSmartControllerDeviceResponse) {
        if (data.status === 'success') {
          props.setDeviceAddedSuccessfully('Succesvol toegevoegd')
          setConnected(true)
        } else {
          setDeviceAddedFailure('Kan slimme schakelaar niet toevoegen')
          setIsDeviceAddedFailure(true)
        }
      }
    })
    try {
      const data = { ...data1, zigbeeProtocolParameter: { ieee: selectedSerialnumber } }
      await deviceService.addSmartControllerDevice(
        authContext?.accessToken!,
        props.library.libraryId,
        selectedSerialnumber!,
        data
      )
      // props.setSelectedDevice(null)
    } catch (error) {
      console.error(error)
    }
  }

  setTimeout(() => {
    setDeviceConnectedSuccessfully('')
    setDeviceConnectedFailure('')
    props.setDeviceAddedSuccessfully('')
    setDeviceAddedFailure('')
    setSerialNumberNotFound(false)
  }, 40000)
  setTimeout(() => {
    setfillRequiredFields('')
  }, 5000)

  return (
    <Grid>
      <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
        <Grid.Column width="6" textAlign="left">
          <strong className="heading medium">Verbind apparaat</strong>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
        <Grid.Column width="4" textAlign="left" verticalAlign="middle">
          <div className="heading medium">Stap 1:</div>
        </Grid.Column>
        <Grid.Column width="10" textAlign="left">
          Noteer het serienummer van de slimme schakelaar. Sluit het vervolgens aan op een stopcontact.
        </Grid.Column>
        <Grid.Column width="2" textAlign="right" verticalAlign="middle">
          <Popup
            content={<Image src={step1info.default} />}
            trigger={<IoMdInformationCircleOutline />}
            position="bottom left"
            size="huge"
            wide="very"
            hoverable
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
        <Grid.Column width="4" textAlign="left" verticalAlign="middle">
          <div className="heading medium">Stap 2:</div>
        </Grid.Column>
        <Grid.Column width="10" textAlign="left">
          Druk en houdt de knop ingedrukt op het apparaat totdat de rode LED continu knippert (ong. 15 seconden). Laat
          vervolgens de knop los. Nu moet de rode LED elke seconde knipperen.
        </Grid.Column>
        <Grid.Column width="2" textAlign="right" verticalAlign="middle">
          <Popup
            content={<Image src={step2info.default} />}
            trigger={<IoMdInformationCircleOutline />}
            position="bottom left"
            size="huge"
            wide="very"
            hoverable
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="4" textAlign="left">
          Stap 3:
        </Grid.Column>
        <Grid.Column width="12" textAlign="left">
          Druk op Scan nummer.
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
        {isLoading ? (
          <>
            <Grid.Column width="2"></Grid.Column>
            <Grid.Column width="2" textAlign="left">
              <Image src={loadingSymbol.default} className="rotate m-r-medium" size="mini" />
            </Grid.Column>
          </>
        ) : (
          <Grid.Column width="4" textAlign="left"></Grid.Column>
        )}
        <Grid.Column width="5" textAlign="left">
          <Button
            content="Scan nummer"
            disabled={isLoading}
            className="add-device-btn"
            onClick={() => scanDevices()}
          ></Button>
        </Grid.Column>
        <Grid.Column width="7"></Grid.Column>
      </Grid.Row>

      {scannedSerialNumbers.length !== 0 && (
        <Grid style={{ marginTop: '10px' }}>
          <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
            <Grid.Column width="4" textAlign="left"></Grid.Column>
            <Grid.Column width="12" textAlign="left">
              Selecteer het juiste nummer. Niet het juiste nummer? Volg vanaf stap 1.
            </Grid.Column>
          </Grid.Row>
          {scannedSerialNumbers.map((serialNumber, index) => (
            <Grid.Row key={index} style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
              <Grid.Column width="4" textAlign="left"></Grid.Column>

              <Grid.Column width="2" textAlign="left" verticalAlign="middle">
                <Checkbox
                  onChange={() => setSelectedSerialNumber(serialNumber)}
                  checked={serialNumber === selectedSerialnumber}
                ></Checkbox>
              </Grid.Column>
              <Grid.Column width="10" textAlign="left">
                <input
                  disabled={serialNumber === selectedSerialnumber}
                  className="full-width"
                  id={isConnected ? 'serial' : ''}
                  defaultValue={formatSerialNumber(serialNumber)}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      )}
      {scannedSerialNumbers.length !== 0 && (
        <Grid.Row style={{ paddingTop: '1rem', paddingBottom: '0.7rem' }}>
          {selectedConnectDevice && !isConnected && !isDeviceConnectedFailure ? (
            <>
              <Grid.Column width="2"></Grid.Column>
              <Grid.Column width="2" textAlign="left">
                <Image src={loadingSymbol.default} className="rotate m-r-medium" size="mini" />
              </Grid.Column>
            </>
          ) : (
            <Grid.Column width="4" textAlign="left"></Grid.Column>
          )}
          <Grid.Column width="6" textAlign="left">
            <Button
              disabled={!selectedSerialnumber}
              content="Verbinden"
              className="add-device-btn"
              onClick={connectDevice}
            ></Button>
          </Grid.Column>
          <Grid.Column width="6" textAlign="left">
            <div
              className="flex1-column flex1-center"
              style={{
                height: '36px',
                width: '100%',
                textAlign: 'left',
                backgroundColor: isConnected ? '#a0d2c3' : '#BDBDBD',
                border: '1px #bdbdbd solid'
              }}
            >
              {isConnected ? 'Verbonden' : 'Niet verbonden'}
            </div>
          </Grid.Column>
        </Grid.Row>
      )}
      {serialNumberNotFound && (
        <Grid.Row style={{ paddingTop: '1rem', paddingBottom: '0.7rem' }}>
          <Grid.Column width="4"></Grid.Column>
          <Grid.Column width="12" textAlign="left">
            Geen apparaat gevonden. Probeer het opnieuw vanaf stap 1.
          </Grid.Column>
        </Grid.Row>
      )}
      {deviceConnectedFailure && (
        <Grid.Row style={{ paddingTop: '1rem', paddingBottom: '0.7rem' }}>
          <Grid.Column width="16">
            <Message color="red">{deviceConnectedFailure}</Message>
          </Grid.Column>
        </Grid.Row>
      )}
      {props.deviceAddedSuccessfully && (
        <Grid.Row style={{ paddingTop: '1rem', paddingBottom: '0.7rem' }}>
          <Grid.Column width="16">
            <Message color="green">{props.deviceAddedSuccessfully}</Message>
          </Grid.Column>
        </Grid.Row>
      )}
      {deviceAddedFailure && (
        <Grid.Row style={{ paddingTop: '1rem', paddingBottom: '0.7rem' }}>
          <Grid.Column width="16">
            <Message color="red">{deviceAddedFailure}</Message>
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
        <Grid.Column width="4"></Grid.Column>
        <Grid.Column width="8" textAlign="right">
          <Button content="Annuleren" className="add-device-btn" onClick={() => props.setSelectedDevice(null)}></Button>
        </Grid.Column>
        <Grid.Column width="4" textAlign="left">
          {
            <Button
              disabled={!isConnected}
              content="Toevoegen"
              className="add-device-btn"
              onClick={addDeviceSubmit}
            ></Button>
          }
        </Grid.Column>
      </Grid.Row>
      {fillRequiredFields && (
        <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
          <Grid.Column width="16">
            <Message color="red">{fillRequiredFields}</Message>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  )
}

export default genericSmartController
