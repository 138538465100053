import * as React from 'react'
import routes from './pages/Routes'
import { Suspense, useState } from 'react'
import { Tab } from 'semantic-ui-react'
import { Route, NavLink, Switch } from 'react-router-dom'
import ErrorBoundary from './util/error_boundary'
import {
  Header,
  HeaderMenu,
  CreateAccount,
  GatewayConnected,
  CustomerDetails,
  HomeMenu,
  LoginForm,
  MailVerified,
  CompanyForm,
  AdminHomeMenu,
  Date,
  CommunityHomeMenu,
  CommunityLogin
} from './components'
import Page from './pages/Page'
import './components/Register/register.scss'
import { RouteComponentProps, withRouter } from 'react-router'
import './app.scss'
import * as PropTypes from 'prop-types'
import ProtectedRoute from './components/AuthUser/ProtectedRoute'
import AdminLogin from './components/Admin/AdminLogin'
import AdminProtectedRoute from './components/AuthUser/AdminProtectedRoute'
import LoginRoute from './components/AuthUser/LoginRoute'
import CommunityProtectedRoute from './components/AuthUser/CommunityProtectedRoute'

type SomeComponentProps = RouteComponentProps

const App: React.FC<SomeComponentProps> = ({ location }) => {
  const [activeIndex] = useState<number>(-1)
  const path = location.pathname
  const panes = [
    {
      menuItem: {
        as: NavLink,
        id: 'tab1',
        content: 'Stap 1',
        to: '/createaccount/1',
        exact: true,
        key: 'createaccount'
      },
      pane: (
        <Route
          path="/createaccount/:activeIndex"
          exact
          render={() => (
            <Tab.Pane key="createaccount">
              <CreateAccount />
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: 'tab2',
        content: 'Stap 2',
        to: '/gateway/2',
        exact: true,
        key: 'gateway'
      },
      pane: (
        <ProtectedRoute
          path="/gateway/:activeIndex"
          exact
          component={() => (
            <Tab.Pane key="gateway">
              <GatewayConnected />
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        as: NavLink,
        id: 'tab3',
        content: 'Stap 3',
        to: '/customerdetails/3',
        exact: true,
        key: 'customerdetails'
      },
      pane: (
        <ProtectedRoute
          path="/customerdetails/:activeIndex"
          exact
          component={() => (
            <Tab.Pane key="customerdetails">
              <CustomerDetails />
            </Tab.Pane>
          )}
        />
      )
    },
    {
      menuItem: {
        id: 'Date',
        content: <Date />,
        position: 'right',
        className: 'date'
      }
    }
  ]
  const panesCommunity = [
    {
      menuItem: {
        as: NavLink,
        id: 'tab4',
        content: '',
        to: '/create-account-community-manager/1',
        exact: true,
        key: 'create-account-community-manager'
      },
      pane: (
        <Route
          path="/create-account-community-manager/:activeIndex"
          exact
          render={() => (
            <Tab.Pane key="createaccount">
              <CreateAccount />
            </Tab.Pane>
          )}
        />
      )
    }
  ]
  const appContent = (
    <Switch>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading...</div>}>
          <CommunityProtectedRoute
            path={routes.COMMUNITY_HOME}
            exact
            component={(props: any) => <CommunityHomeMenu {...props} />}
          />
          <AdminProtectedRoute
            path={routes.ADMIN_HOME}
            exact
            component={(props: any) => <AdminHomeMenu {...props} />}
          />
          <LoginRoute path={routes.COMMUNITY_LOGIN} exact component={(props: any) => <CommunityLogin {...props} />} />
          <LoginRoute path={routes.ADMIN_LOGIN} exact component={(props: any) => <AdminLogin {...props} />} />
          <Route path={routes.MAILVERIFIED} exact component={(props: any) => <MailVerified {...props} />} />
          <ProtectedRoute path={routes.COMPANYFORM} exact component={(props: any) => <CompanyForm {...props} />} />
          <ProtectedRoute path={routes.HOME} exact component={(props: any) => <HomeMenu {...props} />} />
          <LoginRoute path={routes.LOGIN} exact component={(props: any) => <LoginForm {...props} />} />
        </Suspense>
      </ErrorBoundary>
    </Switch>
  )
  return (
    <>
      {path === routes.HOME ||
      path === routes.LOGIN ||
      path === routes.MAILVERIFIED ||
      path === routes.ADMIN_LOGIN ||
      path === routes.ADMIN_HOME ||
      path === routes.COMPANYFORM ||
      path === routes.COMMUNITY_HOME ||
      path === routes.COMMUNITY_LOGIN ? (
          appContent
        ) : (
          <React.Fragment>
            {path === routes.CREATEACCOUNT || path === routes.CREATE_ACCOUNT_COMMUNITY_MANAGER ? (
              <Header />
            ) : (
              <HeaderMenu />
            )}
            <Page>
              {path === routes.CREATE_ACCOUNT_COMMUNITY_MANAGER ? (
                <Tab
                  renderActiveOnly={false}
                  activeIndex={activeIndex}
                  panes={panesCommunity}
                  className="register-container"
                />
              ) : (
                <Tab renderActiveOnly={false} activeIndex={activeIndex} panes={panes} className="register-container" />
              )}
            </Page>
          </React.Fragment>
        )}
    </>
  )
}
App.propTypes = {
  location: PropTypes.any.isRequired
}
export default withRouter(App)
