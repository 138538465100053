import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import AuthContext from '../../contexts/auth'

const AdminProtectedRoute: React.FC<RouteProps> = ({ component, ...rest }: any) => {
  const authContext = React.useContext(AuthContext)

  const routeComponent = (props: any) => {
    if (authContext?.isAdminLoggedIn()) {
      return React.createElement(component, props)
    } else if (authContext?.isRegularUserLoggedIn()) {
      return <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
    } else if (authContext?.isCommunityAdminLoggedIn()) {
      return <Redirect to={{ pathname: '/community/home', state: { from: props.location } }} />
    } else {
      return <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />
    }
  }
  return <Route {...rest} render={routeComponent} />
}

export default AdminProtectedRoute
