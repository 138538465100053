import * as React from 'react'
import { Button } from 'semantic-ui-react'

export interface IButtonFeature {
  color?: string | null
  backgroundColor?: string | null
  height?: string | null
  border?: string | null
  content?: string | null
  fontSize?: string | null
  width?: string | null
  onClick: () => any
  marginLeft?: string | null
  padding?: string | null
  disabled?: boolean
  //   setTileExpanded: (value: string) => void
  //   tileExpanded: string
}
const CustomizedButton = (props: IButtonFeature) => {
  return (
    <Button
      content={props.content}
      onClick={props.onClick}
      disabled={props.disabled}
      style={{
        width: props.width,
        height: props.height,
        backgroundColor: props.backgroundColor,
        color: props.color,
        border: props.border,
        marginLeft: props.marginLeft,
        padding: props.padding
      }}
    ></Button>
  )
}
export default CustomizedButton
