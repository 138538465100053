import * as React from 'react'
import { Date } from '../..'
import { Tab } from 'semantic-ui-react'
import NewUser from '../../shared/user/NewUser'
import UsersList from '../../shared/user/UsersList'

import './users.scss'

interface IUsersProps {
  community: any
}

const Users: React.FC<IUsersProps> = (props: IUsersProps) => {
  const panes = [
    {
      menuItem: 'New User',
      render: () => (
        <Tab.Pane>
          <NewUser community={props.community} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'All Users',
      render: () => (
        <Tab.Pane>
          <div className="users-container">
            <UsersList community={props.community} />
          </div>
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        content: <Date />,
        id: 'Date',
        position: 'right'
      },
      render: () => (
        <Tab.Pane>
          <NewUser community={props.community} />
        </Tab.Pane>
      )
    }
  ]

  return (
    <>
      <Tab panes={panes} className="home-menu-container scroll" />
    </>
  )
}

export default Users
