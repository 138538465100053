import * as React from 'react'
import { Grid, Segment } from 'semantic-ui-react'
import { ActiveDevices, DevicesList } from '../..'

const createDevice: React.FC = () => {
  return (
    <>
      <Grid columns={2} stackable id="device">
        <Grid.Row>
          <Grid.Column>
            <Segment className="column1-left" style={{ overflow: 'auto', minHeight: 670 }}>
              <ActiveDevices />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment className="active-device" style={{ overflow: 'auto', minHeight: 670 }}>
              <DevicesList />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}
export default createDevice
