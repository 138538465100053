import * as React from 'react'
import AuthContext from '../../../contexts/auth'
import CommunityService from '../../../services/community'
import OnboardService from '../../../services/onboard'
import { Form, Dropdown, Image, Message } from 'semantic-ui-react'
import '../../Register/customer_details.scss'
import * as newUser from '../../../assets/Customer.svg'
import './user_details.scss'

interface INewUserProps {
  community: any
}

const NewUser: React.FC<INewUserProps> = (props: INewUserProps) => {
  const [customerTypeError, setCustomerTypeError] = React.useState<boolean>(false)
  const [communityError, setCommunityError] = React.useState<boolean>(false)
  const [userNameDisable, setUserNameDisable] = React.useState<boolean>(false)
  const [successMessage, setSuccessMessage] = React.useState<boolean>(false)
  const [failureMessage, setFailureMessage] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState<string>('')
  const onboardService = new OnboardService()
  const communityService = new CommunityService()
  const authContext = React.useContext(AuthContext)

  const initialNewUserData = {
    communityId: '',
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    customerType: ''
  }

  const [communities, setCommunities] = React.useState([])
  const [newUserData, setNewUserData] = React.useState<any>(initialNewUserData)

  const customerTypes = [
    { key: 'business', text: 'business', value: 'business' },
    { key: 'private', text: 'private', value: 'private' }
  ]

  const fetchCommunities = async () => {
    const token = authContext?.accessToken
    let result = await communityService.listCommunities(token!)
    result = result.map((value: any) => {
      return { key: value.name, text: value.name, value: value.id }
    })
    setCommunities(result)
  }

  React.useEffect(() => {
    if (!('id' in props.community)) {
      fetchCommunities()
    }
  }, [])

  const onChange = (e: any, data: any) => {
    if (customerTypeError && data.value !== '') {
      setCustomerTypeError(false)
    }
    if (data.value === 'private') {
      setUserNameDisable(true)
    }
    if (data.value === 'business') {
      setUserNameDisable(false)
    }
    setNewUserData({
      ...newUserData,
      customerType: data.value
    })
  }
  setTimeout(() => {
    setSuccessMessage(false)
    setFailureMessage(false)
  }, 9000)
  const onSubmit = async (e: any) => {
    if ('id' in props.community) {
      newUserData.communityId = props.community.id
    }
    const token = authContext?.accessToken!
    e.preventDefault()
    if (newUserData.customerType === '') {
      setCustomerTypeError(true)
    }
    if (newUserData.communityId === '') {
      setCommunityError(true)
    }

    try {
      await onboardService.inviteUser(token, newUserData, true)
      setNewUserData({
        ...newUserData,
        userName: '',
        firstName: '',
        lastName: '',
        email: ''
      })
      if (newUserData.customerType === 'private') {
        setUserNameDisable(true)
      }
      if (newUserData.customerType === 'business') {
        setUserNameDisable(false)
      }
      setSuccessMessage(true)
      setMessage('User invited successfully')
    } catch (err) {
      setFailureMessage(true)
      setMessage('User is not invited')
    }
  }
  const getCommunityDropdownProps = () => {
    if ('id' in props.community) {
      return {
        disabled: true,
        options: [
          {
            key: props.community.id,
            value: props.community.name,
            text: props.community.name
          }
        ],
        value: props.community.name
      }
    } else {
      return {
        onChange: (e: any, data: any) => {
          if (communityError && data.value !== '') {
            setCommunityError(false)
          }
          setNewUserData({ ...newUserData, communityId: data.value })
        },
        value: newUserData.comunityId,
        options: communities,
        error: communityError,
        placeholder: 'Community'
      }
    }
  }

  return (
    <div className="customer-detail-container form-padding">
      <div className="customer-detail-form-container">
        <div className="flex1-row">
          <Image src={newUser.default} className="customer-detail-logo-size"></Image>
          <strong className="customer-details-header">New user</strong>
        </div>
        <hr />
        <Form onSubmit={onSubmit} className="admin-form">
          <div className="customer-details flex1-column">
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Community</p>
              <Form.Field className="flex1-row">
                <Dropdown
                  selection
                  className="form1-item user-details-dropdown"
                  fluid
                  {...getCommunityDropdownProps()}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Customer type</p>
              <Form.Field>
                <Dropdown
                  placeholder="Customer type"
                  selection
                  label="Please select Klanttype"
                  onChange={onChange}
                  value={newUserData.customerType}
                  options={customerTypes}
                  className="form1-item user-details-dropdown"
                  error={customerTypeError}
                  fluid
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names">Company name</p>
              <Form.Field>
                <input
                  placeholder="Company name"
                  onChange={(e) => setNewUserData({ ...newUserData, userName: e.target.value })}
                  value={newUserData.userName}
                  className="form1-item"
                  disabled={userNameDisable}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <div className="customer-detail-names">
                <p className="required-field">First name</p>
              </div>
              <Form.Field>
                <input
                  placeholder="First name"
                  required
                  onChange={(e) =>
                    setNewUserData({
                      ...newUserData,
                      firstName: e.target.value
                    })
                  }
                  value={newUserData.firstName}
                  className="form1-item"
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <div className="customer-detail-names">
                <p className="required-field">Last name</p>
              </div>
              <Form.Field>
                <input
                  placeholder="Last name"
                  required
                  onChange={(e) => setNewUserData({ ...newUserData, lastName: e.target.value })}
                  value={newUserData.lastName}
                  className="form1-item"
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Email</p>
              <Form.Field>
                <input
                  placeholder="E-mail"
                  required
                  onChange={(e) => setNewUserData({ ...newUserData, email: e.target.value })}
                  value={newUserData.email}
                  className="form1-item"
                />
              </Form.Field>
            </div>
            <div className="flex1-row flex1-end btn-conatiner">
              <div className="flex1-row m-py-bottom">
                <div className="flex1-row flex1-end">
                  <button className="btn-blue m-x-r" id="sentInvite">
                    Send invite
                  </button>
                </div>
              </div>
            </div>
            {successMessage && (
              <Message color="green" className="m-py-bottom">
                {message}
              </Message>
            )}
            {failureMessage && (
              <Message color="red" className="m-py-bottom">
                {message}
              </Message>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

export default NewUser
