import { createContext } from 'react'
import { IUserBuildingDetail, IUserBuildingDetailsUpdate, IUserCustomerDetail, IUserCustomerDetailsUpdate, IUserSettings, IUserSettingsUpdate, IUserSubscription } from '../interfaces/user'

export interface IUserProfileProps{
    id?: number,
    email?: string,
    firstName?: string,
    lastName?: string,
    role: string,
    street?: string,
    streetNumber?: string,
    postcode?: string,
    city?: string,
    mobileNumber?: string,
    phone?: string,
    isActive?: boolean,
    isSuspended?: boolean
    customerType?: string
    userName?: string
    onboardingCompletedOn?: string
    isGatewayPaired?: boolean
}

export interface IUserContextProps{
    hasOnboarded: boolean,
    profile: IUserProfileProps|null,
    socket: WebSocket|null,
    customerDetail: IUserCustomerDetail,
    buildingDetail: IUserBuildingDetail,
    settingsDetail: IUserSettings,
    subscriptionDetail: IUserSubscription,
    setProfile: (profile: IUserProfileProps|null) => any
    completeOnboarding: () => Promise<void>
    updateCustomerDetails: (data: IUserCustomerDetailsUpdate) => Promise<void>
    updateBuildingDetails: (data: IUserBuildingDetailsUpdate) => Promise<void>
    updateSettings: (data: IUserSettingsUpdate) => Promise<void>
}

const UserContext = createContext<IUserContextProps|undefined>(undefined)

export default UserContext
