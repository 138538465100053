import { hexToRgb } from '../utils'

export const usageGraphColors: any = ['#fbbb21', 'rgba(38, 134, 159)', 'rgba(126,126,126)']
export const lightGreyColorScale: any = ['rgba(204,204,204)']
export const yellowColorScale: any = ['rgba(251,187,33)']
export const darkGreyColorScale: any = ['#7E7E7E']
export const chargedColorScae: any = ['#26869f']
export const dischargedColorScae: any = ['#dc7570']
export const electricityImportColor = '#FFC000'
export const gasImportColor = '#5f5f5f'
export const productionColor = '#FFC864'
export const consumptionColor = '#DC7570'
export const sumElectricityImportColor = '#ed7d31'
export const solarProductionColor = '#ffc104'
export const batteryChargeColor = '#4975c5'
export const totalEnergyConsumption = '#FFC000'
export const electricityExportPowerColor = '#ed7d31'
export const graph2 = '#3CD2A0'
export const yearColor = ['#ffc000', '#dc7570']
export const defaultGraphicData = [
  { x: 1, y: 0 },
  { x: 2, y: 0 },
  { x: 3, y: 0 }
]
export const wantedGraphicData = [
  { x: 1, y: 0 },
  { x: 2, y: 0 },
  { x: 3, y: 100 }
]
