import * as React from 'react'
import * as PropTypes from 'prop-types'
import { Footer } from '../components/index'

const Page: React.FC = ({ children }) => {
  return (
    <>
      {children}
      <Footer />
    </>
  )
}
Page.propTypes = {
  children: PropTypes.node
}
export default Page
