import * as React from 'react'
import './register.scss'
import routes from '../../pages/Routes'

// Component to display message that verification mail has been sent to your email
const VerificationMail: React.FC = () => {
  return (
    <>
      <div className="flex1-center flex1-column m-y-top">
        <strong className="font-medium m-bottom">Aanmelding gelukt!</strong>
        <div>Er is een verificatiemail naar het door u opgegeven emailadres verstuurd.</div>
        <div>Klik op de link in de mail om uw account te bevestigen.</div>
        <div>U kunt nu het tabblad sluiten.</div>
      </div>
    </>
  )
}

export default VerificationMail
