import * as React from 'react'
import { Form, Confirm, Dropdown, Message } from 'semantic-ui-react'
import { useState } from 'react'
import DataContext from '../../../contexts/data'
import { IGasConnectionContractUpdate } from '../../../interfaces/contracts'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { consumerType } from '../../../util/options'
import NumberFormat from 'react-number-format'

const NetworkOperatorGas: React.FC = () => {
  const dataContext = React.useContext(DataContext)
  const [changeValues, setChangeValues] = useState<boolean>(true)
  const [smallUser, setSmallUser] = useState<boolean>(true)
  const [obligationInput, setObligationInput] = useState('obligation-input')
  const [dsoOptions, setDsoOptions] = useState<any[]>([])
  const [gasConnectionTypeOptions, setGasConnectionTypeOptions] = useState<any[]>([])
  const [selectedDsoId, setSelectedDsoId] = useState<number | undefined>(dataContext?.contract.gasConnection.dso.id)
  const [selectedGasConnectionTypeId, setSelectedGasConnectionTypeId] = useState<number | undefined>(
    dataContext?.contract.gasConnection.gasConnectionType.id
  )
  const [consumerTypeOptions, setConsumerTypeOptions] = useState<any[]>([])
  // const [selectedConsumerType, setSelectedConsumerType] = useState<string | undefined>(
  //   dataContext?.contract.electricityGrid.gridConnectionType.consumerType
  // )
  const [selectedConsumerType, setSelectedConsumerType] = useState<string | undefined>(consumerType[0].value)
  const [failureMessage, setFailureMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const [openPopUp, setOpenPopUp] = useState<boolean>(false)

  const close = () => {
    setOpenPopUp(false)
  }
  const onSubmit = async (e: any) => {
    e.preventDefault()
    const payload: IGasConnectionContractUpdate = {
      dsoId: selectedDsoId!,
      gasConnectionTypeId: selectedGasConnectionTypeId!
    }
    try {
      await dataContext?.contract?.updateGasConnectionContract(payload)
      setChangeValues(!changeValues)
      setObligationInput('obligation-input')
      setSuccessMessage('De gasgegevens zijn succesvol verwerkt.')
    } catch (err) {
      setFailureMessage(err.errors[0])
    }
  }
  const onChangeConfirm = () => {
    setChangeValues(!changeValues)
    setObligationInput('')
  }

  const onDsoSelected = (e: any, data: any) => {
    setSelectedDsoId(data.value)
  }

  const onConnectionTypeSelected = (e: any, data: any) => {
    setSelectedGasConnectionTypeId(data.value)
  }
  const onConsumerTypeSelected = (e: any, data: any) => {
    setSelectedConsumerType(data.value)
    if (data.value === 'large') {
      setSmallUser(false)
    } else if (data.value === 'Kleinverbruiker') {
      setSmallUser(true)
    }
  }
  setTimeout(() => {
    setSuccessMessage(null)
    setFailureMessage(null)
  }, 5000)

  const fetchGasTariff = async () => {
    await dataContext?.contract?.fetchGasConnectionTariff(selectedDsoId!, selectedGasConnectionTypeId!)
  }

  React.useEffect(() => {
    fetchGasTariff()
  }, [selectedGasConnectionTypeId])

  React.useEffect(() => {
    fetchGasTariff()
  }, [selectedDsoId])

  React.useEffect(() => {
    const result = dataContext?.contract.dsos.map((dso) => {
      return { key: dso.id, text: dso.name, value: dso.id }
    })
    setDsoOptions(result || [])
  }, [dataContext?.contract.dsos])

  React.useEffect(() => {
    const result = dataContext?.contract?.gasConnectionTypes.map((conn) => {
      return { key: conn.id, text: conn.connectionName, value: conn.id }
    })
    setGasConnectionTypeOptions(result || [])
  }, [dataContext?.contract.gasConnectionTypes])

  React.useEffect(() => {
    const result = dataContext?.contract.consumerTypes.map((con) => {
      return { key: con, text: con, value: con }
    })
    setConsumerTypeOptions(result || [])
  }, [dataContext?.contract.consumerTypes])
  return (
    <div className="energy-supply-container">
      <div className="energy-form-container">
        <hr className="network-m-t" />
        <Form onSubmit={onSubmit} className="form">
          <div className="energy-supply-details flex1-column">
            <div className="flex1-row">
              <p className="energy-supply-detail-names">Netwerkbeheerder</p>
              <Dropdown
                name="networkAdministration"
                options={dsoOptions}
                onChange={onDsoSelected}
                value={selectedDsoId}
                placeholder="stedin"
                selection
                fluid
                disabled={changeValues}
                style={{ marginLeft: '6px', marginRight: '32px' }}
              />
              <strong className="info"></strong>
            </div>
            {/* <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Type</p>
              </div>
              <Dropdown
                name="type"
                options={consumerType}
                onChange={onConsumerTypeSelected}
                value={selectedConsumerType}
                disabled={changeValues}
                selection
                fluid
                style={{ marginLeft: '5px' }}
              />
              <div className="tooltip">
                <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                <div className="tooltiptext" style={{ height: '50px', bottom: '-110%' }}>
                  Grootverbruik is een aansluiting boven de 3x 80A,
                </div>
              </div>
            </div> */}
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Aansluiting</p>
              </div>
              <Dropdown
                name="connection"
                options={gasConnectionTypeOptions}
                onChange={onConnectionTypeSelected}
                value={selectedGasConnectionTypeId}
                placeholder="3x25A"
                disabled={changeValues}
                selection
                fluid
                style={{ marginLeft: '6px', marginRight: '32px' }}
              />
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Tarief</p>
              </div>
              <NumberFormat
                disabled={true}
                value={dataContext?.contract?.gasTariff?.fixedTariffPerYear.toFixed(2) || 0}
                name="connection"
                className="form-item obligation-input number-format"
                style={{ marginRight: '5px' }}
                decimalSeparator={','}
                allowedDecimalSeparators={[',', '.']}
                isNumericString={true}
              />
              <span style={{ marginLeft: '-48px', paddingTop: '4px', opacity: '0.9' }}>€/jaar</span>
              <div className="tooltip"></div>
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Jaar</p>
              </div>
              <input
                disabled={true}
                value={dataContext?.contract?.gasTariff?.year || 0}
                name="year"
                className="form-item obligation-input"
                style={{ marginLeft: '2px', marginRight: '30px' }}
              />
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names"></div>
              {failureMessage && <Message color="red">{failureMessage}</Message>}
              {successMessage && <Message color="green">{successMessage}</Message>}
            </div>
            <div className="flex1-row flex1-end" style={{ marginRight: '30px' }}>
              {changeValues && (
                <button className="btn-blue" onClick={onChangeConfirm}>
                  Wijzig
                </button>
              )}
              {!changeValues && (
                <button className="btn-blue" type="submit">
                  Pas toe
                </button>
              )}
              <Confirm
                content="
                Wil je de data pastoe?"
                cancelButton="Nee"
                confirmButton="Ja"
                open={openPopUp}
                onCancel={close}
                onConfirm={close}
              />
            </div>
          </div>
          {/* {failureMessage && <Message color="red">{failureMessage}</Message>}
          {successMessage && <Message color="green">{successMessage}</Message>} */}
        </Form>
      </div>
    </div>
  )
}

export default NetworkOperatorGas
