import * as React from 'react'
import * as carEmpty from '../../../assets/white_car.svg'
import { Image } from 'semantic-ui-react'
import * as carFull from '../../../assets/full_car.svg'
import { useState } from 'react'
import * as blueUpArrow from '../../../assets/blue_up_arrow.png'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import './assets.scss'
import './electric_auto.scss'

const ElectricCar: React.FC = () => {
  const [expand, setExpand] = useState(false)
  const detailsExpand = () => {
    setExpand(!expand)
  }
  // full empty 105px, filled one 23px then start from 1 px .. each one px can be calculated as one percentage
  const filling = 20
  const fillingPercentage = `${filling}px`
  return (
    <>
      <div className="electrische-auto">
        <div className="asset-container">
          <strong className="title">Elektrische auto</strong>
          <hr />
          <div>
            <div className="electric-car-outer flex1-row space-between">
              <img src={carFull.default} className="assets-size" alt="N\A" />
              <div className="electric-car-inner" style={{ height: fillingPercentage }}>
                <img src={carEmpty.default} className="assets-size" alt="N\A" />
              </div>
              <div className="flex1-column m-y-t">
                <strong className="discharged" style={{ marginBottom: '10px' }}>
                  Laden
                </strong>
                <strong className="discharged" style={{ marginBottom: '10px', fontSize: '18px' }}>
                  44% {''} 135 km
                </strong>
                <div className="reading-info">53 min tot volledig geladen</div>
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical">
            <div className="reading-info" style={{ fontSize: '14px', width: '50px' }}>
              Opladen
            </div>
            <div>
              <label className="switch m-x-l">
                <input type="checkbox" />
                <span className="slider round" />
              </label>
            </div>
          </div>
          <br />
          <div className="flex1-row flex-center-vertical">
            <div className="reading-info" style={{ fontSize: '14px', width: '50px' }}>
              Ontladen
            </div>
            <div>
              <label className="switch m-x-l">
                <input type="checkbox" />
                <span className="slider round" />
              </label>
            </div>
          </div>
        </div>
        <div className="flex1-row m-y-t flex1-end">
          <div className="fle1-row flex-center-vertical" onClick={detailsExpand}>
            <strong className="detail-txt">Details</strong>
            {expand && <Image src={blueUpArrow.default} style={{ marginBottom: '2px' }} />}
            {!expand && <Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
          </div>
        </div>
        <hr />
        {expand && (
          <div className="detail-container">
            <div className="flex1-column ">
              <div className="asset-details flex-center-h-v ">Detail information here</div>
            </div>
          </div>
        )}
        <div className="flex1-row flex1-end">
          <button className="btn-blue">Pas toe</button>
        </div>
      </div>
    </>
  )
}
export default ElectricCar
