import './company_form.scss'
import * as React from 'react'
import { Form, Image } from 'semantic-ui-react'
import * as abonnementLogo from '../../assets/Abonnement.svg'
import UserContext from '../../contexts/user'

type SubscriptionForm = {
  cidNumber: string
  startdate: string
  einddate: string
  community: string
  gatewayNo: string
}
// Component to get all the details of the customer
const Subscription: React.FC = () => {
  const userContext = React.useContext(UserContext)
  return (
    // <div>Subscription form</div>
    <React.Fragment>
      <div className="customer-detail-container" id="company-container">
        <div className="customer-detail-form-container company-form">
          <div className="flex1-row">
            <Image src={abonnementLogo.default} className="customer-detail-logo-size"></Image>
            <strong className="customer-details-header" style={{ fontWeight: 'bold', fontSize: '22px' }}>
              Abonnement
            </strong>
          </div>
          <hr></hr>
          <Form>
            <div className="customer-details flex1-column">
              <div className="flex1-row">
                <p className="customer-detail-names">User-nummer</p>
                <Form.Field>
                  <input
                    disabled
                    defaultValue={userContext?.subscriptionDetail.userId ? userContext?.subscriptionDetail.userId : ''}
                    className="form1-item obligation-input"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names">Startdatum</p>
                <Form.Field>
                  <input
                    disabled
                    defaultValue={userContext?.subscriptionDetail.subscriptionStart ? userContext?.subscriptionDetail.subscriptionStart : ''}
                    name="startdate"
                    className="form1-item obligation-input"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p>Einddatum</p>
                </div>
                <Form.Field>
                  <input
                    disabled
                    defaultValue={userContext?.subscriptionDetail.subscriptionEnd ? userContext?.subscriptionDetail.subscriptionEnd : ''}
                    name="einddate"
                    className="form1-item obligation-input"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p>Community</p>
                </div>
                <Form.Field>
                  <input
                    disabled
                    defaultValue={userContext?.subscriptionDetail.communityName ? userContext?.subscriptionDetail.communityName : ''}
                    name="community"
                    className="form1-item obligation-input"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p>Gateway nr.</p>
                </div>
                <Form.Field>
                  <input
                    disabled
                    defaultValue={userContext?.subscriptionDetail.gatewayNumber ? userContext?.subscriptionDetail.gatewayNumber : ''}
                    name="gatewayNo"
                    className="form1-item obligation-input"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row flex1-end m-py-top-subscription">
                <button className="m-px-application btn-blue">Pas toe</button>
                {/* <Confirm
                  content="
                  Wil je de data opslaan?"
                  cancelButton="Nee"
                  confirmButton="Ja"
                  open={openPopUp}
                  onCancel={close}
                  onConfirm={close}
                /> */}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Subscription
