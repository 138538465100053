import * as React from 'react'
import * as houseLogo from '../../assets/House.svg'
import { industryTypeOptions , houseTypeOptions} from '../../util/options'
import { Image, Form, Dropdown, Message } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import './company_form.scss'
import UserContext from '../../contexts/user'

// Bedrijfstak is industry in english
// Oppervlak - Surface
// Inhoud Gebouw- contentBuilding
// Verwarming - heating
type BuildingForm = {
  industry: string
  yearBuilt: string
  surface: string
  contentBuilding: string
  energyLabel: string
  heating: string
}
// Component to get all the details of the customer
const Building: React.FC = () => {
  // const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const { register, handleSubmit, errors } = useForm<BuildingForm>()
  // const change = (event: React.SyntheticEvent<HTMLSelectElement>) => {
  //   const input = event.target as HTMLSelectElement
  //   const dropDownChange: string = input.value
  // }

  const userContext = React.useContext(UserContext)

  const [formData, setFormData] = useState<any>({})
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [selectedHouseType, setSelectedHouseType] = useState<string | null>(null)
  const [selectedIndustryType, setSelectedIndustryType] = useState<string | null>(null)
  const [selectedEnergyLabel, setSelectedEnergyLabel] = useState<string | null>(null)
  const [selectedHeatingType, setSelectedHeatingType] = useState<string | null>(null)

  

  const energyLabelOptions = [
    { key: 'none', text: 'Not Selected', value: null },
    { key: 'A', value: 'A', text: 'A' },
    { key: 'B', text: 'B', value: 'B' }
  ]
  const heatingTypeOptions = [
    { key: 'none', text: 'Not Selected', value: null },
    { key: 'gasBoiler', text: 'Gas CV', value: 'Gas boiler' },
    { key: 'electricBoiler', text: 'Elektrische boiler', value: 'Electric boiler' }
  ]

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const data = { ...formData }
    delete data.createdOn
    delete data.lastUpdatedOn
    data.energyLabel = selectedEnergyLabel
    data.heatingType = selectedHeatingType
    data.houseType = selectedHouseType
    data.industryType = selectedIndustryType
    try {
      await userContext?.updateBuildingDetails(data)
      setSuccessMessage('Gegevens succesvol bijgewerkt.')
    } catch (err) {
      setErrorMessage(err.errors[0])
    }
  }

  const onInputChange = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setFormData({ ...formData, [name]: value })
  }

  React.useEffect(() => {
    setFormData({ ...userContext?.buildingDetail })
    setSelectedHouseType(userContext?.buildingDetail.houseType || null)
    setSelectedIndustryType(userContext?.buildingDetail.industryType || null)
    setSelectedEnergyLabel(userContext?.buildingDetail.energyLabel || null)
    setSelectedHeatingType(userContext?.buildingDetail.heatingType || null)
  }, [userContext?.buildingDetail])

  setTimeout(() => {
    setErrorMessage('')
    setSuccessMessage('')
  }, 5000)

  return (
    // <div>Building Form</div>
    <React.Fragment>
      <div className="customer-detail-container" id="company-container">
        <div className="customer-detail-form-container company-form">
          <div className="flex1-row">
            <Image src={houseLogo.default} className="customer-detail-logo-size"></Image>
            <strong className="customer-details-header" style={{ fontWeight: 'bold', fontSize: '22px' }}>
              Gebouw
            </strong>
          </div>
          <hr></hr>
          <Form onSubmit={onSubmit}>
            <div className="customer-details flex1-column">
              {userContext?.profile?.customerType === 'business' && (
                <div className="flex1-row">
                  <p className="customer-detail-names">Bedrijfstak</p>
                  <Form.Field className="flex1-row">
                    <Dropdown
                      placeholder=""
                      selection
                      onChange={(e, data) => setSelectedIndustryType(data.value)}
                      value={selectedIndustryType}
                      name="industryType"
                      options={industryTypeOptions}
                      className="form1-item"
                    />
                    {errors.industry && errors.industry.type === 'required' && (
                      <div className="error-msg">U moet uw Bedrijfstak invoeren.</div>
                    )}
                  </Form.Field>
                </div>
              )}
              {userContext?.profile?.customerType === 'private' && (
                <div className="flex1-row">
                  <p className="customer-detail-names">Soort woning</p>
                  <Form.Field className="flex1-row">
                    <Dropdown
                      placeholder=""
                      selection
                      name="houseType"
                      onChange={(e, data) => setSelectedHouseType(data.value)}
                      value={selectedHouseType}
                      options={houseTypeOptions}
                      className="form1-item"
                    />
                    {errors.industry && errors.industry.type === 'required' && (
                      <div className="error-msg">U moet uw Soort woning invoeren.</div>
                    )}
                  </Form.Field>
                </div>
              )}
              <div className="flex1-row">
                <p className="customer-detail-names">Bouwjaar</p>
                <Form.Field>
                  <input
                    value={formData.builtYear ? formData.builtYear : ''}
                    onChange={onInputChange}
                    name="builtYear"
                    className="form1-item"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p>Oppervlak in m2</p>
                </div>
                <Form.Field>
                  <input
                    value={formData.surfaceM2 ? formData.surfaceM2 : ''}
                    onChange={onInputChange}
                    name="surfaceM2"
                    className="form1-item"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p>Inhoud gebouw m3</p>
                </div>
                <Form.Field>
                  <input
                    value={formData.volumeM3 ? formData.volumeM3 : ''}
                    onChange={onInputChange}
                    name="volumeM3"
                    className="form1-item"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names">Energielabel</p>
                <Form.Field className="flex1-row">
                  <Dropdown
                    placeholder=""
                    selection
                    onChange={(e, data) => {
                      setSelectedEnergyLabel(data.value)
                    }}
                    value={selectedEnergyLabel}
                    options={energyLabelOptions}
                    className="form1-item"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names">Verwarming</p>
                <Form.Field className="flex1-row">
                  <Dropdown
                    placeholder=""
                    selection
                    name="heatingType"
                    onChange={(e, data) => setSelectedHeatingType(data.value)}
                    value={selectedHeatingType}
                    options={heatingTypeOptions}
                    className="form1-item"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row flex1-end m-py-bottom  m-py-top-building">
                <button type="submit" className="m-px-application btn-blue">
                  Pas toe
                </button>
              </div>
              {errorMessage && errorMessage.length > 0 && <Message color="red">{errorMessage}</Message>}
              {successMessage && successMessage.length > 0 && <Message color="green">{successMessage}</Message>}
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Building
