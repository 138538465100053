import api from '../api'

export default class DsoService {
  getGridTariffs = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.get('/dsos/grid', config)
    const data = res.data
    return parseGridTariffs(data)
  }

  updateGridTariffs = async (accessToken: string, gridTariffs: any[]) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.put('/dsos/grid', gridTariffs, config)
    const data = response.data
    return parseGridTariffs(data)
  }

  getGridConnectionTypes = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.get('/dsos/grid/connection_types', config)
    const data = res.data
    const parsedData: any[] = []
    data.map((connType: any) => {
      parsedData.push({ key: connType.id, value: connType.id, text: connType.connectionName })
    })
    return parsedData
  }

  getGasTariffs = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.get('/dsos/gas', config)
    const data = res.data
    return parseGasTariffs(data)
  }

  updateGasTariffs = async (accessToken: string, gasTariffs: any[]) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.put('/dsos/gas', gasTariffs, config)
    const data = response.data
    return parseGasTariffs(data)
  }

  getGasConnectionTypes = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.get('/dsos/gas/connection_types', config)
    const data = res.data
    const parsedData: any[] = []
    data.map((connType: any) => {
      parsedData.push({ key: connType.id, value: connType.id, text: connType.connectionName })
    })
    return parsedData
  }

  getDsos = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.get('/dsos/', config)
    const data = res.data
    const parsedData: any[] = []
    data.map((dso: any) => {
      parsedData.push({ key: dso.id, value: dso.id, text: dso.name })
    })
    return parsedData
  }
}

const parseGasTariffs = (data: any[]) => {
  const parsedData: any[] = []
  const years: Set<number> = new Set<number>()
  data.map((tariff: any) => {
    const name = tariff.dso.name
    const size = tariff.gasConnectionType.connectionName
    const connectionTypeId = tariff.gasConnectionType.id
    const year = tariff.year
    const value = tariff.fixedTariffPerYear
    const tariffId = tariff.id
    const dsoId = tariff.dso.id

    years.add(year)

    const entry: any = parsedData.filter((item) => {
      return item.name.name === name && item.size.name === size
    })[0]
    if (entry === undefined) {
      const newObj: any = {
        name: {
          name: name,
          id: dsoId,
          ids: []
        },
        size:
        {
          name: size,
          id: connectionTypeId,
          ids: []
        },
        years: {}
      }
      newObj.years[year] = { value: value, ids: [tariffId] }
      newObj.name.ids.push(tariffId)
      newObj.size.ids.push(tariffId)
      parsedData.push(newObj)
    } else {
      entry.years[year] = { value: value, ids: [tariffId] }
      entry.name.ids.push(tariffId)
      entry.size.ids.push(tariffId)
    }
  })
  return { tariffs: parsedData, years: Array.from(years).sort() }
}

const parseGridTariffs = (data: any[]) => {
  const parsedData: any[] = []
  const years: Set<number> = new Set<number>()
  data.map((tariff: any) => {
    const name = tariff.dso.name
    const size = tariff.gridConnectionType.connectionName
    const connectionTypeId = tariff.gridConnectionType.id
    const year = tariff.year
    const value = tariff.fixedTariffPerYear
    const tariffId = tariff.id
    const dsoId = tariff.dso.id

    years.add(year)
    const entry: any = parsedData.filter((item) => {
      return item.name.name === name && item.size.name === size
    })[0]
    if (entry === undefined) {
      const newObj: any = {
        name: {
          name: name,
          id: dsoId,
          ids: []
        },
        size: {
          name: size,
          id: connectionTypeId,
          ids: []
        },
        years: {}
      }
      newObj.years[year] = { value: value, ids: [tariffId] }
      newObj.name.ids.push(tariffId)
      newObj.size.ids.push(tariffId)
      parsedData.push(newObj)
    } else {
      entry.years[year] = { value: value, ids: [tariffId] }
      entry.size.ids.push(tariffId)
      entry.name.ids.push(tariffId)
    }
  })
  return { tariffs: parsedData, years: Array.from(years).sort() }
}
