import api from '../api'

export default class CommunityService {
  listCommunities = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get('/communities/', config)
    return response.data
  }

  createCommunity = async (accessToken: string, data: any) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.post('/communities/', data, config)
    return response.data
  }

  updateCommunity = async (accessToken: string, communityId: number, data: any) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.put(`/communities/${communityId}`, data, config)
    return response.data
  }

  fetchCommunitySubusers = async (accessToken: string, communityId: number) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get(`/communities/${communityId}/subusers`, config)
    return response.data
  }
}
