import * as React from 'react'
import { Grid, Segment, Modal, Header, Button } from 'semantic-ui-react'
import { UsageGraph, Battery, Electricity, Gas, ElectricCar, SolarPanel, SmartMeterSearch } from '../..'
import DataContext from '../../../contexts/data'
import ConsumerTypeService from '../../../services/consumerType'
import OnboardService from '~/services/onboard'
import AuthContext from '../../../contexts/auth'
import './monitor.scss'
import UserContext from '../../../contexts/user'
import * as constants from '../../../constants'
import { SetupMainMeterDeviceDataStatus, StatusType, ConsumerType, MainMeterApplicaiton } from '~/enums'
import MainMeterApplicationsMenu from './components/MainMeterApplicationsMenu'
import SmartMeterConfirmation from './smart_meter_confirmation'
import { IDeviceLibrary } from '~/interfaces/device'

const Monitor: React.FC = () => {
  const authContext = React.useContext(AuthContext)
  const dataContext = React.useContext(DataContext)
  const userContext = React.useContext(UserContext)

  const [tileExpanded, setTileExpanded] = React.useState('')
  const [deviceData, setDeviceData] = React.useState<any>(null)
  const [deviceErrorMessage, setDeviceErrorMessage] = React.useState<string>('')
  const [applicationLibraries, setApplicationLibraries] = React.useState<any[]>([])
  const [selectedApplicationLibrary, setSelectedApplicationLibrary] = React.useState<any>()
  const [consumerTypeData, setConsumerTypeData] = React.useState<any>(null)

  const onboardService = new OnboardService()

  const setupMainMeterDevice = async () => {
    const token = authContext?.accessToken!
    try {
      await onboardService.setupMainMeterDevice(token)
    } catch (err) {
      console.error()
      console.log(err.error)
    }
  }


  const setupDeviceError = 'Unable to fetch devices please ensure your gateway is connected and try again'
  const defaultOnConsumerFetchError = 'Probeer het opnieuw door de gateway en de meter goed aan te sluiten.'

  const userHasSmartmerter = () => !!dataContext?.device.activeUserMainMeterDevice

  const userHasGas = () => !!dataContext?.device.activeUserMainMeterDevice?.isGasConnected

  const userHasHybridInverter = () => !!dataContext?.device.activeUserHybridInverterDevice

  const userHasElectricCar = () => false

  const onRetry = async () => {
    // setConsumerTypeErrorMessage('')
    // await fetchConsumerType()
    setDeviceErrorMessage('')
    await setupMainMeterDevice()
  }

  const onSetupLibrary = async (library: IDeviceLibrary) => {
    try {
      await onboardService.setupMainMeterLibrary(authContext?.accessToken!, library.id, deviceData.commParameters);
      await userContext?.completeOnboarding();
    } catch (err) { }
  }

  const getLargeConsumerLibrary = (libraries: IDeviceLibrary[]) => {
    for (const library of libraries) {
      if (library.application === MainMeterApplicaiton.ELECTRICITY_METER_LARGE_CONSUMER) {
        return library
      }
    }
  }

  const getSmallConsumerLibrary = (libraries: IDeviceLibrary[]) => {
    for (const library of libraries) {
      if (library.application != MainMeterApplicaiton.ELECTRICITY_METER_LARGE_CONSUMER) {
        return library
      }
    }
  }

  React.useEffect(() => {
    if (!!userContext?.profile && !!userContext?.socket && userContext?.profile.onboardingCompletedOn === null) {
      userContext?.socket?.addEventListener('message', (event) => {
        const data = JSON.parse(event.data)
        const eventType = data.eventType
        if (eventType === constants.SetupMainMeterDeviceResponse) {
          if (data.status === StatusType.Success) {
            const responseData = data.data
            if (responseData == null) {
              setDeviceErrorMessage(setupDeviceError)
              // setConsumerTypeErrorMessage(defaultOnConsumerFetchError)
            } else {
              setDeviceData(responseData)
              // setConsumerTypeData(responseData)
            }
          } else {
            setDeviceErrorMessage(setupDeviceError)
            // setConsumerTypeErrorMessage(defaultOnConsumerFetchError)
          }
        }
      })
      // fetchConsumerType()
      setupMainMeterDevice()
    }
  }, [userContext?.profile, userContext?.socket])

  React.useEffect(() => {
    if (deviceData) {
      switch (deviceData.status) {
        case SetupMainMeterDeviceDataStatus.ADDED:
          userContext?.completeOnboarding()
        case SetupMainMeterDeviceDataStatus.ACTION_REQUIRED:
          setApplicationLibraries(deviceData.libraries)
        default:
      }
    }
  }, [deviceData])


  return (
    <>
      {/* <SmartMeterConfirmation largeConsumerLibrary={getLargeConsumerLibrary(applicationLibraries)} smallConsumerLibrary={getSmallConsumerLibrary(applicationLibraries)} onSelect={onSetupLibrary} /> */}
      {!!userContext?.profile && userContext?.profile.onboardingCompletedOn === null && !deviceData && (
        <SmartMeterSearch title="Zoeken naar slimme meter" error={deviceErrorMessage} onRetry={onRetry} />
      )}
      {!!userContext?.profile && userContext?.profile.onboardingCompletedOn === null && !!applicationLibraries && applicationLibraries.length == 2 && <SmartMeterConfirmation largeConsumerLibrary={getLargeConsumerLibrary(applicationLibraries)} smallConsumerLibrary={getSmallConsumerLibrary(applicationLibraries)} onSelect={onSetupLibrary} />}
      {!!userContext?.profile && userContext?.profile.onboardingCompletedOn === null && !!applicationLibraries && applicationLibraries.length > 2 && <Modal
        closeIcon
        open={!!applicationLibraries}
      >
        <Header content="Select Meter Application" />
        <Modal.Content>
          <div>
            <MainMeterApplicationsMenu
              libraries={applicationLibraries}
              onSelect={(library) => setSelectedApplicationLibrary(library)}
              selectedLibrary={selectedApplicationLibrary} />
            <Button onClick={() => onSetupLibrary(selectedApplicationLibrary)}>Submit</Button>
          </div>
        </Modal.Content>
      </Modal>}
      {!!userContext?.profile && userContext?.profile.onboardingCompletedOn !== null && (
        <Grid columns={3} stackable>
          <Grid.Row>
            {userHasSmartmerter() && (
              <Grid.Column className="grid-column-height ">
                <Segment className="grid-size">
                  <UsageGraph />
                </Segment>
              </Grid.Column>
            )}
            {tileExpanded === '' && (
              <>
                <Grid.Column className="grid-column-width">
                  {userHasSmartmerter() && (
                    <Segment className="grid-height-half">
                      <Electricity setTileExpanded={setTileExpanded} tileExpanded={tileExpanded} />
                    </Segment>
                  )}
                  {userHasHybridInverter() && (
                    <Segment className="grid-height-half" id="battery">
                      <Battery setTileExpanded={setTileExpanded} tileExpanded={tileExpanded} />
                    </Segment>
                  )}
                </Grid.Column>

                <Grid.Column className="grid-column-width">
                  {!!dataContext?.device.activeUserMainMeterDevice &&
                    dataContext?.device.activeUserMainMeterDevice.consumerType === ConsumerType.Small &&
                    userHasGas() && (
                      <Segment className="grid-height-half">
                        <Gas setTileExpanded={setTileExpanded} tileExpanded={tileExpanded} />
                      </Segment>
                    )}
                  {userHasElectricCar() && (
                    <Segment className="grid-height-half">
                      <ElectricCar />
                    </Segment>
                  )}
                  {userHasHybridInverter() && (
                    <Segment className="grid-height-half" id={!userHasGas() ? '' : 'solar'}>
                      <SolarPanel setTileExpanded={setTileExpanded} tileExpanded={tileExpanded} />
                    </Segment>
                  )}
                </Grid.Column>
              </>
            )}
            {tileExpanded === 'battery' && (
              <>
                <Grid.Column>
                  <Segment className="grid-expanded">
                    <Battery setTileExpanded={setTileExpanded} tileExpanded={tileExpanded} />
                  </Segment>
                </Grid.Column>
              </>
            )}
            {tileExpanded === 'solar' && (
              <>
                <Grid.Column>
                  <Segment className="grid-expanded">
                    <SolarPanel setTileExpanded={setTileExpanded} tileExpanded={tileExpanded} />
                  </Segment>
                </Grid.Column>
              </>
            )}
            {tileExpanded === 'electricity' && (
              <>
                <Grid.Column>
                  <Segment className="grid-expanded">
                    <Electricity setTileExpanded={setTileExpanded} tileExpanded={tileExpanded} />
                  </Segment>
                </Grid.Column>
              </>
            )}
            {tileExpanded === 'gas' && (
              <>
                <Grid.Column>
                  <Segment className="grid-expanded">
                    <Gas setTileExpanded={setTileExpanded} tileExpanded={tileExpanded} />
                  </Segment>
                </Grid.Column>
              </>
            )}
          </Grid.Row>
        </Grid>
      )}
    </>
  )
}
export default Monitor
