import * as React from 'react'
import { useContext, useState, useEffect } from 'react'
import AuthContext from '../../../../contexts/auth'
import DsoService from '../../../../services/dso'
import { Table, Dropdown, Input } from 'semantic-ui-react'

interface IGasProp {
  gasTariffs: any[]
  dsos: any[]
  years: number[]
  editGasTariffs: any
  inEditMode: any
  handleTariffsChange: Function
  handleTariffsClick: Function
}

const Gas: React.FC<IGasProp> = (props: IGasProp) => {
  const dsoService = new DsoService()
  const authContext = useContext(AuthContext)

  const [connTypes, setConnTypes] = useState<any[]>([])

  const fetchConnTypes = async () => {
    const accessToken = authContext?.accessToken!
    const result = await dsoService.getGasConnectionTypes(accessToken)
    setConnTypes(result)
  }

  const handleClickEvent = (ids: number[], field: string, value: number) => {
    props.handleTariffsClick(ids, field, value)
  }

  const handleTariffChange = (ids: number[], field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleTariffsChange(ids, field, event.target.value)
  }

  const handleSelectChange = (ids: number, field: string, _event: any, data: any) => {
    props.handleTariffsChange(ids, field, data.value)
  }

  useEffect(() => {
    fetchConnTypes()
  }, [])

  return (
    <>
      <div className="dso-container">
        {props.gasTariffs.length > 0 && (
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="3">Name</Table.HeaderCell>
                <Table.HeaderCell width="3">Size</Table.HeaderCell>
                {props.years.map((year, i) => {
                  return (
                    <Table.HeaderCell key={i} width="2">
                      {year}
                    </Table.HeaderCell>
                  )
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.gasTariffs.map((row: any, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell
                      className={
                        row.size.ids.every((id: number) => {
                          return props.inEditMode.ids.includes(id)
                        }) &&
                        row.size.ids.every((id: number) => {
                          return 'dsoId' in props.editGasTariffs[id]
                        })
                          ? 'dirty'
                          : ''
                      }
                    >
                      {props.inEditMode.status &&
                      Object.keys(props.editGasTariffs).length > 0 &&
                      row.size.ids.every((id: number) => {
                        return props.inEditMode.ids.includes(id) &&
                          id in props.editGasTariffs &&
                          'dsoId' in props.editGasTariffs[id]
                      }) ? (
                          <Dropdown
                            selection
                            options={props.dsos}
                            className="tableInput"
                            value={props.editGasTariffs[row.size.ids[0]].dsoId}
                            onChange={handleSelectChange.bind(this, row.size.ids, 'dsoId')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, row.size.ids, 'dsoId', row.name.id)}
                          >
                            {row.name.name}
                          </p>
                        )}
                    </Table.Cell>
                    <Table.Cell
                      className={
                        row.size.ids.every((id: number) => {
                          return props.inEditMode.ids.includes(id)
                        }) &&
                        row.size.ids.every((id: number) => {
                          return id in props.editGasTariffs &&
                            'gasConnectionTypeId' in props.editGasTariffs[id]
                        })
                          ? 'dirty'
                          : ''
                      }
                    >
                      {props.inEditMode.status &&
                      Object.keys(props.editGasTariffs).length > 0 &&
                      row.size.ids.every((id: number) => {
                        return props.inEditMode.ids.includes(id) &&
                          id in props.editGasTariffs &&
                          'gasConnectionTypeId' in props.editGasTariffs[id]
                      }) ? (
                          <Dropdown
                            selection
                            options={connTypes}
                            className="tableInput"
                            value={props.editGasTariffs[row.size.ids[0]].gasConnectionTypeId}
                            onChange={handleSelectChange.bind(this, row.size.ids, 'gasConnectionTypeId')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, row.size.ids, 'gasConnectionTypeId', row.size.id)}
                          >
                            {row.size.name}
                          </p>
                        )}
                    </Table.Cell>
                    {props.years.map((year: number, i) => {
                      return (
                        <Table.Cell
                          key={i}
                          className={
                            year in row.years &&
                            row.years[year].ids.every((id: number) => {
                              return props.inEditMode.ids.includes(id)
                            }) &&
                            row.years[year].ids.every((id: number) => {
                              return id in props.editGasTariffs &&
                                'fixedTariffPerYear' in props.editGasTariffs[id]
                            })
                              ? 'dirty'
                              : ''
                          }
                        >
                          {year in row.years ? (
                            props.inEditMode.status &&
                            props.inEditMode.ids.includes(row.years[year].ids[0]) &&
                            'fixedTariffPerYear' in props.editGasTariffs[row.years[year].ids[0]] ? (
                                <Input
                                  transparent
                                  className="tableInput"
                                  type="number"
                                  value={props.editGasTariffs[row.years[year].ids[0]].fixedTariffPerYear}
                                  onChange={handleTariffChange.bind(this, row.years[year].ids, 'fixedTariffPerYear')}
                                />
                              ) : (
                                <p
                                  className="editableElement"
                                  onClick={handleClickEvent.bind(
                                    this,
                                    row.years[year].ids,
                                    'fixedTariffPerYear',
                                    row.years[year].value
                                  )}
                                >
                                  {parseFloat(row.years[year].value.toFixed(4))}
                                </p>
                              )
                          ) : (
                            <p></p>
                          )}
                        </Table.Cell>
                      )
                    })}
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  )
}

export default Gas
