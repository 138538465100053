import * as React from 'react'
import cn from 'classnames'
import './main_meter_application_menu.scss'
import { MainMeterApplicationEnumDisplayMapping } from '~/mappings'

interface MainMeterApplicationMenuProps {
    libraries: any[]
    selectedLibrary: any
    onSelect: (library: any) => any
}

interface LibraryItemProps {
    library: any
    onClick: () => any
    className?: string
}

const MainMeterLibraryOptionItem = ({ library, className, onClick }: LibraryItemProps) => (
    <div className={cn('main_meter_library_option_item', className)} onClick={onClick}>
        <div className="main_meter_library_option_item-make">{library.make}</div>
        <div className="main_meter_library_option_item-model">{library.model}</div>
        <div className="main_meter_library_option_item-application">{MainMeterApplicationEnumDisplayMapping[library.application]}</div>
    </div>
)

const MainMeterApplicationMenu = ({ libraries, selectedLibrary, onSelect }: MainMeterApplicationMenuProps) => {
    return (
        <div className="main_meter_application_menu">
            {
                libraries.map((library, index) => (
                    <MainMeterLibraryOptionItem
                        className={cn("main_meter_application_menu-library_item", { "main_meter_application_menu-library_item-selected": (selectedLibrary?.id === library.id) })}
                        library={library}
                        key={index}
                        onClick={() => onSelect(library)} />
                ))
            }
        </div>
    )
}

export default MainMeterApplicationMenu
