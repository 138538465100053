import * as React from 'react'
import { Checkbox } from 'semantic-ui-react'
import './smart_meter_search.scss'

interface IDetailsProps {
  add: (choice: boolean) => any
}
interface SmartMeterConfirmationProps {
  largeConsumerLibrary: any,
  smallConsumerLibrary: any,
  onSelect: (library: any) => any
}

const SmartMeterConfirmation = (props: SmartMeterConfirmationProps) => {
  return (
    <div className="large-consumer-sm-container">
      <div className="content-center">
        <strong className="bold">Er is geen slimme meter gevonden.</strong>
        <div className="bold">Heeft u een grootverbruik aansluiting voor stroom?</div>
        <div className="flex1-column m-t-large">
          <div className="flex1-row">
            <div style={{ width: '46%' }}>Ja</div>
            <div style={{ width: '50px' }}></div>
            <Checkbox style={{ width: '50%' }} onClick={() => props.onSelect(props.largeConsumerLibrary)} />
          </div>
          <div className="flex1-row m-t-large">
            <div style={{ width: '46%' }}>Nee</div>
            <div style={{ width: '50px' }}></div>
            <Checkbox style={{ width: '50%' }} onClick={() => props.onSelect(props.smallConsumerLibrary)} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default SmartMeterConfirmation
