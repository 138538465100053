import * as React from 'react'
import { dateFunction } from '../utils'
import { useState, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import * as PropTypes from 'prop-types'
import routes from '../pages/Routes'

type SomeComponentProps = RouteComponentProps
const Date: React.FC<SomeComponentProps> = ({ location }) => {
  const path = location.pathname
 
  const [date, setDate] = useState(dateFunction())
  useEffect(() => {
    const interval = setInterval(() => setDate(dateFunction()), 1000)
    return () => clearInterval(interval)
  }, [date])
  return <div style={{ fontSize: '14px' }}>{path === routes.CREATEACCOUNT ? '' : date}</div>
}
Date.propTypes = {
  location: PropTypes.any.isRequired
}
export default withRouter(Date)
