import * as React from 'react'
import { arc } from 'd3-shape'

import './charts.scss'

interface IGaugeProps {
  value: number,
  min: number,
  max: number,
  threshold: number,
  lessIsMore: boolean
}

const Gauge: React.FC<IGaugeProps> = ({ value, min, max, lessIsMore, threshold }) => {
  const goodColour = '#3CD2A0'
  const badColour = '#DC7570'
  const backgroundColour = '#d3d3d3'

  const inTheRed = lessIsMore ? (
    (value - min) / (max - min) >= threshold
  ) : (
    (value - min) / (max - min) <= threshold
  )

  const arcGen = arc()

  const outerBackgroundArc = arcGen({
    innerRadius: 0.95,
    outerRadius: 1,
    startAngle: -2,
    endAngle: 2
  }) || ''

  const outerArc = arcGen({
    innerRadius: 0.95,
    outerRadius: 1,
    startAngle: lessIsMore ? -2 + 4 * threshold : -2,
    endAngle: lessIsMore ? 2 : -2 + 4 * threshold
  }) || ''

  const backgroundArc = arcGen({
    innerRadius: 0.6,
    outerRadius: 0.94,
    startAngle: -2,
    endAngle: 2
  }) || ''

  const filledArc = arcGen({
    innerRadius: 0.6,
    outerRadius: 0.94,
    startAngle: -2,
    endAngle: -2 + ((value - min) / (max - min)) * 4
  }) || ''

  return (
    <div>
      <svg
        width="70%"
        viewBox="-1 -1 2 1.5"
      >
        <path
          d={outerBackgroundArc}
          fill={goodColour}
        />
        <path
          d={outerArc}
          fill={badColour}
        />
        <path
          d={backgroundArc}
          fill={backgroundColour}
        />
        <path
          d={filledArc}
          fill={inTheRed ? badColour : goodColour }
        />
        <text
          id="gauge-value"
          x="0"
          y="0.2"
          dominantBaseline="middle"
          textAnchor="middle"
          fill={inTheRed ? badColour : goodColour }
        >{value}</text>
      </svg>
    </div>
  )
}

export default Gauge
