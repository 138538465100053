export enum MainMeterApplicaiton {
  SMART_METER = 'SMART_METER',
  ELECTRICITY_METER_SMALL_CONSUMER = 'ELECTRICITY_METER_SMALL_CONSUMER',
  SMART_GAS_METER_SMALL_CONSUMER = 'SMART_GAS_METER_SMALL_CONSUMER',
  ELECTRICITY_METER_LARGE_CONSUMER = 'ELECTRICITY_METER_LARGE_CONSUMER',
}

export enum SmartControllerApplicaiton {
  GENERIC = 'generic',
  BOILER = 'boiler',
  SPACE_HEATER = 'space_heater',
}

export enum HybridInverterApplication {
  HYBRID_INVERTER = 'hybrid_inverter',
}

export enum SmartControllerApplianceType {
  LAMP = 'lamp',
  HEATER = 'heater',
  WASHING_MACHINE = 'washing_machine',
  DRYER = 'dryer',
  ELECTRIC_BOILER = 'electric_boiler',
  COOKING_STOVE = 'cooking_stove',
  OTHERS = 'others',
}
export enum SmartControllerApplianceTypeDutch {
  LAMP = 'Lamp',
  HEATER = 'Elektrische verwarming',
  WASHING_MACHINE = 'Wasmachine',
  DRYER = 'Droger',
  COOKING_STOVE = 'Elektrische kookplaat',
  OTHERS = 'Anders',
}
export enum DutchBatteryState {
  Inactief = 'Inactief',
  Opladen = 'Opladen',
  Ontladen = 'Ontladen',
}

export enum EnBatteryState {
  Idle = 'idle',
  Charging = 'charging',
  Discharging = 'discharging',
}

export enum ConsumerType {
  Small = 'small',
  Large = 'large',
}

export enum electricityTariffType {
  Single = 'single',
  Double = 'double',
}

export enum electricityTariffTypeDutch {
  Single = 'Enkeltarief',
  Double = 'Dubbeltarief',
}

export enum StatusType {
  Success = 'success',
  Failure = 'failure',
}

export enum clientType {
  Private = 'private',
  Business = 'business',
}
export enum clientTypeDutch {
  Private = 'Particulier',
  Business = 'Commercieel',
}
export enum industryTypeDutch {
  Private = 'Particulier',
  Business = 'Commercieel',
}
export enum toolTipText {
  Inclusive = 'Tarieven voor particulieren worden inclusief BTW weergegeven',
  Exclusive = 'Tarieven voor particulieren worden exclusief BTW weergegeven',
}

export enum ApplicationGrouping {
  GROUP_LEVEL = 'group',
  MAKE_LEVEL = 'make',
  MODEL_LEVEL = 'model',
}

export enum SetupMainMeterDeviceDataStatus {
  ADDED = 'added',
  ACTION_REQUIRED = 'action_required',
}

export enum Mounting {
  SOCKET = 'socket',
  DIN_RAIL = 'dinrail',
}
export enum AssetTiles {
  BOILER = 'Boiler',
  MAIN_METER = 'MainMeter',
  SMART_PLUG = 'SmartPlug',
  SPACE_HEATER = 'SpaceHeater',
  HYBRID_INVERTER = 'HybridInverter',
}
