import * as React from 'react'
import { Dropdown, Form, Table, TableBody, TableRow, Message, Button, Modal, Header } from 'semantic-ui-react'
import { BsFillCaretRightFill, BsFillCaretDownFill } from 'react-icons/bs'
import './community_details.scss'
import CommunityService from '../../../services/community'
import * as communityManagerService from '../../../services/communityManager'
import UserService from '../../../services/user'
import AuthContext from '../../../contexts/auth'
import { normalizeCase } from '../../../util/strings'
import { useContactPersonOptions } from '../../../util/options'
import { useHistory } from 'react-router'

interface ICommunityDetailsProp {
  community: any
}

const CommunityDetails: React.FC<ICommunityDetailsProp> = ({ community }) => {
  const initialCommunityManagerData = {
    useContactPerson: '',
    firstName: '',
    lastName: '',
    email: ''
  }
  const authContext = React.useContext(AuthContext)
  const history = useHistory()
  const [communityData, setCommunityData] = React.useState<any>(community)
  const [managerDataList, setManagerDataList] = React.useState<any[]>([])
  const [communityManagerData, setCommunityManagerData] = React.useState<any>()
  const [selectedUserContactOption, setSelectedUserContactOption] = React.useState<string>('Yes')
  const [showAddManagerForm, setShowAddManagerForm] = React.useState<boolean>(false)
  const communityService = new CommunityService()
  const userService = new UserService()
  const [successMessage, setSuccessMessage] = React.useState<string>('')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [expandedManagerId, setExpandedManagerId] = React.useState<number>(0)
  const [urlModalHeader, setUrlModalHeader] = React.useState<string>('')
  const [urlModalContent, setUrlModalContent] = React.useState<string>('')
  const IconComponent = expandedManagerId > 0 ? BsFillCaretDownFill : BsFillCaretRightFill

  const onSubmitCommunity = async (e: any) => {
    const accessToken = authContext?.accessToken!
    e.preventDefault()
    await communityService.updateCommunity(accessToken, community.id, {
      name: communityData.name,
      companyName: communityData.companyName,
      email: communityData.email,
      first_name: communityData.firstName,
      last_name: communityData.last_name,
      phone: communityData.phone,
      street: communityData.street,
      postalCode: communityData.postalCode,
      city: communityData.city,
      street_number: communityData.streetNumber
    })
  }

  const addManagerClicked = () => {
    setShowAddManagerForm(true)
  }

  const expandedClicked = (manager: any) => {
    if (expandedManagerId === manager.id) {
      setExpandedManagerId(0)
    } else {
      setExpandedManagerId(manager.id)
      setShowAddManagerForm(false)
    }
  }
  React.useEffect(() => {
    setCommunityManagerData({
      firstName: communityData.firstName,
      lastName: communityData.lastName,
      email: communityData.email
    })
  }, [])
  setTimeout(() => {
    setErrorMessage('')
    setSuccessMessage('')
  }, 5000)
  const toCommunityPortal = async (communityManager: any) => {
    const token = authContext?.accessToken!
    const result = await userService.getUserToken(token, communityManager.id)
    const { access, refresh } = result
    authContext?.setUserToken({ accessToken: access, refreshToken: refresh })
    history.replace('/community/home')
  }

  const getInviationUrl = async (communityManager: any) => {
    const url = await communityManagerService.getInvitationUrl(authContext?.accessToken!, communityManager.id)
    setUrlModalHeader('Invitation Url')
    setUrlModalContent(url)
  }

  const getVerificationUrl = async (communityManager: any) => {
    const url = await communityManagerService.getVerificationUrl(authContext?.accessToken!, communityManager.id)
    setUrlModalHeader('Verification Url')
    setUrlModalContent(url)
  }

  const fetchAndSetCommunityManagers = async () => {
    const result = await communityManagerService.fetchCommunityManagers(authContext?.accessToken!, community.id)
    setManagerDataList(result)
  }

  const deleteCommunityManager = async (communityManager: any) => {
    // try catch display success or error
    try {
      const token = authContext?.accessToken!
      const result = await communityManagerService.deleteCommunityManager(token, communityManager.id)
      const newManagersList = managerDataList.filter((val) => val.id !== result.id)
      setManagerDataList(newManagersList)
      setSuccessMessage('Community manager deleted successfully')
    } catch (err) {
      setErrorMessage('Community manager is not deleted')
    }
  }

  const suspendCommunityManager = async (communityManager: any) => {
    const token = authContext?.accessToken!
    const result = await communityManagerService.suspendCommunityManager(token, communityManager.id)
    const newManagersList = [...managerDataList]
    for (let i = 0; i < newManagersList.length; i++) {
      if (newManagersList[i].id === result.id) {
        newManagersList[i] = result
      }
    }
    setManagerDataList(newManagersList)
  }

  const unSuspendCommunityManager = async (communityManager: any) => {
    const token = authContext?.accessToken!
    const result = await communityManagerService.unsuspendCommunityManager(token, communityManager.id)
    const newManagersList = [...managerDataList]
    for (let i = 0; i < newManagersList.length; i++) {
      if (newManagersList[i].id === result.id) {
        newManagersList[i] = result
      }
    }
    setManagerDataList(newManagersList)
  }

  const onUseContactPersonChange = (e: any, data: any) => {
    if (data.value === 'No') {
      setSelectedUserContactOption('No')
      setCommunityManagerData(initialCommunityManagerData)
    } else if (data.value === 'Yes') {
      setSelectedUserContactOption('Yes')
      setCommunityManagerData(communityData)
    }
  }
  const onSendInvite = async () => {
    const data = { communityId: community.id, ...communityManagerData }
    delete data.useContactPerson
    try {
      const result = await communityManagerService.inviteCommunityManager(authContext?.accessToken!, data)
      const newManagersList = [...managerDataList, result]
      setManagerDataList(newManagersList)
      setSuccessMessage('Community manager invited successfully')
    } catch (err) {
      setErrorMessage('Community manager is not invited')
    }
  }
  const onCancel = () => {
    setShowAddManagerForm(false)
  }
  const onResendInvite = async (communityManager: any) => {
    try {
      const result = await communityManagerService.resendInviteCommunityManager(
        authContext?.accessToken!,
        communityManager.id
      )
      const newManagersList = [...managerDataList]
      for (let i = 0; i < newManagersList.length; i++) {
        if (newManagersList[i].id === result.id) {
          newManagersList[i] = result
        }
      }
      setManagerDataList(newManagersList)
      setSuccessMessage('Community manager reinvited successfully')
    } catch (err) {
      setErrorMessage('Community manager is not reinvited')
    }
  }
  React.useEffect(() => {
    fetchAndSetCommunityManagers()
  }, [])
  React.useEffect(() => {
    if (showAddManagerForm) {
      setExpandedManagerId(0)
    }
  }, [showAddManagerForm])
  return (
    <div className="community-row-detail">
      <div className="community-row-detail__inner">
        <Modal
          closeIcon
          open={urlModalContent.length > 0 && urlModalHeader.length > 0}
          onClose={() => { setUrlModalContent(''); setUrlModalHeader('') }}>
          <Header content={`${urlModalHeader}`} />
          <Modal.Content>
            <div style={{ overflowWrap: 'break-word', textAlign: 'left' }}>
              {urlModalContent}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              onClick={() => {
                setUrlModalContent('')
                setUrlModalHeader('')
              }}>
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
        <div className="community">
          <div className="form-title">Community</div>
          <div className="community-form">
            <Form onSubmit={onSubmitCommunity}>
              <div className="flex1-row">
                <p className="community-details-names required-field">Community</p>
                <Form.Field className="flex1-row form2-item">
                  <input
                    placeholder="Community"
                    className="input-details-container"
                    required
                    disabled={location.pathname === '/community/home'}
                    value={communityData.name === '' ? 'N/A' : communityData.name}
                    onChange={(e) => {
                      setCommunityData({ ...communityData, name: e.target.value })
                    }}
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="community-details-names">Company name</p>
                <Form.Field className="flex1-row form2-item">
                  <input
                    placeholder="Bedrijfsnaam"
                    value={communityData.companyName === '' ? 'N/A' : normalizeCase(communityData.companyName)}
                    className="input-details-container"
                    onChange={(e) => {
                      setCommunityData({ ...communityData, companyName: e.target.value })
                    }}
                  />
                </Form.Field>
              </div>
              <strong>Contact</strong>
              <div className="flex1-row">
                <p className="community-details-names required-field">First name</p>
                <Form.Field className="flex1-row form2-item">
                  <input
                    placeholder="First Name"
                    value={communityData.firstName === '' ? 'N/A' : normalizeCase(communityData.firstName)}
                    required
                    className="input-details-container"
                    onChange={(e) => {
                      setCommunityData({ ...communityData, firstName: e.target.value })
                    }}
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="community-details-names required-field">Last Name</p>
                <Form.Field className="flex1-row form2-item">
                  <input
                    placeholder="Last name"
                    value={communityData.lastName === '' ? 'N/A' : normalizeCase(communityData.lastName)}
                    className="input-details-container"
                    required
                    onChange={(e) => {
                      setCommunityData({ ...communityData, lastName: e.target.value })
                    }}
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="community-details-names required-field">E-mail</p>
                <Form.Field className="flex1-row form2-item">
                  <input
                    placeholder="E-mail"
                    className="input-details-container"
                    required
                    value={communityData.email === '' ? 'N/A' : communityData.email}
                    onChange={(e) => {
                      setCommunityData({ ...communityData, email: e.target.value })
                    }}
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="community-details-names required-field">Phone</p>
                <Form.Field className="flex1-row form2-item">
                  <input
                    placeholder="Phone"
                    required
                    className="input-details-container"
                    value={communityData.phone === '' ? 'N/A' : communityData.phone}
                    onChange={(e) => {
                      setCommunityData({ ...communityData, phone: e.target.value })
                    }}
                  />
                </Form.Field>
              </div>
              <strong>Adress</strong>
              <div className="flex1-row">
                <p className="community-details-names required-field">Street + number</p>
                <Form.Field className="flex1-row large-field">
                  <input
                    placeholder="Street"
                    required
                    value={communityData.street === '' ? 'N/A' : normalizeCase(communityData.street)}
                    onChange={(e) => {
                      setCommunityData({ ...communityData, street: e.target.value })
                    }}
                  />
                </Form.Field>
                <Form.Field className="short-field m-l-community">
                  <input
                    placeholder="Street number"
                    required
                    value={communityData.streetNumber === '' ? 'N/A' : normalizeCase(communityData.streetNumber)}
                    onChange={(e) => {
                      setCommunityData({ ...communityData, streetNumber: e.target.value })
                    }}
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="community-details-names required-field">Postcode + city</p>
                <Form.Field className="short-field">
                  <input
                    placeholder="Postal code"
                    required
                    value={communityData.postalCode === '' ? 'N/A' : communityData.postalCode}
                    onChange={(e) => {
                      setCommunityData({ ...communityData, postalCode: e.target.value })
                    }}
                  />
                </Form.Field>
                <Form.Field className="large-field m-l-community">
                  <input
                    placeholder="City"
                    required
                    value={communityData.city === '' ? 'N/A' : communityData.city}
                    onChange={(e) => {
                      setCommunityData({ ...communityData, city: e.target.value })
                    }}
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="community-details-names"></p>
                <div className="input-details-container">
                  <div className="input-details-container flex1-row flex1-end">
                    <Button className="lyv-blue-btn" id="sentInvite">
                      Change
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="community-manager">
          <div className="form-title">Community manager</div>
          {/* Need it for later use while integrating with backend */}

          <div className="m-t-medium community-manager-details">
            {!showAddManagerForm && managerDataList.length === 0 && (
              <div className="m-t-medium">No Community manager found</div>
            )}
            {managerDataList.map((val, index) => (
              <>
                <Table key={index} onClick={() => expandedClicked(val)}>
                  <TableBody>
                    <TableRow>
                      <Table.Cell style={{ fontWeight: 'bold' }}>
                        {`Manager
                              :  
                              ${val.firstName} 
                              ${val.lastName}`}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="flex1-row flex1-end">
                          <IconComponent />
                        </div>
                      </Table.Cell>
                    </TableRow>
                  </TableBody>
                </Table>
                {expandedManagerId === val.id && (
                  <div className="p-medium">
                    <div className="flex1-row">
                      <p className="community-details-names">First name</p>
                      <Form.Field className="form2-item">
                        <input
                          placeholder="First name"
                          className="input-details-container"
                          value={val.firstName}
                          disabled={true}
                        />
                      </Form.Field>
                    </div>
                    <div className="flex1-row">
                      <p className="community-details-names">Last name</p>
                      <Form.Field className="form2-item">
                        <input
                          placeholder="Last name"
                          className="input-details-container"
                          value={val.lastName}
                          disabled={true}
                        />
                      </Form.Field>
                    </div>
                    <div className="flex1-row">
                      <p className="community-details-names">E-mail</p>
                      <Form.Field className="form2-item">
                        <input
                          disabled={true}
                          placeholder="E-mail"
                          className="input-details-container"
                          value={val.email}
                        />
                      </Form.Field>
                    </div>
                  </div>
                )}
                {location.pathname === '/admin/home' && expandedManagerId === val.id && (
                  <div className="flex1-row flex1-end full-width">
                    <Button className="lyv-blue-btn m-x-r" onClick={() => toCommunityPortal(val)}>
                      To portal
                    </Button>
                    <Button
                      onClick={async () => {
                        await onResendInvite(val)
                      }}
                      className="lyv-blue-btn  m-x-r"
                    >
                      Resend invite
                    </Button>
                    {!val.isSuspended && (
                      <Button
                        className="delete-btn"
                        onClick={async () => {
                          await suspendCommunityManager(val)
                        }}
                      >
                        Suspend
                      </Button>
                    )}
                    {val.isSuspended && (
                      <Button
                        className="lyv-blue-btn m-x-r"
                        onClick={async () => {
                          await unSuspendCommunityManager(val)
                        }}
                      >
                        Unsuspend
                      </Button>
                    )}
                    <Button
                      className="delete-btn"
                      onClick={async () => {
                        await deleteCommunityManager(val)
                      }}
                    >
                      Delete
                    </Button>
                    {!val.hasSetPassword && <Button
                      className="lyv-blue-btn m-x-r"
                      onClick={async () => {
                        await getInviationUrl(val)
                      }}
                    >
                      Get Invitation URL
                    </Button>}
                    {val.hasSetPassword && !val.isActive && <Button
                      className="lyv-blue-btn m-x-r"
                      onClick={async () => {
                        await getVerificationUrl(val)
                      }}
                    >
                      Get Verification URL
                    </Button>}
                  </div>
                )}
              </>
            ))}

            <div className="community-manager-form p-medium">
              {showAddManagerForm && (
                <Form className=" flex1-column flex1-center">
                  <div className="form-inner-item flex1-column">
                    {location.pathname === '/admin/home' && (
                      <div className="flex1-row">
                        <p className="community-details-names">Use contact person</p>
                        <Form.Field className="form2-item">
                          <Dropdown
                            selection
                            fluid
                            className="input-details-container"
                            placeholder="Yes"
                            options={useContactPersonOptions}
                            onChange={onUseContactPersonChange}
                            defaultValue={selectedUserContactOption}
                          />
                        </Form.Field>
                      </div>
                    )}
                    <div className="flex1-row">
                      <p className="community-details-names">First name</p>
                      <Form.Field className="form2-item">
                        <input
                          placeholder="First name"
                          className="input-details-container"
                          onChange={(e) =>
                            setCommunityManagerData({ ...communityManagerData, firstName: e.target.value })
                          }
                          value={communityManagerData.firstName}
                          disabled={location.pathname === '/community/home'}
                        />
                      </Form.Field>
                    </div>
                    <div className="flex1-row">
                      <p className="community-details-names">Last name</p>
                      <Form.Field className="form2-item">
                        <input
                          placeholder="Last name"
                          className="input-details-container"
                          onChange={(e) =>
                            setCommunityManagerData({ ...communityManagerData, lastName: e.target.value })
                          }
                          value={communityManagerData.lastName}
                          disabled={location.pathname === '/community/home'}
                        />
                      </Form.Field>
                    </div>
                    <div className="flex1-row">
                      <p className="community-details-names">E-mail</p>
                      <Form.Field className="form2-item">
                        <input
                          disabled={location.pathname === '/community/home'}
                          placeholder="E-mail"
                          className="input-details-container"
                          onChange={(e) => setCommunityManagerData({ ...communityManagerData, email: e.target.value })}
                          value={communityManagerData.email}
                        />
                      </Form.Field>
                    </div>
                    {location.pathname === '/admin/home' && (
                      <div className="flex1-row full-width flex1-end">
                        <Button className="lyv-blue-btn m-x-r" onClick={onCancel}>
                          Cancel
                        </Button>
                        <Button className="lyv-blue-btn" onClick={onSendInvite}>
                          Send Invite
                        </Button>
                      </div>
                    )}
                    {successMessage && successMessage !== '' && <Message color="green">{successMessage}</Message>}
                    {errorMessage && errorMessage !== '' && <Message color="red">{errorMessage}</Message>}
                  </div>
                </Form>
              )}
            </div>
          </div>

          {location.pathname === '/admin/home' && (
            <div className="flex1-row">
              <p className="community-details-names"></p>
              <div className="input-details-container">
                <div className="input-details-container flex1-row flex1-end">
                  <Button className="lyv-blue-btn" onClick={addManagerClicked}>
                    Add manager
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div >
  )
}

export default CommunityDetails
