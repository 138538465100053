import api from '../api'
import { getConfig } from './base'
import {
  IBatteryModel,
  IBatteryModelCreate
} from '../interfaces/battery'

export const fetchBatteryModels = async (token: string): Promise<IBatteryModel[]> => {
  const config = getConfig(token)
  const result = await api.get('/batteries/', config)
  return result.data
}

export const createBatteryModel = async (token: string, data: IBatteryModelCreate): Promise<IBatteryModel> => {
  const config = getConfig(token)
  const result = await api.post('/batteries/', data, config)
  return result.data
}
