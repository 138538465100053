import * as React from 'react'
import { Divider, Input, Grid, Button, Form, Checkbox, Image } from 'semantic-ui-react'
import * as save from '../../../assets/save.png'
import * as unsave from '../../../assets/unsave.png'
import './configuration_details.scss'
import * as loadingSymbol from '../../../assets/loading.png'
import UnitInput from './unit_input'
import GenericSmartController from './generic_smart_controller'

const SpaceHeaterConfigurationDetails = () => {
  const [scanNumber, setScanNumber] = React.useState<boolean>(false)
  const [knownNumber, setKnownNumber] = React.useState<boolean>(false)
  const [scanNumberSelected, setScanNumberSelected] = React.useState<boolean>()
  return (
    <Form>
      <Grid>
        <Grid.Row style={{ paddingBottom: '0.2rem' }}>
          <Grid.Column width="6" textAlign="left">
            <strong className="heading medium">Benoem apparaat</strong>
          </Grid.Column>
        </Grid.Row>
        <Divider></Divider>
        <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
          <Grid.Column width="4" textAlign="left" verticalAlign="middle">
            <div className="heading medium required-field">Naam</div>
          </Grid.Column>
          <Grid.Column width="12" textAlign="left">
            <Input className="full-width" placeholder="Naam" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '1.5rem' }}>
          <Grid.Column width="4" textAlign="left" verticalAlign="middle">
            <div className="heading medium required-field">Vermogen</div>
          </Grid.Column>
          <Grid.Column width="12" textAlign="left">
            <UnitInput unitLabel="W" width="86.5%" placeholder="Vermogen" />
          </Grid.Column>
        </Grid.Row>
        <GenericSmartController />
      </Grid>
    </Form>
  )
}

export default SpaceHeaterConfigurationDetails
