import * as React from 'react'
import '../Monitor/electric_car.scss'
import { Image } from 'semantic-ui-react'
import * as chargingPoleEv from '../../../assets/charging_pole_ev_add_device.png'
import * as battery from '../../../assets/battery_add_device.png'
import * as carEmpty from '../../../assets/empty_car.png'
import * as helper from '../../../util/helper'

interface IDetailsProps {
  add: () => void
}

const Details = (props: IDetailsProps) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <div className="details-page">
        <div>
          <strong className="title">Voeg apparaat toe</strong>
          <hr />
          <div className="asset-details">
            Voeg nieuwe en bestaande apparaten toe aan uw omgeving en krijg inzicht in verbruik, kosten en optimaliseer
            uw verbruik.
          </div>
        </div>
        <div className="flex1-row space-between">
          <div className="flex1-column flex1-center m-t-medium">
            <Image src={helper.deviceGroupAssetMapping.boiler} size="mini" />
            <Image src={battery.default} className="m-t-medium" />
          </div>
          <div className="flex1-column flex1-center" style={{ marginTop: '25px' }}>
            <Image src={chargingPoleEv.default} />
            <Image src={carEmpty.default} />
          </div>
        </div>
        <hr />
        <div className="flex1-row flex1-end">
          <button className="btn-blue m-y-t " onClick={props.add}>
            Voeg toe
          </button>
        </div>
      </div>
    </>
  )
}
export default Details
