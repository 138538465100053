import * as React from 'react'
import { Table, Input } from 'semantic-ui-react'

interface IBtwPercentProps {
  btwPercent: any
  editTaxRates: any[]
  inEditMode: any
  handleTaxRatesChange: Function
  handleTaxRatesClick: Function
}

const BtwPercent: React.FC<IBtwPercentProps> = (props: IBtwPercentProps) => {
  const handleClickEvent = (ids: number[], field: string, value: number) => {
    props.handleTaxRatesClick(ids, field, value)
  }

  const handleTaxRatesChange = (ids: number[], field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleTaxRatesChange(ids, field, event.target.value)
  }

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Tax</Table.HeaderCell>
            <Table.HeaderCell width={2}> </Table.HeaderCell>
            <Table.HeaderCell width={3}> </Table.HeaderCell>
            {Object.keys(props.btwPercent).length > 0 &&
              Object.keys(props.btwPercent).map((year) => {
                return (
                  <Table.HeaderCell key={year} width={2}>
                    {year}
                  </Table.HeaderCell>
                )
              })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>BTW</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            {Object.keys(props.btwPercent).length > 0 &&
              Object.keys(props.btwPercent).map((year) => {
                return (
                  <Table.Cell
                    key={year}
                    className={
                      props.btwPercent[year].ids.every((id: number) => {
                        return props.inEditMode.ids.includes(id)
                      }) &&
                      props.btwPercent[year].ids.every((id: number) => {
                        return id in props.editTaxRates &&
                          'btwPercent' in props.editTaxRates[id]
                      })
                        ? 'dirty'
                        : ''
                    }
                  >
                    {props.inEditMode.status &&
                    Object.keys(props.editTaxRates).length > 0 &&
                    props.btwPercent[year].ids.every((id: number) => {
                      return props.inEditMode.ids.includes(id) &&
                        id in props.editTaxRates &&
                        'btwPercent' in props.editTaxRates[id]
                    }) ? (
                        <Input
                          transparent
                          className="tableInput"
                          type="number"
                          value={props.editTaxRates[props.btwPercent[year].ids[0]].btwPercent}
                          onChange={handleTaxRatesChange.bind(this, props.btwPercent[year].ids, 'btwPercent')}
                        />
                      ) : (
                        <p
                          className="editableElement"
                          onClick={handleClickEvent.bind(
                            this,
                            props.btwPercent[year].ids,
                            'btwPercent',
                            props.btwPercent[year].value
                          )}
                        >
                          {props.btwPercent[year].value}
                        </p>
                      )}
                  </Table.Cell>
                )
              })}
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}

export default BtwPercent
