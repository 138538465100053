import * as React from 'react'
import { useState, useContext, useEffect } from 'react'
import { Button, Menu, Segment, Confirm } from 'semantic-ui-react'
import TaxService from '../../../../services/tax'
import AuthContext from '../../../../contexts/auth'
import TaxType from './TaxType'
import BtwPercent from './BtwPercent'
import TaxReductions from './TaxReductions'

const Tax: React.FC = () => {
  const taxService = new TaxService()
  const authContext = useContext(AuthContext)

  const [taxRates, setTaxRates] = useState<any>({})
  const [taxReductions, setTaxReductions] = useState<any[]>([])
  const [years, setYears] = useState<number[]>([])

  const [editTaxReductions, setEditTaxReductions] = useState<any>({})
  const [inEditModeReductions, setInEditModeReductions] = useState<any>({
    status: false,
    ids: []
  })

  const [editTaxRates, setEditTaxRates] = useState<any>({})
  const [inEditModeRates, setInEditModeRates] = useState<any>({
    status: false,
    ids: []
  })

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  const openConfirm = () => {
    setConfirmOpen(true)
  }

  const closeConfirm = () => {
    setConfirmOpen(false)
  }

  const fetchTaxRates = async () => {
    const accessToken = authContext?.accessToken!
    const result = await taxService.getTaxRates(accessToken)
    setTaxRates(result)
    setYears(result.years)
  }

  const updateTaxRates = async () => {
    const accessToken = authContext?.accessToken!
    const result = await taxService.updateTaxRates(accessToken, Object.values(editTaxRates))
    setTaxRates(result)
    setInEditModeRates({
      status: false,
      ids: []
    })
    setEditTaxRates({})
  }

  const fetchTaxReductions = async () => {
    const accessToken = authContext?.accessToken!
    const result = await taxService.getTaxReductions(accessToken)
    setTaxReductions(result)
  }

  const updateTaxReductions = async () => {
    const accessToken = authContext?.accessToken!
    const result = await taxService.updateTaxReductions(accessToken, Object.values(editTaxReductions))
    setTaxReductions(result)
    setInEditModeReductions({
      status: false,
      ids: []
    })
    setEditTaxReductions({})
  }

  const addReductionToEdits = (id: number, field: string, value: number) => {
    setInEditModeReductions({ status: true, ids: inEditModeReductions.ids.concat(id) })
    if (!(id in editTaxReductions)) {
      editTaxReductions[id] = { id: id }
    }
    editTaxReductions[id][field] = value
    setEditTaxReductions(editTaxReductions)
  }

  const addTaxRatesToEdits = (ids: number[], field: string, value: number) => {
    setInEditModeRates({ status: true, ids: inEditModeRates.ids.concat(ids) })
    ids.map((id) => {
      if (!(id in editTaxRates)) {
        editTaxRates[id] = { id: id }
      }
      editTaxRates[id][field] = value
    })
    setEditTaxRates(editTaxRates)
  }

  const handleReductionsChange = (id: number, field: string, value: number) => {
    editTaxReductions[id][field] = value
    setEditTaxReductions(JSON.parse(JSON.stringify(editTaxReductions)))
  }

  const handleTaxRatesChange = (ids: number[], field: string, value: number) => {
    ids.map((id) => {
      editTaxRates[id][field] = value
    })
    setEditTaxRates(JSON.parse(JSON.stringify(editTaxRates)))
  }

  const handleButtonClick = () => {
    if (Object.keys(editTaxRates).length > 0) {
      updateTaxRates()
    }
    if (Object.keys(editTaxReductions).length > 0) {
      updateTaxReductions()
    }
    closeConfirm()
  }

  useEffect(() => {
    fetchTaxRates()
    fetchTaxReductions()
    taxReductions.sort((a, b) => {
      return a.year - b.year
    })
  }, [])

  return (
    <>
      <div className="source-container">
        <Segment style={{ overflow: 'auto', maxHeight: '55rem' }}>
          <div>
            <Menu secondary size="small">
              <Menu.Item position="right">
                <Button className="btn-green"
                  onClick={openConfirm}
                  disabled={!(inEditModeRates.status || inEditModeReductions.status)}
                >
                  Modify
                </Button>
                <Confirm
                  open={confirmOpen}
                  onCancel={closeConfirm}
                  onConfirm={handleButtonClick}
                  content="Are you sure you want to modify the values?"
                  cancelButton="No"
                  confirmButton="Yes"
                />
              </Menu.Item>
            </Menu>
          </div>
          <div className="source-container" id="taxContainer">
            <h3>Tax</h3>
            <hr />
            {'btwPercent' in taxRates && <BtwPercent
              btwPercent={taxRates.btwPercent}
              inEditMode={inEditModeRates}
              editTaxRates={editTaxRates}
              handleTaxRatesChange={handleTaxRatesChange}
              handleTaxRatesClick={addTaxRatesToEdits}
            />}
            {taxReductions.length > 0 && (
              <TaxReductions
                taxReductions={taxReductions}
                years={years}
                editTaxReductions={editTaxReductions}
                inEditMode={inEditModeReductions}
                handleReductionChange={handleReductionsChange}
                handleReductionClick={addReductionToEdits}
              />
            )}
            {'parsedData' in taxRates && (
              <div>
                <TaxType
                  taxRates={taxRates.parsedData}
                  taxType={'energy'}
                  years={taxRates.years}
                  commodity={'electricity'}
                  editTaxRates={editTaxRates}
                  inEditMode={inEditModeRates}
                  handleTaxRatesChange={handleTaxRatesChange}
                  handleTaxRatesClick={addTaxRatesToEdits}
                />
                <TaxType
                  taxRates={taxRates.parsedData}
                  taxType={'sustainability'}
                  years={taxRates.years}
                  commodity={'electricity'}
                  editTaxRates={editTaxRates}
                  inEditMode={inEditModeRates}
                  handleTaxRatesChange={handleTaxRatesChange}
                  handleTaxRatesClick={addTaxRatesToEdits}
                />
                <TaxType
                  taxRates={taxRates.parsedData}
                  taxType={'energy'}
                  years={taxRates.years}
                  commodity={'gas'}
                  editTaxRates={editTaxRates}
                  inEditMode={inEditModeRates}
                  handleTaxRatesChange={handleTaxRatesChange}
                  handleTaxRatesClick={addTaxRatesToEdits}
                />
                <TaxType
                  taxRates={taxRates.parsedData}
                  taxType={'sustainability'}
                  years={taxRates.years}
                  commodity={'gas'}
                  editTaxRates={editTaxRates}
                  inEditMode={inEditModeRates}
                  handleTaxRatesChange={handleTaxRatesChange}
                  handleTaxRatesClick={addTaxRatesToEdits}
                />
              </div>
            )}
          </div>
        </Segment>
      </div>
    </>
  )
}

export default Tax
