import * as React from 'react'
import { Form, Image } from 'semantic-ui-react'
import AuthContext from '../../../contexts/auth'
import CommunityService from '../../../services/community'
import * as community from '../../../assets/community.png'
import '../../Register/customer_details.scss'
import '../../shared/community/community_details.scss'

const CreateCommunity: React.FC = () => {
  const communityService = new CommunityService()
  const initialCommunityData = {
    name: '',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    streetNumber: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
    postbox: '',
    postboxCode: ''
  }

  const [data, setData] = React.useState(initialCommunityData)

  const authContext = React.useContext(AuthContext)

  const submitForm = async (e: any) => {
    e.preventDefault()
    try {
      const token = authContext?.accessToken!
      await communityService.createCommunity(token, data)
      alert('community created')
    } catch (err) {
      alert(err.errors[0])
    }
    setData(initialCommunityData)
  }

  return (
    <div className="customer-detail-container community-container form-padding">
      <div className="customer-detail-form-container">
        <div className="flex1-row flex-center-vertical">
          <Image src={community.default} className="customer-detail-logo-size"></Image>
          <strong className="customer-details-header m-t-medium">New Community</strong>
        </div>
        <hr />
        <Form onSubmit={submitForm} stackable>
          <div className="customer-details flex1-column">
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Community</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Community"
                  required
                  value={data.name}
                  className="form2-item"
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Company name</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Company name"
                  required
                  className="form2-item"
                  value={data.companyName}
                  onChange={(e) => {
                    setData({ ...data, companyName: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <strong className="customer-detail-names new-community">Contact</strong>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">First name</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="First name"
                  required
                  className="form2-item"
                  value={data.firstName}
                  onChange={(e) => {
                    setData({ ...data, firstName: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Last name</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Last name"
                  required
                  className="form2-item"
                  value={data.lastName}
                  onChange={(e) => {
                    setData({ ...data, lastName: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">E-mail</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Email"
                  required
                  className="form2-item"
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Phone</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Phone"
                  required
                  className="form2-item"
                  value={data.phone}
                  onChange={(e) => {
                    setData({ ...data, phone: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <strong className="customer-detail-names new-community">Adres</strong>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Street + number</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Street"
                  required
                  className="form2-item"
                  value={data.street}
                  onChange={(e) => {
                    setData({ ...data, street: e.target.value })
                  }}
                />
                <input
                  placeholder="Street number"
                  style={{ marginLeft: '15px' }}
                  required
                  className="form2-item"
                  value={data.streetNumber}
                  onChange={(e) => {
                    setData({ ...data, streetNumber: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names required-field">Postcode + city</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Postal code"
                  required
                  className="form2-item"
                  value={data.postalCode}
                  onChange={(e) => {
                    setData({ ...data, postalCode: e.target.value })
                  }}
                />
                <input
                  placeholder="City"
                  required
                  className="form2-item large-field"
                  style={{ marginLeft: '15px' }}
                  value={data.city}
                  onChange={(e) => {
                    setData({ ...data, city: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names">Province</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Province"
                  className="form2-item"
                  value={data.province}
                  onChange={(e) => {
                    setData({ ...data, province: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names">Country</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Country"
                  className="form2-item"
                  value={data.country}
                  onChange={(e) => {
                    setData({ ...data, country: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names">Post box</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Post box"
                  className="form2-item"
                  value={data.postbox}
                  onChange={(e) => {
                    setData({ ...data, postbox: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row">
              <p className="customer-detail-names">Post box code</p>
              <Form.Field className="flex1-row">
                <input
                  placeholder="Post box code"
                  className="form2-item"
                  value={data.postboxCode}
                  onChange={(e) => {
                    setData({ ...data, postboxCode: e.target.value })
                  }}
                />
              </Form.Field>
            </div>
            <div className="flex1-row flex1-end">
              <button type="submit" className="btn-blue" style={{ color: 'white' }}>
                Add
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default CreateCommunity
