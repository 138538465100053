import * as React from 'react'
import * as headerLogo from '../../assets/logo_rgb100x100.png'
import * as userLogo from '../../assets/User_Icon.svg'
import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Segment, Menu, Dropdown, Image, MenuItemProps } from 'semantic-ui-react'
import { IconContext } from 'react-icons'
import { AiOutlineBell } from 'react-icons/ai'
import { Home, Reports, VPP ,  Subscription, Building, Customer, UserSettings} from '..'
import { RouteComponentProps , Link} from 'react-router'
import './home.scss'
import '../Header/header.scss'
import Page from '../../pages/Page'
import * as options from '../../util/options'
import UserContext from '../../contexts/user'
import AuthContext from '../../contexts/auth'

type SomeComponentProps = RouteComponentProps
const HomeMenu: React.FC<SomeComponentProps> = () => {
  const [activeItem, setActiveItem] = useState<string>('HOME')
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext)
  const history = useHistory()

  if (userContext?.profile && !userContext?.profile?.onboardingCompletedOn && !userContext?.profile?.isGatewayPaired) {
    console.log({ profile: userContext?.profile })
    history.replace('/gateway/2')
  }

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    setActiveItem(data.name as string)
  }

  const onChange = (e: any, data: any) => {
    if (data.value === 2) {
      history.push('/companyform')
    } else if (data.value === 3) {
      authContext?.logout()
    }
  }
  return (
    <>
      <Page>
        <div className="home-container">
          <Menu attached="top" tabular className="header-container">
            <div className="header-column1 flex1-row flex1-start">
              <Image src={headerLogo.default} className="header-logo-size header-logo-positioning" alt="Header logo" />
            </div>
            <div className="header-column2 flex1-row flex1-center">
              <Menu.Item active={activeItem === 'HOME'} name="HOME" onClick={handleItemClick} id="home-active-user">
                <strong style={{ color: '#00b7d5', fontSize: '18px' }}>HOME</strong>
              </Menu.Item>
              <Menu.Item
                fitted
                name="RAPPORTAGE"
                active={activeItem === 'RAPPORTAGE'}
                onClick={handleItemClick}
                as={Link}
                to={'/reports'}
                id="reports-active-user"
              >
                <strong style={{ color: '#00b7d5', fontSize: '18px' }}>RAPPORTAGE</strong>
              </Menu.Item>
              <Menu.Item
                fitted
                name="OPTIMALISATIE"
                active={activeItem === 'OPTIMALISATIE'}
                onClick={handleItemClick}
                id="optimalisatie-active-user"
              >
                <strong style={{ color: '#00b7d5', fontSize: '18px' }}>OPTIMALISATIE</strong>
              </Menu.Item>
              <Menu.Item name="VPP" active={activeItem === 'VPP'} onClick={handleItemClick} id="vpp-active-user">
                <strong style={{ color: '#00b7d5', fontSize: '18px' }}>VPP</strong>
              </Menu.Item>
            </div>
            <div className="header-column3 flex1-row flex1-end">
              <Menu.Menu position="right">
                <Menu.Item>
                  <div className="flex1-row flex1-center">
                    <IconContext.Provider
                      value={{
                        size: '37px',
                        style: {
                          verticalAlign: 'middle',
                          fill: '#E6F3F8',
                          strokeWidth: '1em'
                        }
                      }}
                    >
                      <div>
                        <AiOutlineBell className="bell-logo" />
                      </div>
                    </IconContext.Provider>
                    <Image src={userLogo.default} className="user-logo" />
                    <Dropdown
                      className="user-name"
                      text={userContext?.profile?.lastName}
                      options={options.homeOptions}
                      onChange={onChange}
                      simple
                      item
                    />
                  </div>
                </Menu.Item>
              </Menu.Menu>
            </div>
          </Menu>
          {activeItem === 'HOME' && (
            <Segment attached="bottom">
              <Home />
            </Segment>
          )}
          {activeItem === 'RAPPORTAGE' && (
            <Segment attached="bottom">
              <Reports />
            </Segment>
          )}
          {activeItem === 'OPTIMALISATIE' && (
            <Segment attached="bottom">
              <div>OPTIMALISATIE</div>
            </Segment>
          )}
          {activeItem === 'VPP' && (
            <Segment attached="bottom">
              <VPP />
            </Segment>
          )}
        </div>
      </Page>
    </>
  )
}

export default HomeMenu
