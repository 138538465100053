import * as React from 'react'
import * as customer from '../../assets/Customer.svg'
import { Image, Form, Message } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import './company_form.scss'
import UserContext from '../../contexts/user'

type CustomerForm = {
  userName: string
  firstName: string
  lastName: string
  street: string
  streetNumber: string
  postcode: string
  city: string
  mobileNumber: string
  phone: string
}

// Component to get all the details of the customer
const Customer: React.FC = () => {
  let privateOrBusiness = ''
  const userContext = React.useContext(UserContext)

  const [formData, setFormData] = useState<any>({})
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  const { register, handleSubmit, errors } = useForm<CustomerForm>()
  userContext?.profile?.customerType !== 'private'
    ? (privateOrBusiness = 'm-py-top-customer ')
    : (privateOrBusiness = 'm-py-top-customerprivate')

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const data = { ...formData }
    delete data.email
    delete data.customerType
    delete data.createdOn
    delete data.lastUpdatedOn
    try {
      await userContext?.updateCustomerDetails(data)
      setSuccessMessage('Gegevens succesvol bijgewerkt.')
    } catch (err) {
      setErrorMessage(err.errors[0])
    }
  }

  const onInputChange = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setFormData({ ...formData, [name]: value })
  }

  React.useEffect(() => { setFormData({ ...userContext?.customerDetail }) }, [userContext?.customerDetail])

  setTimeout(() => { setErrorMessage(''); setSuccessMessage('') }, 5000)
  console.log('form data', formData)
  return (
    <React.Fragment>
      <div className="customer-detail-container" id="company-container">
        <div className="customer-detail-form-container company-form">
          <div className="flex1-row">
            <Image src={customer.default} className="customer-detail-logo-size"></Image>
            <strong className="customer-details-header" style={{ fontWeight: 'bold', fontSize: '22px' }}>
              Gebruiker
            </strong>
          </div>
          <hr></hr>
          <Form onSubmit={onSubmit}>
            <div className="customer-details flex1-column">
              {userContext?.profile?.customerType !== 'private' && (
                <div className="flex1-row">
                  <p className="customer-detail-names required-field ">Bedrijfsnaam</p>
                  <Form.Field>
                    <input
                      value={formData.companyName ? formData.companyName : ''}
                      onChange={onInputChange}
                      name="companyName"
                      className="form1-item"
                      ref={register({ required: true })}
                    />
                    {errors.userName && errors.userName.type === 'required' && (
                      <div className="error-msg">U moet uw Bedrijfsnaam invoeren.</div>
                    )}
                  </Form.Field>
                </div>
              )}
              <div className="flex1-row">
                <p className="customer-detail-names required-field">Voornaam</p>
                <Form.Field>
                  <input
                    value={formData.firstName ? formData.firstName : ''}
                    onChange={onInputChange}
                    name="firstName"
                    className="form1-item"
                    ref={register({ required: true })}
                  />
                  {errors.firstName && errors.firstName.type === 'required' && (
                    <div className="error-msg">U moet uw Voornaam invoeren.</div>
                  )}
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p className="required-field">Achternaam</p>
                </div>
                <Form.Field>
                  <input
                    value={formData.lastName ? formData.lastName : ''}
                    onChange={onInputChange}
                    name="lastName"
                    className="form1-item"
                    ref={register({ required: true })}
                  />
                  {errors.lastName && errors.lastName.type === 'required' && (
                    <div className="error-msg">U moet uw achternaam invoeren.</div>
                  )}
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p className="required-field">Straat + Nummer</p>
                </div>
                <div className="streethous flex1-row">
                  <Form.Field>
                    <input
                      value={formData.street ? formData.street : ''}
                      onChange={onInputChange}
                      name="street"
                      className="form1-item"
                      ref={register({ required: true })}
                    />
                    {errors.street && errors.street.type === 'required' && (
                      <div className="error-msg">U moet uw straat naam invoeren.</div>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <input
                      value={formData.streetNumber ? formData.streetNumber : ''}
                      onChange={onInputChange}
                      name="streetNumber"
                      className="form1-item"
                      ref={register({ required: true })}
                    />
                    {errors.streetNumber && errors.streetNumber.type === 'required' && (
                      <div className="error-msg">U moet uw straat nummer invoeren.</div>
                    )}
                  </Form.Field>
                </div>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p className="required-field">Postcode + Platsnaam</p>
                </div>
                <div className="postcode-city flex1-row">
                  <Form.Field>
                    <input
                      value={formData.postcode ? formData.postcode : ''}
                      onChange={onInputChange}
                      name="postcode"
                      className="form1-item"
                      ref={register({
                        required: true,
                        pattern: {
                          value: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
                          message: 'Voer alstublieft een geldige postcode in'
                        }
                      })}
                    />
                    {errors.postcode && errors.postcode.type === 'required' && (
                      <div className="error-msg">Voer uw postcode in.</div>
                    )}
                    {errors.postcode && errors.postcode.type === 'pattern' && (
                      <div className="error-msg">{errors.postcode.message}</div>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <input
                      value={formData.city ? formData.city : ''}
                      onChange={onInputChange}
                      name="city"
                      className="form1-item"
                      ref={register({ required: true })}
                    />
                    {errors.city && errors.city.type === 'required' && (
                      <div className="error-msg">U moet uw plaatsnaam invoeren.</div>
                    )}
                  </Form.Field>
                </div>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names">E-mail</p>
                <Form.Field>
                  <input
                    disabled
                    defaultValue={userContext?.customerDetail.email ? userContext?.customerDetail.email : ''}
                    name="email"
                    className="form1-item obligation-input"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names required-field">Mobiele nr.</p>
                <Form.Field>
                  <input
                    value={formData.mobileNumber ? formData.mobileNumber : ''}
                    onChange={onInputChange}
                    name="mobileNumber"
                    className="form1-item"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/i,
                        message: 'Voer een geldig mobiele nummer in'
                      }
                    })}
                  />
                  {errors.mobileNumber && errors.mobileNumber.type === 'required' && (
                    <div className="error-msg">Voer uw mobiele nummer in</div>
                  )}
                  {errors.mobileNumber && errors.mobileNumber.type === 'pattern' && (
                    <div className="error-msg">{errors.mobileNumber.message}</div>
                  )}
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names">Telefoon</p>
                <Form.Field>
                  <input
                    value={formData.phone ? formData.phone : ''}
                    onChange={onInputChange}
                    name="phone"
                    className="form1-item"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/i,
                        message: 'Voer een geldig telefoonnnummer in'
                      }
                    })}
                  />
                  {errors.phone && errors.phone.type === 'pattern' && (
                    <div className="error-msg">{errors.phone.message}</div>
                  )}
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names">Klanttype</p>
                <Form.Field>
                  <input
                    disabled
                    defaultValue={userContext?.customerDetail.customerType}
                    name="customerType"
                    className="form1-item obligation-input"
                  />
                </Form.Field>
              </div>
              <div className={`flex1-row flex1-end m-py-bottom ${privateOrBusiness}`}>
                <button className="m-px-application btn-blue">Pas toe</button>
              </div>
              {errorMessage && errorMessage.length > 0 && <Message color="red">{errorMessage}</Message>}
              {successMessage && successMessage.length > 0 && <Message color="green">{successMessage}</Message>}
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Customer
