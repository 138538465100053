import * as React from 'react'
import { Tab } from 'semantic-ui-react'
import Overview from '../../shared/overview/Overview'
import Status from '../../shared/status/Status'
import { Date } from '../..'

import './dashboard.scss'

interface IDashboardProps {
  community: any
}

const Dashboard: React.FC<IDashboardProps> = ({ community }) => {
  const panes = [
    {
      menuItem: 'Overview',
      render: () => (
        <Tab.Pane>
          <Overview community={community} communities={[]} />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Status',
      render: () => (
        <Tab.Pane>
          <Status community={community} communities={[]} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        content: <Date />,
        id: 'Date',
        position: 'right'
      },
      render: () => (
        <Tab.Pane>
          <Overview community={community} communities={[]}/>
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <Tab panes={panes} className="home-menu-container dashboard-menu-container" />
    </>
  )
}

export default Dashboard
