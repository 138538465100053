const routes: any = {
  LOGIN: '/',
  CREATEACCOUNT: '/createaccount/1',
  CREATE_ACCOUNT_COMMUNITY_MANAGER: '/create-account-community-manager/1',
  HOME: '/home',
  MAILVERIFIED: '/mailverified',
  GATEWAY: '/gateway/2',
  CUSTOMERDETAILS: '/customerdetails/3',
  COMPANYFORM: '/companyform',
  ADMIN_LOGIN: '/admin/login',
  ADMIN_HOME: '/admin/home',
  REPORTS: '/reports',
  COMMUNITY_HOME: '/community/home',
  COMMUNITY_LOGIN: '/community/login'
}

export const adminRoutes: any = {
  LOGIN: '/admin/login',
  HOME: '/admin/home'
}

export const communityRoutes: any = {
  LOGIN: '/community/login',
  HOME: '/community/home'
}

export default routes
