import * as React from 'react'
import { BsFillCaretRightFill, BsFillCaretDownFill } from 'react-icons/bs'
import './row.scss'

interface IRowProps {
  rowData: any
  children: any
}

interface IDetailsButtonProps {
  IconComponent: any
  className: any
}

const DetailsButton = ({ IconComponent, className }: IDetailsButtonProps) => {
  return (
    <div className={`details-button ${className}`}>
      Details
      <IconComponent />
    </div>
  )
}

const Row = (props: IRowProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const IconComponent = expanded ? BsFillCaretDownFill : BsFillCaretRightFill
  const dataKeys = Object.keys(props.rowData)
  const expandButtonClassName = expanded ? 'cursor-pointer' : 'cursor-pointer border'

  const cellClassName = expanded ? 'row-cell expand-border' : 'row-cell bottom-border'

  return (
    <>
      <tr className="list-row">
        {dataKeys.map((key, index) => {
          const value = props.rowData[key]
          const textAlign = typeof value === 'number' ? 'textAlignCenter' : 'textAlignLeft'
          return (
            <td key={index} className={`${cellClassName} ${textAlign}`}>
              {value}
            </td>
          )
        })}
        <td className={cellClassName} onClick={() => setExpanded(!expanded)}>
          <DetailsButton className={expandButtonClassName} IconComponent={IconComponent} />
        </td>
      </tr>
      {expanded && (
        <tr className="details-row">
          <td colSpan={dataKeys.length + 1} className={cellClassName}>
            <div style={{ paddingTop: '40px' }}>{props.children}</div>
          </td>
        </tr>
      )}
    </>
  )
}

export default Row
