import { createContext } from 'react'
import {
  IBatteryAggregate,
  IElectricityAggregate,
  IGasAggregate,
  ISolarAggregate,
  ISummaryAggregate
} from '../interfaces/aggregate'
import { IBatteryUsage, IElectricityUsage, IGasUsage, ISolarUsage } from '../interfaces/usage'
import { IBatteryModel, IBatteryModelCreate } from '../interfaces/battery'
import {
  IDso,
  IElectricityContract,
  IElectricityContractCreate,
  IElectricityGridContract,
  IElectricityGridContractLarge,
  IElectricityGridContractLargeUpdate,
  IElectricityGridContractUpdate,
  IEnergyRetailer,
  IGasConnectionContract,
  IGasConnectionContractUpdate,
  IGasConnectionType,
  IGasContract,
  IGasContractCreate,
  IGasTariff,
  IGridConnectionType,
  IGridTariff
} from '../interfaces/contracts'
import {
  IHybridInverter,
  IHybridInverterCreate,
  IHybridInverterUpdate,
  IMainMeter,
  IMainMeterCreate,
  IMainMeterUpdate,
  IUserDevice,
  IUserHybridInverterDevice,
  IUserHybridInverterDeviceCreate,
  IUserMainMeterDevice
} from '../interfaces/device'

interface IAggregateContext {
  electricityAggregate: IElectricityAggregate
  gasAggregate: IGasAggregate
  solarAggregate: ISolarAggregate
  batteryAggregate: IBatteryAggregate
  smartControllerDevicesAggregate: any[]
  summaryAggregate: ISummaryAggregate
}
interface IUsageContext {
  electricityUsage: IElectricityUsage
  gasUsage: IGasUsage
  solarUsage: ISolarUsage
  batteryUsage: IBatteryUsage
}

interface IDeviceContext {
  mainMeters: IMainMeter[] // TODO change to type
  hybridInverters: IHybridInverter[]
  smartControllers: any[]
  userMainMeterDevices: IUserMainMeterDevice[] // TODO change to device
  userHybridInverterDevices: IUserHybridInverterDevice[]
  userSmartControllerDevices: any[],
  activeUserMainMeterDevice: IUserMainMeterDevice | null
  activeUserHybridInverterDevice: IUserHybridInverterDevice | null
  activeBoilerDevice: IUserDevice | null,
  activeSpaceHeaterDevice: IUserDevice | null,
  activeSmartPlugDevice: IUserDevice | null,
  userHas: (deviceType: string) => boolean
  createMainMeter: (data: IMainMeterCreate) => Promise<void>
  createHybridInverter: (data: IHybridInverterCreate) => Promise<void>
  createSmartController: (data: any) => Promise<void>
  updateMainMeter: (data: IMainMeterUpdate) => Promise<void>
  updateHybridInverter: (data: IHybridInverterUpdate) => Promise<void>
  updateSmartController: (data: any) => Promise<void>
  addMainMeterDevice: (mainMeterLibraryId: number) => Promise<void>
  addHybridInverterDevice: (hybridInverterLibraryId: number, payload: IUserHybridInverterDeviceCreate) => Promise<void>
  updateHybridInverterDevice: (
    deviceId: string,
    payload: IUserHybridInverterDeviceCreate,
  ) => Promise<void> // TODO rename to IUserHybridInverterDeviceCreateUpdate
  updateMainMeterDeviceGasConnected: (value: boolean) => Promise<void>
  updateSmartControllerDevice: (deviceId: string, payload: any) => Promise<void>

  removeMainMeterDevice: (mainMeterDevice: IUserMainMeterDevice) => Promise<void>
  removeHybridInverterDevice: (hybridInverterDevice: IUserHybridInverterDevice) => Promise<void>
  activateMainMeterDevice: (mainMeterDeviceId: string) => Promise<void>
  activateHybridInverterDevice: (hybridInverterDeviceId: string) => Promise<void>
  deactivateMainMeterDevice: (mainMeterDeviceId: string) => Promise<void>
  deactivateHybridInverterDevice: (hybridInverterDeviceId: string) => Promise<void>
  toggleSmartControllerDevice: (smartControllerDeviceId: string) => Promise<void>
  resetSmartControllerDeviceReading: (smartControllerDeviceId: string) => Promise<void>
  switchSmartControllerDevice: (deviceId: string, on: boolean) => Promise<void>
}

interface IContractContext {
  electricity: IElectricityContract
  defaultElectricity: IElectricityContract
  electricityGrid: IElectricityGridContract
  electricityGridLarge: IElectricityGridContractLarge | null
  gas: IGasContract
  defaultGas: IGasContract
  gasConnection: IGasConnectionContract
  gridTariff: IGridTariff
  gasTariff: IGasTariff
  energyRetailers: IEnergyRetailer[]
  tariffTypes: string[]
  dsos: IDso[]
  gridConnectionTypes: IGridConnectionType[]
  gasConnectionTypes: IGasConnectionType[]
  consumerTypes: string[]
  fetchGridTariff: (dsoId: number | null, gridConnectionTypeId: number | null) => Promise<void>
  fetchGasConnectionTariff: (dsoId: number | null, gasConnectionTypeId: number | null) => Promise<void>
  createElectricityContract: (data: IElectricityContractCreate) => Promise<void>
  updateElectricityGridContract: (data: IElectricityGridContractUpdate) => Promise<void>
  updateElectricityGridContractLarge: (data: IElectricityGridContractLargeUpdate) => Promise<void>
  createGasContract: (data: IGasContractCreate) => Promise<void>
  updateGasConnectionContract: (data: IGasConnectionContractUpdate) => Promise<void>
}

interface IBatteryModelContext {
  batteryModels: IBatteryModel[]
  createBatteryModel: (data: IBatteryModelCreate) => Promise<void>
}

export interface IDataContext {
  aggregate: IAggregateContext
  device: IDeviceContext
  contract: IContractContext
  battery: IBatteryModelContext
}

const DataContext = createContext<IDataContext | undefined>(undefined)

export default DataContext
