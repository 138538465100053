import * as React from 'react'
import { Image } from 'semantic-ui-react'
import { RouteComponentProps, withRouter } from 'react-router'
import * as PropTypes from 'prop-types'
import * as successful from '../../assets/Successful.png'
import './register.scss'

type SomeComponentProps = RouteComponentProps
// Component to display message that gateway connection has been done successfully.
const RegisterSuccessful: React.FC<SomeComponentProps> = ({ history }) => {
  setTimeout(() => {
    history.push('/customerdetails/3')
  }, 10000)

  return (
    <>
      <div className="flex1-center flex1-column py-top">
        <strong className="font-medium m-bottom">Gateway gekoppeld</strong>
        <div className="flex1-column flex1-start">
          Gefeliciteerd, uw Lyv gateway is succesvol
          <br />
          gekoppeld! In de volgende stap kunt u uw <br />
          gebruikersgegevens invullen.
        </div>
        <div className="succesful-py-top">
          <Image src={successful.default} />
        </div>
      </div>
    </>
  )
}
RegisterSuccessful.propTypes = {
  history: PropTypes.any.isRequired
}
export default withRouter(RegisterSuccessful)
