import api from '../api'
import { IElectricityUsage, IGasUsage, ISolarUsage, IBatteryUsage } from '../interfaces/usage'
import { getConfig } from './base'

export default class UsageService {
  getElectricityUsage = async (accessToken: string, day: string | undefined, forecast: boolean = false): Promise<IElectricityUsage> => {
    const config = getConfig(accessToken)
    config.params = {
      day,
      forecast
    }
    const result = await api.get(`/usage/electricity`, config)
    return result.data
  }

  getGasUsage = async (accessToken: string, day: string | undefined, forecast: boolean = false): Promise<IGasUsage> => {
    const config = getConfig(accessToken)
    config.params = {
      day,
      forecast
    }
    const result = await api.get(`/usage/gas`, config)
    return result.data
  }

  getBatteryChargedUsage = async (accessToken: string, day: string | undefined): Promise<IBatteryUsage> => {
    const config = getConfig(accessToken)
    let queryParams = ''
    if (day) {
      queryParams = `&day=${day}`
    }
    const result = await api.get(`/usage/battery?charge=true${queryParams}`, config)
    // const result = await api.get('/usage/battery?charge=true', config)
    return result.data
  }

  getBatteryDischargedUsage = async (accessToken: string, day: string | undefined): Promise<IBatteryUsage> => {
    const config = getConfig(accessToken)
    let queryParams = ''
    if (day) {
      queryParams = `?day=${day}`
    }
    const result = await api.get(`/usage/battery${queryParams}`, config)
    // const result = await api.get('/usage/battery', config)
    return result.data
  }

  getSolarUsage = async (accessToken: string, day: string | undefined): Promise<ISolarUsage> => {
    const config = getConfig(accessToken)
    let queryParams = ''
    if (day) {
      queryParams = `?day=${day}`
    }
    const result = await api.get(`/usage/solar${queryParams}`, config)
    return result.data
  }
}
