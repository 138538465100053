import * as React from 'react'
import { Grid, GridColumn, Image, Divider } from 'semantic-ui-react'
import ReactToPrint from 'react-to-print'
import * as logoWhite from '../../assets/rsz_logo_rgb_3-09-2019_3.jpg'
import * as userLogo from '../../assets/report_user_logo.png'
import * as downloadIcon from '../../assets/download_icon.png'
import UserContext from '../../contexts/user'
import DataContext from '../../contexts/data'
import AuthContext from '../../contexts/auth'
import { ConsumerType, clientType } from '../../enums'
import { industryTypeOptions, houseTypeOptions } from '../../util/options'
import { ReportingService, AggregatedDateReportData } from '../../services/reporting'
import { useContext } from 'react'
import 'moment/locale/nl'
const moment = require('moment')

interface IReportHeaderProps {
  title: string
  elementRef: any
  onBeforePrint: () => any
  onAfterPrint: () => any
}
export const ReportHeader: React.FC<IReportHeaderProps> = (props: IReportHeaderProps) => {
  const userContext = useContext(UserContext)
  const dataContext = React.useContext(DataContext)
  const [isPrintMode, setIsPrintMode] = React.useState<boolean>(false)
  const [isPrivateSmallConsumer, setPrivateSmallConsumer] = React.useState<boolean>(false)
  const [industryType, setIndustryType] = React.useState<string | undefined | null>('')
  const [houseType, setHouseType] = React.useState<string | undefined | null>('')
  const pageStyle = `
    @media print {
      body{
        -webkit-print-color-adjust: exact;
        padding: 5px;
      }
      .flex1-row.flex1-start,
      .four.wide.column.report-content,
      .five.wide.column,
      .flex1-column,
      tr td {
        white-space: nowrap !important;
      }
      thead tr th {
        text-align: center !important;
      }
      .page-break {
        page-break-before: always !important;
      }
      .page-together {
        page-break-inside: avoid !important;
        margin-top: 0.31cm;
        margin-bottom: 0.31cm;
      }
      .aligned.four.wide.column {
        margin-top: 2cm !important;
      }
      .year-root-print-page>.ui.grid>.row>.wide.column {
        display: flex !important;
        flex-direction: row !important;
      }
      .financial-root-print-page>.ui.grid>.row>.wide.column {
        display: flex !important;
        flex-direction: row !important;
      }
    } 
  `
  React.useEffect(() => {
    if (
      userContext?.profile?.customerType === clientType.Private &&
      !!dataContext?.device.activeUserMainMeterDevice &&
      dataContext?.device.activeUserMainMeterDevice.consumerType === ConsumerType.Small
    ) {
      setPrivateSmallConsumer(true)
    }
  }, [])

  React.useEffect(() => {
    const industryType =
      userContext?.buildingDetail.industryType &&
      industryTypeOptions.find((item: any) => {
        return item.value === userContext?.buildingDetail.industryType
      })
    setIndustryType(industryType && industryType.text)

    const houseType =
    userContext?.buildingDetail.houseType &&
    houseTypeOptions.find((item: any) => {
      return item.value === userContext?.buildingDetail.houseType
    })
    setHouseType(houseType && houseType.text)
  })
  return (
    <>
      <Grid>
        {/* Header */}
        <Grid.Row style={{ paddingBottom: '10px' }}>
          <GridColumn width="seven">
            <div className="report-topleft">
              <Image src={logoWhite.default} />
              <strong
                style={{
                  fontWeight: 'bolder',
                  fontSize: '20px',
                  color: '#00add1',
                  marginLeft: '15px'
                }}
              >
                Rapporten
              </strong>
            </div>
          </GridColumn>
          <GridColumn width="three" textAlign="center">
            <div className="flex1-row flex1-center">
              <strong
                style={{
                  fontWeight: 'bolder',
                  fontSize: '24px',
                  color: '#4d4d4d'
                }}
              >
                {props.title}
              </strong>
            </div>
          </GridColumn>
          <GridColumn width="four" textAlign="right">
            <div className="flex1-row  flex-center-vertical">
              <Image src={userLogo.default} style={{ marginRight: '10px' }} />
              <div className="flex1-column">
                <div className="flex1-row flex1-start">
                  <strong style={{ color: '#4d4d4d', fontSize: '16px' }}>
                    {userContext?.profile?.firstName} {userContext?.profile?.lastName}
                  </strong>
                </div>
                <div className="flex1-row flex1-start">{moment().format('DD-MM-YY')}</div>
              </div>
            </div>
          </GridColumn>
          <Grid.Column width="two">
            <div className="flex1-colum f">
              <div>
                {!isPrintMode && (
                  <ReactToPrint
                    trigger={() => (
                      <div className="flex1-row flex1-center">
                        <Image
                          src={downloadIcon.default}
                          style={{ height: '40px', width: '40px', cursor: 'pointer' }}
                        />
                      </div>
                    )}
                    content={() => props.elementRef}
                    pageStyle={pageStyle}
                    onBeforeGetContent={() => {
                      setIsPrintMode(true)
                      props.onBeforePrint()
                    }}
                    onAfterPrint={() => {
                      setIsPrintMode(false)
                      props.onAfterPrint()
                    }}
                  />
                )}
              </div>
              <div>Download</div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid style={{ paddingTop: '10px', paddingBottom: '10px' }}>
        {/* Locatie, etc */}
        <Grid.Row>
          <GridColumn width="four">
            <strong
              style={{
                fontSize: '18px',
                color: '#4d4d4d'
              }}
            >
              Locatie
            </strong>
          </GridColumn>
          <GridColumn width="three" className="report-content">
            <div className="flex-column">
              <div className="flex1-row">
                <div>{userContext?.profile?.street}</div>
                &nbsp;
                <div>{userContext?.profile?.streetNumber}</div>
              </div>
              <div className="flex1-row">
                <div>{userContext?.profile?.postcode}</div>
                &nbsp;
                <div>{userContext?.profile?.city}</div>
              </div>
              <div>Nederland</div>
            </div>
          </GridColumn>
          <GridColumn width="2">
            <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
              Oppervlakte
              <br />
              Bouwjaar
              <br />
              Type
            </strong>
          </GridColumn>
          <GridColumn width="3" textAlign="right" className="report-content">
            {userContext?.buildingDetail.surfaceM2 ? userContext?.buildingDetail.surfaceM2 : 'N/A'}
            <br />
            {userContext?.buildingDetail.builtYear ? userContext?.buildingDetail.builtYear : 'N/A'}
            <br />
            {userContext?.profile?.customerType === clientType.Business
              ? userContext?.buildingDetail.industryType
                ? industryType
                : 'N/A'
              : userContext?.buildingDetail.houseType
                ? houseType
                : 'N/A'}
          </GridColumn>

          <GridColumn width="2">
            {isPrivateSmallConsumer ? (
              <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                Energielabel
                <br />
                Aantal personen
              </strong>
            ) : (
              <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                Energielabel
                <br />
                Medewerkers
                <br />
                Bedrijfsnaam
              </strong>
            )}
          </GridColumn>
          {isPrivateSmallConsumer ? (
            <GridColumn width="2" textAlign="right" className="report-content">
              {userContext?.buildingDetail.energyLabel ? userContext?.buildingDetail.energyLabel : 'N/A'}
              <br />
              {userContext?.settingsDetail.totalAdults && userContext?.settingsDetail.totalChildren
                ? userContext?.settingsDetail.totalAdults + userContext?.settingsDetail.totalChildren
                : 'N/A'}
            </GridColumn>
          ) : (
            <GridColumn width="2" textAlign="right" className="report-content">
              {userContext?.buildingDetail.energyLabel ? userContext?.buildingDetail.energyLabel : 'N/A'}
              <br />
              {userContext?.settingsDetail.totalFte ? userContext?.settingsDetail.totalFte : 'N/A'}
              <br />
              {userContext?.profile?.customerType === clientType.Business ? userContext?.profile?.userName : 'N/A'}
              <br />
            </GridColumn>
          )}
        </Grid.Row>
      </Grid>
      <Divider />
    </>
  )
}

export const ReportConnectionRow: React.FC = () => {
  const [reportingData, setReportingData] = React.useState<AggregatedDateReportData | undefined>([])
  const reportingService = new ReportingService()
  const authContext = React.useContext(AuthContext)
  const userContext = useContext(UserContext)
  const dataContext = React.useContext(DataContext)
  const [isBusinessLargeConsumer, setBusinessLargeConsumer] = React.useState<boolean>(false)

  const fetchData = async () => {
    const token = authContext?.accessToken!
    const result = await reportingService.getYearReportData(token)

    setReportingData(result)
  }
  React.useEffect(() => {
    fetchData()
    if (
      userContext?.profile?.customerType === clientType.Business &&
      !!dataContext?.device.activeUserMainMeterDevice &&
      dataContext?.device.activeUserMainMeterDevice.consumerType === ConsumerType.Large
    ) {
      setBusinessLargeConsumer(true)
    }
  }, [])
  return (
    <>
      <Grid>
        <GridColumn width="four">
          <strong style={{ color: '#4d4d4d', fontSize: '18px' }}>Aansluitgegevens</strong>
          <div className="report-content">Elektriciteit</div>
          <div className="report-content">Gas</div>
        </GridColumn>
        {isBusinessLargeConsumer && (
          <GridColumn width="four">
            <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Netbeheerder</strong>
            <br />
            <div className="flex1-column">
              <div className="report-content">{dataContext?.contract?.electricityGridLarge?.dso.name}</div>
              <div className="report-content">{'N/A'}</div>
            </div>
          </GridColumn>
        )}
        {!isBusinessLargeConsumer && (
          <GridColumn width="four">
            <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Netbeheerder</strong>
            <br />
            {reportingData &&
              reportingData.results &&
              reportingData.results.map((data: any, i: any) => {
                if (data.year === new Date().getFullYear()) {
                  return (
                    data.gridConnection &&
                    data.gasConnection && (
                      <div className="flex1-column" key={i}>
                        <div className="report-content">{data.gridConnection.dso}</div>
                        <div className="report-content">{data.gasConnection.dso}</div>
                      </div>
                    )
                  )
                }
              })}
          </GridColumn>
        )}
        {!isBusinessLargeConsumer && (
          <GridColumn width="four" className="report-content">
            <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Capaciteit</strong>
            {reportingData &&
              reportingData.results &&
              reportingData.results.map((data: any, i: any) => {
                if (data.year === new Date().getFullYear()) {
                  return (
                    data.gridConnection &&
                    data.gasConnection && (
                      <div className="flex1-column">
                        <div>{data.gridConnection.connectionType}</div>
                        <div>{data.gasConnection.connectionType}</div>
                      </div>
                    )
                  )
                }
              })}
          </GridColumn>
        )}
        {isBusinessLargeConsumer && (
          <GridColumn width="four" className="report-content">
            <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Capaciteit</strong>

            <div className="flex1-column">
              <div className="report-content">
                {dataContext?.contract?.electricityGridLarge?.gridConnectionTypeLarge.connectionName}
              </div>
              <div className="report-content">{'N/A'}</div>
            </div>
          </GridColumn>
        )}

        {isBusinessLargeConsumer && (
          <GridColumn width="four" className="report-content">
            <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Transportcategorie</strong>

            <div className="flex1-column">
              <div className="report-content">
                {dataContext?.contract?.electricityGridLarge?.gridTransportTypeLarge.transportName}
              </div>
              <div className="report-content">{'N/A'}</div>
            </div>
          </GridColumn>
        )}
      </Grid>
    </>
  )
}
