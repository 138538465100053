import * as React from 'react'
import { Input, Label } from 'semantic-ui-react'
import './configuration_details.scss'

interface IUnitProps {
  unitLabel: string
  width: string
  placeholder: string
  onChange?: (e: any) => any
  value?: any
  name?: string
  required?: boolean
}
const UnitInput = (props: IUnitProps) => {
  return (
    <div>
      <Input
        labelPosition="right"
        type="text"
        style={{ width: props.width }}
        id="unit-input"
        placeholder={props.placeholder}
        required
      >
        <input name={props.name} value={props.value} onChange={props.onChange} />
        <Label
          style={{
            borderLeft: 'none',
            borderRight: '1px solid #bdbdbd',
            borderTop: '1px solid #bdbdbd',
            borderBottom: '1px solid #bdbdbd',
            backgroundColor: 'white',
            color: '#4D4D4D',
            fontWeight: '400',
            height: '2.78rem'
          }}
        >
          {props.unitLabel}
        </Label>
      </Input>
    </div>
  )
}

export default UnitInput
