import { result } from 'lodash'
import api from '../api'
import {
  IElectricityAggregate,
  IGasAggregate,
  ISolarAggregate,
  IBatteryAggregate,
  ISummaryAggregate
} from '../interfaces/aggregate'
import { getConfig } from './base'

export const getElectricityAggregate = async (accessToken: string): Promise<IElectricityAggregate> => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/electricity', config)
  return result.data
}
export const getSolarAggregate = async (accessToken: string): Promise<ISolarAggregate> => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/solar', config)
  return result.data
}
export const getSummaryAggregate = async (accessToken: string): Promise<ISummaryAggregate> => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/summary', config)
  return result.data
}
export const getGasAggregate = async (accessToken: string): Promise<IGasAggregate> => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/gas', config)
  return result.data
}
export const getBatteryAggregate = async (accessToken: string): Promise<IBatteryAggregate> => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/battery', config)
  return result.data
}

export const getSmartControllerDevicesAggregate = async (accessToken: string): Promise<any[]> => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/smart-controllers', config)
  return result.data
}

export const getCommunityNetImportPower = async (accessToken: string, communityId: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      community_id: communityId
    }
  }
  const result = await api.get('/aggregates/electricity/import/community', config)
  return result.data
}

export const getCommunityTotalSolarPower = async (accessToken: string, communityId: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      community_id: communityId
    }
  }
  const result = await api.get('/aggregates/electricity/solar/community', config)
  return result.data
}

export const getCommunitiesTotalSolarPower = async (accessToken: string) => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/electricity/solar/communities', config)
  return result.data
}

export const getCommunityTotalHybridPower = async (accessToken: string, communityId: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    params: {
      community_id: communityId
    }
  }
  const result = await api.get('/aggregates/electricity/hybrid/community', config)
  return result.data
}

export const getCommunitiesNetImportPower = async (accessToken: string) => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/electricity/import/communities', config)
  return result.data
}

export const getCommunitiesTotalHybridPower = async (accessToken: string) => {
  const config = getConfig(accessToken)
  const result = await api.get('/aggregates/electricity/hybrid/communities', config)
  return result.data
}
