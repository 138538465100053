import api from '../api'

export default class GatewayService {
  listGateways = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get('/gateways/', config)
    const data = response.data
    data.map((gateway: any) => {
      gateway.lastUpdatedOn += 'Z'
    })
    return data.filter((gateway: any) => (gateway.userId !== null))
  }

  listUnidentifiedGateways = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get('/gateways/unidentified', config)
    const data = response.data
    data.map((gateway: any) => {
      gateway.lastUpdatedOn += 'Z'
    })
    return response.data
  }

  listCommunityGateways = async (accessToken: string, communityID: number) => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { community_id: communityID }
    }
    const response = await api.get('/gateways/community', config)
    const data = response.data
    data.map((gateway: any) => {
      gateway.lastUpdatedOn += 'Z'
    })
    return response.data
  }

  fetchGraphData = async (accessToken: string, startDate: string, endDate: string) => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        startDate: startDate,
        endDate: endDate
      }
    }
    const response = await api.get('/reporting/gateways/communities', config)
    const data = response.data
    return data
  }

  fetchUnidentifiedGraphData = async (accessToken: string, startDate: string, endDate: string) => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        startDate: startDate,
        endDate: endDate
      }
    }
    const response = await api.get('/reporting/gateways/unidentified', config)
    return response.data
  }

  fetchCommunityGraphData = async (accessToken: string, communityID: number, startDate: string, endDate: string) => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        community_id: communityID,
        startDate: startDate,
        endDate: endDate
      }
    }
    const response = await api.get('/reporting/gateways/community', config)
    return response.data
  }
}
