import * as React from 'react'
import { InlineTextInputField } from '../../Generic/InlineField'
import { Modal, Header, Message, Button } from 'semantic-ui-react'
import { IBatteryModelCreate } from '../../../interfaces/battery'
import DataContext from '../../../contexts/data'

const initialBatteryModelData: IBatteryModelCreate = {
  make: '',
  model: '',
  powerRating: 0,
  capacity: 0,
  type: ''
}

const AddBatteryModal = () => {
  const [createBatteryModalOpen, setCreateBatteryModalOpen] = React.useState<boolean>(false)
  const [newBatteryModelData, setNewBatteryModelData] = React.useState<IBatteryModelCreate>(initialBatteryModelData)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [successMessage, setSuccessMessage] = React.useState<string>('')

  const dataContext = React.useContext(DataContext)

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value
    setNewBatteryModelData({ ...newBatteryModelData, [name]: value })
  }

  const onClickAddBattery = async () => {
    try {
      await dataContext?.battery?.createBatteryModel(newBatteryModelData)
      setSuccessMessage('Battery added successfully')
    } catch (err) {
      setErrorMessage(err.errors[0])
    }
  }

  setTimeout(() => {
    setErrorMessage('')
    setSuccessMessage('')
  }, 5000)

  return (
    <Modal
      closeIcon
      open={createBatteryModalOpen}
      trigger={<Button type="button">Add Battery</Button>}
      onClose={() => setCreateBatteryModalOpen(false)}
      onOpen={() => setCreateBatteryModalOpen(true)}
    >
      <Header content="Add New Battery Model" />
      <Modal.Content>
        <div className="flex1-row flex1-center forgetpassword-inputform">
          <div className="flex1-column forgetpassword-container">
            <InlineTextInputField label="Make" placeholder="" value={newBatteryModelData.make} name="make" onChange={handleTextInputChange} />
            <InlineTextInputField label="Model" placeholder="" value={newBatteryModelData.model} name="model" onChange={handleTextInputChange} />
            <InlineTextInputField label="Power Rating" placeholder="" value={newBatteryModelData.powerRating.toString()} name="powerRating" onChange={handleTextInputChange} />
            <InlineTextInputField label="Capacity" placeholder="" value={newBatteryModelData.capacity.toString()} name="capacity" onChange={handleTextInputChange} />
            <InlineTextInputField label="Type" placeholder="" value={newBatteryModelData.type} name="type" onChange={handleTextInputChange} />
            <Button color="green" onClick={onClickAddBattery}>Add Battery</Button>
            {errorMessage && errorMessage !== '' && <Message color="red">{errorMessage}</Message>}
            {successMessage && successMessage !== '' && <Message color="green">{successMessage}</Message>}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default AddBatteryModal
