import * as React from 'react'
import { Form, Button, Image } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import * as logoWhite from '../../assets/rsz_logo_rgb_3-09-2019_3.jpg'
import { PairingSuccessful } from '..'
import './register.scss'
import OnboardService from '../../services/onboard'
import AuthContext from '../../contexts/auth'

type GatewayPairing = {
  gatewayConnectionNumber: string
}

// Component which takes koppelen nummber as the input in this format 191xAXExxxxxx
const GatewayKoppelen: React.FC = () => {
  const onboardService = new OnboardService()
  const authContext = React.useContext(AuthContext)

  const { register, handleSubmit, errors } = useForm<GatewayPairing>()
  const [pairingnNumberSent, setPairingNumberSent] = useState<boolean>(false)
  const [deviceInActive, setDeviceInActive] = useState<boolean>(false)

  const onSubmit = async (data: GatewayPairing) => {
    try {
      const token = authContext?.accessToken!
      const result = await onboardService.setupGateway(token, data.gatewayConnectionNumber)
      setPairingNumberSent(true)
    } catch {
      setDeviceInActive(true)
    }
    setTimeout(() => {
      setDeviceInActive(false)
    }, 5000)
  }

  return (
    <>
      {pairingnNumberSent ? (
        <PairingSuccessful />
      ) : (
        <>
          <div className="flex1-column-start">
            <div className="gateway-koppelen-form-container gateway-m-top ">
              <div className="gateway-container flex1-column flex1-center">
                <div className="flex1-start gateway-m-top ">
                  <strong className="m-bottom font-medium">Gateway koppelen</strong>
                  <p className="flex1-column instruction m-bottom gateway-m-top ">
                    Voer het Serial No (S/N) nummer van de
                    <br />
                    gateway in. Dit nummer kunt u vinden op de
                    <br />
                    verpakking van de gateway of op de achterkant
                    <br />
                    van de gateway zelf.
                  </p>
                </div>
                <div className="form-container flex1-center flex1-column gateway-m-top">
                  <Form onSubmit={handleSubmit(onSubmit)} stackable>
                    <div>
                      <div className="flex1-center flex1-column logo-margin">
                        <Image src={logoWhite.default} className="logo-size" />
                        <h4>Smart Energy Platform</h4>
                      </div>
                      <Form.Field>
                        <input
                          placeholder="Nummer gateway"
                          className="form1-item"
                          name="gatewayConnectionNumber"
                          ref={register({
                            required: true,
                            pattern: /(1{1,1}[8-9]{1,1}[0-9]{1,1}[0-9]{1,1}A{1,1}X{1,1}E{1,1}[0-9]{6,6})/
                          })}
                        />
                        {errors.gatewayConnectionNumber && errors.gatewayConnectionNumber.type === 'required' && (
                          <div className="error-msg">Voer hier uw nummer in.</div>
                        )}
                        {errors.gatewayConnectionNumber && errors.gatewayConnectionNumber.type === 'pattern' && (
                          <div className="error-msg">
                            Uw gateway- nummer komt niet overeen met dit nummer 191xAXExxxxxx. Controleer het nummer van
                            uw gateway.
                          </div>
                        )}
                        {deviceInActive && (
                          <div className="error-msg">
                            Het is niet mogelijk om de gateway in te stellen. Is de gateway uitgeschakeld? Of is de
                            gateway niet beschikbaar.
                          </div>
                        )}
                      </Form.Field>
                    </div>
                    <Button className="gatewaykoppelen-ok-btn btn-green" content="OK" />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default GatewayKoppelen
