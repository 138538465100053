import * as React from 'react'
import { useState } from 'react'
import { EnergySupplyFlow, EnergySupplyGas, NetworkManagemntFlow, NetworkOperatorGas } from '../..'
import './smart_meter_details.scss'
import { Confirm, Dropdown } from 'semantic-ui-react'
import DataContext from '../../../contexts/data'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { confirmationOptions } from '../../../util/options'

const SmartMeterDetails: React.FC = () => {
  const dataContext = React.useContext(DataContext)
  const [confirmValue, setConfirmValue] = useState<boolean>(
    dataContext?.device?.userMainMeterDevices[0]?.isGasConnected || false
  )
  const [open, setOpen] = useState(false)
  const [openGasYes, setOpenGasYes] = useState(false)
  const close = () => {
    setConfirmValue(dataContext?.device?.userMainMeterDevices[0]?.isGasConnected!)
    setOpen(false)
  }
  const confirm = async () => {
    await dataContext?.device?.updateMainMeterDeviceGasConnected(confirmValue)
    setOpen(false)
  }
  const onChange = (e: any, data: any) => {
    setConfirmValue(data.value)
    setOpen(true)
  }

  return (
    <div className="asset-detail-container flex1-row">
      <div className="flex1-column">
        <strong className="flex1-row flex1-start">Energieleverancier stroom</strong>
        <EnergySupplyFlow />
        <strong className="flex1-row flex1-start" style={{ marginTop: '10px' }}>
          Netwerkbeheer stroom
        </strong>
        <NetworkManagemntFlow />
      </div>
      <div className="flex1-column">
        <div className="flex1-row flex-center-vertical">
          <strong className="flex1-row flex1-start" style={{ marginLeft: '5px' }}>
            Energieleverancier gas
          </strong>
          <Dropdown
            id="confirm"
            style={{ marginLeft: '25px', width: '80px' }}
            options={confirmationOptions}
            value={confirmValue}
            onChange={onChange}
            selection
            fluid
          />
          <div className="tooltip" style={{ width: '30px' }}>
            <IoMdInformationCircleOutline className="info" />
            <div className="tooltiptext" style={{ height: '50px', bottom: '-220%', left: '-800%' }}>
              Heeft u gas? Selecteer dan ja.
            </div>
          </div>
          {open && (
            <Confirm
              open={open}
              onCancel={close}
              onConfirm={confirm}
              content={confirmValue ? 'Wilt u de gasaansluiting koppelen?' : 'Wilt u de gasaansluiting verbreken?'}
              cancelButton="Nee"
              confirmButton="Ja"
            />
          )}
          {openGasYes && (
            <Confirm
              open={openGasYes}
              onCancel={close}
              onConfirm={confirm}
              content="
              Wilt u de gasaansluiting koppelen?"
              cancelButton="Nee"
              confirmButton="Ja"
            />
          )}
        </div>
        {dataContext?.device?.userMainMeterDevices[0]?.isGasConnected && (
          <div className="flex1-column">
            <EnergySupplyGas />
            <strong className="flex1-row flex1-start network-m-t-small " style={{ marginLeft: '5px' }}>
              Netwerkbeheer gas
            </strong>
            <NetworkOperatorGas />
          </div>
        )}
      </div>
    </div>
  )
}

export default SmartMeterDetails
