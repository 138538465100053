import * as React from 'react'
import './battery.scss'
import { Image } from 'semantic-ui-react'
import * as arrowIcon from '../../../assets/Arrow.svg'
import * as blueUpArrow from '../../../assets/blue_up_arrow.png'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import * as upArrow from '../../../assets/up_arrow.png'
import { useContext } from 'react'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryStack, VictoryLabel } from 'victory'
import { chargedColorScae, dischargedColorScae } from '../../../util/graph_data'
import { DutchBatteryState, EnBatteryState } from '~/enums'
import * as batteryEmpty from '../../../assets/full_battery.svg'
import * as batteryFull from '../../../assets/empty_battery.svg'
import DataContext from '../../../contexts/data'
import AuthContext from '../../../contexts/auth'
import { tickCount, batteryLevel } from '../../../util/helper'
import UsageService from '../../../services/usage'
import { IBatteryAggregate } from '../../../interfaces/aggregate'

const moment = require('moment')

interface IProps {
  setTileExpanded: (value: string) => void
  tileExpanded: string
}

const Battery: React.FC<IProps> = (props: IProps) => {
  const [expand, setExpand] = React.useState(false)
  const [batterytickCount, setBatteryTickcount] = React.useState<number>()
  const [value, setValue] = React.useState<string | undefined>(moment(new Date()).format('YYYY-MM-DD'))
  const [arrowUp, setArrowUp] = React.useState(false)
  const [usageChargedData, setUsageChargedData] = React.useState([])
  const [usageDischargedData, setUsageDischargedData] = React.useState([])
  const dataContext = useContext(DataContext)
  const [battery, setBattery] = React.useState<IBatteryAggregate | undefined>(dataContext?.aggregate?.batteryAggregate)
  const authContext = React.useContext(AuthContext)
  const usageService = new UsageService()
  const detailsExpand = () => {
    setExpand(!expand)
  }
  const closeGraph = () => {
    setArrowUp(true)
    props.setTileExpanded('')
  }
  const handleDate = (e: any) => {
    setValue(e.target.value)
    setArrowUp(false)
  }
  const rightArrowClicked = () => {
    props.setTileExpanded('battery')
  }

  const state = () => {
    if (battery && battery.state === EnBatteryState.Idle) {
      return DutchBatteryState.Inactief
    } else if (battery && battery.state === EnBatteryState.Charging) {
      return DutchBatteryState.Opladen
    } else if (battery && battery.state === EnBatteryState.Discharging) {
      return DutchBatteryState.Ontladen
    }
  }
  // batteryLevel(battery)
  const fetchChargeData = async () => {
    const token = authContext?.accessToken!
    const resultsCharged = await usageService.getBatteryChargedUsage(token, value)
    setUsageChargedData(resultsCharged)
  }
  const fetchDischargeData = async () => {
    const token = authContext?.accessToken!
    const resultsDischarged = await usageService.getBatteryDischargedUsage(token, value)
    setUsageDischargedData(resultsDischarged)
  }
  React.useEffect(() => {
    fetchChargeData()
    fetchDischargeData()
  }, [value])
  React.useEffect(() => {
    setBatteryTickcount(tickCount(usageDischargedData))
  }, [usageDischargedData])

  const isAllChargedZero: boolean = usageChargedData.every((item: any) => item.value === 0)
  const isAllDisChargedZero: boolean = usageDischargedData.every((item: any) => item.value === 0)

  React.useEffect(() => {
    setBattery(dataContext?.aggregate?.batteryAggregate)
  }, [dataContext?.aggregate?.batteryAggregate])

  return (
    <>
      <div className="grid-container flex1-column">
        <strong className="title">Batterij</strong>
        <hr />
        <div className="tile-content">
          <div className={props.tileExpanded ? 'expanded-tile' : 'normal-tile'}>
            <strong
              className="flex1-column flex1-center discharged"
              style={{ marginTop: '30px', marginBottom: '35px' }}
            >
              {state()}
            </strong>
            <div className="flex1-row flex1-center">
              <div className="battery-outer" style={{ justifyContent: 'center' }}>
                <img src={batteryFull.default} className="battery-size" />
                <div className="battery-inner" style={{ width: `${batteryLevel(battery)}px` }}>
                  <img src={batteryEmpty.default} className="battery-size" />
                </div>
              </div>
            </div>
            <div className="flex1-row battery-filled-value flex1-center">
              <strong className="discharged">{battery && `${battery.percentage}%`}</strong>
            </div>
          </div>
        </div>
        {!props.tileExpanded && (
          <div className="flex1-row flex1-end">
            <Image src={arrowIcon.default} className="battery-arrow-icon" onClick={rightArrowClicked} />
          </div>
        )}
        {props.tileExpanded && (
          <div>
            <div className="flex1-row space-between m-t-large">
              <strong>Batterij historie</strong>
              <div className="fle1-row flex-center-vertical" onClick={detailsExpand}>
                <strong className="detail-txt select-period">Selecteer periode</strong>
                <input type="date" className="input-date" style={{ opacity: '0' }} onChange={handleDate}></input>
                {expand && <Image src={blueUpArrow.default} style={{ marginBottom: '2px' }} />}
                {!expand && <Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
              </div>
            </div>
            <hr />
            <div>
              {value && (
                <div className="m-t-medium">
                  <div style={{ fontSize: '14px' }}> {value && moment(value).format('DD MMMM YYYY')}</div>
                  <div className="monitor-graph-container m-t-medium">
                    <VictoryChart
                      height={80}
                      scale={{ x: 'time' }}
                      padding={{ top: 5, bottom: 20, right: 10, left: 26 }}
                    >
                      {usageChargedData && usageDischargedData && isAllChargedZero && isAllDisChargedZero ? (
                        <VictoryAxis
                          dependentAxis={true}
                          crossAxis={false}
                          style={{
                            tickLabels: { fontSize: '5px', padding: 3 }
                          }}
                          tickFormat={() => ''}
                        />
                      ) : (
                        <VictoryAxis
                          dependentAxis={true}
                          crossAxis={false}
                          style={{
                            tickLabels: { fontSize: '5px', padding: 3 }
                          }}
                          tickFormat={(t) => t.toFixed(2)}
                        />
                      )}
                      {usageChargedData && usageDischargedData && isAllChargedZero && isAllDisChargedZero ? (
                        <VictoryAxis tickFormat={() => ''} />
                      ) : (
                        <VictoryAxis
                          style={{
                            axis: { stroke: 'black' },
                            grid: { stroke: 'rgba(204,204,204)', width: '2px' },
                            tickLabels: { fontSize: '5px' }
                          }}
                          tickFormat={(time) => {
                            return moment(time).format('HH:mm')
                          }}
                          tickLabelComponent={<VictoryLabel dy={-40} dx={4} />}
                          tickCount={batterytickCount && batterytickCount}
                        // tickCount={6}
                        />
                      )}
                      {usageChargedData.length > 1 && usageDischargedData.length > 1 && (
                        <VictoryStack>
                          <VictoryArea
                            style={{ data: { fill: '#26869f', opacity: '0.6' } }}
                            interpolation="basis"
                            data={
                              usageChargedData &&
                              usageChargedData.map((data: any) => {
                                return {
                                  x: data.time,
                                  y: data.value
                                }
                              })
                            }
                          />
                          <VictoryArea
                            interpolation="cardinal"
                            style={{ data: { fill: '#dc7570', opacity: '0.6' } }}
                            data={
                              usageDischargedData &&
                              usageDischargedData.map((data: any) => {
                                return {
                                  x: data.time,
                                  y: data.value
                                }
                              })
                            }
                          />
                        </VictoryStack>
                      )}
                      <VictoryLabel
                        textAnchor="end"
                        style={{ fontSize: 8, fill: chargedColorScae }}
                        x={200}
                        y={75}
                        text="Opladen"
                      />
                      <VictoryLabel
                        textAnchor="end"
                        style={{ fontSize: 8, fill: dischargedColorScae }}
                        x={300}
                        y={75}
                        text="Ontladen"
                      />
                    </VictoryChart>
                  </div>
                  <div className="flex1-row flex1-end">
                    <Image src={upArrow.default} onClick={closeGraph} className=" monitor-arrow-up" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default Battery
