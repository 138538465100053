import * as React from 'react'
import { useState, useEffect } from 'react'
import { Dropdown, Form, Button, Message } from 'semantic-ui-react'
import { IDropdownOption } from '../../../interfaces/general'
import { InlineTextInputField } from '../InlineField'

interface IListWithOtherProps {
    options: IDropdownOption[]
    selected: any
    placeholder: string
    name: string
    label: string
    hasOther?: boolean
    allowSearch?: boolean
    allowMultipleSelection?: boolean
    setSelected: (name: string, selected: any) => void
}

const ListWithOther = (props: IListWithOtherProps) => {
  const otherOption: IDropdownOption = { key: 'other', value: 'other', text: 'Other' }

  const [otherValue, seOtherValue] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([])

  setTimeout(() => {
    setErrorMessage('')
  }, 5000)

  const onChange = (e: any, data: any) => {
    props.setSelected(props.name, data.value)
  }

  const onAddOtherClick = (): void => {
    // create new dropdown option from the text in the 'other' input field
    const newOption: IDropdownOption = { key: otherValue, text: otherValue, value: otherValue }
    // check if new option already exists in list of options and set error message
    const existingOptionWithSameValue = dropdownOptions.find((e) => e.value === otherValue)
    if (existingOptionWithSameValue) {
      setErrorMessage('Option already exists')
    } else {
      // new dropdown options should contain the newly added option
      const newdropDownOptions = [...props.options, newOption, otherOption]
      setDropdownOptions(newdropDownOptions)
      if (props.allowMultipleSelection) {
        // for multiple selection filter out the previously selected other option
        // and set new option as part of selected values
        const oldSelected = props.selected as any[]
        const newSelected = oldSelected.filter((v) => v !== otherOption.value)
        newSelected.push(newOption.value)
        props.setSelected(props.name, newSelected)
      } else {
        // set new option as selected value
        props.setSelected(props.name, newOption.value)
      }
    }
  }

  const isOtherSelected = (): boolean => {
    if (props.allowMultipleSelection) {
      const selectedOptions = props.selected as any[]
      const selectedOtherOption = selectedOptions.find((opt) => opt === otherOption.value)
      return !!selectedOtherOption
    } else { return props.selected === otherOption.value }
  }

  useEffect(() => {
    if (props.hasOther) {
      setDropdownOptions([...props.options, otherOption])
    } else { setDropdownOptions(props.options) }
  }, [props.options])

  return (
    <>
      <div className="flex1-row">
        <p className="customer-detail-names">{props.label}</p>
        <Form.Field className="flex1-row">
          <Dropdown
            selection
            search={props.allowSearch}
            value={props.selected}
            multiple={props.allowMultipleSelection}
            placeholder={props.placeholder}
            onChange={onChange}
            options={dropdownOptions}/>
        </Form.Field>
      </div>

      {isOtherSelected() &&
      <div>
        <InlineTextInputField placeholder="" label="Other value" value={otherValue} onChange={(e) => seOtherValue(e.target.value)}/>
        <Button type="button" onClick={onAddOtherClick}>Add</Button>
      </div> }
      {errorMessage && errorMessage.length && <Message color="red">{errorMessage}</Message>}
    </>)
}

export default ListWithOther
