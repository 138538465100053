import * as React from 'react'
import { Grid, Segment, Modal } from 'semantic-ui-react'
import {
  Boiler,
  Details,
  ElectricAuto,
  SpaceHeater,
  SmartMeter,
  SmartPlug,
  AddDevice,
  HybridInverter,
  LargeConsumerSmartMeter
} from '../..'
import './assets.scss'
import '../Monitor/monitor.scss'
import DataContext from '../../../contexts/data'
import { ConsumerType } from '~/enums'
import { IUserDevice } from '~/interfaces/device'

const Assets: React.FC = () => {
  const dataContext = React.useContext(DataContext)
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Modal
        closeIcon
        open={open}
        trigger={<button className="add-device">Bewerk apparaten</button>}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        id="modal"
        stackable
      >
        <Modal.Content>
          <AddDevice />
        </Modal.Content>
      </Modal>
      <Grid columns={3} stackable>
        <Grid.Row style={{ marginLeft: '20px', marginRight: '20px', paddingTop: '0px' }}>
          {false && (
            <Grid.Column style={{ marginTop: '20px' }}>
              <Segment className="grid-height-half-middle">
                <ElectricAuto />
              </Segment>
            </Grid.Column>
          )}
          {!!dataContext?.device.activeUserMainMeterDevice && (
            <Grid.Column style={{ marginTop: '25px' }}>
              <Segment className="grid-height-half-middle">
                {dataContext?.device.activeUserMainMeterDevice.consumerType === ConsumerType.Small && <SmartMeter />}
                {dataContext?.device.activeUserMainMeterDevice.consumerType === ConsumerType.Large && (
                  <LargeConsumerSmartMeter />
                )}
              </Segment>
            </Grid.Column>
          )}{' '}
          {!!dataContext?.device.activeUserHybridInverterDevice && (
            <Grid.Column style={{ marginTop: '25px' }}>
              <Segment className="grid-height-half-middle">
                <HybridInverter />
              </Segment>
            </Grid.Column>
          )}
          {!!dataContext?.device.activeBoilerDevice &&
            dataContext?.device.activeBoilerDevice.map((boiler: IUserDevice, index: React.Key | undefined) => {
              return (
                <Grid.Column style={{ marginTop: '25px' }} key={index}>
                  <Segment className="grid-height-half-middle">
                    <Boiler device={boiler} />
                  </Segment>
                </Grid.Column>
              )
            })}
          {!!dataContext?.device.activeSmartPlugDevice &&
            dataContext?.device.activeSmartPlugDevice.map((smartPlug: IUserDevice, index: React.Key | undefined) => {
              return (
                <Grid.Column style={{ marginTop: '25px' }} key={index}>
                  <Segment className="grid-height-half-middle">
                    <SmartPlug device={smartPlug} />
                  </Segment>
                </Grid.Column>
              )
            })}
          {!!dataContext?.device.activeSpaceHeaterDevice &&
            dataContext?.device.activeSpaceHeaterDevice.map(
              (spaceHeater: IUserDevice, index: React.Key | undefined) => {
                return (
                  <Grid.Column style={{ marginTop: '25px' }} key={index}>
                    <Segment className="grid-height-half-middle">
                      <SpaceHeater device={spaceHeater} />
                    </Segment>
                  </Grid.Column>
                )
              },
            )}
          <Grid.Column style={{ marginTop: '25px' }}>
            <Segment className="grid-height-half-middle">
              <Details add={() => setOpen(true)} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}
export default Assets
