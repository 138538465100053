import * as React from 'react'
import { Grid, Form, Input, Dropdown } from 'semantic-ui-react'
import * as save from '../../../assets/save.png'
import * as unsave from '../../../assets/unsave.png'
import { IUserDevice } from '../../../interfaces/device'
import { SmartControllerApplianceTypeOptions } from '~/util/options'
import { SmartControllerApplianceType, SmartControllerApplicaiton, SmartControllerApplianceTypeDutch } from '~/enums'
import * as helper from '../../../util/helper'
import UnitInput from './unit_input'
import DataContext from '~/contexts/data'
import styled from 'styled-components'

interface IProps {
  addConfiguration: string
  library: IUserDevice
  expand: boolean
  setExpand: (value: boolean) => void
}

const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown > .text {
    color: #4d4d4d !important;
  }
  &.ui.selection.dropdown {
    border-color: rgba(34, 36, 38, 0.35);
    padding-left: 0.5rem;
  }
  &.ui.selection.dropdown > .dropdown.icon {
    top: 0.5em !important;
  }
`
const SmartCotrolllerDetailContainer = (props: IProps) => {
  const dataContext = React.useContext(DataContext)
  const [formData, setFormData] = React.useState<any>({
    applianceName: props.library.applianceName,
    powerRating: props.library.powerRating * 1000,
    applianceType: props.library.applianceType,
    boilerCapacity: props.library.boilerCapacity
  })
  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const onSubmit = async () => {
    try {
      const data = {
        ...formData,
        powerRating: formData.powerRating,
        zigbeeProtocolParameter: { ieee: props.library.serialNumber }
      }
      await dataContext?.device.updateSmartControllerDevice(props.library.deviceId, data)
      props.setExpand(!props.expand)
    } catch (error) {
      console.log('error updating device', error)
      console.error(error)
    }
  }
  return (
    <div className="detail-container device-selected-detail" style={{ borderTop: 'none' }}>
      <Form>
        <Grid>
          {props.addConfiguration === SmartControllerApplicaiton.GENERIC && (
            <>
              <Grid.Row style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium ">Naam</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <Input
                    className="full-width"
                    placeholder="Naam"
                    name="applianceName"
                    onChange={handleInputChange}
                    value={
                      formData.applianceName
                        ? helper.capitalize(formData.applianceName)
                        : helper.deviceDescriptionAssetApplicationMapping.generic.generic_dutch
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Type</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <StyledDropdown
                    options={SmartControllerApplianceTypeOptions}
                    value={formData.applianceType}
                    onChange={(e, data) => setFormData({ ...formData, applianceType: data.value })}
                    className="full-width"
                    style={{ height: '36px', backgroundColor: 'white' }}
                    selection
                    name="applianceType"
                    placeholder="Type"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          {props.addConfiguration === SmartControllerApplicaiton.BOILER && (
            <>
              <Grid.Row style={{ paddingTop: '1.5rem', paddingBottom: '0.7rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Naam</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <Input
                    className="full-width"
                    placeholder="Naam"
                    onChange={handleInputChange}
                    value={
                      formData.applianceName
                        ? helper.capitalize(formData.applianceName)
                        : helper.deviceDescriptionAssetApplicationMapping.boiler.boiler_dutch
                    }
                    name="applianceName"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Inhoud</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <UnitInput
                    unitLabel="Liter"
                    width="82%"
                    placeholder="Inhoud"
                    name="boilerCapacity"
                    value={formData.boilerCapacity ? formData.boilerCapacity : ''}
                    onChange={handleInputChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Vermogen</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <UnitInput
                    unitLabel="W"
                    width="86.5%"
                    onChange={handleInputChange}
                    placeholder="Vermogen"
                    value={formData.powerRating ? formData.powerRating : ''}
                    name="powerRating"
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          {props.addConfiguration === SmartControllerApplicaiton.SPACE_HEATER && (
            <>
              <Grid.Row style={{ paddingTop: '1.5rem', paddingBottom: '0.7rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Naam</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <Input
                    className="full-width"
                    placeholder="Naam"
                    name="applianceName"
                    onChange={handleInputChange}
                    value={
                      formData.applianceName
                        ? helper.capitalize(formData.applianceName)
                        : helper.deviceDescriptionAssetApplicationMapping.space_heater.space_heater_dutch
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Vermogen</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <UnitInput
                    unitLabel="W"
                    width="86.5%"
                    name="powerRating"
                    onChange={handleInputChange}
                    placeholder="Vermogen"
                    value={formData.powerRating ? formData.powerRating : ''}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          <Grid.Row style={{ paddingTop: '1.5rem', paddingBottom: '0.7rem' }}>
            <Grid.Column width="4" textAlign="left" verticalAlign="middle">
              <div className="heading medium">Make</div>
            </Grid.Column>
            <Grid.Column width="12" textAlign="left">
              <input
                className="full-width"
                placeholder="Make"
                disabled
                value={props.library.deviceLibrary.make ? props.library.deviceLibrary.make : ''}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
            <Grid.Column width="4" textAlign="left" verticalAlign="middle">
              <div className="heading medium">Model</div>
            </Grid.Column>
            <Grid.Column width="12" textAlign="left">
              <input
                className="full-width"
                placeholder="Model"
                disabled
                value={props.library.deviceLibrary.model ? props.library.deviceLibrary.model : ''}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
            <Grid.Column width="4" textAlign="left" verticalAlign="middle">
              <div className="heading medium">Serienummer</div>
            </Grid.Column>
            <Grid.Column width="12" textAlign="left">
              <input
                style={{ backgroundColor: 'grey' }}
                className="full-width"
                placeholder="Serial number"
                disabled
                value={helper.formatSerialNumber(props.library.serialNumber)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
            <Grid.Column width="10"></Grid.Column>
            <Grid.Column width="6" textAlign="right">
              <img src={unsave.default} onClick={() => props.setExpand(!props.expand)}></img>
              <img src={save.default} onClick={onSubmit}></img>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  )
}

export default SmartCotrolllerDetailContainer
