import * as React from 'react'
import { useState, useEffect, useContext } from 'react'
import { Segment, Dropdown, Input } from 'semantic-ui-react'
import { VictoryChart, VictoryArea, VictoryAxis, VictoryLabel, VictoryGroup, VictoryLegend } from 'victory'
import {
  getCommunityNetImportPower,
  getCommunityTotalHybridPower,
  getCommunitiesNetImportPower,
  getCommunitiesTotalHybridPower,
  getCommunitiesTotalSolarPower,
  getCommunityTotalSolarPower
} from '../../../services/data'
import { ReportingService } from '../../../services/reporting'
import AuthContext from '../../../contexts/auth'
import { LegendPoint } from '../charts'
import Theme from './theme'

import { solarProductionColor, gasImportColor, productionColor, consumptionColor } from '../../../util/graph_data'
import './overview.scss'

const moment = require('moment-timezone')

interface IOverviewProps {
  community: any
  communities: any[]
}

const Overview: React.FC<IOverviewProps> = ({ community, communities }) => {
  const authContext = useContext(AuthContext)
  const reportingService = new ReportingService()

  const [netImportPower, setNetImportPower] = useState<number>(0)
  const [hybridPower, setHybridPower] = useState<number>(0)
  const [solarPower, setSolarPower] = useState<number>(0)

  const [consumptionSeries, setConsumptionSeries] = useState<any[]>([])
  const [productionSeries, setProductionSeries] = useState<any[]>([])
  const [maxValue, setMaxValue] = useState<number>(0)
  const [selectedCommunity, setSelectedCommunity] = useState<string>(community.id || 'All')


  const fetchNetImportPower = async () => {
    const accessToken = authContext?.accessToken!
    const result =
      selectedCommunity === 'All'
        ? await getCommunitiesNetImportPower(accessToken)
        : await getCommunityNetImportPower(accessToken, parseInt(selectedCommunity))
    setNetImportPower(result)
  }

  const fetchTotalSolarPower = async () => {
    const accessToken = authContext?.accessToken!
    const result =
      selectedCommunity === 'All'
        ? await getCommunitiesTotalSolarPower(accessToken)
        : await getCommunityTotalSolarPower(accessToken, parseInt(selectedCommunity))
    setSolarPower(result)
  }

  const fetchTotalHybridPower = async () => {
    const accessToken = authContext?.accessToken!
    const result =
      selectedCommunity === 'All'
        ? await getCommunitiesTotalHybridPower(accessToken)
        : await getCommunityTotalHybridPower(accessToken, parseInt(selectedCommunity))
    setHybridPower(result)
  }

  const fetchGraphData = async () => {
    const accessToken = authContext?.accessToken!
    const result: any =
      selectedCommunity === 'All'
        ? await reportingService.getCommunitiesReportData(
          accessToken,
          'day',
          moment().local().startOf('day').utc().toISOString(),
          moment().local().endOf('day').utc().toISOString()
        )
        : await reportingService.getCommunityReportData(
          accessToken,
          parseInt(selectedCommunity),
          'day',
          moment().local().startOf('day').utc().toISOString(),
          moment().local().endOf('day').utc().toISOString()
        )
    const data = result
    setConsumptionSeries(data.consumption)
    setProductionSeries(data.production)
    setMaxValue(Math.ceil(data.max * 1.2))
  }
  const getCommunitiesDropdownData = () => {
    const items = [{ key: 'All', value: 'All', text: 'All' }]
    communities.map((community) => {
      items.push({ key: community.id, value: community.id, text: community.name })
    })
    return items
  }

  const onChangeCommunitiesDropdown = (event: React.SyntheticEvent, data: any) => {
    setSelectedCommunity(data.value)
  }

  useEffect(() => {
    fetchNetImportPower()
    fetchTotalHybridPower()
    fetchTotalSolarPower()
    fetchGraphData()
  }, [selectedCommunity])

  useEffect(() => {
    fetchNetImportPower()
    fetchTotalHybridPower()
    fetchGraphData()
  }, [])
  return (
    <div className="dashboard-container">
      <div className="container">
        <div className="flex1-row">
          <div className="listbox-container">
            <h3>Current Day</h3>
          </div>
          <div className="flex1-grow flex1-row flex1-end">
            {!('id' in community) && (
              <div className="flex1-row mr2">
                <div className="listbox-container">
                  <h3 className="mr2">Community</h3>
                </div>
                <div className="listbox-container">
                  <Dropdown
                    selection
                    placeholder="Community"
                    options={getCommunitiesDropdownData()}
                    value={selectedCommunity}
                    onChange={onChangeCommunitiesDropdown}
                  ></Dropdown>
                </div>
              </div>
            )}
            <div className="flex1-row">
              <div className="listbox-container">
                <h3 className="mr2">History</h3>
              </div>
              <div className="listbox-container">
                <Dropdown
                  selection
                  placeholder="Date"
                  options={[{ key: 'Today', value: 'Today', text: 'Today' }]}
                  value={'Today'}
                ></Dropdown>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex1-row mt1">
          <div className="flex1-row mr2">
            <p className="mr2">
              <strong>Consumption</strong>
            </p>
            <Input
              size="mini"
              className="mr2"
              placeholder="consumption"
              value={`${(netImportPower + hybridPower).toFixed(3)} kW`}
            />
          </div>
          <div className="flex1-row mr2">
            <p className="mr2">
              <strong>Production</strong>
            </p>
            <Input size="mini" className="mr2" placeholder="production" value={`${solarPower.toFixed(3)} kW`} />
          </div>
        </div>
        <hr />
        <Segment id="overview_chart">
          <VictoryChart
            theme={Theme}
            height={125}
            scale={{ x: 'time' }}
            padding={{ top: 5, bottom: 15, right: 10, left: 23 }}
          >
            <VictoryAxis
              dependentAxis
              crossAxis={false}
              label={'kW'}
              orientation="left"
              domain={maxValue > 0 ? [-maxValue, maxValue] : [-4, 4]}
              style={{
                tickLabels: { fontSize: '4px', padding: 3 }
              }}
              tickFormat={(t) => `${t.toFixed(2)}`}
            />
            <VictoryAxis
              offsetY={67.5}
              scale="time"
              style={{
                tickLabels: { fontSize: '4px', padding: 3 }
              }}
              tickCount={24}
              domain={[moment().local().startOf('day'), moment().local().endOf('day')]}
              tickFormat={(time) => {
                const formated = moment.utc(time).local().format('HH:mm')
                return formated === '00:00' ? '' : parseInt(formated.substr(0, 2)) % 2 === 0 ? formated : ''
              }}
              orientation="bottom"
            />
            <VictoryLegend
              x={230}
              y={115}
              orientation="horizontal"
              gutter={12}
              itemsPerRow={3}
              style={{ labels: { fontSize: 4 } }}
              data={[
                {
                  name: 'Production',
                  symbol: {
                    fill: productionColor,
                    type: 'minus',
                    size: 3
                  }
                },
                {
                  name: 'Consumption',
                  symbol: {
                    fill: consumptionColor,
                    type: 'minus',
                    size: 3
                  }
                }
              ]}
            />
            <VictoryGroup>
              {consumptionSeries.length > 0 && (
                <VictoryArea
                  animate={{ duration: 500 }}
                  interpolation="cardinal"
                  data={consumptionSeries}
                  x={(d) => moment.utc(d.time)}
                  y={'value'}
                  style={{
                    data: {
                      fill: consumptionColor,
                      fillOpacity: 0.5,
                      stroke: consumptionColor,
                      strokeWidth: 0.6
                    },
                    parent: { border: '1px solid #ccc' }
                  }}
                />
              )}

              {productionSeries.length > 0 && (
                <VictoryArea
                  animate={{ duration: 500 }}
                  interpolation="cardinal"
                  data={productionSeries}
                  x={(d) => moment.utc(d.time)}
                  y={'value'}
                  style={{
                    data: { fill: productionColor, fillOpacity: 0.5, stroke: productionColor, strokeWidth: 0.6 },
                    parent: { border: '1px solid #ccc' }
                  }}
                />
              )}
            </VictoryGroup>
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 5, fill: 'rgba(204,204,204)' }}
              x={5}
              y={30}
              // text={title}
              angle={270}
            />
          </VictoryChart>
        </Segment>
      </div>
    </div>
  )
}

export default Overview
