import * as React from 'react'
import './footer.scss'
import { privacyStatement } from '../../util/options'

const Footer: React.FC = () => {
  return (
    <React.Fragment>
      <div className="footer-contianer flex-center-vertical">
        <a className="footer-policy-txt" href={privacyStatement} target="_blank" rel="noreferrer">
          Privacybeleid
        </a>
      </div>
    </React.Fragment>
  )
}
export default Footer
