import api from '../api'
import { getConfig } from './base'
import * as deviceInterface from '../interfaces/device'
import * as batteryInterface from '../interfaces/battery'

export const fetchMainMeterDevices = async (accessToken: string): Promise<deviceInterface.IMainMeter[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/device-types/main-meters', config)
  return response.data
}

export const fetchHybridInverterDeviceTypes = async (
  accessToken: string
): Promise<deviceInterface.IHybridInverter[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/device-types/hybrid-inverters', config)
  return response.data
}

export const fetchSmartControllerDeviceTypes = async (
  accessToken: string
): Promise<any[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/device-types/smart-controllers', config)
  return response.data
}

export const fetchUserMainMeterDevices = async (
  accessToken: string
): Promise<deviceInterface.IUserMainMeterDevice[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/devices/main-meters', config)
  return response.data
}

export const fetchUserHybridInverterDevices = async (
  accessToken: string
): Promise<deviceInterface.IUserHybridInverterDevice[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/devices/hybrid-inverters', config)
  return response.data
}

export const fetchUserSmartControllerDevices = async (
  accessToken: string
): Promise<any[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/devices/smart-controllers', config)
  return response.data
}

export const createOrUpdateMainMeterDevice = async (
  accessToken: string,
  data: deviceInterface.IMainMeterCreate
): Promise<deviceInterface.IMainMeter> => {
  const config = getConfig(accessToken)
  const response = await api.post('/device-types/main-meters', data, config)
  return response.data
}

export const createOrUpdateHybridInverterDeviceType = async (
  accessToken: string,
  data: deviceInterface.IHybridInverterCreate
): Promise<deviceInterface.IHybridInverter> => {
  const config = getConfig(accessToken)
  const response = await api.post('/device-types/hybrid-inverters', data, config)
  return response.data
}

export const createOrUpdateSmartControllerDeviceType = async (
  accessToken: string,
  data: any
): Promise<any> => {
  const config = getConfig(accessToken)
  const response = await api.post('/device-types/smart-controllers', data, config)
  return response.data
}

export const addMainMeterDevice = async (
  accessToken: string,
  mainMeterLibraryId: number
): Promise<deviceInterface.IUserMainMeterDevice> => {
  const config = getConfig(accessToken)
  const response = await api.post(`/devices/main-meters/${mainMeterLibraryId}`, null, config)
  return response.data
}

export const addHybridInverterDevice = async (
  accessToken: string,
  hybridInverterLibraryId: number,
  payload: deviceInterface.IUserHybridInverterDeviceCreate
): Promise<void> => {
  const config = getConfig(accessToken)
  await api.post(`/devices/hybrid-inverters/${hybridInverterLibraryId}`, payload, config)
}

export const updateHybridInverterDevice = async (
  accessToken: string,
  deviceId: string,
  payload: deviceInterface.IUserHybridInverterDeviceCreate
): Promise<deviceInterface.IUserHybridInverterDevice> => {
  const config = getConfig(accessToken)
  const response = await api.put(`/devices/hybrid-inverters/${deviceId}`, payload, config)
  return response.data
}

export const removeMainMeterDevice = async (
  accessToken: string,
  mainMeterDeviceId: string
): Promise<deviceInterface.IUserMainMeterDevice> => {
  const config = getConfig(accessToken)
  const response = await api.delete(`/devices/main-meters/${mainMeterDeviceId}`, config)
  return response.data
}

export const removeHybridInverterDevice = async (
  accessToken: string,
  hybridInverterDeviceId: string
): Promise<void> => {
  const config = getConfig(accessToken)
  await api.delete(`/devices/hybrid-inverters/${hybridInverterDeviceId}`, config)
}

export const updateIsGasConnected = async (
  accessToken: string,
  value: boolean
): Promise<deviceInterface.IUserMainMeterDevice> => {
  const config = getConfig(accessToken)
  const response = await api.put(`/devices/main-meters/gas-connected/${value}`, null, config)
  return response.data
}

export const fetchHybridInverterBatteryModels = async (
  accessToken: string,
  hybridInverterModelId: number
): Promise<batteryInterface.IBatteryModel[]> => {
  const config = getConfig(accessToken)
  const response = await api.get(`/device-types/${hybridInverterModelId}/battery-models`, config)
  return response.data
}

export const activateHybridInverterDevice = async (
  accessToken: string,
  deviceId: string
): Promise<deviceInterface.IUserHybridInverterDevice> => {
  const config = getConfig(accessToken)
  const response = await api.post(`/devices/hybrid-inverters/${deviceId}/activate`, null, config)
  return response.data
}

export const deactivateHybridInverterDevice = async (
  accessToken: string,
  deviceId: string
): Promise<deviceInterface.IUserHybridInverterDevice> => {
  const config = getConfig(accessToken)
  const response = await api.post(`/devices/hybrid-inverters/${deviceId}/deactivate`, null, config)
  return response.data
}

export const scanIp = async (accessToken: string, hybridInverterLibraryId: number): Promise<void> => {
  const authConfig = getConfig(accessToken)
  const config = { ...authConfig, params: { hybrid_inverter_library_id: hybridInverterLibraryId } }
  const response = await api.get(`/devices/hybrid-inverters/scan-ip`, config)
  return response.data
}

export const scanSmartControllerDevices = async (accessToken: string, smart_controller_library_id: number): Promise<void> => {
  const authConfig = getConfig(accessToken)
  const config = { ...authConfig, params: { smart_controller_library_id } }
  const response = await api.get('/devices/smart-controllers/scan', config)
  return response.data
}


export const connectSmartControllerDevice = async (accessToken: string, smart_controller_library_id: number, serial_number: string) => {
  const authConfig = getConfig(accessToken)
  const config = { ...authConfig, params: { smart_controller_library_id, serial_number } }
  const response = await api.post('/devices/smart-controllers/connect', null, config)
  return response.data
}


export const addSmartControllerDevice = async (accessToken: string, smart_controller_library_id: number, serial_number: string, data: any) => {
  const authConfig = getConfig(accessToken)
  const config = { ...authConfig, params: { smart_controller_library_id, serial_number } }
  const response = await api.post('/devices/smart-controllers', data, config)
  return response.data
}

export const removeSmartControllerDevice = async (accessToken: string, device_id: string) => {
  const authConfig = getConfig(accessToken)
  const response = await api.delete(`/devices/smart-controllers/${device_id}`, authConfig)
  console.log('remove device', response)
  return response.data
}

export const switchSmartControllerDevice = async (accessToken: string, device_id: string, on: boolean) => {
  const authConfig = getConfig(accessToken)
  const config = { ...authConfig, params: { on } }
  const response = await api.put(`/devices/smart-controllers/${device_id}/switch`, null, config)
  return response.data
}

export const toggleSmartControllerDevice = async (
  accessToken: string,
  deviceId: string
): Promise<any> => {
  const config = getConfig(accessToken)
  const response = await api.put(`/devices/smart-controllers/${deviceId}/toggle`, null, config)
  return response.data
}


export const resetSmartControllerDeviceReading = async (
  accessToken: string,
  deviceId: string
): Promise<any> => {
  const config = getConfig(accessToken)
  const response = await api.put(`/devices/smart-controllers/${deviceId}/reset-reading`, null, config)
  return response.data
}


export const updateSmartControllerDevice = async (
  accessToken: string,
  deviceId: string,
  payload: any
): Promise<deviceInterface.IUserHybridInverterDevice> => {
  const config = getConfig(accessToken)
  const response = await api.put(`/devices/smart-controllers/${deviceId}`, payload, config)
  return response.data
}
