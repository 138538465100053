import * as React from 'react'
import { Tab } from 'semantic-ui-react'
import DeviceList from './DeviceList'
import NewDevice from './NewDevice'

const DeviceComponent: React.FC = () => {
  const panes = [
    {
      menuItem: 'New Device',
      render: () => (
        <Tab.Pane>
          <NewDevice />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'All Devices',
      render: () => (
        <Tab.Pane className="device_list_container">
          <DeviceList />
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <Tab panes={panes} className="home-menu-container" />
    </>
  )
}

export default DeviceComponent
