import * as React from 'react'
import { Tab } from 'semantic-ui-react'
import DSO from './DSO/DSO'
import Retailer from './Retailer/Retailer'
import Tax from './Tax/Tax'
import Reference from './Reference/Reference'

import './source.scss'

const Source: React.FC = () => {
  const panes = [
    {
      menuItem: 'DSO',
      render: () => (
        <Tab.Pane>
          <DSO />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Retailer',
      render: () => (
        <Tab.Pane>
          <Retailer />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Tax',
      render: () => (
        <Tab.Pane>
          <Tax />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Reference',
      render: () => (
        <Tab.Pane>
          <Reference />
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <Tab panes={panes} className="home-menu-container" />
    </>
  )
}

export default Source
