import * as React from 'react'
import { Table, Input } from 'semantic-ui-react'
import './retailer.scss'

interface IElectricityProp {
  handleRefChange: Function
  handleRefClick: Function
  elecPriceRefs: any[]
  editPriceRefs: any
  inEditMode: any
}

const Electricity: React.FC<IElectricityProp> = (prop: IElectricityProp) => {
  const handleClickEvent = (id: string, field: string) => {
    prop.handleRefClick(id, field)
  }

  const handleElecRefChange = (id: number, field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    prop.handleRefChange(id, field, event)
  }

  return (
    <div className="source-container">
      <h3>Electricity (ex BTW)</h3>
      <hr />
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="4">Name</Table.HeaderCell>
            {prop.elecPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return <Table.HeaderCell key={i} width="3">{ref.year}</Table.HeaderCell>
              })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Fixed fee</Table.Cell>
            {prop.elecPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return (
                  <Table.Cell key={i} className={
                    prop.inEditMode.ids.includes(ref.id) &&
                    ref.id in prop.editPriceRefs &&
                    'fixedFeePerYear' in prop.editPriceRefs[ref.id] ? 'dirty' : ''
                  }>
                    {
                      prop.inEditMode.status &&
                      prop.inEditMode.ids.includes(ref.id) &&
                      ref.id in prop.editPriceRefs &&
                      'fixedFeePerYear' in prop.editPriceRefs[ref.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={prop.editPriceRefs[ref.id].fixedFeePerYear}
                            onChange={handleElecRefChange.bind(this, ref.id, 'fixedFeePerYear')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, ref.id, 'fixedFeePerYear')}
                          >
                            {ref.fixedFeePerYear}
                          </p>
                        )
                    }
                  </Table.Cell>
                )
              })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Commodity high</Table.Cell>
            {prop.elecPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return (
                  <Table.Cell key={i} className={
                    prop.inEditMode.ids.includes(ref.id) &&
                    ref.id in prop.editPriceRefs &&
                    'tariffHighPerKwh' in prop.editPriceRefs[ref.id] ? 'dirty' : ''
                  }>
                    {
                      prop.inEditMode.status &&
                      prop.inEditMode.ids.includes(ref.id) &&
                      ref.id in prop.editPriceRefs &&
                      'tariffHighPerKwh' in prop.editPriceRefs[ref.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={prop.editPriceRefs[ref.id].tariffHighPerKwh}
                            onChange={handleElecRefChange.bind(this, ref.id, 'tariffHighPerKwh')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, ref.id, 'tariffHighPerKwh')}
                          >
                            {ref.tariffHighPerKwh}
                          </p>
                        )
                    }
                  </Table.Cell>
                )
              })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Commodity low</Table.Cell>
            {prop.elecPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return (
                  <Table.Cell key={i} className={
                    prop.inEditMode.ids.includes(ref.id) &&
                    ref.id in prop.editPriceRefs &&
                    'tariffLowPerKwh' in prop.editPriceRefs[ref.id] ? 'dirty' : ''
                  }>
                    {
                      prop.inEditMode.status &&
                      prop.inEditMode.ids.includes(ref.id) &&
                      ref.id in prop.editPriceRefs &&
                      'tariffLowPerKwh' in prop.editPriceRefs[ref.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={prop.editPriceRefs[ref.id].tariffLowPerKwh}
                            onChange={handleElecRefChange.bind(this, ref.id, 'tariffLowPerKwh')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, ref.id, 'tariffLowPerKwh')}
                          >
                            {ref.tariffLowPerKwh}
                          </p>
                        )
                    }
                  </Table.Cell>
                )
              })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Commodity continuous rate</Table.Cell>
            {prop.elecPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return (
                  <Table.Cell key={i} className={
                    prop.inEditMode.ids.includes(ref.id) &&
                    ref.id in prop.editPriceRefs &&
                    'tariffFlatPerKwh' in prop.editPriceRefs[ref.id] ? 'dirty' : ''
                  }>
                    {
                      prop.inEditMode.status &&
                      prop.inEditMode.ids.includes(ref.id) &&
                      ref.id in prop.editPriceRefs &&
                      'tariffFlatPerKwh' in prop.editPriceRefs[ref.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={prop.editPriceRefs[ref.id].tariffFlatPerKwh}
                            onChange={handleElecRefChange.bind(this, ref.id, 'tariffFlatPerKwh')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, ref.id, 'tariffFlatPerKwh')}
                          >
                            {ref.tariffFlatPerKwh}
                          </p>
                        )
                    }
                  </Table.Cell>
                )
              })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Commodity return rate</Table.Cell>
            {prop.elecPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return (
                  <Table.Cell key={i} className={
                    prop.inEditMode.ids.includes(ref.id) &&
                    ref.id in prop.editPriceRefs &&
                    'feedInTariffPerKwh' in prop.editPriceRefs[ref.id] ? 'dirty' : ''
                  }>
                    {
                      prop.inEditMode.status &&
                      prop.inEditMode.ids.includes(ref.id) &&
                      ref.id in prop.editPriceRefs &&
                      'feedInTariffPerKwh' in prop.editPriceRefs[ref.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={prop.editPriceRefs[ref.id].feedInTariffPerKwh}
                            onChange={handleElecRefChange.bind(this, ref.id, 'feedInTariffPerKwh')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, ref.id, 'feedInTariffPerKwh')}
                          >
                            {ref.feedInTariffPerKwh}
                          </p>
                        )
                    }
                  </Table.Cell>
                )
              })}
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

export default Electricity
