import * as React from 'react'
import { Tab, Menu, Dropdown, Image, MenuItemProps, Segment } from 'semantic-ui-react'
import './company_form.scss'
import '../Register/customer_details.scss'
import Page from '../../pages/Page'
import * as headerLogo from '../../assets/logo_rgb100x100.png'
import * as userLogo from '../../assets/User_Icon.svg'
import { useState, useContext } from 'react'
import { IconContext } from 'react-icons'
import { AiOutlineBell } from 'react-icons/ai'
import { Subscription, Building, Customer, UserSettings, Date , Reports} from '..'
import { RouteComponentProps } from 'react-router'
import '../Home/home.scss'
import '../Header/header.scss'
import * as options from '../../util/options'
import UserContext from '../../contexts/user'
import AuthContext from '../../contexts/auth'

type SomeComponentProps = RouteComponentProps
const CompanyForm: React.FC<SomeComponentProps> = ({ location, history }) => {
  const path = location.pathname
  const [activeItem, setActiveItem] = useState<string>('')
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext)
  const [ generateReport ,  setGenerateReport] = useState(false)

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    if (data.name === 'HOME') {
      history.push('/home')
    }
    else if (data.name === 'RAPPORTAGE') {
      setGenerateReport(true)
      setActiveItem(data.name as string)
    }
  }
  const onChange = (e: any, data: any) => {
    if (data.value === 3) {
      authContext?.logout()
    }
    else if (data.value === 2) {
      history.push('/companyform')
    }
  }
  const panes = [
    {
      menuItem: {
        content: 'Klantgegevens',
        id: 'clientdetails'
      },
      render: () => (
        <Tab.Pane>
          <div className="customer-detail-container" id="company-container">
            <div className="flex1-column">
              <div className="flex1-row">
                <Customer />
                <Building />
              </div>
              <div className="flex1-row">
                <UserSettings />
                <Subscription />
              </div>
            </div>
          </div>
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        id: 'smart-meter-date',
        content: <Date />,
        className: 'date'
      }
    }
  ]

  return (
    <React.Fragment>
      <Page>
        <div className="user-detail-container">
          <Menu attached="top" tabular className="header-container">
            <div className="header-column1 flex1-row flex1-start">
              <Image src={headerLogo.default} className="header-logo-size header-logo-positioning" alt="Header logo" />
            </div>
            <div className="header-column2 flex1-row flex1-center">
              <Menu.Item active={activeItem === 'HOME'} name="HOME" onClick={handleItemClick} id="home-active-user">
                <strong style={{ color: '#00b7d5', fontSize: '18px' }}>HOME</strong>
              </Menu.Item>
              <Menu.Item
                fitted
                name="RAPPORTAGE"
                active={activeItem === 'RAPPORTAGE'}
                onClick={handleItemClick}
                id="reports-active-user"
              >
                <strong style={{ color: '#00b7d5', fontSize: '18px' }}>RAPPORTAGE</strong>
              </Menu.Item>
              <Menu.Item
                fitted
                name="OPTIMALISATIE"
                active={activeItem === 'OPTIMALISATIE'}
                onClick={handleItemClick}
                id="optimalisatie-active-user"
              >
                <strong style={{ color: '#00b7d5', fontSize: '18px' }}>OPTIMALISATIE</strong>
              </Menu.Item>
              <Menu.Item name="VPP" active={activeItem === 'VPP'} onClick={handleItemClick} id="vpp-active-user">
                <strong style={{ color: '#00b7d5', fontSize: '18px' }}>VPP</strong>
              </Menu.Item>
            </div>
            <div className="header-column3 flex1-row flex1-end">
              <Menu.Menu position="right">
                <Menu.Item>
                  <div className="flex1-row flex1-center">
                    <IconContext.Provider
                      value={{
                        size: '37px',
                        style: {
                          verticalAlign: 'middle',
                          fill: '#E6F3F8',
                          strokeWidth: '1em'
                        }
                      }}
                    >
                      <div>
                        <AiOutlineBell className="bell-logo" />
                      </div>
                    </IconContext.Provider>
                    <Image src={userLogo.default} className="user-logo" />
                    <Dropdown
                      className="user-name"
                      text={userContext?.profile?.lastName}
                      options={options.homeOptions}
                      onChange={onChange}
                      simple
                      item
                    />
                  </div>
                </Menu.Item>
              </Menu.Menu>
            </div>
          </Menu>
          {activeItem === 'HOME' && (
            <Segment attached="bottom">
              <Home />
            </Segment>
          )}
          {activeItem === 'RAPPORTAGE' && (
            <Segment attached="bottom">
              <Reports />
            </Segment>
          )}
          {activeItem === 'OPTIMALISATIE' && (
            <Segment attached="bottom">
              <div>OPTIMALISATIE</div>
            </Segment>
          )}
          {activeItem === 'VPP' && (
            <Segment attached="bottom">
              <VPP />
            </Segment>
          )}
        </div>
        {path === '/companyform' && !generateReport && <Tab panes={panes} className="register-container company-form-height" />
}
      </Page>
    </React.Fragment>
  )
}
export default CompanyForm