import history from '../history'
import api from '../api'

export default class AuthService {
  login = async (email: string, password: string) => {
    const data = { email, password }
    const response = await api.post('/auth/', data)
    return response.data
  }

  loginAdmin = async (email: string, password: string) => {
    const data = { email, password }
    const response = await api.post('/auth/admin', data)
    return response.data
  }

  loginCommunityAdmin = async (email: string, password: string) => {
    const data = { email, password }
    const response = await api.post('/auth/communityAdmin', data)
    return response.data
  }

  // logout = async () => {
  //   sessionStorage.removeItem('accessToken')
  //   sessionStorage.removeItem('refreshToken')
  //   history.push('/')
  // }

  logoutCommunity = async () => {
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
    history.replace('/community/login')
  }

  forgotPassword = async (email: string) => {
    const data = { email }
    const response = await api.post('/forgot_password?email=' + data.email)
    return response.data
  }

  resetPassword = async (email: string, code: string, password: string) => {
    const data = { email, code, password }
    const response = await api.post('/reset-password', data)
    return response.data
  }

  refreshToken = async (refreshToken: string) => {
    const config = { headers: { Authorization: `Bearer ${refreshToken}` } }
    const response = await api.post('/auth/refresh', null, config)
    return response.data
  }
}
