import api from '../api'
import { getConfig } from './base'

export const fetchPhysicalInterfaceOptions = async (accessToken: string): Promise<string[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/options/device-physical-interfaces', config)
  return response.data
}

export const fetchConnectionOptions = async (accessToken: string): Promise<string[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/options/device-connections', config)
  return response.data
}

export const fetchCommunicationPathOptions = async (accessToken: string): Promise<string[]> => {
  const config = getConfig(accessToken)
  const response = await api.get('/options/device-communication-paths', config)
  return response.data
}
