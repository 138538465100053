import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import AuthContext from '../../contexts/auth'
import UserContext from '../../contexts/user'

const LoginRoute: React.FC<RouteProps> = ({ component, ...rest }: any) => {
  const authContext = React.useContext(AuthContext)
  const userContext = React.useContext(UserContext)

  const isAuthenticated = () => {
    return authContext?.isLoggedIn()
  }

  const homeRoute =
    userContext?.profile?.role === 'admin'
      ? '/admin/home'
      : userContext?.profile?.role === 'communityAdmin'
        ? '/community/home'
        : '/home'

  const routeComponent = (props: any) =>
    !isAuthenticated() ? React.createElement(component, props) : <Redirect to={{ pathname: homeRoute }} />
  return <Route {...rest} render={routeComponent} />
}

export default LoginRoute
