import * as React from 'react'
import { useState, useContext, useEffect } from 'react'
import { Dropdown, Segment, Table, Input } from 'semantic-ui-react'
import { VictoryChart, VictoryArea, VictoryAxis, VictoryLabel, VictoryLegend, VictoryStack } from 'victory'
import DatePicker from 'react-datepicker'
import { BsFillCaretRightFill, BsFillCaretLeftFill } from 'react-icons/bs'
import GatewayService from '../../../services/gateway'
import AuthContext from '../../../contexts/auth'

import { Gauge, LegendPoint } from '../charts'
import Theme from './theme'

import './status.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { truncate } from 'lodash'

const moment = require('moment')

interface IStatusProps {
  community: any
  communities: any[]
}

const Status: React.FC<IStatusProps> = ({ community, communities }) => {
  const gatewayService = new GatewayService()
  const authContext = useContext(AuthContext)
  const accessToken = authContext?.accessToken!
  const [selectedCommunity, selectCommunity] = useState<string>('All')
  const [selectedStatus, selectStatus] = useState<string>('All')
  const [selectedDate, selectDate] = useState(new Date())
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [selectedPage, selectPage] = useState<number>(1)
  const [pageNumbersToDisplay, setPageNumbersToDisplay] = useState<number[]>([1, 2])
  const [numPages, setNumPages] = useState<number>(1)
  const [serialAscending, setSerialAscending] = useState<boolean>(false)
  const [userAscending, setUserAscending] = useState<boolean>(false)
  const [lastSeenAscending, setLastSeenAscending] = useState<boolean>(false)
  const [createdAscending, setCreatedAscending] = useState<boolean>(false)
  const [sortedBy, setSortedBy] = useState<string>('lastSeenOn')
  const [gateways, setGateways] = useState<any[]>([])
  const [tableGateways, setTableGateways] = useState<any[]>([])
  const [gatewaysOnDisplay, setGatewaysOnDisplay] = useState<any[]>([])

  const [onlineSeries, setOnlineSeries] = useState<any[]>([])
  const [offlineSeries, setOfflineSeries] = useState<any[]>([])
  const [tempOfflineSeries, setTempOfflineSeries] = useState<any[]>([])
  const [permOfflineSeries, setPermOfflineSeries] = useState<any[]>([])

  const getCommunitiesDropdownData = () => {
    const items = [
      { key: 'All', value: 'All', text: 'All' },
      { key: 'unidentified', value: 'unidentified', text: 'unidentified' }
    ]
    communities.map((community) => {
      items.push({ key: community.id, value: community.id, text: community.name })
    })
    return items
  }

  const getStatusDropdownData = () => {
    return [
      { key: 'All', value: 'All', text: 'All' },
      { key: 'online', value: 'online', text: 'Online' },
      { key: 'temporarily offline', value: 'temporarily offline', text: 'Temporarily offline' },
      { key: 'offline', value: 'offline', text: 'Offline' },
      { key: 'permanently offline', value: 'permanently offline', text: 'Permanently offline' }
    ]
  }

  const fetchGateways = async () => {
    let result = []
    if ('id' in community) {
      result = await gatewayService.listCommunityGateways(accessToken, community.id)
    } else {
      result = await gatewayService.listGateways(accessToken)
    }
    const rawGateways = result
    setGateways(rawGateways)
    resetTable(rawGateways)
  }

  const fetchGraphData = async () => {
    let result = []
    if ('id' in community) {
      result = await gatewayService.fetchCommunityGraphData(
        accessToken,
        community.id,
        moment().startOf('day').toISOString(),
        moment().endOf('day').toISOString()
      )
    } else {
      result = await gatewayService.fetchGraphData(
        accessToken,
        moment().startOf('day').toISOString(),
        moment().endOf('day').toISOString()
      )
    }

    setOnlineSeries(result.online)
    setOfflineSeries(result.offline)
    setTempOfflineSeries(result.temp_offline)
    setPermOfflineSeries(result.perm_offline)
  }

  const onChangeCommunitiesDropdown = async (event: React.SyntheticEvent, data: any) => {
    const newComm = data.value
    selectCommunity(newComm)
    let result = []

    if (newComm === 'All') {
      result = await gatewayService.listGateways(accessToken)
    } else if (newComm === 'unidentified') {
      result = await gatewayService.listUnidentifiedGateways(accessToken)
    } else {
      result = await gatewayService.listCommunityGateways(accessToken, newComm)
    }
    const rawGateways = result
    setGateways(rawGateways)
    resetTable(rawGateways)

    let result2 = []
    if (newComm !== 'All' && newComm !== 'unidentified') {
      result2 = await gatewayService.fetchCommunityGraphData(
        accessToken,
        parseInt(newComm),
        moment(selectedDate).startOf('day').toISOString(),
        moment(selectedDate).endOf('day').toISOString()
      )
    } else if (newComm === 'unidentified') {
      result2 = await gatewayService.fetchUnidentifiedGraphData(
        accessToken,
        moment(selectedDate).startOf('day').toISOString(),
        moment(selectedDate).endOf('day').toISOString()
      )
    } else {
      result2 = await gatewayService.fetchGraphData(
        accessToken,
        moment(selectedDate).startOf('day').toISOString(),
        moment(selectedDate).endOf('day').toISOString()
      )
    }

    setOnlineSeries(result2.online)
    setOfflineSeries(result2.offline)
    setTempOfflineSeries(result2.temp_offline)
    setPermOfflineSeries(result2.perm_offline)
  }

  const onChangeStatusDropdown = (event: React.SyntheticEvent, data: any) => {
    const newStatus = data.value
    selectStatus(newStatus)
    if (newStatus === 'All') {
      const filteredGateways = [...gateways]
      resetTable(filteredGateways)
    } else if (newStatus === 'online') {
      const filteredGateways = getOnlineGateways()
      resetTable(filteredGateways)
    } else if (newStatus === 'temporarily offline') {
      const filteredGateways = getTempOfflineGateways()
      resetTable(filteredGateways)
    } else if (newStatus === 'offline') {
      const filteredGateways = getOfflineGateways()
      resetTable(filteredGateways)
    } else {
      const filteredGateways = getPermOfflineGateways()
      resetTable(filteredGateways)
    }
  }

  const getPermOfflineGateways = () => {
    return gateways.filter((gateway) => {
      // const diff = new Date().getTime() - new Date(gateway.lastUpdatedOn).getTime()
      // const days = diff / (1000 * 60 * 60 * 24)
      // return days > 30
      return gateway.onlineStatus === 'permanently offline'
    })
  }

  const getOfflineGateways = () => {
    return gateways.filter((gateway) => {
      // const diff = new Date().getTime() - new Date(gateway.lastUpdatedOn).getTime()
      // const days = diff / (1000 * 60 * 60 * 24)
      // return days > 1 && days <= 30
      return gateway.onlineStatus === 'offline'
    })
  }

  const getTempOfflineGateways = () => {
    return gateways.filter((gateway) => {
      // const diff = new Date().getTime() - new Date(gateway.lastUpdatedOn).getTime()
      // const minutes = diff / (1000 * 60)
      // const days = diff / (1000 * 60 * 60 * 24)
      // return minutes > 5 && days <= 1
      return gateway.onlineStatus === 'temporarily offline'
    })
  }

  const getOnlineGateways = () => {
    return gateways.filter((gateway) => {
      // const diff = new Date().getTime() - new Date(gateway.lastUpdatedOn).getTime()
      // const minutes = diff / (1000 * 60)
      // return minutes <= 5
      return gateway.onlineStatus === 'online'
    })
  }

  const resetTable = (filteredGateways: any[]) => {
    filteredGateways.sort((a: any, b: any) => {
      return new Date(b.lastUpdatedOn).getTime() - new Date(a.lastUpdatedOn).getTime()
    })
    setLastSeenAscending(false)
    filteredGateways.map((gateway: any, i: number) => {
      gateway.order = i + 1
    })
    setTableGateways(filteredGateways)
    setGatewaysOnDisplay(
      filteredGateways.filter((gateway: any, i: number) => {
        return i >= (selectedPage - 1) * itemsPerPage && i < itemsPerPage * selectedPage
      })
    )
    setNumPages(Math.ceil(filteredGateways.length / itemsPerPage))
    selectPage(1)
    if (numPages > 1) {
      setPageNumbersToDisplay([1, 2])
    } else {
      setPageNumbersToDisplay([1])
    }
  }

  const onChangeDate = async (date: Date) => {
    selectDate(date)
    let result = []
    if ('id' in community || selectedCommunity !== 'All') {
      result = await gatewayService.fetchCommunityGraphData(
        accessToken,
        community.id || parseInt(selectedCommunity),
        moment(date).startOf('day').toISOString(),
        moment(date).endOf('day').toISOString()
      )
    } else {
      result = await gatewayService.fetchGraphData(
        accessToken,
        moment(date).startOf('day').toISOString(),
        moment(date).endOf('day').toISOString()
      )
    }

    setOnlineSeries(result.online)
    setOfflineSeries(result.offline)
    setTempOfflineSeries(result.temp_offline)
    setPermOfflineSeries(result.perm_offline)
  }

  const onChangeItemsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value)
    setItemsPerPage(newValue)
    selectPage(1)
    setGatewaysOnDisplay(
      tableGateways.filter((gateway: any, i: number) => {
        return i >= 0 && i < newValue * 1
      })
    )
    setNumPages(Math.ceil(tableGateways.length / newValue))
    if (Math.ceil(tableGateways.length / newValue) > 1) {
      setPageNumbersToDisplay([1, 2])
    } else {
      setPageNumbersToDisplay([1])
    }
  }

  const onClickPageButton = (event: any) => {
    const value = parseInt(event.target.value)
    selectPage(value)
    setGatewaysOnDisplay(
      tableGateways.filter((gateway: any, i: number) => {
        return i >= (value - 1) * itemsPerPage && i < itemsPerPage * value
      })
    )
  }

  const onClickNext = () => {
    if (selectedPage === numPages) {
      return
    }
    const currentPage = selectedPage + 1
    const firstNum = currentPage - 1
    const secondNum = currentPage
    setPageNumbersToDisplay([firstNum, secondNum])
    selectPage(currentPage)
    setGatewaysOnDisplay(
      tableGateways.filter((gateway: any, i: number) => {
        return i >= (currentPage - 1) * itemsPerPage && i < itemsPerPage * currentPage
      })
    )
  }

  const onClickPrev = () => {
    if (selectedPage === 1) {
      return
    }
    const currentPage = selectedPage - 1
    const firstNum = currentPage
    const secondNum = currentPage + 1
    setPageNumbersToDisplay([firstNum, secondNum])
    selectPage(currentPage)
    setGatewaysOnDisplay(
      tableGateways.filter((gateway: any, i: number) => {
        return i >= (currentPage - 1) * itemsPerPage && i < itemsPerPage * currentPage
      })
    )
  }

  const onClickAddedOn = () => {
    setSortedBy('addedOn')
    const mutableGateways = [...tableGateways]
    if (createdAscending) {
      setCreatedAscending(false)
      mutableGateways.sort((a: any, b: any) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
    } else {
      setCreatedAscending(true)
      mutableGateways.sort((a: any, b: any) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime())
    }
    mutableGateways.map((gateway: any, i: number) => {
      gateway.order = i + 1
    })
    setTableGateways(mutableGateways)
    setGatewaysOnDisplay(
      mutableGateways.filter((gateway: any, i: number) => {
        return i >= (selectedPage - 1) * itemsPerPage && i < itemsPerPage * selectedPage
      })
    )
  }

  const onClickLastSeenOn = () => {
    setSortedBy('lastSeenOn')
    const mutableGateways = [...tableGateways]
    if (lastSeenAscending) {
      setLastSeenAscending(false)
      mutableGateways.sort(
        (a: any, b: any) => new Date(b.lastUpdatedOn).getTime() - new Date(a.lastUpdatedOn).getTime()
      )
    } else {
      setLastSeenAscending(true)
      mutableGateways.sort(
        (a: any, b: any) => new Date(a.lastUpdatedOn).getTime() - new Date(b.lastUpdatedOn).getTime()
      )
    }
    mutableGateways.map((gateway: any, i: number) => {
      gateway.order = i + 1
    })
    setTableGateways(mutableGateways)
    setGatewaysOnDisplay(
      mutableGateways.filter((gateway: any, i: number) => {
        return i >= (selectedPage - 1) * itemsPerPage && i < itemsPerPage * selectedPage
      })
    )
  }

  const onClickUserID = () => {
    setSortedBy('userID')
    const mutableGateways = [...tableGateways]
    if (userAscending) {
      setUserAscending(false)
      mutableGateways.sort((a: any, b: any) => (b.userId || 0) - (a.userId || 0))
    } else {
      setUserAscending(true)
      mutableGateways.sort((a: any, b: any) => (a.userId || 0) - (b.userId || 0))
    }
    mutableGateways.map((gateway: any, i: number) => {
      gateway.order = i + 1
    })
    setTableGateways(mutableGateways)
    setGatewaysOnDisplay(
      mutableGateways.filter((gateway: any, i: number) => {
        return i >= (selectedPage - 1) * itemsPerPage && i < itemsPerPage * selectedPage
      })
    )
  }

  const onClickSNo = () => {
    setSortedBy('sNo')
    const mutableGateways = [...tableGateways]
    if (serialAscending) {
      setSerialAscending(false)
      mutableGateways.sort((b: any, a: any) => {
        if (a.serialnr > b.serialnr) {
          return 1
        } else if (a.serialnr < b.serialnr) {
          return -1
        } else {
          return 0
        }
      })
    } else {
      setSerialAscending(true)
      mutableGateways.sort((a: any, b: any) => {
        if (a.serialnr > b.serialnr) {
          return 1
        } else if (a.serialnr < b.serialnr) {
          return -1
        } else {
          return 0
        }
      })
    }
    mutableGateways.map((gateway: any, i: number) => {
      gateway.order = i + 1
    })
    setTableGateways(mutableGateways)
    setGatewaysOnDisplay(
      mutableGateways.filter((gateway: any, i: number) => {
        return i >= (selectedPage - 1) * itemsPerPage && i < itemsPerPage * selectedPage
      })
    )
  }

  const formatDate = (datetime: Date) => {
    const hours = datetime.getHours().toLocaleString('en', { minimumIntegerDigits: 2 })
    const minutes = datetime.getMinutes().toLocaleString('en', { minimumIntegerDigits: 2 })
    const seconds = datetime.getSeconds().toLocaleString('en', { minimumIntegerDigits: 2 })
    const date = datetime.getDate().toLocaleString('en', { minimumIntegerDigits: 2 })
    const month = (datetime.getMonth() + 1).toLocaleString('en', { minimumIntegerDigits: 2 })
    const year = datetime.getFullYear()

    return `${date}/${month}/${year} ${hours}:${minutes}:${seconds}`
  }

  useEffect(() => {
    fetchGateways()
    fetchGraphData()
  }, [])

  return (
    <div className="dashboard-container">
      <div className="container status-container">
        {!('id' in community) && (
          <div>
            <div className="flex1-row" id="community-selector">
              <div className="listbox-container">
                <h3 className="mr2">Community</h3>
              </div>
              <div className="listbox-container">
                <Dropdown
                  selection
                  placeholder="Community"
                  options={getCommunitiesDropdownData()}
                  value={selectedCommunity}
                  onChange={onChangeCommunitiesDropdown}
                ></Dropdown>
              </div>
            </div>

            <hr className="mtb2" />
          </div>
        )}

        <div className="grid-col-2" id="status-content">
          <div id="status-chart">
            <div className="flex1-row mr2 mb2">
              <div className="listbox-container">
                <h3 className="mr2">Total Users/Gateways:</h3>
              </div>
              <div className="listbox-container mr2">
                <h3 className="mr2" id="num_gateways">
                  {gateways.length}
                </h3>
              </div>
            </div>

            <div className="mb2 grid-col-4">
              <div>
                <div className="flex1-row content-center">
                  <Gauge
                    max={gateways.length}
                    min={0}
                    value={getOnlineGateways().length}
                    lessIsMore={false}
                    threshold={0.95}
                  />
                </div>
                <div className="flex1-row content-center">Online</div>
              </div>
              <div>
                <div className="flex1-row content-center">
                  <Gauge
                    max={gateways.length}
                    min={0}
                    value={getTempOfflineGateways().length}
                    lessIsMore={true}
                    threshold={0.05}
                  />
                </div>
                <div className="flex1-row content-center">Temporarily offline</div>
              </div>
              <div>
                <div className="flex1-row content-center">
                  <Gauge
                    max={gateways.length}
                    min={0}
                    value={getOfflineGateways().length}
                    lessIsMore={true}
                    threshold={0.05}
                  />
                </div>
                <div className="flex1-row content-center">Offline</div>
              </div>
              <div>
                <div className="flex1-row content-center">
                  <Gauge
                    max={gateways.length}
                    min={0}
                    value={getPermOfflineGateways().length}
                    lessIsMore={true}
                    threshold={0.05}
                  />
                </div>
                <div className="flex1-row content-center">Permanently offline</div>
              </div>
            </div>

            <div className="flex1-row flex1-end">
              <DatePicker
                selected={selectedDate}
                onChange={onChangeDate}
                maxDate={new Date()}
                customInput={<Input icon="calendar" />}
              />
            </div>

            <Segment id="status_chart_element">
              <VictoryChart
                theme={Theme}
                height={140}
                scale={{ x: 'time' }}
                padding={{ top: 5, bottom: 30, right: 10, left: 23 }}
              >
                <VictoryAxis
                  dependentAxis
                  crossAxis={false}
                  orientation="left"
                  domain={[0, 100]}
                  tickCount={5}
                  style={{
                    tickLabels: { fontSize: '7px', padding: 3 }
                  }}
                  tickFormat={(t) => `${t}%`}
                />
                <VictoryAxis
                  offsetY={30}
                  scale="time"
                  style={{
                    tickLabels: { fontSize: '7px', padding: 3 },
                    grid: { stroke: 'none' }
                  }}
                  tickCount={24}
                  domain={[moment(selectedDate).startOf('day').valueOf(), moment(selectedDate).endOf('day').valueOf()]}
                  tickFormat={(time) => {
                    const formated = moment(time).local().format('HH:MM').substring(0, 4) + '0'
                    if (!isNaN(time.getTime())) {
                      return parseInt(formated.substr(0, 2)) % 4 === 0 ? formated : ''
                    } else {
                      return ''
                    }
                  }}
                  orientation="bottom"
                />
                <VictoryLegend
                  x={20}
                  y={128}
                  orientation="horizontal"
                  gutter={12}
                  itemsPerRow={4}
                  style={{ labels: { fontSize: 7 } }}
                  data={[
                    {
                      name: 'Online',
                      symbol: {
                        fill: '#3CD2A0',
                        type: 'minus',
                        size: 7
                      }
                    },
                    {
                      name: 'Temporarily offline',
                      symbol: {
                        fill: '#FBBB21',
                        type: 'minus',
                        size: 7
                      }
                    },
                    {
                      name: 'Offline',
                      symbol: {
                        fill: '#DC7570',
                        type: 'minus',
                        size: 7
                      }
                    },
                    {
                      name: 'Permanently offline',
                      symbol: {
                        fill: '#7E7E7E',
                        type: 'minus',
                        size: 7
                      }
                    }
                  ]}
                />
                <VictoryStack>
                  {onlineSeries.length > 0 && (
                    <VictoryArea
                      animate={{ duration: 500 }}
                      interpolation="linear"
                      data={onlineSeries}
                      x={(d) => d.time}
                      y={'value'}
                      style={{
                        data: {
                          fill: '#3CD2A0',
                          fillOpacity: 0.5,
                          stroke: '#3CD2A0',
                          strokeWidth: 0.6
                        },
                        parent: { border: '1px solid #ccc' }
                      }}
                    />
                  )}

                  {tempOfflineSeries.length > 0 && (
                    <VictoryArea
                      animate={{ duration: 500 }}
                      interpolation="linear"
                      data={tempOfflineSeries}
                      x={(d) => d.time}
                      y={'value'}
                      style={{
                        data: { fill: '#FBBB21', fillOpacity: 0.5, stroke: '#FBBB21', strokeWidth: 0.6 },
                        parent: { border: '1px solid #ccc' }
                      }}
                    />
                  )}

                  {offlineSeries.length > 0 && (
                    <VictoryArea
                      animate={{ duration: 500 }}
                      interpolation="linear"
                      data={offlineSeries}
                      x={(d) => d.time}
                      y={'value'}
                      style={{
                        data: { fill: '#DC7570', fillOpacity: 0.5, stroke: '#DC7570', strokeWidth: 0.6 },
                        parent: { border: '1px solid #ccc' }
                      }}
                    />
                  )}

                  {permOfflineSeries.length > 0 && (
                    <VictoryArea
                      animate={{ duration: 500 }}
                      interpolation="linear"
                      data={permOfflineSeries}
                      x={(d) => d.time}
                      y={'value'}
                      style={{
                        data: { fill: '#7E7E7E', fillOpacity: 0.5, stroke: '#7E7E7E', strokeWidth: 0.6 },
                        parent: { border: '1px solid #ccc' }
                      }}
                    />
                  )}
                </VictoryStack>
                <VictoryLabel
                  textAnchor="middle"
                  style={{ fontSize: 5, fill: 'rgba(204,204,204)' }}
                  x={5}
                  y={30}
                  // text={title}
                  angle={270}
                />
              </VictoryChart>
            </Segment>

            <div className="description-text">
              <div className="legend-explainer">
                <div className="legend-explainer-title">
                  <h4>Online:</h4>
                </div>
                <div className="legend-explainer-text">Last update within 5 minutes</div>
              </div>
              <div className="legend-explainer">
                <div className="legend-explainer-title">
                  <h4>Temporarily offline:</h4>
                </div>
                <div className="legend-explainer-text">Last update within 1 day</div>
              </div>
              <div className="legend-explainer">
                <div className="legend-explainer-title">
                  <h4>Offline:</h4>
                </div>
                <div className="legend-explainer-text">Last update within 30 days</div>
              </div>
              <div className="legend-explainer">
                <div className="legend-explainer-title">
                  <h4>Permanently offline:</h4>
                </div>
                <div className="legend-explainer-text">Last update before 30 days</div>
              </div>
            </div>
          </div>

          <div id="gateway-table">
            <div className="flex1-row">
              <div className="listbox-container">
                <h3 className="mr2">Gateway status</h3>
              </div>
              <div className="listbox-container">
                <Dropdown
                  selection
                  placeholder="Community"
                  options={getStatusDropdownData()}
                  value={selectedStatus}
                  onChange={onChangeStatusDropdown}
                ></Dropdown>
              </div>
            </div>

            <Segment id="gateway-table-element">
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">
                      <span className="table-header">S.no</span>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      <span onClick={onClickUserID} className={'table-header-clickable'}>
                        {sortedBy === 'userID' ? (
                          <span className="table-header">
                            User ID <span className="sort-identifier">&#9660;</span>
                          </span>
                        ) : (
                          <span className="table-header">User ID</span>
                        )}
                      </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <span onClick={onClickSNo} className="table-header-clickable">
                        {sortedBy === 'sNo' ? (
                          <span className="table-header">
                            Gateway s.no <span className="sort-identifier">&#9660;</span>
                          </span>
                        ) : (
                          <span className="table-header">Gateway s.no</span>
                        )}
                      </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <span onClick={onClickAddedOn} className="table-header-clickable">
                        {sortedBy === 'addedOn' ? (
                          <span className="table-header">
                            Added on <span className="sort-identifier">&#9660;</span>
                          </span>
                        ) : (
                          <span className="table-header">Added on</span>
                        )}
                      </span>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <span onClick={onClickLastSeenOn} className="table-header-clickable">
                        {sortedBy === 'lastSeenOn' ? (
                          <span className="table-header">
                            Last seen on <span className="sort-identifier">&#9660;</span>
                          </span>
                        ) : (
                          <span className="table-header">Last seen on</span>
                        )}
                      </span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {gatewaysOnDisplay.map((gateway) => (
                    <Table.Row key={gateway.serialnr}>
                      <Table.Cell>{gateway.order}</Table.Cell>
                      <Table.Cell>{gateway.userId || '-'}</Table.Cell>
                      <Table.Cell>{gateway.serialnr}</Table.Cell>
                      <Table.Cell>{formatDate(new Date(gateway.createdOn))}</Table.Cell>
                      <Table.Cell>{formatDate(new Date(gateway.lastUpdatedOn))}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Segment>

            <div className="gateway-table-footer">
              <div className="flex1-row mr2">
                <div className="listbox-container">
                  <h4 className="mr">Total: </h4>
                </div>
                <div className="listbox-container mr2">
                  <h4 className="mr2" id="num_gateways">
                    {tableGateways.length}
                  </h4>
                </div>
              </div>

              <div className="gateway-table-pagination flex1-row">
                <div className="pagination-btn-div">
                  <div className="flex1-column flex1-center">
                    <BsFillCaretLeftFill className="pagination-back" onClick={onClickPrev}></BsFillCaretLeftFill>
                  </div>
                  {pageNumbersToDisplay.map((page: number) => (
                    <button
                      key={`page-${page}`}
                      className={page === selectedPage ? 'pagination-btn active' : 'pagination-btn'}
                      value={page}
                      onClick={onClickPageButton}
                    >
                      {page}
                    </button>
                  ))}
                  <div className="flex1-column flex1-center">
                    <BsFillCaretRightFill className="pagination-next" onClick={onClickNext}></BsFillCaretRightFill>
                  </div>
                </div>

                <div className="pagination-items">
                  <div className="flex1-column flex1-center">
                    <input
                      type="number"
                      className="pagination-input"
                      value={itemsPerPage}
                      onChange={onChangeItemsPerPage}
                    />
                  </div>
                  <div className="flex1-column flex1-center">
                    <span> items/page</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Status
