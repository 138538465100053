import * as React from 'react'
import './usage_graph.scss'
import { VictoryPie, VictoryLabel } from 'victory'
import { AiOutlineSync } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { useContext, useState, useEffect } from 'react'
import { usageGraphColors, defaultGraphicData, wantedGraphicData } from '../../../util/graph_data'
import DataContext from '../../../contexts/data'
import { ISummaryAggregate } from '../../../interfaces/aggregate'

export interface IUsageGraphData {
  x: number
  y: number | undefined
}

const UsageGraph: React.FC = () => {
  const dataContext = useContext(DataContext)
  const [summary, setSummary] = React.useState<ISummaryAggregate | undefined>()

  useEffect(() => {
    setSummary(dataContext?.aggregate?.summaryAggregate)
  }, [summary])
  return (
    <>
      <div className="usage-graph">
        <div className="flex1-row space-between">
          <strong className="title">Herkomst</strong>
          <div className="refresh-icon">
            <IconContext.Provider
              value={{
                style: { verticalAlign: 'middle', fill: 'rgba(0,183,213)' }
              }}
            >
              <div>
                <AiOutlineSync />
              </div>
            </IconContext.Provider>
          </div>
        </div>
        <hr />
        <div>
          <svg viewBox="0, 0, 400, 350">
            <circle cx="200" cy="200" r="120" fill="none" stroke="rgba(204,204,204)" strokeWidth={8} />
            <VictoryPie
              animate={{ duration: 1000 }}
              startAngle={180}
              endAngle={540}
              colorScale={usageGraphColors}
              standalone={false}
              data={[
                { x: 1, y: dataContext?.aggregate?.summaryAggregate?.fromSolar },
                { x: 2, y: dataContext?.aggregate?.summaryAggregate?.fromBattery },
                { x: 3, y: dataContext?.aggregate?.summaryAggregate?.fromGrid }
              ]}
              labels={() => null}
              innerRadius={130}
              style={{ labels: { fontSize: 20, fill: 'white' } }}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 30, fill: 'rgba(204,204,204)' }}
              x={200}
              y={200}
              text={`${dataContext?.aggregate?.summaryAggregate?.totalConsumption
                .toFixed(2)
                .toString()
                .replace('.', ',')} kWh`}
            />
          </svg>
        </div>
        <div className="flex1-row m-p-y-top space-between">
          <div className="flex1-column">
            <div className="flex1-row legend-p-y">
              <div className="solar-legend" />
              <div>Zonnepanelen</div>
            </div>
            <div className="flex1-row legend-p-y">
              <div className="home-battery-legend" />
              <div>Batterij</div>
            </div>
            <div className="flex1-row legend-p-y">
              <div className="grid-legend" />
              <div>Elektriciteitsnet</div>
            </div>
          </div>
          <div className="flex1-column legend-p-x">
            <div className="legend-p-y">
              {dataContext?.aggregate?.summaryAggregate?.fromSolar.toFixed(2).toString().replace('.', ',')}%
            </div>
            <div className="legend-p-y">
              {dataContext?.aggregate?.summaryAggregate?.fromBattery.toFixed(2).toString().replace('.', ',')}%
            </div>
            <div className="legend-p-y">
              {dataContext?.aggregate?.summaryAggregate?.fromGrid.toFixed(2).toString().replace('.', ',')}%
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UsageGraph
