
import * as React from 'react'
import { Button, Image, Form } from 'semantic-ui-react'
import { CustomerFormComplete } from '..'
import { useState } from 'react'
import * as customer from '../../assets/Customer.svg'
import { useForm } from 'react-hook-form'
import './customer_details.scss'
import OnboardService from '../../services/onboard'
import AuthContext from '../../contexts/auth'
import UserContext from '../../contexts/user'

type CustomerDetailForm = {
  firstName: string
  lastName: string
  streethous1: string
  streethous2: string
  postcode1: string
  postcode2: string
  mobileno: string
  telephoneno: string
}

// Component to get all the details of the customer
const CustomerDetails: React.FC = () => {
  const onboardService = new OnboardService()
  const authContext = React.useContext(AuthContext)
  const userContext = React.useContext(UserContext)

  const [customerFormComplete, setCustomerFormComplete] = useState<boolean>(false)
  const { register, handleSubmit, errors } = useForm<CustomerDetailForm>()
  const onSubmit = async (data: CustomerDetailForm) => {
    const token = authContext?.accessToken!
    const cdata = {
      firstName: data.firstName,
      lastName: data.lastName,
      street: data.streethous1,
      streetNumber: data.streethous2,
      postcode: data.postcode1,
      city: data.postcode2,
      mobileNumber: data.mobileno,
      phone: data.telephoneno
    }
    const result = await onboardService.updateCustomerDetails(token, cdata)
    //
    if (!result) {
      // TODO proper error display
      alert('Unable to update customer details')
      return
    }
    userContext?.setProfile(result)
    setCustomerFormComplete(true)
  }
  return (
    <>
      {customerFormComplete ? (
        <CustomerFormComplete />
      ) : (
        <>
          <div className="customer-detail-container">
            <div className="customer-detail-form-container">
              <div className="flex1-row">
                <Image src={customer.default} className="customer-detail-logo-size"></Image>
                <strong className="customer-details-header" style={{ fontWeight: 'bold', fontSize: '22px' }}>
                  Gebruiker
                </strong>
              </div>
              <hr />
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="customer-details flex1-column">
                  {userContext?.profile?.customerType !== 'private' && (
                    <div className="flex1-row">
                      <p className="customer-detail-names">Bedrijfsnaam</p>
                      <Form.Field>
                        <input
                          name="companyName"
                          value={userContext?.profile?.userName}
                          className="form1-item"
                          disabled
                        />
                      </Form.Field>
                    </div>
                  )}

                  <div className="flex1-row">
                    <p className="customer-detail-names required-field">Voornaam</p>
                    <Form.Field>
                      <input
                        name="firstName"
                        value={userContext?.profile?.firstName}
                        className="form1-item"
                        ref={register({ required: true })}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <div className="customer-detail-names">
                      <p className="required-field">Achternaam</p>
                    </div>
                    <Form.Field>
                      <input
                        name="lastName"
                        value={userContext?.profile?.lastName}
                        className="form1-item"
                        ref={register({ required: true })}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <div className="customer-detail-names">
                      <p className="required-field">Straat + Nummer</p>
                    </div>
                    <div className="streethous flex1-row">
                      <Form.Field>
                        <input name="streethous1" className="form1-item" ref={register({ required: true })} />
                        {/* {errors.streethous1 &&
                          errors.streethous1.type === 'required' && (
                          <div className="error-msg">
                              U moet uw straat naam invoeren.
                          </div>
                        )} */}
                      </Form.Field>
                      <Form.Field>
                        <input name="streethous2" className="form1-item" ref={register({ required: true })} />
                        {/* {errors.streethous2 &&
                          errors.streethous2.type === 'required' && (
                          <div className="error-msg">
                              U moet uw straat nummer invoeren.
                          </div>
                        )} */}
                      </Form.Field>
                    </div>
                  </div>
                  <div className="flex1-row">
                    <div className="customer-detail-names">
                      <p className="required-field">Postcode + Plaatsnaam</p>
                    </div>
                    <div className="postcode-city flex1-row">
                      <Form.Field>
                        <input
                          name="postcode1"
                          className="form1-item"
                          ref={register({
                            required: true,
                            pattern: {
                              value: /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i,
                              message: 'Voer alstublieft een geldige postcode in'
                            }
                          })}
                        />
                        {errors.postcode1 && errors.postcode1.type === 'pattern' && (
                          <div className="error-msg">{errors.postcode1.message}</div>
                        )}
                      </Form.Field>
                      <Form.Field>
                        <input name="postcode2" className="form1-item" ref={register({ required: true })} />
                        {/* {errors.postcode2 &&
                          errors.postcode2.type === 'required' && (
                          <div className="error-msg">
                              U moet uw plaatsnaam invoeren.
                          </div>
                        )} */}
                      </Form.Field>
                    </div>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">E-mail</p>
                    <Form.Field>
                      <input
                        name="email"
                        value={userContext?.profile?.email}
                        className="form1-item obligation-input"
                        disabled
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names required-field">Mobiele nr.</p>
                    <Form.Field>
                      <input
                        name="mobileno"
                        className="form1-item"
                        ref={register({
                          required: true,
                          pattern: {
                            value: /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/i,
                            message: 'Voer een geldig mobiel nummer in'
                          }
                        })}
                      />
                      {errors.mobileno && errors.mobileno.type === 'pattern' && (
                        <div className="error-msg">{errors.mobileno.message}</div>
                      )}
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">Telefoon</p>
                    <Form.Field>
                      <input
                        name="telephoneno"
                        className="form1-item"
                        ref={register({
                          required: false,
                          pattern: {
                            value: /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/i,
                            message: 'Vul alsjeblieft een juist telefoonnummer in'
                          }
                        })}
                      />
                      {errors.telephoneno && errors.telephoneno.type === 'pattern' && (
                        <div className="error-msg">{errors.telephoneno.message}</div>
                      )}
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">Klanttype</p>
                    <Form.Field>
                      <input
                        name="customer-type"
                        value={userContext?.profile?.customerType}
                        className="form1-item obligation-input"
                        disabled
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row flex1-end m-px-r m-py-bottom">
                    <Button className="btn-green" content="OK" />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default CustomerDetails
