import * as React from 'react'
import * as loading from '../../../assets/loading.png'
import { Image, Message } from 'semantic-ui-react'
import './smart_meter_search.scss'

interface ISmartMeterSearchProps {
  title: string
  error: string
  onRetry: () => any
}

const SmartMeterSearch = (props: ISmartMeterSearchProps) => {
  return (
    <div className="large-consumer-sm-container">
      {(!props.error || props.error.length < 1) && (
        <div className="content-center">
          <strong className="bold">{props.title}</strong>
          <div className="normal">even geduld a.u.b</div>
          <Image src={loading.default} className="rotate" />
        </div>
      )}
      {props.error && props.error.length > 0 && (
        <div className="content-center">
          <strong className="bold">{'Slimme meter niet gevonden'}</strong>
          <div style={{ padding: '20px' }} className="flex1-row flex1-center">
            {'Slimme meter niet gevonden. Probeer het opnieuw door te gateway en meter correct te verbinden.'}
          </div>
          <button onClick={props.onRetry} className="btn-blue try-again">
            Probeer het opnieuw
          </button>
          <Message color="red">{props.error}</Message>
        </div>
      )}
      {/* <div className="content-center">
        {(!props.error || props.error.length < 1) && <strong className="bold">{props.title}</strong>}
        {(!props.error || props.error.length < 1) && <div className="normal">even geduld a.u.b</div>}
        {(!props.error || props.error.length < 1) && <Image src={loading.default} className="rotate" />}
        {props.error && props.error.length > 0 && <strong className="bold">{'Slimme meter niet gevonden'}</strong>}
        {props.error && props.error.length > 0 && (
          <div style={{ padding: '20px' }} className="flex1-row flex1-center">
            {'Slimme meter niet gevonden. Probeer het opnieuw door te gateway en meter correct te verbinden.'}
          </div>
        )}
        {props.error && props.error.length > 0 && (
          <button onClick={props.onRetry} className="btn-blue try-again">
            Probeer het opnieuw
          </button>
        )}
      </div>
      {props.error && props.error.length > 0 && <Message color="red">{props.error}</Message>} */}
    </div>
  )
}

export default SmartMeterSearch
