import * as React from 'react'
import AuthContext from '../../../contexts/auth'
import UserService from '../../../services/user'
import CommunityService from '../../../services/community'
import { DataTable } from '../../Generic/Table'
import UserDetails from './UserDetails'
import { normalizeCase } from '../../../util/strings'
import { Dropdown, Input } from 'semantic-ui-react'
import * as searchIcon from '../../../assets/Search-icon.png'

import './users_list.scss'

interface IUsersListProps {
  community: any
}

const UsersList: React.FC<IUsersListProps> = (props: IUsersListProps) => {
  const [users, setUsers] = React.useState<any[]>([])
  const [filteredUsers, setFilteredUsers] = React.useState<any[]>([])
  const [filterValue, setFilterValue] = React.useState<string>('')
  const [searchValue, setSearchValue] = React.useState<string>('')

  const authContext = React.useContext(AuthContext)
  const userService = new UserService()
  const communityService = new CommunityService()

  const fetchUsers = async () => {
    const token = authContext?.accessToken!
    var result: any

    if (!('id' in props.community)) {
      result = await userService.fetchUsers(token)
    } else {
      result = await communityService.fetchCommunitySubusers(token, props.community.id)
    }

    result.map((user: any) => {
      const color = getUserStatusColor(user)
      user.statusColor = (
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: color,
            border: '1px solid black',
            width: 20,
            height: 20,
            margin: 'auto'
          }}
        ></div>
      )
    })
    setUsers(result)
    setFilteredUsers(result)
  }
  console.log('users', users)
  const getUserStatusColor = (user: any) => {
    const status = user.onlineStatus
    if (status === 'online') {
      return '#3CD2A0'
    } else if (status === 'temporarily offline') {
      return '#FBBB21'
    } else if (status === 'offline') {
      return '#DC7570'
    } else if (status === 'permanently offline') {
      return '#7E7E7E'
    } else {
      return '#FFFFFF'
    }
  }

  const userDetailsComponent = (user: any) => {
    return <UserDetails user={user} community={props.community} />
  }

  const getTableValueForKey = (key: string, user: any) => {
    const mapping: any = {
      Community: 'communityName',
      User: 'firstName',
      Status: 'status',
      'User ID': 'userId',
      Connection: 'statusColor'
    }
    if (key.toLowerCase() === 'user') {
      return normalizeCase(`${user.firstName} ${''} ${user.lastName}`)
    }
    if (key.toLowerCase() === 'status') {
      return normalizeCase(user[mapping[key]])
    }
    return user[mapping[key]]
  }

  const onClickSearch = () => {}

  const onChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
    let temp = users
    if (filterValue === '') {
      temp = users.filter(
        (user) =>
          user.userId.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
          user.communityName.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
          user.status.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
          user.firstName.toString().toLowerCase().includes(event.target.value.toLowerCase())
      )
    } else {
      temp = users.filter((user) =>
        filterValue === 'userId'
          ? user[filterValue] === parseInt(event.target.value)
          : user[filterValue].toLowerCase().includes(event.target.value.toLowerCase())
      )
    }
    setFilteredUsers(temp)
  }

  const onChangeFilterValue = (event: React.SyntheticEvent, data: any) => {
    setFilterValue(data.value)
    setFilteredUsers(users)
  }

  const searchOptions =
    'id' in props.community
      ? [
        {
          key: 'firstName',
          text: 'User',
          value: 'firstName'
        },
        {
          key: 'userId',
          text: 'User number',
          value: 'userId'
        },
        {
          key: 'status',
          text: 'Status',
          value: 'status'
        }
      ]
      : [
        {
          key: 'communityName',
          text: 'Community',
          value: 'communityName'
        },
        {
          key: 'firstName',
          text: 'User',
          value: 'firstName'
        },
        {
          key: 'userId',
          text: 'User number',
          value: 'userId'
        },
        {
          key: 'status',
          text: 'Status',
          value: 'status'
        }
      ]

  React.useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <div id="users-list-container">
      <div id="search-bar" className="flex1-row flex-center-vertical">
        <Dropdown
          className="users-list-field"
          placeholder="Filter"
          // defaultValue={this.state.selected}
          fluid
          selection
          options={searchOptions}
          onChange={onChangeFilterValue}
        />
        <Input className="users-list-field" placeholder="Search" onChange={onChangeSearchValue} />
        <div className="users-list-button" onClick={onClickSearch}>
          <img src={searchIcon.default} className="search-box m-t-medium" alt="search" />
        </div>
      </div>
      <div id={'id' in props.community ? 'users-list-community' : 'users-list-admin'}>
        <DataTable
          displayKeys={
            'id' in props.community
              ? ['User', 'User ID', 'Connection', 'Status']
              : ['Community', 'User', 'User ID', 'Connection', 'Status']
          }
          records={filteredUsers}
          detailsComponent={userDetailsComponent}
          valueForkey={getTableValueForKey}
        />
      </div>
    </div>
  )
}

export default UsersList
