import * as React from 'react'
import { Image } from 'semantic-ui-react'
import * as carEmpty from '../../../assets/white_car.svg'
import * as carFull from '../../../assets/full_car.svg'
import * as arrowIcon from '../../../assets/Arrow.svg'
import './electric_car.scss'

const ElectricCar: React.FC = () => {
  // full empty 105px, filled one 23px then start from 1 px .. each one px can be calculated as one percentage
  const filling = 50
  const fillingPercentage = `${filling}px`
  return (
    <>
      <div className="grid-container">
        <strong className="title">Elektrische auto</strong>
        <hr />
        <strong className="flex1-column flex1-center discharged" style={{ marginTop: '30px', marginBottom: '10px' }}>
          Laden
        </strong>
        <div className="flex1-column flex1-center">
          <div className="car-outer">
            <img src={carFull.default} className="car-size" />
            <div className="car-inner" style={{ height: fillingPercentage }}>
              <img src={carEmpty.default} className="car-size" />
            </div>
          </div>
          <div className="flex1-row space-between">
            <div className="flex1-column electric-auto-filled-value">
              <div>44%</div>
              <div>135 km</div>
            </div>
            <div className="electric-auto-detail m-x-l">53 min. tot volledig geladen</div>
          </div>
        </div>
        <div className="flex1-row flex1-end">
          <Image src={arrowIcon.default} className="arrow-icon" />
        </div>
      </div>
    </>
  )
}
export default ElectricCar
