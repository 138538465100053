import api from '../api'

export default class TaxService {
  years: Set<number> = new Set<number>()

  getTaxRates = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.get('/taxes/', config)
    const data = res.data
    data.map((item:any) => {
      this.years.add(item.year)
    })
    return this.parseTaxRates(data)
  }

  updateTaxRates = async (accessToken: string, taxRates: any[]) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.put('/taxes/', taxRates, config)
    const data = res.data
    return this.parseTaxRates(data)
  }

  getTaxReductions = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.get('/taxes/reductions', config)
    const data = res.data
    data.map((item:any) => {
      this.years.add(item.year)
    })
    return data
  }

  updateTaxReductions = async (accessToken: string, taxReductions: any[]) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const res = await api.put('/taxes/reductions', taxReductions, config)
    const data = res.data
    return data
  }

  parseTaxRates = (data: any[]) => {
    const btwPercent: any = {}
    const parsedData: any = {}
    data.map((t: any) => {
      this.years.add(t.year)
      if (!(t.tax.taxType in parsedData)) {
        parsedData[t.tax.taxType] = {}
      }
      if (!(t.commodity.commodityType in parsedData[t.tax.taxType])) {
        parsedData[t.tax.taxType][t.commodity.commodityType] = {}
      }
      if (!(t.lowerLimit in parsedData[t.tax.taxType][t.commodity.commodityType])) {
        parsedData[t.tax.taxType][t.commodity.commodityType][t.lowerLimit] = {}
        parsedData[t.tax.taxType][t.commodity.commodityType][t.lowerLimit].upperLimit = t.upperLimit
      }
      if (!(t.year in parsedData[t.tax.taxType][t.commodity.commodityType][t.lowerLimit])) {
        parsedData[t.tax.taxType][t.commodity.commodityType][t.lowerLimit][t.year] = {}
      }
      parsedData[t.tax.taxType][t.commodity.commodityType][t.lowerLimit][t.year] = {
        value: t.taxEuroPerKwh > 0 ? t.taxEuroPerKwh : t.taxEuroPerM3,
        id: t.id,
        field: t.commodity.commodityType === 'gas' ? 'taxEuroPerM3' : 'taxEuroPerKwh'
      }

      if (btwPercent[t.year] === undefined) {
        btwPercent[t.year] = { value: t.btwPercent, ids: [t.id] }
      } else {
        btwPercent[t.year].ids.push(t.id)
      }
    })
    return { parsedData: parsedData, btwPercent: btwPercent, years: Array.from(this.years).sort() }
  }
}
