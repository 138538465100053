import * as React from 'react'
import { useState, useEffect } from 'react'
import * as logoWhite from '../../assets/rsz_logo_rgb_3-09-2019_3.jpg'
import { BsQuestion } from 'react-icons/bs'
import { VerificationMailSent, MailVerified } from '..'
import './register.scss'
import { useForm } from 'react-hook-form'
import { Form, Button, Image } from 'semantic-ui-react'
import OnboardService from '../../services/onboard'
import { Loader } from '../Generic/Loader'
import { isCommunityMode } from '../../util/helper'
import routes from '../../pages/Routes'

type OnBoardAcctMakingForm = {
  username: string
  mailadress: string
  pswd: string
  strongPswd: string
}

// Component to make an account for the new user
const CreateAccount: React.FC = () => {
  const onboardService = new OnboardService()

  let toolTipClassName = 'tooltip'
  const { errors } = useForm<OnBoardAcctMakingForm>()
  const [isValidatingToken, setIsValidatingToken] = useState<boolean>(true)
  const [tokenIsBad, setTokenIsBad] = useState<boolean>(false)
  const [verificationMailSent, setVerificationMailSent] = useState<boolean>(false)
  const [mailVerified, setMailVerified] = useState<boolean>(false)
  const [patternMatch, setPatternMatch] = useState<boolean>(false)
  const [showTick, setShowTick] = useState<boolean>(false)
  const [pwdTyping, setPwdTyping] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [passwordMatchError, setPasswordMatchError] = useState<boolean>(false)
  const [passwordEmpty, setPasswordEmpty] = useState<boolean>(false)
  // Password entered should match this pattern
  // Password should contain minimum 8 characters , in that it should have one special character
  // one Capital letter , one small letter and one numbers
  // e.g. City@12345
  const pattern = RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*@#%^&?!/]).{8,}$')

  const passwordIsMatch = () => {
    return password === confirmPassword
  }

  const tokenFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    let urlToken = urlParams.get('token')
    urlToken = urlToken || ''
    return urlToken
  }

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setPwdTyping(true)
    toolTipClassName = pwdTyping ? '' : 'tooltip'
    const input = event.target as HTMLInputElement
    const inputKey: string = input.value
    if (pattern.test(inputKey) === true) {
      setShowTick(true)
      setPatternMatch(true)
    } else {
      setShowTick(true)
      setPatternMatch(false)
    }
  }

  const pwdClassName = patternMatch ? 'valid' : 'invalid'

  const onSubmit = async () => {
    if (password === '') {
      setPasswordEmpty(true)
    } else {
      const result = await onboardService.setUserPassword(token, password)
      if (result && password !== '' && confirmPassword !== '') {
        setVerificationMailSent(true)
      }
    }
  }

  const validateToken = async () => {
    const urlToken: string = tokenFromUrl()
    try {
      setIsValidatingToken(true)
      const result = await onboardService.validateToken(urlToken)
      setToken(urlToken)
      switch (result.type) {
        case 'invite':
          setName(`${result.firstName} ${result.lastName}`)
          setEmail(result.email)
          break
        case 'account_verification':
          setMailVerified(true)
          break
        default:
      }
      setTokenIsBad(false)
      setIsValidatingToken(false)
    } catch (err) {
      setTokenIsBad(true)
      setIsValidatingToken(false)
    }
  }

  useEffect(() => {
    validateToken()
  }, [])

  if (isValidatingToken) {
    return <Loader title="Verifying" subtitle="Please wait..." />
  }

  if (tokenIsBad) {
    return (
      <div className="mail-verified-container flex1-column flex1-start  m-y-top">
        <div className="flex1-column flex1-center">
          <h5>Bad or Expired Token</h5>
          <div>Token has either been used or has expired</div>
        </div>
      </div>
    )
  }

  return (
    <>
      {mailVerified ? (
        <MailVerified token={token} />
      ) : verificationMailSent ? (
        <VerificationMailSent />
      ) : (
        <>
          <div className="acctmaking-container flex1-column-start">
            {/* <div className="txt-container" /> */}
            <div className="acctmaking-form-container flex1-start">
              <div className="account-container flex1-cloumn flex1-center">
                <strong className="font-medium">Welkom bij Lyv!</strong>
                <br />
                {isCommunityMode() ? (
                  <strong className="font-medium">Maak hier uw community</strong>
                ) : (
                  <strong className="font-medium">Maak hier uw account aan.</strong>
                )}
                <br />
                {isCommunityMode() && <strong className="font-medium"> manager account aan. </strong>}
                <p className="flex1-column instruction m-bottom m-t-medium">
                  Voer een sterk wachtwoord in en
                  <br />
                  klik op OK.
                </p>
                <div className="form-container">
                  <Form onSubmit={onSubmit} className="stackable">
                    <div className="resgister-form-inner-item">
                      <div className="flex1-center flex1-column m-bottom">
                        <Image src={logoWhite.default} className="logo-size m-top" />
                        <strong className="m-top">Smart Energy Platform</strong>
                      </div>
                      <Form.Field>
                        <input
                          placeholder="Brendan de Graaf"
                          className="form1-item obligation-input"
                          value={name}
                          name="name"
                          onChange={(e) => {
                            setName(e.target.value)
                          }}
                          disabled
                          required
                        />
                      </Form.Field>
                      <Form.Field>
                        <input
                          placeholder="Brendan Tx @123"
                          className="form1-item obligation-input m-bottom"
                          name="mailadress"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value)
                          }}
                          disabled
                          required
                        />
                      </Form.Field>
                      <Form.Field>
                        <div className="flex1-row password-container">
                          <input
                            placeholder="Wachtwoord"
                            type="password"
                            className="form1-item pswd-input"
                            name="pswd"
                            pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*@#%^&?!/]).{8,}$"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value)
                              setPasswordEmpty(false)
                            }}
                            onKeyUp={onKeyUp}
                            title="Graag het gevraagde formaat aan te passen in het Netherlands."
                          />
                          <div className="flex2-row">
                            <div className={toolTipClassName}>
                              <BsQuestion className="help" />
                              <span className="tooltiptext">
                                Gebruik voor uw nieuwe wachtwoord in ieder geval:
                                <br />
                                1) Minimaal acht tekens <br />
                                2) Een speciaal teken <br />
                                3) Een hoofdletter <br />
                                4) Kleine letters <br />
                                5) Nummers
                              </span>
                            </div>
                            {showTick && <div className={pwdClassName} />}
                          </div>
                        </div>
                        {passwordEmpty && <div className="error-msg">Voer hier uw wachtwoord in.</div>}
                      </Form.Field>
                      <Form.Field>
                        <input
                          placeholder="Bevestig wachtwoord"
                          type="password"
                          className="form1-item"
                          name="strongPswd"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value)
                            if (password === e.target.value) {
                              setPasswordMatchError(false)
                            } else {
                              setPasswordMatchError(true)
                            }
                          }}
                        />
                        {passwordMatchError && (
                          <div className="error-msg">Het wachtwoord komt niet overeen. Controleer uw wachtwoord.</div>
                        )}
                      </Form.Field>
                      <Button className="m-top btn-green" content="OK" id="userType" disabled={!passwordIsMatch()} />
                    </div>
                  </Form>
                </div>
                <p className="flex1-column instruction m-top">
                  Na bevestiging van uw wachtwoord klikt u op OK. U ontvangt een bevestigingsmail.
                  {/* <br /> */}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default CreateAccount
