import * as React from 'react'
import { useContext, useState, useEffect } from 'react'
import { MenuItemProps, Button, Menu, Segment, Confirm } from 'semantic-ui-react'
import Electricity from './Electricity'
import Gas from './Gas'
import AuthContext from '../../../../contexts/auth'
import DsoService from '../../../../services/dso'

const DSO: React.FC = () => {
  const dsoService = new DsoService()
  const authContext = useContext(AuthContext)

  const [activeItem, setActiveItem] = useState<string>('Electricity')
  const [gridTariffs, setGridTariffs] = useState<any[]>([])
  const [gasTariffs, setGasTariffs] = useState<any[]>([])
  const [dsos, setDsos] = useState<any[]>([])
  const [gridYears, setGridYears] = useState<number[]>([])
  const [gasYears, setGasYears] = useState<number[]>([])

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  const openConfirm = () => {
    setConfirmOpen(true)
  }

  const closeConfirm = () => {
    setConfirmOpen(false)
  }

  const [inEditModeGrid, setInEditModeGrid] = useState<any>({
    status: false,
    ids: []
  })
  const [editGridTariffs, setEditGridTariffs] = useState<any>({})
  const [inEditModeGas, setInEditModeGas] = useState<any>({
    status: false,
    ids: []
  })
  const [editGasTariffs, setEditGasTariffs] = useState<any>({})

  const fetchDsos = async () => {
    const accessToken = authContext?.accessToken!
    const result = await dsoService.getDsos(accessToken)
    setDsos(result)
  }

  const fetchGridTariffs = async () => {
    const accessToken = authContext?.accessToken!
    const result = await dsoService.getGridTariffs(accessToken)
    setGridTariffs(result.tariffs)
    setGridYears(result.years)
  }

  const updateGridTariffs = async () => {
    const accessToken = authContext?.accessToken!
    const result = await dsoService.updateGridTariffs(accessToken, Object.values(editGridTariffs))
    setGridTariffs(result.tariffs)
    setGridYears(result.years)
    setInEditModeGrid({
      status: false,
      ids: []
    })
    setEditGridTariffs({})
  }

  const fetchGasTariffs = async () => {
    const accessToken = authContext?.accessToken!
    const result = await dsoService.getGasTariffs(accessToken)
    const tariffs = result.tariffs
    tariffs.sort((a: any, b: any) => {
      const sizes = {
        'G4/G6SJV<500': 0,
        'G4/G6SJV500-4000': 1,
        'G4/G6SJV>4000': 2,
        G10: 3,
        G16: 4,
        G25: 5
      }
      const aSize = a.size.name.replaceAll(/[\s.,]/g, '')
      const bSize = b.size.name.replaceAll(/[\s.,]/g, '')
      if (a.name.name < b.name.name) {
        return -1
      } else if (a.name.name > b.name.name) {
        return 1
      } else {
        return sizes[aSize] - sizes[bSize]
      }
    })
    setGasTariffs(tariffs)
    setGasYears(result.years)
  }

  const updateGasTariffs = async () => {
    const accessToken = authContext?.accessToken!
    const result = await dsoService.updateGasTariffs(accessToken, Object.values(editGasTariffs))
    setGasTariffs(result.tariffs)
    setGasYears(result.years)
    setInEditModeGas({
      status: false,
      ids: []
    })
    setEditGasTariffs({})
  }

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    setActiveItem(data.name as string)
  }

  const addGridTariffsToEdits = (ids: number[], field: string, value: number) => {
    setInEditModeGrid({ status: true, ids: inEditModeGrid.ids.concat(ids) })
    ids.map((id) => {
      if (!(id in editGridTariffs)) {
        editGridTariffs[id] = { id: id }
      }
      editGridTariffs[id][field] = value
    })
    setEditGridTariffs(editGridTariffs)
  }

  const addGasTariffsToEdits = (ids: number[], field: string, value: number) => {
    setInEditModeGas({ status: true, ids: inEditModeGas.ids.concat(ids) })
    ids.map((id) => {
      if (!(id in editGasTariffs)) {
        editGasTariffs[id] = { id: id }
      }
      editGasTariffs[id][field] = value
    })
    setEditGasTariffs(editGasTariffs)
  }

  const handleGridTariffsChange = (ids: number[], field: string, value: number) => {
    ids.map((id) => {
      editGridTariffs[id][field] = value
    })
    setEditGridTariffs(JSON.parse(JSON.stringify(editGridTariffs)))
  }

  const handleGasTariffsChange = (ids: number[], field: string, value: number) => {
    ids.map((id) => {
      editGasTariffs[id][field] = value
    })
    setEditGasTariffs(JSON.parse(JSON.stringify(editGasTariffs)))
  }

  const handleButtonClick = () => {
    closeConfirm()
    if (Object.keys(editGridTariffs).length > 0) {
      updateGridTariffs()
    }
    if (Object.keys(editGasTariffs).length > 0) {
      updateGasTariffs()
    }
  }

  useEffect(() => {
    fetchDsos()
    fetchGridTariffs()
    fetchGasTariffs()
  }, [])

  return (
    <div className="source-container dso-source-container">
      <div className="dso-header">
        <Menu secondary size="small">
          <Menu.Item
            name="Electricity"
            active={activeItem === 'Electricity'}
            onClick={handleItemClick}
          />
          <Menu.Item
            name= "Gas"
            active={activeItem === 'Gas'}
            onClick={handleItemClick}
          />
          <Menu.Item position="right">
            <Button className="btn-green" size="medium"
              onClick={openConfirm}
              disabled={!(inEditModeGrid.status || inEditModeGas.status)}
            >
              Modify
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={closeConfirm}
              onConfirm={handleButtonClick}
              content="Are you sure you want to modify the values?"
              cancelButton="No"
              confirmButton="Yes"
            />
          </Menu.Item>
        </Menu>
      </div>
      <Segment style={{ width: '100%' }}>
        {activeItem === 'Electricity' && (
          <Segment attached="bottom">
            <Electricity
              gridTariffs={gridTariffs}
              dsos={dsos}
              years={gridYears}
              editGridTariffs={editGridTariffs}
              inEditMode={inEditModeGrid}
              handleTariffsChange={handleGridTariffsChange}
              handleTariffsClick={addGridTariffsToEdits}
            />
          </Segment>
        )}
        {activeItem === 'Gas' && (
          <Segment attached="bottom">
            <Gas
              gasTariffs={gasTariffs}
              dsos={dsos}
              years={gasYears}
              editGasTariffs={editGasTariffs}
              inEditMode={inEditModeGas}
              handleTariffsChange={handleGasTariffsChange}
              handleTariffsClick={addGasTariffsToEdits}
            />
          </Segment>
        )}
      </Segment>
    </div>
  )
}

export default DSO
