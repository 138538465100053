import { ChangeEvent } from 'react'
import * as boilerSwitch from '../assets/boiler.svg'
import * as smartMeter from '../assets/car_gauge.png'
import * as smartPlug from '../assets/develco_smart_plug.png'
import * as solarPlant from '../assets/solar_plant.png'
import * as solarPanelBattery from '../assets/solar_plant_battery.png'
import * as chargingPoleEv from '../assets/charging_pole_ev.png'
import * as spaceHeater from '../assets/space_heater.png'
// import * as smartPlug from '../assets/smart_plug.png'
import * as battery from '../assets/battery_charging.svg'
import * as smartRelay from '../assets/smart_relay.png'
import * as gasMeter from '../assets/gas_meter.png'
import * as lightWarmPump from '../assets/light_warm_pump.png'
import { IBatteryAggregate } from '../interfaces/aggregate'
import { SmartControllerApplianceType, SmartControllerApplianceTypeDutch } from '~/enums'
import routes from '../pages/Routes'
import { useToasts } from 'react-toast-notifications'
/** Convert list of key val objects to a single object */
export const listToObject = (data: any[]) => {
  const result = Object()
  data.map((value) => {
    result[value.fieldKey] = value.fieldValue
  })
  return result
}
/** Tick count calculations for usage Graph **/
export const tickCount = (data: any[]) => {
  if (data.length > 48) {
    return Math.round(data.length / 8)
  } else if (data.length < 48) {
    return Math.round(data.length / 4)
  }
}
/** Return an updated list of objects when a change event occurs on a parameter at a certain index */
export const getUpdatedParameterList = (parameterList: any, index: number, event: ChangeEvent<HTMLInputElement>) => {
  const newParameterList = [...parameterList]
  const item = parameterList[index]
  let newKey = item.fieldKey
  let newVal = item.fieldValue
  if (event.target.name === 'key') {
    newKey = event.target.value
  } else if (event.target.name === 'value') {
    newVal = event.target.value
  }
  newParameterList[index] = { fieldKey: newKey, fieldValue: newVal }
  return newParameterList
}

export const deviceGroupAssetMapping = {
  main_meter: smartMeter.default,
  hybrid_inverter: solarPanelBattery.default,
  'zb_smt-relay': smartPlug.default,
  solar_plant: solarPlant.default,
  charging_pole_ev: chargingPoleEv.default,
  battery: battery.default,
  boiler: boilerSwitch.default,
  smart_plug: smartPlug.default,
  smart_relay: smartRelay.default,
  gas_meter: gasMeter.default,
  light_warm_pump: lightWarmPump.default,
  smart_controller: spaceHeater.default
}
export const deviceDescriptionAssetMapping = {
  main_meter: 'Slimme meter',
  large_consumer_main_meter: 'Elektrameter grootverbruik',
  hybrid_inverter: 'Zonnepanelen met batterij',
  solar_plant: 'Zonnepanelen Meter',
  solar_plant_sma: 'Zonnepanelen met omvormer',
  charging_pole_ev: 'Laadpaal EV',
  battery: 'Batterij',
  boiler: 'Meten en schakelen',
  smart_plug: 'Meten en schakelen',
  smart_relay: 'Meten en schakelen',
  space_heater: 'Meten en schakelen',
  gas_meter: 'Slimme gasmeter kleinverbruik',
  light_warm_pump: 'Warmtepomp'
}
export const deviceDescriptionAssetApplicationMapping = {
  smart_meter: {
    smart_meter_english: 'SMART_METER',
    smart_meter_image: smartMeter.default,
    smart_meter_dutch: 'Slimme meter (Elektra en gas)'
  },
  electricity_meter_small_consumer: {
    electricity_meter_small_consumer_english: 'ELECTRICITY_METER_SMALL_CONSUMER',
    electricity_meter_small_consumer_image: smartMeter.default,
    electricity_meter_small_consumer_dutch: 'Elektrameter kleinverbruik'
  },
  electricity_meter_large_consumer: {
    electricity_meter_large_consumer_english: 'ELECTRICITY_METER_LARGE_CONSUMER',
    electricity_meter_large_consumer_image: smartMeter.default,
    electricity_meter_large_consumer_dutch: 'Elektrameter grootverbruik'
  },
  smart_gas_meter_consumer: {
    smart_gas_meter_consumer_english: 'SMART_GAS_METER_CONSUMER',
    smart_gas_meter_consumer_image: gasMeter.default,
    smart_gas_meter_consumer_dutch: 'Slimme gasmeter kleinverbruik '
  },
  boiler: {
    boiler_english: 'boiler',
    boiler_image: boilerSwitch.default,
    boiler_dutch: 'Boiler Meten en schakelen'
  },
  space_heater: {
    space_heater_english: 'space_heater',
    space_heater_image: spaceHeater.default,
    space_heater_dutch: 'E-radiator Meten en schakelen'
  },
  generic: {
    generic_english: 'generic',
    generic_image: smartPlug.default,
    generic_dutch: 'Meten en schakelen '
  },
  hybrid_inverter: {
    hybrid_inverter_english: 'hybrid_inverter',
    hybrid_inverter_image: solarPanelBattery.default,
    hybrid_inverter_dutch: 'Zonnepanelen met batterij'
  }
}

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const isCommunityMode = () => {
  return location.pathname === routes.CREATE_ACCOUNT_COMMUNITY_MANAGER
}
export const batteryLevel = (battery: IBatteryAggregate | undefined) => {
  if (battery && battery.percentage === 0) {
    return 0
  } else if (battery && battery.percentage !== 0 && battery.percentage <= 30) {
    return 13 + (250 * battery.percentage) / 100
  } else if (battery && battery.percentage > 30 && battery.percentage <= 75) {
    return (250 * battery.percentage) / 100
  } else if (battery && battery.percentage > 75 && battery.percentage !== 100) {
    return (250 * battery.percentage) / 100 - 10
  } else if (battery && battery.percentage === 100) {
    return 250
  }
}
export const formatSerialNumber = (serialNumber: string) => {
  const replacedText = serialNumber.replace(/:/g, '')
  const capitalizedText = replacedText.toUpperCase()

  let newstr = ''
  for (let i = 0; i < capitalizedText.length;) {
    newstr = newstr.concat(capitalizedText[i])
    i++
    if (i % 4 === 0) {
      newstr = newstr.concat(' ')
    }
  }
  return newstr
}

// export const ToastDemo = ({ content }) => {
//   const { addToast } = useToasts()
//   return (
//     <Button
//       onClick={() =>
//         addToast(content, {
//           appearance: 'success',
//           autoDismiss: true,
//         })
//       }
//     >
//       Add Toast
//     </Button>
//   )
// }
