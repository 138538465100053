import * as React from 'react'
import { Tab } from 'semantic-ui-react'
import CommunitiesList from './CommunitiesList'
import CreateCommunity from './CreateCommunity'

const Community: React.FC = () => {
  const panes = [
    {
      menuItem: 'New Community',
      render: () => (
        <Tab.Pane>
          <CreateCommunity />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'All Communities',
      render: () => (
        <Tab.Pane>
          <CommunitiesList />
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <Tab panes={panes} className="home-menu-container scroll" />
    </>
  )
}

export default Community
