import * as React from 'react'
import { Dropdown, Message, Grid, Popup, PopupHeader } from 'semantic-ui-react'
import { useState } from 'react'
import DataContext from '../../../contexts/data'
import {
  IElectricityGridContractLargeUpdate,
  IGridConnectionTypeLarge,
  IGridTransportTypeLarge
} from '../../../interfaces/contracts'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { eancodeBoek } from '../../../util/options'
import NumberFormat from 'react-number-format'
import * as contractService from '../../../services/contract'
import AuthContext from '../../../contexts/auth'

const LargeConsumerNetworkManagementFlow = () => {
  const dataContext = React.useContext(DataContext)
  const authContext = React.useContext(AuthContext)

  const [changeValues, setChangeValues] = useState<boolean>(true)
  const [obligationInput, setObligationInput] = useState('obligation-input')
  const [dsoOptions, setDsoOptions] = useState<any[]>([])
  const [contractedPower, setContractedPower] = useState<number | string>(
    dataContext?.contract?.electricityGridLarge?.contractedPower || 0
  )
  const [selectedDsoId, setSelectedDsoId] = useState<number | undefined>(
    dataContext?.contract.electricityGridLarge?.dso?.id
  )
  const [gridLargeConnectionTypes, setGridLargeConnectionTypes] = useState<IGridConnectionTypeLarge[]>([])
  const [gridLargeTransportTypes, setGridLargeTransportTypes] = useState<IGridTransportTypeLarge[]>([])
  const [gridLargeConnectionTypeOptions, setGridLargeConnectionTypeOptions] = useState<any[]>([])
  const [gridLargeTransportTypeOptions, setGridLargeTransportTypeOptions] = useState<any[]>([])
  const [selectedLargeConnectionType, setSelectedLargeConnectionType] = useState<IGridConnectionTypeLarge | undefined>(
    dataContext?.contract?.electricityGridLarge?.gridConnectionTypeLarge
  )
  const [selectedLargeTransportType, setSelectedLargeTransportType] = useState<IGridTransportTypeLarge | undefined>(
    dataContext?.contract?.electricityGridLarge?.gridTransportTypeLarge
  )

  const [failureMessage, setFailureMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const payload: IElectricityGridContractLargeUpdate = {
      contractedPower: contractedPower as number,
      gridConnectionTypeLargeId: selectedLargeConnectionType?.id!,
      gridTransportTypeLargeId: selectedLargeTransportType?.id!
    }
    try {
      await dataContext?.contract?.updateElectricityGridContractLarge(payload)
      setChangeValues(!changeValues)
      setObligationInput('obligation-input')
      setSuccessMessage('De elektriciteitsgegevens zijn succesvol verwerkt.')
    } catch (err) {
      setFailureMessage(err.errors[0])
    }
  }

  const onChangeConfirm = () => {
    setChangeValues(!changeValues)
    setObligationInput('')
  }

  const onDsoSelected = (e: any, data: any) => {
    setSelectedDsoId(data.value)
  }

  const onConnectionTypeSelected = (e: any, data: any) => {
    setSelectedLargeConnectionType(data.value)
  }

  const onTransportTypeSelected = (e: any, data: any) => {
    setSelectedLargeTransportType(data.value)
  }

  setTimeout(() => {
    setSuccessMessage(null)
    setFailureMessage(null)
  }, 5000)

  const fetchGridLargeConnectionTypes = async () => {
    const result = await contractService.fetchLargeConnectionTypes(authContext?.accessToken!, selectedDsoId!)
    setGridLargeConnectionTypes(result)
  }

  const fetchGridLargeTransportTypes = async () => {
    const result = await contractService.fetchLargeTransportTypes(authContext?.accessToken!, selectedDsoId!)
    setGridLargeTransportTypes(result)
  }

  React.useEffect(() => {
    // fetchGridTariff()
    if (selectedDsoId) {
      fetchGridLargeConnectionTypes()
      fetchGridLargeTransportTypes()
    }
  }, [selectedDsoId])

  React.useEffect(() => {
    console.log({ gridLargeConnectionTypes })
    const result = gridLargeConnectionTypes.map((ct) => {
      return { key: ct.id, text: ct.connectionName, value: ct }
    })
    setGridLargeConnectionTypeOptions(result)
  }, [gridLargeConnectionTypes])

  React.useEffect(() => {
    console.log({ gridLargeTransportTypes })
    const result = gridLargeTransportTypes.map((tt) => {
      return { key: tt.id, text: tt.transportName, value: tt }
    })
    setGridLargeTransportTypeOptions(result)
  }, [gridLargeTransportTypes])

  React.useEffect(() => {
    const result = dataContext?.contract.dsos.map((dso) => {
      return { key: dso.id, text: dso.name, value: dso.id }
    })
    setDsoOptions(result || [])
  }, [dataContext?.contract.dsos])

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width="7">Netwerkbeheerder</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <Dropdown
              name="networkAdministration"
              placeholder="Netwerkbeheerder"
              options={dsoOptions}
              onChange={onDsoSelected}
              value={selectedDsoId}
              disabled={changeValues}
              selection
              fluid
            />
            <div style={{ marginTop: '8px' }} className="m-l-small width-small">
              <Popup
                content={'De netwerkbeheerder kunt u hier vinden:'}
                hoverable
                trigger={<IoMdInformationCircleOutline />}
              >
                <Popup.Content>
                  De netwerkbeheerder kunt u hier vinden:
                  <a href={eancodeBoek} target="_blank" rel="noreferrer">
                    {eancodeBoek}
                  </a>
                </Popup.Content>
              </Popup>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">Aansluiting</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <Dropdown
              options={gridLargeConnectionTypeOptions}
              onChange={onConnectionTypeSelected}
              value={selectedLargeConnectionType}
              name="connection"
              selection
              disabled={changeValues}
              fluid
            />
            <div style={{ marginTop: '5px' }} className="m-l-small width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">Tarief</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              value={selectedLargeConnectionType?.connectionFeePerMonth.toFixed(2) || 0}
              disabled={true}
              name="connection"
              className="obligation-input number-format"
              decimalSeparator={','}
              allowedDecimalSeparators={[',', '.']}
              isNumericString={true}
            />
            <span style={{ marginLeft: '-35px' }} className="unit">
              €/jaar
            </span>
            <div style={{ marginTop: '8px' }} className="m-l-small width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">Transportcategorie</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <Dropdown
              placeholder=""
              options={gridLargeTransportTypeOptions}
              name="transportcategorie"
              value={selectedLargeTransportType}
              disabled={changeValues}
              onChange={onTransportTypeSelected}
              selection
              fluid
            />
            <div style={{ width: '15px' }}></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">Gecontracteerd vermogen</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              placeholder=""
              name="contractedPower"
              disabled={changeValues}
              value={contractedPower}
              onChange={(e) => setContractedPower(e.target.value)}
              className="obligation-input"
            />
            <span style={{ marginLeft: '-22px' }} className="unit">
              kW
            </span>
            <div className="width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">Vastrecht transportdienst</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              placeholder=""
              name="fixedTransportService"
              disabled={true}
              value={selectedLargeTransportType?.fixedTariffPerMonth}
              className="obligation-input"
            />
            <span style={{ marginLeft: '-54px' }} className="unit">
              €/maand
            </span>
            <div className="width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">kW gecontracteerd per mnd</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              placeholder=""
              name="kwContractPerMonth"
              disabled={true}
              value={selectedLargeTransportType?.contractedTariffPerKwPerMonth}
              className="obligation-input"
            />
            <span style={{ marginLeft: '-78px' }} className="unit">
              €/maand/kW
            </span>
            <div className="width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width="7">kW max. per mnd</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              value={selectedLargeTransportType?.peakTariffPerKwPerMonth}
              placeholder=""
              name="kwperMonth"
              disabled={true}
              className="obligation-input"
            />
            <span style={{ marginLeft: '-78px' }} className="unit">
              €/maand/kW
            </span>
            <div className="width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">kWh hoog verbruik</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              value={selectedLargeTransportType?.tariffPerKwhHigh}
              placeholder=""
              name="kwperNormalUsage"
              disabled={true}
              className="obligation-input"
            />
            <span style={{ marginLeft: '-42px' }} className="unit">
              €/kWh
            </span>
            <div className="width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">kWh laag verbruik</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              value={selectedLargeTransportType?.tariffPerKwhLow}
              placeholder=""
              name="kwperLowUsage"
              disabled={true}
              className="obligation-input"
            />
            <span style={{ marginLeft: '-42px' }} className="unit">
              €/kWh
            </span>
            <div className="width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">Blind-verbruik</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              value={selectedLargeTransportType?.tariffBlindPowerKvar}
              placeholder=""
              name="blindConsumption"
              disabled={true}
              className="obligation-input"
            />
            <span style={{ marginLeft: '-50px' }} className="unit">
              €/kVArh
            </span>
            <div className="width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7">Jaar</Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row">
            <NumberFormat
              value={selectedLargeConnectionType?.year}
              disabled={true}
              name="year"
              className="obligation-input"
            />
            <div className="width-small"></div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {failureMessage && <Message color="red">{failureMessage}</Message>}
        {successMessage && <Message color="green">{successMessage}</Message>}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7"></Grid.Column>
        <Grid.Column width="9">
          <div className="flex1-row flex1-end">
            {changeValues && (
              <button className="btn-blue" onClick={onChangeConfirm}>
                Wijzig
              </button>
            )}
            {!changeValues && (
              <button className="btn-blue" type="submit" onClick={onSubmit}>
                Pas toe
              </button>
            )}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default LargeConsumerNetworkManagementFlow
