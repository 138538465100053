import * as React from 'react'
import { Image, Dropdown, Divider, Grid, Popup, Message } from 'semantic-ui-react'
import { useState } from 'react'
import DataContext from '../../../contexts/data'
import { IElectricityContract, IElectricityContractCreate } from '../../../interfaces/contracts'
import {
  contractOptions,
  confirmationOptions,
  typeTariff,
} from '../../../util/options'
import {
  electricityTariffType,
  electricityTariffTypeDutch,
  clientType,
  toolTipText
} from '~/enums'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { LargeConsumerNetworkManagementFlow, SmartMeterGeneric } from '../..'
import NumberFormat from 'react-number-format'
import UserContext from '../../../contexts/user'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import * as blueUpArrow from '../../../assets/blue_up_arrow.png'
import './smart_meter_details.scss'
const moment = require('moment')

const LargeConsumerSmartMeter: React.FC = () => {
  const dataContext = React.useContext(DataContext)
  const userContext = React.useContext(UserContext)
  const [formData, setFormData] = useState<any>({})
  const [currentContract, setCurrentContract] = useState<IElectricityContract>(dataContext?.contract?.electricity!)
  const [energyRetailerOptions, setEnergyRetailerOptions] = useState<any[]>([])
  const [tariffTypeOptions, setTariffTypeOptions] = useState<any[]>([])
  const [selectedContractType, setSelectedContractType] = useState<any>(
    currentContract.isDefaultContract ? 'Standaard' : 'Werkelijk'
  )
  const [change, setChange] = useState<boolean>(false)
  const [obligationInput, setObligationInput] = useState('obligation-input')
  const [doubleTariff, setDoubleTariff] = useState<boolean>(false)
  const [disabledForm, setDisabledForm] = useState<boolean>(true)
  const [selectedRetailer, setSelectedRetailer] = useState(dataContext?.contract.electricity.retailer?.id)
  const [selectedTariffType, setSelectedTariffType] = useState(
    dataContext?.contract.electricity.tariffType === 'single' ? 'Enkeltarief' : 'Dubbeltarief'
  )
  const [selectedTariffTypeToBackend, setSelectedTariffTypeToBackend] = useState(
    dataContext?.contract.electricity.tariffType
  )
  const [failureMessage, setFailureMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [openPopUp, setOpenPopUp] = useState<boolean>(false)
  const [isTaxReductionApplicable, setIsTaxReductionApplicable] = useState<boolean>(true)

  const handleInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.replace(',', '.') })
  }
  const close = () => {
    setOpenPopUp(false)
  }
  const inclusiveOrExclusive = () => {
    if (userContext?.profile?.customerType === clientType.Business) {
      return toolTipText.Exclusive
    } else if (userContext?.profile?.customerType === clientType.Private) {
      return toolTipText.Inclusive
    }
  }
  const onSubmit = async (e: any) => {
    e.preventDefault()
    const payload: IElectricityContractCreate = {
      ...formData,
      tariffType: selectedTariffTypeToBackend!,
      retailerId: selectedRetailer,
      isTaxReductionApplicable
    }
    setDisabledForm(true)
    setObligationInput('obligation-input')
    setChange(false)
    try {
      await dataContext?.contract?.createElectricityContract(payload)
      setSuccessMessage('De elektriciteitsgegevens zijn succesvol verwerkt.')
    } catch (err) {
      setFailureMessage('Klant heeft reeds actieve, niet standaard, gas en elektriciteitsgegevens.')
    }
  }
  const onEnergyRetailerSelected = (e: any, data: any) => {
    setSelectedRetailer(data.value)
  }

  const onContractChange = (e: any, data: any) => {
    if (data.value === 'Werkelijk') {
      setChange(true)
      setDisabledForm(false)
      setObligationInput('')
    } else {
      setDisabledForm(true)
      setObligationInput('obligation-input')
      setChange(false)
    }
    setSelectedContractType(data.value)
  }
  const onTariffTypeSelected = (e: any, data: any) => {
    if (data.value === electricityTariffTypeDutch.Double) {
      setSelectedTariffType('Dubbeltarief')
      setSelectedTariffTypeToBackend('double')
      setDoubleTariff(true)
    } else if (data.value === electricityTariffTypeDutch.Single) {
      setSelectedTariffType('Enkeltarief')
      setSelectedTariffTypeToBackend('single')
      setDoubleTariff(false)
    }
  }
  const onChangeConfirm = () => {
    setChange(true)
    setDisabledForm(false)
    setObligationInput('')
  }
  setTimeout(() => {
    setSuccessMessage(null)
    setFailureMessage(null)
  }, 6000)
  React.useEffect(() => {
    const result = dataContext?.contract.energyRetailers.map((value) => {
      return { key: value.id, text: value.displayName, value: value.id }
    })
    setEnergyRetailerOptions(result || [])
  }, [dataContext?.contract.energyRetailers])
  // React.useEffect(() => {
  //   const result = dataContext?.contract.tariffTypes.map((value) => {
  //     return { key: value, text: value, value }
  //   })
  //   setTariffTypeOptions(result || [])
  // }, [dataContext?.contract.tariffTypes])

  React.useEffect(() => {
    if (dataContext?.contract.defaultElectricity) {
      setCurrentContract(dataContext.contract.defaultElectricity)
    }
  }, [dataContext?.contract.defaultElectricity])

  React.useEffect(() => {
    setFormData({
      tariffHighPerKwh: currentContract.tariffHighPerKwh.toFixed(2),
      tariffLowPerKwh: currentContract.tariffLowPerKwh.toFixed(2),
      tariffFlatPerKwh: currentContract.tariffFlatPerKwh?.toFixed(2),
      feedInTariffPerKwh: currentContract.feedInTariffPerKwh.toFixed(2),
      fixedFeePerYear: currentContract.fixedFeePerYear.toFixed(2),
      startDateTime: moment(currentContract.startDateTime).format('DD-MM-YYYY'),
      endDateTime: moment(currentContract.endDateTime).format('DD-MM-YYYY')
    })
    const contractType = currentContract.isDefaultContract ? 'Standaard' : 'Werkelijk'
    setSelectedContractType(contractType)
  }, [currentContract])

  React.useEffect(() => {
    if (dataContext?.contract.electricity) {
      setCurrentContract(dataContext.contract.electricity)
    }
  }, [dataContext?.contract.electricity])
  const [expand, setExpand] = React.useState(false)

  const detailsExpand = () => {
    setExpand(!expand)
  }

  return (
    <>
      <div className="smart-meter-container">
        <SmartMeterGeneric title="Elektrameter grootverbruik" />
        <div className="flex1-row m-y-t flex1-end">
          <div className="fle1-row flex-center-vertical" onClick={detailsExpand}>
            <strong className="detail-txt">Details</strong>
            {expand && <Image src={blueUpArrow.default} style={{ marginBottom: '2px' }} />}
            {!expand && <Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
          </div>
        </div>
        <hr />
        {expand && (
          <Grid className="large-cons-sm-container" style={{ marginTop: '0px', marginLeft: '0px' }}>
            <Grid.Row>
              <strong className="m-l-large">Energieleverancier stroom grootverbruik</strong>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width="7">Contract gegevens</Grid.Column>
              <Grid.Column width="9">
                <div className="flex1-row">
                  <Dropdown
                    name="contract"
                    onChange={onContractChange}
                    options={contractOptions}
                    value={selectedContractType}
                    selection
                    fluid
                  />
                  <div style={{ marginTop: '5px' }} className="m-l-small width-small">
                    <Popup
                      content={`Werkerlijk: Selecteren als u de werkelijke waarde van uw
                        energiecontract wilt gebruiken. Dit geeft het meest nauwkeurige resultaat.
                        Standaard: Selecteert u als u niet weet wat u voor stroom en gas
                        betaald. Het syteem Rekent dan met standaard waarde. Let op!
                        De overzichten komen dan niet overeen met wat u
                        werkelijk betaald.`}
                      trigger={<IoMdInformationCircleOutline />}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="7">Leverancier</Grid.Column>
              <Grid.Column width="9">
                <div className="flex1-row">
                  <Dropdown
                    name="supplier"
                    placeholder="Details"
                    onChange={onEnergyRetailerSelected}
                    value={selectedRetailer}
                    options={energyRetailerOptions}
                    disabled={disabledForm}
                    className={obligationInput}
                    selection
                    fluid
                  />
                  <div style={{ width: '20px' }}></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="7">Type tarief</Grid.Column>
              <Grid.Column width="9">
                <div className="flex1-row">
                  <Dropdown
                    name="rateType"
                    placeholder="Type tarief"
                    onChange={onTariffTypeSelected}
                    value={selectedTariffType}
                    options={typeTariff}
                    disabled={true}
                    className={obligationInput}
                    selection
                    fluid
                  />
                  <div style={{ width: '20px' }}></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            {!doubleTariff && (
              <Grid.Row>
                <Grid.Column width="7">Tarief</Grid.Column>
                <Grid.Column width="9">
                  <div className="flex1-row">
                    <NumberFormat
                      value={formData.tariffFlatPerKwh}
                      onChange={handleInputChange}
                      name="tariffFlatPerKwh"
                      className={`${obligationInput} number-format`}
                      disabled={disabledForm}
                      decimalSeparator={','}
                      allowedDecimalSeparators={[',']}
                      isNumericString={true}
                    />
                    <span style={{ marginLeft: '-41px' }} className="unit">
                      €/kWh
                    </span>
                    <div style={{ marginTop: '8px' }} className="m-l-small width-small">
                      <Popup content={inclusiveOrExclusive()} trigger={<IoMdInformationCircleOutline />} />
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}

            {doubleTariff && (
              <Grid.Row>
                <Grid.Column width="7">Laagtarief</Grid.Column>
                <Grid.Column width="9">
                  <div className="flex1-row">
                    <NumberFormat
                      value={formData.tariffLowPerKwh}
                      onChange={handleInputChange}
                      name="tariffLowPerKwh"
                      className={`${obligationInput} number-format`}
                      disabled={disabledForm}
                      decimalSeparator={','}
                      allowedDecimalSeparators={[',']}
                      isNumericString={true}
                    />
                    <span style={{ marginLeft: '-41px' }} className="unit">
                      €/kWh
                    </span>
                    <div style={{ marginTop: '8px' }} className="m-l-small tooltip width-small">
                      <IoMdInformationCircleOutline />
                      <div className="tooltiptext" style={{ height: '50px', bottom: '-100%' }}>
                        {inclusiveOrExclusive()}
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column width="7">Teruglevertarief</Grid.Column>
              <Grid.Column width="9">
                <div className="flex1-row">
                  <NumberFormat
                    value={formData.feedInTariffPerKwh}
                    onChange={handleInputChange}
                    name="feedInTariffPerKwh"
                    className={`${obligationInput} number-format`}
                    disabled={disabledForm}
                    decimalSeparator={','}
                    allowedDecimalSeparators={[',']}
                    isNumericString={true}
                  />
                  <span style={{ marginLeft: '-41px' }} className="unit">
                    €/kWh
                  </span>
                  <div style={{ marginTop: '8px' }} className="m-l-small width-small">
                    <Popup content={inclusiveOrExclusive()} trigger={<IoMdInformationCircleOutline />} />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="7">Vastrecht</Grid.Column>
              <Grid.Column width="9">
                <div className="flex1-row">
                  <NumberFormat
                    value={formData.fixedFeePerYear}
                    onChange={handleInputChange}
                    name="fixedFeePerYear"
                    className={`${obligationInput} number-format`}
                    disabled={disabledForm}
                    decimalSeparator={','}
                    allowedDecimalSeparators={[',']}
                    isNumericString={true}
                  />
                  <span style={{ marginLeft: '-35px' }} className="unit">
                    €/jaar
                  </span>
                  <div style={{ marginTop: '8px' }} className="m-l-small width-small">
                    <Popup content={inclusiveOrExclusive()} trigger={<IoMdInformationCircleOutline />} />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="7">
                <p>Start contract</p>
              </Grid.Column>
              <Grid.Column width="9">
                <div className="flex1-row">
                  <input
                    value={formData.startDateTime}
                    onChange={handleInputChange}
                    name="startDateTime"
                    className={`${obligationInput}`}
                    disabled={disabledForm}
                  />
                  <div style={{ width: '15px' }}></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="7">
                <p>Einde contract</p>
              </Grid.Column>
              <Grid.Column width="9">
                <div className="flex1-row">
                  <input
                    value={formData.endDateTime}
                    onChange={handleInputChange}
                    name="endDateTime"
                    className={`form-item ${obligationInput}`}
                    disabled={disabledForm}
                  />
                  <div style={{ width: '15px' }}></div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="16">
                {failureMessage && <Message color="red">{failureMessage}</Message>}
                {successMessage && <Message color="green">{successMessage}</Message>}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="7"></Grid.Column>
              <Grid.Column width="9">
                <div className="flex1-row flex1-end">
                  {selectedContractType === 'Werkelijk' && !change && (
                    <button className="btn-blue" onClick={onChangeConfirm}>
                      Wijzig
                    </button>
                  )}
                  {change && (
                    <button className="btn-blue" type="submit" onClick={onSubmit}>
                      Pas toe
                    </button>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <strong className="m-l-large">Netwerkbeheer stroom</strong>
            </Grid.Row>
            <Divider />
            <LargeConsumerNetworkManagementFlow />
          </Grid>
        )}
      </div>
    </>
  )
}
export default LargeConsumerSmartMeter
