import * as React from 'react'
import { Image, Modal } from 'semantic-ui-react'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import { SmartMeterDetails, SmartMeterGeneric } from '../..'

const SmartMeter: React.FC = () => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <div className="smart-meter-container">
        <SmartMeterGeneric title="Slimme meter" />
        <div className="flex1-row m-y-t flex1-end">
          <div className="fle1-row flex-center-vertical">
            <Modal
              closeIcon
              open={open}
              trigger={<div className="detail-txt">Details</div>}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              id="modal"
              stackable
            >
              <Modal.Content>
                <SmartMeterDetails />
              </Modal.Content>
            </Modal>
            <Modal
              closeIcon
              open={open}
              trigger={<Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              id="modal"
              stackable
            >
              <Modal.Content>
                <SmartMeterDetails />
              </Modal.Content>
            </Modal>
          </div>
        </div>
        <hr />
        <div className="flex1-row flex1-end">
          <button className="btn-blue">Pas toe</button>
        </div>
      </div>
    </>
  )
}
export default SmartMeter
