import * as React from 'react'
import { VictoryAxis, VictoryChart, VictoryBar, VictoryArea, VictoryTheme, VictoryLabel } from 'victory'
import { AggregatedDateReportTimeSeriesData } from '../../services/reporting'
import 'moment/locale/nl'

const moment = require('moment')

interface ITimeSeriesGraphProps {
  timeSeriesData: [AggregatedDateReportTimeSeriesData]
  title: string
  fillColor: string
  daysRequested: number | undefined
}
interface ITimeSeriesYearGraphProps {
  timeSeriesData: [AggregatedDateReportTimeSeriesData]
  title: string
  color: string
}
interface ITimeSeriesGraphMonthProps {
  timeSeriesData: [AggregatedDateReportTimeSeriesData]
  color: string
  title: string
}
export const TimeSeriesGraph: React.FC<ITimeSeriesGraphProps> = ({
  timeSeriesData,
  title,
  fillColor,
  daysRequested
}) => {
  const isAllZero = timeSeriesData.every((item) => item.value === 0 || item.value === null)

  const max = Math.max.apply(
    Math,
    timeSeriesData.map(function (o) {
      return o.value
    })
  )
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      height={80}
      scale={{ x: 'time' }}
      padding={{ top: 5, bottom: 15, right: 10, left: 26 }}
    >
      {isAllZero || max === 0.01 ? (
        <VictoryAxis
          dependentAxis={true}
          crossAxis={false}
          orientation="left"
          style={{
            tickLabels: { fontSize: '4px', padding: 3 }
          }}
          domain={[0, 1]}
          tickFormat={(t) => `${t.toFixed(2)}`}
        />
      ) : (
        <VictoryAxis
          dependentAxis={true}
          crossAxis={false}
          orientation="left"
          style={{
            tickLabels: { fontSize: '4px', padding: 3 }
          }}
          tickFormat={(t) => `${t.toFixed(2)}`}
        />
      )}

      <VictoryAxis
        scale={{ x: 'time' }}
        offsetY={15}
        style={{
          tickLabels: { fontSize: '4px', padding: 3 }
        }}
        tickFormat={(time) => {
          return isAllZero
            ? ''
            : daysRequested === 3 || daysRequested === 2 || daysRequested === 1 || daysRequested === 0
              ? moment(time).format('DD-MM-YYYY HH:mm')
              : moment(time).format('DD-MM-YYYY')
        }}
        orientation="bottom"
      />

      <VictoryArea
        animate={{ duration: 500 }}
        interpolation="cardinal"
        data={timeSeriesData}
        x={(d) => moment(d.time)}
        y={'value'}
        style={{
          data: {
            stroke: fillColor,
            strokeWidth: 0.5,
            fill: fillColor,
            opacity: '0.5'
          },
          parent: { border: '1px solid #ccc' }
        }}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: '5px', fill: '#4d4d4d', fontWeight: 'bold', fontFamily: 'Nunito Sans' }}
        x={2}
        y={30}
        text={title}
        angle={270}
      />
    </VictoryChart>
  )
}

export const TimeSeriesBarGraph: React.FC<ITimeSeriesGraphMonthProps> = ({ timeSeriesData, color, title }) => {
  const isAllZero = timeSeriesData.every((item) => item.value === 0)
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      height={70}
      scale={{ x: 'time' }}
      padding={{ top: 15, bottom: 15, right: 10, left: 28 }}
    >
      <VictoryAxis
        dependentAxis={true}
        style={{
          grid: { stroke: 'none' },
          tickLabels: { fontSize: '4px', padding: 4 }
        }}
        tickFormat={(t) => `${t.toFixed(2)}`}
      />
      <VictoryAxis
        style={{
          tickLabels: { fontSize: '4px', padding: 3 }
        }}
        tickFormat={(time) => {
          return isAllZero ? '' : moment(time).format('DD-MM-YYYY')
        }}
      />
      <VictoryBar
        animate={{ duration: 500 }}
        barWidth={3}
        alignment="start"
        data={timeSeriesData.map((data: any) => {
          return { x: moment(data.time), y: data.value }
        })}
        style={{
          data: { fill: color },
          parent: { border: '1px solid #ccc' }
        }}
      />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: '5px', fill: '#4d4d4d', fontWeight: 'bold', fontFamily: 'Nunito Sans' }}
        x={2}
        y={30}
        text={title}
        angle={270}
      />
    </VictoryChart>
  )
}
export const TimeSeriesYearBarGraph: React.FC<ITimeSeriesYearGraphProps> = ({ timeSeriesData, color, title }) => {
  return (
    <VictoryChart height={350} domainPadding={{ x: 20 }}>
      <VictoryLabel textAnchor="start" style={{ fontSize: 20, fill: 'black' }} x={30} y={10} text={title} />
      <VictoryLabel
        textAnchor="middle"
        style={{ fontSize: 16, fill: '#4d4d4d' }}
        x={250}
        y={318}
        text={`-${moment().format('MMMM Do')}`}
        angle={360}
      />
      <VictoryAxis />
      <VictoryAxis dependentAxis={true} domain={{ y: [0, 2.5] }} />
      <VictoryBar
        animate={{ duration: 500 }}
        data={timeSeriesData}
        domain={{ x: [0, 5] }}
        barWidth={50}
        style={{
          data: { fill: color }
        }}
      />
    </VictoryChart>
  )
}
