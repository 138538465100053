import axios from 'axios'
import { isArray, isString } from 'lodash';
// import { isArray, isString } from 'lodash'
// import history from './history'

const hostname = process.env.API_HOST

const api = axios.create({
  baseURL: hostname ? `${hostname}/api` : '/api'
})

api.interceptors.response.use(undefined, async (error) => {
  const modifiedError = { ...error }
  if (error.isAxiosError) {
    if (error.response.data?.detail && isString(error.response.data?.detail)) {
      modifiedError.errors = [error.response.data.detail];
    } else if (error.response.data?.detail && isArray(error.response.data?.detail)) {
      modifiedError.errors = error.response.data.detail?.map(({ msg }: { msg: any }) => msg);
    } else if (error.response.data?.details && isArray(error.response.data?.details)) {
      modifiedError.errors = error.response.data.details?.map(({ msg }: { msg: any }) => msg);
    }
  }

  return Promise.reject(modifiedError)
})

export default api
