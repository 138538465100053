import * as React from 'react'
import { Grid, Container, Divider, Dimmer, Image } from 'semantic-ui-react'
import AuthContext from '../../contexts/auth'
import { ReportingService, YearReportData } from '../../services/reporting'
import { ReportHeader } from './generic'
import { TimeSeriesYearBarGraph } from './graphs'
import { ConsumerType, clientType} from '~/enums'
import UserContext from '../../contexts/user'
import DataContext from '../../contexts/data'
import { useContext } from 'react'
import * as loadingSymbol from '../../assets/loading.png'
import { VictoryChart, VictoryPie, VictoryBar, VictoryLabel, VictoryStack, VictoryLegend } from 'victory'
import {
  electricityExportPowerColor,
  electricityImportColor,
  solarProductionColor,
  gasImportColor,
  sumElectricityImportColor,
  yearColor
} from '../../util/graph_data'
const moment = require('moment')


const YearReport: React.FC = () => {
  const [reportingData, setReportingData] = React.useState<YearReportData | undefined>()
  const [isPrintMode, setIsPrintMode] = React.useState<boolean>(false)
  const reportingService = new ReportingService()
  const reportElement = React.useRef<HTMLDivElement>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const userContext = useContext(UserContext)
  const dataContext = React.useContext(DataContext)
  const authContext = React.useContext(AuthContext)
  const [isBusinessLargeConsumer, setBusinessLargeConsumer] = React.useState<boolean>(false)

  const fetchData = async () => {
    const token = authContext?.accessToken!
    const result = await reportingService.getYearReportData(token)

    setReportingData(result)
  }

  React.useEffect(() => {
    fetchData()
    if(userContext?.profile?.customerType === clientType.Business &&   !!dataContext?.device.activeUserMainMeterDevice &&
      dataContext?.device.activeUserMainMeterDevice.consumerType === ConsumerType.Large)
      {
        setBusinessLargeConsumer(true)
      }
  }, [])

  const onBeforePrint = () => {
    setIsPrintMode(true)
  }

  const onAfterPrint = () => {
    setIsPrintMode(false)
  }

  React.useEffect(() => {
    if (reportingData && userContext?.profile) {
      setLoading(false)
    }
  })

  return (
    
    <>
     <div className="Loader" style={{display: loading? "block": "none"}}>
        <Dimmer active>
          <Image src={loadingSymbol.default} className="rotate" />
        </Dimmer>
      </div>
    {isBusinessLargeConsumer ? <strong className="flex1-row flex1-center">Not Available</strong>:
    <Container className="report-workspace" id="year-report-container" style={{display: loading? "none": "block"}}>
      <div className="report-container year-root-print-page" ref={reportElement}>
        <ReportHeader
          title="JAAR"
          elementRef={reportElement.current}
          onBeforePrint={onBeforePrint}
          onAfterPrint={onAfterPrint}
        />

        <Grid style={{ paddingTop: '10px' }}>
          {/* Years header */}
          <Grid.Row>
            <Grid.Column width="five" textAlign="center"></Grid.Column>
            {reportingData &&
              reportingData.results &&
              reportingData.results.map((data: any, i: any) => {
                if (data.contracts.length !== 0) {
                  return (
                    <Grid.Column key={i} width="five" style={{ justifyContent: 'center' }} >
                        <strong style={{ color: '#4d4d4d', fontSize: '16px', textAlign: 'center'}}>{data.year}</strong>
                      {data.year === new Date().getFullYear() ? <div>{`- ${moment().format('MMMM Do')}`}</div> : <span className="white-spaces"></span>}
                    </Grid.Column>
                  )
                }
              })}
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width="5" className="report-content">
              <div className="flex1-column">
                <div className="flex1-row flex1-start report-content">
                Elektriciteit: consumptie uit het net
                </div>
                <div className="flex1-row flex1-start report-content">
                Elektriciteit: teruglevering naar net
                </div>
                <div className="flex1-row flex1-start report-content">
                Opbrengst zonnepanelen
                </div>
              </div>
            </Grid.Column>
            {/* Two years ago */}
            {reportingData &&
              reportingData.results &&
              reportingData.results.map((data: any, i: any) => {
                if (data.contracts.length !== 0) {
                  return ( 
                    <Grid.Column key={i} width="five" style={{ justifyContent: 'center' }}>
                        <div className="flex1-column report-content" style={{width:'50%'}} >
                          <div style={{ textAlign: 'right' }} className="report-content">{`${data.electricityImport
                            .toFixed(2)
                            .replace('.', ',')} kWh`}</div>
                          <div style={{ textAlign: 'right' }} className="report-content">
                            {`${data.electricityExport.toFixed(2).replace('.', ',')} kWh`}
                          </div>
                          <div style={{ textAlign: 'right' }} className="report-content">
                            {`${data.solarGeneration.toFixed(2).replace('.', ',')} kWh`}
                          </div>
                        </div>
                        <div style={{width:'50%'}} className="flex1-row flex1-start">
                        <span className="reporting-price" style={{ marginLeft:'10px'}}>
                        <span className="reporting-price-bracket">}</span>
                        </span>
                        <div className="flex1-row" style={{ marginTop:'10px', marginLeft:'10px' width:'80%'}}>
                          <div className="report-content" style={{textAlign:'right' width:'46%'}}>
                        {`${(
                            data.gridConnection.transportationCosts +
                            data.electricityDeliveryTotalCosts +
                            data.taxes.electricityEnergyTax +
                            data.taxes.electricitySustainabilityTax +
                            data.taxes.taxCredit +
                            (data.taxes.btwPercent / 100) *
                            (data.gridConnection.transportationCosts +
                              data.electricityDeliveryTotalCosts +
                                data.taxes.electricityEnergyTax +
                                data.taxes.electricitySustainabilityTax +
                                data.taxes.taxCredit)
                          ).toFixed(2).replace('.', ',')}`}
                          </div>
                          <div className="report-content" style={{textAlign:'left' width:'60%', paddingLeft:'5px'}}>EUR</div>
                        </div>

                     </div>
                      </Grid.Column>     
                  )
                }
              })}
             
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width="5">
              <div className="flex1-column">
                <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Totaal elektriciteitsverbruik</strong>
                <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Gasconsumptie uit het net </strong>
                <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Gasconsumptie weer-gecorrigeerd</strong>
              </div>
            </Grid.Column>
            {reportingData &&
              reportingData.results &&
              reportingData.results.map((data: any, i: any) => {
                if (data.contracts.length !== 0) {
                  return (
                    <Grid.Column width="five" textAlign="right">
                      <div className="flex1-column" style={{width:'50%'}}>
                        <div className="flex1-row" style={{width:'100%'}}>
                        <strong style={{ textAlign: 'right', width:'78%' }} className="report-content" >
                          {`${(data.electricityConsumption)
                            .toFixed(2)
                            .replace('.', ',')} `}{' '}
                        </strong>
                        <strong style={{ textAlign: 'left' , width:'22%', paddingLeft:'5px' }} className="report-content" >kWh</strong>
                        </div>
                        <div className="flex1-row" style={{width:'100%'}}>
                        <strong style={{ textAlign: 'right', width:'78%' }} className="report-content">{`${data.gasConsumption.toFixed(2).replace('.',',')}`}</strong>
                        <strong style={{ textAlign: 'left' , width:'22%' , paddingLeft:'5px' }} className="report-content" >m³</strong>
                        </div>
                        <div className="flex1-row" style={{width:'100%'}}>
                        <strong style={{ textAlign: 'right', width:'78%' }} className="report-content">N/A</strong>
                        <strong style={{ textAlign: 'left' , width:'22%' , paddingLeft:'5px' }} className="report-content" ></strong>
                        </div>
                      </div>
                      <div style={{width:'50%'}} className="flex1-row flex-center-vertical">
                        <strong style={{width:'52%'}} className="report-content">
                        {`${(data.totalEnergyConsumption)
                            .toFixed(2)
                            .replace('.', ',')}`}
                        </strong>
                        <strong style={{ textAlign: 'left', width:'48%' , paddingLeft:'5px'}} className="report-content">kWh</strong>
                      </div>
                    </Grid.Column>
                  )
                }
              })}
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width="six">
              <div className="flex1-column">
                <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Totale energiekosten</strong>
                <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Energiekosten /m2</strong>
              </div>
            </Grid.Column>
            {reportingData &&
              reportingData.results &&
              reportingData.results.map((data: any, i: any) => {
                if (data.contracts.length !== 0) {
                  return (
                    <Grid.Column width="five">
                    <div className="flex1-column" style={{width:'90%'}}>
                    <div className="flex1-row" style={{width:'100%'}}>
                    <strong className="flex1-row flex1-end report-content" style={{ width:'60%' }}>
                      {`${(
                        data.gridConnection.transportationCosts +
                        data.electricityDeliveryTotalCosts +
                        data.taxes.electricityEnergyTax +
                        data.taxes.electricitySustainabilityTax +
                        data.taxes.taxCredit +
                        (data.taxes.btwPercent / 100) *
                          (data.gridConnection.transportationCosts +
                            data.electricityDeliveryTotalCosts +
                            data.taxes.electricityEnergyTax +
                            data.taxes.electricitySustainabilityTax +
                            data.taxes.taxCredit)
                      )
                        .toFixed(2)
                        .replace('.', ',')}`}
                      
                    </strong>
                    <strong className="report-content" style={{ paddingLeft:'5px',width:'40%', textAlign:'left'}}>EUR</strong>
                    </div>
                    <div className="flex1-row" style={{width:'100%'}}>
                    <strong style={{ textAlign: 'right', width:'60%' }} className="report-content flex1-row flex1-end">{userContext?.buildingDetail.surfaceM2 !== null ? `${((
                        data.gridConnection.transportationCosts +
                        data.electricityDeliveryTotalCosts +
                        data.taxes.electricityEnergyTax +
                        data.taxes.electricitySustainabilityTax +
                        data.taxes.taxCredit +
                        (data.taxes.btwPercent / 100) *
                          (data.gridConnection.transportationCosts +
                            data.electricityDeliveryTotalCosts +
                            data.taxes.electricityEnergyTax +
                            data.taxes.electricitySustainabilityTax +
                            data.taxes.taxCredit)
                      )/userContext?.buildingDetail.surfaceM2).toFixed(2)
                        .replace('.', ',') }` : 'N/A' }</strong>
                    <strong className="report-content" style={{ paddingLeft:'5px',width:'40%', textAlign:'left'}}>EUR</strong>
                    </div>
                    </div>
                  </Grid.Column>
                  )
                }
              })}
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width="5">
              <div className="flex1-column">
                <div className="report-content">CO2-besparing</div>
                <div className="report-content">Opgeslagen in batterij</div>
                <div className="report-content">Totaal energieverbruik</div>
              </div>
            </Grid.Column>
            {reportingData &&
              reportingData.results &&
              reportingData.results.map((data: any, i: any) => {
                if (data.contracts.length !== 0) {
                  return (
                    <Grid.Column width="five">
                      <div className="flex1-column" style={{width:'50%'}}>
                      <div className="flex1-row" style={{width:'100%'}}>
                        <div style={{ textAlign: 'right', width:'78%' }} className="report-content">N/A</div>
                        <div style={{ textAlign: 'left' , width:'22%' , paddingLeft:'5px' }} className="report-content" ></div>
                      </div>
                      <div className="flex1-row" style={{width:'100%'}}>
                        <div style={{ textAlign: 'right', width:'78%' }} className="report-content">N/A</div>
                        <div style={{ textAlign: 'left' , width:'22%' , paddingLeft:'5px' }} className="report-content" ></div>
                      </div>
                      <div className="flex1-row" style={{width:'100%'}}>
                        <div style={{ textAlign: 'right', width:'78%' }} className="report-content">{data.totalEnergyConsumption
                          .toFixed(2)
                          .replace('.', ',')}</div>
                        <div style={{ textAlign: 'left' , width:'22%' , paddingLeft:'5px' }} className="report-content" >kWh</div>
                      </div>
                      </div>
                    </Grid.Column>
                  )
                }
              })}
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width="five">
              <div className="flex1-column">
                <div className="report-content">Herkomst stroom</div>
                <div style={{ marginTop: '20px' }}>
                  <div className="flex-center-vertical">
                    <div className="dot dot-yellow" ></div>
                    <div className="report-content">Gebruikte zonnestroom </div>
                  </div>
                  <div className="flex-center-vertical">
                    <div className="dot dot-orange"></div>
                    <div className="report-content">Consumptie uit het net</div>
                  </div>
                </div>
              </div>
            </Grid.Column>
            {reportingData &&
              reportingData.results &&
              reportingData.results.map((data: any, i: any) => {
                if (data.contracts.length !== 0) {
                  return (
                    <Grid.Column width="five">
                      <Grid.Column style={{width:'10%'}}></Grid.Column>
                      <Grid.Column style={{width:'40%'}}>
                        <VictoryPie
                          animate={{ duration: 500 }}
                          labels={() => null}
                          colorScale={yearColor}
                          data={[
                            { x: 'Solar Generation', y: data.solarGeneration },
                            { x: 'Consumption from net', y: data.netElectricityImport }
                          ]}
                        />
                      </Grid.Column>
                      <Grid.Column style={{width:'28%', justifyContent:'end'}}>
                        <div className="flex1-column m-y-t" style={{width:'100%'}}>
                          <div className="flex-center-vertical space-between" style={{width:'90%'}}>
                            <div className="dot dot-yellow" style={{ textAlign:'right', width:'15px'}}></div>
                            <div className="report-content" style={{ textAlign:'right', width:'72%'}} >{data.solarGeneration.toFixed(2).replace('.',',')}</div>
                          </div>
                          <div className="flex-center-vertical space-between" style={{width:'90%'}}>
                            <div className="dot dot-orange" style={{ textAlign:'right', width:'15px '}}></div>
                            <div className="report-content" style={{ textAlign:'right', width:'72%'}} >{data.electricityImport.toFixed(2).replace('.',',')}</div>
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column style={{width:'12%'}}>
                        <div className="flex1-column m-y-t">
                          <div style={{ textAlign:'right'}} className="report-content">
                            kWh
                          </div>
                          <div style={{ textAlign:'right'}} className="report-content">
                            kWh
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Column>
                  )
                }
              })}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="five">
              {reportingData && reportingData.results && (
                <TimeSeriesYearBarGraph
                  timeSeriesData={reportingData.results.map((data: any) => {
                    return {
                      x:
                        `${data.year.toString()}`,
                      y:
                        data.electricityDeliveryTotalCosts +
                        data.taxes.electricityEnergyTax +
                        data.taxes.electricitySustainabilityTax +
                        data.taxes.taxCredit +
                        (data.taxes.btwPercent / 100) *
                          (data.electricityDeliveryTotalCosts +
                            data.taxes.electricityEnergyTax +
                            data.taxes.electricitySustainabilityTax +
                            data.taxes.taxCredit)
                    }
                  })}
                  color={'#dc7570'}
                  title={'Energiekosten (EUR)'}
                />
              )}
            </Grid.Column>
            <Grid.Column width="five">
              {reportingData && reportingData.results && (
                <TimeSeriesYearBarGraph
                  timeSeriesData={reportingData.results.map((data: any) => {
                    return {
                      x:
                       `${data.year.toString()}`,
                      y: data.gasConsumption
                    }
                  })}
                  color={gasImportColor}
                  title={'Gasconsumptie (m³)'}
                />
              )}
            </Grid.Column>
            <Grid.Column width="five">
              {reportingData && reportingData.results && (
                <TimeSeriesYearBarGraph
                  timeSeriesData={reportingData.results.map((data: any) => {
                    return {
                      x:
                        `${data.year.toString()}`,
                      y: data.gasConsumption
                    }
                  })}
                  color={'#5f5f5f'}
                  title={'Gasverbruik weer-gecorrigeerd ( m³/ grd.dg)'}
                />
              )}
            </Grid.Column>
            <Grid.Column width="five">
              {reportingData && reportingData.results && (
                <TimeSeriesYearBarGraph
                  timeSeriesData={reportingData.results.map((data: any) => {
                    return {
                      x:
                       `${data.year.toString()}`,
                      y: data.electricityImport
                    }
                  })}
                  color={'#dc7570'}
                  title={'Elektriciteitsconsumptie uit het net (kWh)'}
                />
              )}
            </Grid.Column>
            <Grid.Column width="five">
              {reportingData && reportingData.results && (
                <TimeSeriesYearBarGraph
                  timeSeriesData={reportingData.results.map((data: any) => {
                    return {
                      x:
                      `${data.year.toString()}`,
                      y: data.electricityImport - data.electricityExport + data.solarGeneration
                    }
                  })}
                  color={'#dc7570'}
                  title={'Totaal elektriciteitsverbruik (kWh)'}
                />
              )}
            </Grid.Column>
            <Grid.Column width="five">
              {reportingData && reportingData.results && (
                <TimeSeriesYearBarGraph
                  timeSeriesData={reportingData.results.map((data: any) => {
                    return {
                      x:
                      `${data.year.toString()}`,
                      y: data.solarGeneration
                    }
                  })}
                  color={solarProductionColor}
                  title={'Opbrengst zonnepanelen (kWh)'}
                />
              )}
            </Grid.Column>
            <Grid.Column width="five">
              {reportingData && reportingData.results && (
                <VictoryChart domainPadding={{ x: 20 }} height={350}>
                  <VictoryLabel
                    textAnchor="start"
                    style={{ fontSize: 20, fill: 'black' }}
                    x={30}
                    y={10}
                    text="Totaal energieverbruik (kWh)"
                  />
                  <VictoryLegend
                    x={250}
                    y={200}
                    centerTitle
                    orientation="vertical"
                    gutter={20}
                    data={[
                      { name: 'Gas', symbol: { fill: '#5f5f5f', type: 'square' } },
                      { name: 'Elektriciteit', symbol: { fill: '#dc7570', type: 'square' } }
                    ]}
                  />
                  <VictoryStack colorScale={['#dc7570', '#5f5f5f']} domain={{ x: [0, 5] }}>
                    <VictoryBar
                    barWidth={50}
                      data={reportingData.results.map((data: any) => {
                        return { x: data.year.toString(), y: data.electricityConsumption }
                      })}
                    />
                    <VictoryBar
                    barWidth={50}
                      data={reportingData.results.map((data: any) => {
                        return {
                          x:
                            data.year.toString(),
                          y: data.gasConsumption * 9.769
                        }
                      })}
                    />
                    <VictoryLabel
                        textAnchor="middle"
                        style={{ fontSize: 16, fill: 'rgba(204,204,204)' }}
                        x={240}
                        y={318}
                        text={`-${moment().format('MMMM Do')}`}
                        angle={360}
                      />
                  </VictoryStack>
                </VictoryChart>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider/>
        <div className="flex1-row flex1-end">©2021 Lyv B.V. Onder voorbehoud van fouten.</div>
      </div>
    </Container>}
    </>
  ) 
}
export default YearReport
