import * as React from 'react'
import '../Authorization/login.scss'
import { Button, Form, Message } from 'semantic-ui-react'
import { useState } from 'react'
import * as zohoService from '../../services/zoho'
import AuthContext from '../../contexts/auth'
import Page from '../../pages/Page'

// Login Ui Contents

const ZohoForm: React.FC = () => {
  const authContext = React.useContext(AuthContext)

  const [grantToken, setGrantToken] = useState<string>('')
  const [failureMessage, setFailureMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const onSubmit = async (e: any) => {
    e.preventDefault()
    try {
      await zohoService.obtainTokens(authContext?.accessToken!, grantToken)
      setSuccessMessage('Tokens obtained successfully')
      setGrantToken('')
    } catch (err) {
      setFailureMessage('Invalid or Expired grant token')
    }
  }

  setTimeout(() => {
    setFailureMessage(null)
    setSuccessMessage(null)
  }, 3000)

  return (
    <Page>
      <div className="flex1-column-start">
        <div className="form-container flex-row flex1-center">
          <Form stackable onSubmit={onSubmit}>
            <div className="form-inner-item flex1-column">
              <div className="login-form-inputdetails">
                <Form.Field>
                  <input
                    placeholder="Zoho Grant Token"
                    name="grantToken"
                    required
                    value={grantToken}
                    onChange={(e) => setGrantToken(e.target.value)}/>
                </Form.Field>
                <Button type="submit" className="btn-green">
                  Obtain
                </Button>
              </div>
              {failureMessage && <Message color="red">{failureMessage}</Message>}
              {successMessage && <Message color="green">{successMessage}</Message>}
            </div>
          </Form>
        </div>
      </div>
    </Page>
  )
}

export default ZohoForm
