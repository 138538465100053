import * as React from 'react'
import { Form, Confirm, Dropdown, Message } from 'semantic-ui-react'
import { eancodeBoek, consumerType, netBeheerNederland } from '../../../util/options'
import { clientType, toolTipText } from '~/enums'
import { useState } from 'react'
import DataContext from '../../../contexts/data'
import { IElectricityGridContractUpdate } from '../../../interfaces/contracts'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import UserContext from '../../../contexts/user'

import NumberFormat from 'react-number-format'

type energySupplyForm = {
  networkAdministration: string
  type: string
  meter: string
  connection: string
  rate: string
  year: string
}

const NetworkManagemntFlow = () => {
  const dataContext = React.useContext(DataContext)
  const userContext = React.useContext(UserContext)
  const [changeValues, setChangeValues] = useState<boolean>(true)
  const [smallUser, setSmallUser] = useState<boolean>(true)
  const [obligationInput, setObligationInput] = useState('obligation-input')
  const [dsoOptions, setDsoOptions] = useState<any[]>([])
  const [gridConnectionTypeOptions, setGridConnectionTypeOptions] = useState<any[]>([])
  const [consumerTypeOptions, setConsumerTypeOptions] = useState<any[]>([])
  const [selectedConsumerType, setSelectedConsumerType] = useState<string | undefined>(consumerType[0].value)
  const [selectedDsoId, setSelectedDsoId] = useState<number | undefined>(dataContext?.contract.electricityGrid.dso.id)
  const [selectedGridConnectionTypeId, setSelectedGridConnectionTypeId] = useState<number | undefined>(
    dataContext?.contract.electricityGrid.gridConnectionType.id
  )
  const [failureMessage, setFailureMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const [openPopUp, setOpenPopUp] = useState<boolean>(false)

  const close = () => {
    setOpenPopUp(false)
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const payload: IElectricityGridContractUpdate = {
      dsoId: selectedDsoId!,
      gridConnectionTypeId: selectedGridConnectionTypeId!
    }
    try {
      await dataContext?.contract?.updateElectricityGridContract(payload)
      setChangeValues(!changeValues)
      setObligationInput('obligation-input')
      setSuccessMessage('De elektriciteitsgegevens zijn succesvol verwerkt.')
    } catch (err) {
      setFailureMessage(err.errors[0])
    }
  }

  const onChangeConfirm = () => {
    setChangeValues(!changeValues)
    setObligationInput('')
  }
  const onDsoSelected = (e: any, data: any) => {
    setSelectedDsoId(data.value)
  }

  const onConnectionTypeSelected = (e: any, data: any) => {
    setSelectedGridConnectionTypeId(data.value)
  }

  const onConsumerTypeSelected = (e: any, data: any) => {
    setSelectedConsumerType(data.value)
  }

  setTimeout(() => {
    setSuccessMessage(null)
    setFailureMessage(null)
  }, 5000)

  const fetchGridTariff = async () => {
    await dataContext?.contract?.fetchGridTariff(selectedDsoId!, selectedGridConnectionTypeId!)
  }

  React.useEffect(() => {
    fetchGridTariff()
  }, [selectedGridConnectionTypeId])

  React.useEffect(() => {
    fetchGridTariff()
  }, [selectedDsoId])

  React.useEffect(() => {
    // if (selectedConsumerType === 'large') {
    //   setSmallUser(false)
    // } else if (selectedConsumerType === 'small') {
    //   setSmallUser(true)
    // }
    setSmallUser(selectedConsumerType === 'Kleinverbruiker')
  }, [selectedConsumerType])

  React.useEffect(() => {
    const result = dataContext?.contract.dsos.map((dso) => {
      return { key: dso.id, text: dso.name, value: dso.id }
    })
    setDsoOptions(result || [])
  }, [dataContext?.contract.dsos])

  React.useEffect(() => {
    const result = dataContext?.contract.gridConnectionTypes.map((conn) => {
      return { key: conn.id, text: conn.connectionName, value: conn.id }
    })
    setGridConnectionTypeOptions(result || [])
  }, [dataContext?.contract.gridConnectionTypes])
  const inclusiveOrExclusive = () => {
    if (userContext?.profile?.customerType === clientType.Business) {
      return toolTipText.Exclusive
    } else if (userContext?.profile?.customerType === clientType.Private) {
      return toolTipText.Inclusive
    }
  }
  React.useEffect(() => {
    const result = dataContext?.contract.consumerTypes.map((con) => {
      return { key: con, text: con, value: con }
    })
    setConsumerTypeOptions(result || [])
  }, [dataContext?.contract.consumerTypes])

  return (
    <div className="energy-supply-container">
      <div className="energy-form-container">
        <hr style={{ marginTop: '5px' }} />
        <Form onSubmit={onSubmit} className="form">
          <div className="energy-supply-details flex1-column">
            <div className="flex1-row">
              <p className="energy-supply-detail-names">Netwerkbeheerder</p>
              <Dropdown
                name="networkAdministration"
                placeholder="stedin"
                options={dsoOptions}
                onChange={onDsoSelected}
                value={selectedDsoId}
                disabled={changeValues}
                selection
                fluid
                style={{ marginLeft: '7px', marginRight: '0px' }}
              />
              <div className="tooltip">
                <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                <div className="tooltiptext" style={{ height: '56px', bottom: '-110%' }}>
                  De netwerkbeheerder kunt u hier vinden:
                  <a href={eancodeBoek} target="_blank" rel="noreferrer">
                    {eancodeBoek}
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Type</p>
              </div>
              <Dropdown
                name="type"
                placeholder="Kleinverbruiker"
                options={consumerType}
                onChange={onConsumerTypeSelected}
                value={selectedConsumerType}
                disabled={changeValues}
                selection
                fluid
                style={{ marginLeft: '7px', marginRight: '0px' }}
              />
              <div className="tooltip">
                <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                <div className="tooltiptext" style={{ height: '50px', bottom: '-110%' }}>
                  Grootverbruik is een aansluiting boven de 3x 80A,
                </div>
              </div>
            </div> */}
            {!smallUser && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Meter</p>
                </div>
                <Dropdown name="meter" placeholder="Slimme meter DSMR 5.0" disabled={changeValues} selection fluid />
              </div>
            )}
            {smallUser && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Aansluiting</p>
                </div>
                <Dropdown
                  options={gridConnectionTypeOptions}
                  onChange={onConnectionTypeSelected}
                  value={selectedGridConnectionTypeId}
                  name="connection"
                  selection
                  disabled={changeValues}
                  fluid
                  style={{ marginLeft: '7px', marginRight: '0px' }}
                />
                <div className="tooltip">
                  <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                  <div className="tooltiptext" style={{ height: '97px', bottom: '-250%' }}>
                    Weet u uw aansluiting niet dan kunt u dit navragen bij uw netwerkbeheerder:
                    <br />
                    <a href={netBeheerNederland} target="_blank" rel="noreferrer">
                      {netBeheerNederland}
                    </a>
                  </div>
                </div>
              </div>
            )}
            {smallUser && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Tarief</p>
                </div>
                <NumberFormat
                  value={dataContext?.contract.gridTariff?.fixedTariffPerYear.toFixed(2) || 0}
                  disabled={true}
                  name="connection"
                  className="form-item obligation-input number-format"
                  style={{ marginRight: '5px' }}
                  decimalSeparator={','}
                  allowedDecimalSeparators={[',', '.']}
                  isNumericString={true}
                />
                <span style={{ marginLeft: '-48px', paddingTop: '4px', opacity: '0.9' }}>€/jaar</span>
                <div className="tooltip">
                  <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                  <div className="tooltiptext" style={{ height: '50px', bottom: '-110%' }}>
                    {inclusiveOrExclusive()}
                  </div>
                </div>
              </div>
            )}
            {!smallUser && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Transportcattegorie</p>
                </div>
                <Dropdown name="transportCategory" placeholder="LS t\m 50 kW" selection disabled={changeValues} fluid />
              </div>
            )}
            {!smallUser && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Vastrecht</p>
                </div>
                <input
                  placeholder="1,5"
                  disabled={changeValues}
                  name="fixedFee"
                  className={`form-item ${obligationInput}`}
                />
                <span style={{ marginLeft: '-61px', paddingTop: '4px' }}>€/maand</span>
              </div>
            )}
            {!smallUser && (
              <div className="flex1-row">
                <div className="energy-supply-detail-names">
                  <p>Kw per mnd</p>
                </div>
                <input
                  placeholder="0,7292"
                  disabled={changeValues}
                  name="kwpermnd"
                  className={`form-item ${obligationInput}`}
                />
              </div>
            )}
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Jaar</p>
              </div>
              <input
                value={dataContext?.contract.gridTariff?.year || 0}
                disabled={true}
                name="year"
                className="form-item obligation-input"
                style={{ marginRight: '28px' }}
              />
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names"></div>
              {failureMessage && <Message color="red">{failureMessage}</Message>}
              {successMessage && <Message color="green">{successMessage}</Message>}
            </div>
            <div className="flex1-row flex1-end" style={{ marginRight: '28px' }}>
              {changeValues && (
                <button className="btn-blue" onClick={onChangeConfirm}>
                  Wijzig
                </button>
              )}
              {!changeValues && (
                <button className="btn-blue" type="submit">
                  Pas toe
                </button>
              )}
              <Confirm
                content="
                Wil je de data pastoe?"
                cancelButton="Nee"
                confirmButton="Ja"
                open={openPopUp}
                onCancel={close}
                onConfirm={close}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default NetworkManagemntFlow
