import * as React from 'react'
import { Grid, Container, Divider, Dimmer, Image } from 'semantic-ui-react'
import AuthContext from '../../contexts/auth'
import { ReportingService, AggregatedDateReportData } from '../../services/reporting'
import { ReportConnectionRow, ReportHeader } from './generic'
import { ConsumerType, clientType } from '~/enums'
import UserContext from '../../contexts/user'
import DataContext from '../../contexts/data'
import { useContext } from 'react'
import * as loadingSymbol from '../../assets/loading.png'
const moment = require('moment')

const FinancialReport: React.FC = () => {
  const [reportingData, setReportingData] = React.useState<AggregatedDateReportData | undefined>()
  const [isPrintMode, setIsPrintMode] = React.useState<boolean>(false)
  const reportingService = new ReportingService()
  const [loading, setLoading] = React.useState<boolean>(true)
  const dataContext = React.useContext(DataContext)
  const userContext = useContext(UserContext)
  const authContext = React.useContext(AuthContext)
  const reportElement = React.useRef<HTMLDivElement>(null)
  const [isBusinessLargeConsumer, setBusinessLargeConsumer] = React.useState<boolean>(false)

  const fetchData = async () => {
    const token = authContext?.accessToken!
    const result = await reportingService.getYearReportData(token)
    setReportingData(result)
  }

  console.log('reporting data', reportingData)

  React.useEffect(() => {
    fetchData()
    if (userContext?.profile?.customerType === clientType.Business && !!dataContext?.device.activeUserMainMeterDevice &&
      dataContext?.device.activeUserMainMeterDevice.consumerType === ConsumerType.Large) {
      setBusinessLargeConsumer(true)
    }
  }, [])

  React.useEffect(() => {
    if (reportingData && userContext?.profile) {
      setLoading(false)
    }
  })
  const onBeforePrint = () => {
    setIsPrintMode(true)
  }
  const onAfterPrint = () => {
    setIsPrintMode(false)
  }
  return (
    <>
      <div className="Loader" style={{ display: loading ? 'block' : 'none' }}>
        <Dimmer active>
          <Image src={loadingSymbol.default} className="rotate" />
        </Dimmer>
      </div>
      {isBusinessLargeConsumer ? <strong className="flex1-row flex1-center">Not Available</strong>
        : <Container
          className="report-workspace"
          id="general-report-container"
          style={{ display: loading ? 'none' : 'block' }}
        >
          <div className="report-container financial-root-print-page" ref={reportElement}>
            <ReportHeader
              title="FINANCIEEL"
              elementRef={reportElement.current}
              onBeforePrint={onBeforePrint}
              onAfterPrint={onAfterPrint}
            />
            <ReportConnectionRow></ReportConnectionRow>
            <Grid>
              {/* Years header */}
              <Grid.Row>
                <Grid.Column width="4"></Grid.Column>
                {reportingData &&
                reportingData.results &&
                reportingData.results.map((data: any, i: any) => {
                  if (data.contracts.length !== 0) {
                    return (
                      <Grid.Column
                        key={i}
                        width={data.gasContracts.length > data.contracts.length
                          ? data.gasContracts.length * 3 : data.gasContracts.length <
                          data.contracts.length ? data.contracts.length * 3
                            : data.contracts.length * 3}
                        style={{ justifyContent: 'center' }}
                      >

                        <strong className="heading medium">
                          {data.year}
                        </strong>

                      </Grid.Column>
                    )
                  }
                })}
              </Grid.Row>
            </Grid>
            <Divider />
            <Grid>
              {/* contract */}
              {
                <>
                  <Grid.Column width="four">
                    <Grid.Row textAlign="left">
                      <strong className="heading big">Contract</strong>
                    </Grid.Row>
                    <Grid.Row textAlign="left">
                      <div className="report-content">Startdatum</div>
                    </Grid.Row>
                    <Grid.Row textAlign="left">
                      <div className="report-content">Einddatum</div>
                    </Grid.Row>
                    <Grid.Row textAlign="left">
                      <div className="report-content">Dagen</div>
                    </Grid.Row>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    if (data.contracts.length !== 0) {
                      return data.contracts.map((contract: any, i: any) => {
                        if (contract.days > 0) {
                          return (
                            <Grid.Column key={i} width="three" textAlign="center">
                              <Grid.Row>
                                <strong className="heading small">
                                  {contract.retailer !== '' ? contract.retailer : 'Lyv standaard'}
                                </strong>
                              </Grid.Row>
                              <Grid.Row textAlign="center">
                                <div className="report-content">
                                  {data.year.toString() === moment(contract.start).format('YYYY')
                                    ? moment(contract.start).format('DD MMMM YYYY')
                                    : '-'}
                                </div>
                              </Grid.Row>
                              <Grid.Row textAlign="center">
                                <div className="report-content">
                                  {data.year.toString() === moment(contract.end).format('YYYY')
                                    ? moment(contract.end).format('DD MMMM YYYY')
                                    : '-'}
                                </div>
                              </Grid.Row>
                              <Grid.Row textAlign="center">
                                <div className="report-content">{contract.days}</div>
                              </Grid.Row>
                            </Grid.Column>
                          )
                        }
                      })
                    }
                  })}
                </>
              }
            </Grid>
            <Divider />
            <Grid>
              {/* Electiricity data */}
              {
                <>
                  <Grid.Column width="four" className="p-b-zero">
                    <Grid.Row>
                      <strong className="heading big">Elektriciteit</strong>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content m-l-small">
                      Vaste vergoeding
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content m-l-small">
                      Levering
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content m-l-small" >
                      Teruglevering
                      </div>
                    </Grid.Row>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    if (data.contracts.length !== 0) {
                      return data.contracts.map((contract: any, i: any) => {
                        if (contract.days > 0) {
                          return (
                            <Grid.Column key={i} width={'three'} className="p-b-zero" textAlign="center">
                              <Grid.Row>
                                <strong className="heading small">
                                  {contract.retailer !== '' ? contract.retailer : 'Lyv standaard'}
                                </strong>
                              </Grid.Row>
                              <Grid.Row>
                                <div className="report-content align-euro-content"
                                >{`${contract.fixedFee
                                  .toFixed(2)
                                  .replace('.', ',')} EUR`}</div>
                              </Grid.Row>
                              <Grid.Row>
                                <div className="report-content align-euro-content">{`${contract.deliveryFee
                                  .toFixed(2)
                                  .replace('.', ',')} EUR`}</div>
                              </Grid.Row>
                              <Grid.Row>
                                <div className="report-content align-euro-content">{`${contract.feedIn
                                  .toFixed(2)
                                  .replace('.', ',')} EUR`}</div>
                              </Grid.Row>
                            </Grid.Column>
                          )
                        }
                      })
                    }
                  })}
                </>
              }
            </Grid>
            {/* Delivery Total */}
            <Grid>
              {
                <>
                  <Grid.Column width="four" className="p-t-zero">
                    <strong className="heading small">Levering totaal</strong>
                  </Grid.Column>
                  {reportingData &&
                reportingData.results &&
                reportingData.results.map((data: any, i: any) => {
                  if (data.contracts.length !== 0) {
                    return (
                      <Grid.Column key={i}
                        width={data.gasContracts.length > data.contracts.length
                          ? data.gasContracts.length * 3 : data.gasContracts.length <
                        data.contracts.length ? data.contracts.length * 3
                            : data.contracts.length * 3}
                        textAlign="center"
                        className="p-t-zero">
                        <Grid.Row>
                          <div
                            style={{
                              color: '#4d4d4d',
                              fontSize: '14px',
                              fontWeight: 'bold'
                            }}
                            className={
                              data.contracts.length === 3 || data.gasContracts.length === 3 ? 'align-euro-content-column2' : 'align-euro-content'
                            }
                          >
                            {`${data.electricityDeliveryTotalCosts.toFixed(2).replace('.', ',')} EUR`}
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                    )
                  }
                })}
                </>
              }
            </Grid>
            <Grid>
              {
              /* network data */
                <>
                  <Grid.Column width="four" className="p-b-zero">
                    <strong className="heading big">Aansluitgegevens</strong>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    return (
                      data.gridConnection &&
                      data.gasConnection && (
                        <Grid.Column
                          key={i}
                          width={data.gasContracts.length > data.contracts.length
                            ? data.gasContracts.length * 3 : data.gasContracts.length <
                            data.contracts.length ? data.contracts.length * 3
                              : data.contracts.length * 3}
                          textAlign="center"
                          className="p-b-zero"
                        >
                          <div className="report-content">{`${data.gridConnection.dso}${''} ${
                            data.gridConnection.connectionType
                          }`}</div>
                        </Grid.Column>
                      )
                    )
                  })}
                </>
              }
            </Grid>
            <Grid>
              {
              /* transportation data */
                <>
                  <Grid.Column width="four" className="p-t-zero">
                    <div className="report-content">Netwerkkosten</div>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    return (
                      data.gridConnection && (
                        <Grid.Column
                          key={i}
                          width={data.gasContracts.length > data.contracts.length
                            ? data.gasContracts.length * 3 : data.gasContracts.length <
                            data.contracts.length ? data.contracts.length * 3
                              : data.contracts.length * 3}
                          textAlign="center"
                          className="p-t-zero"
                        >

                          <div className={ data.contracts.length === 3 || data.gasContracts.length === 3 ? 'report-content align-euro-content-column2' : 'report-content align-euro-content'}>{`${data.gridConnection.transportationCosts
                              .toFixed(2)
                              .replace('.', ',')} EUR`}</div>

                        </Grid.Column>
                      )
                    )
                  })}
                </>
              }
            </Grid>
            <Grid>
              <Grid.Row className="p-t-zero">
                <Grid.Column width="four">
                  <div className="flex1-row">
                    {' '}
                    <strong className="heading big">Belasting</strong>
                  </div>
                </Grid.Column>
                <Grid.Column width="four"></Grid.Column>
                <Grid.Column width="four"></Grid.Column>
                <Grid.Column width="four"></Grid.Column>
              </Grid.Row>

              {
                <>
                  <Grid.Column width="four" className="p-t-zero">
                    <Grid.Row>
                      <div className="report-content m-l-small">
                      Energiebelastingen
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content m-l-small" >
                      ODE belastingen
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content m-l-small">
                      Belastingkorting
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content">Subtotaal</div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content">BTW(21%)</div>
                    </Grid.Row>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    if (data.contracts.length !== 0) {
                      return (
                        data.taxes && (
                          <Grid.Column
                            key={i}
                            width={data.gasContracts.length > data.contracts.length
                              ? data.gasContracts.length * 3 : data.gasContracts.length <
                              data.contracts.length ? data.contracts.length * 3
                                : data.contracts.length * 3}
                            className="p-t-zero"
                            textAlign="center"
                          >
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >{`${data.taxes.electricityEnergyTax.toFixed(2).replace('.', ',')} EUR`}</div>
                            </Grid.Row>
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >{`${data.taxes.electricitySustainabilityTax.toFixed(2).replace('.', ',')} EUR`}</div>
                            </Grid.Row>
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >{`${data.taxes.taxCredit.toFixed(2).replace('.', ',')} EUR`}</div>
                            </Grid.Row>
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >
                                {(
                                  data.gridConnection.transportationCosts +
                                  data.electricityDeliveryTotalCosts +
                                  data.taxes.electricityEnergyTax +
                                  data.taxes.electricitySustainabilityTax +
                                  data.taxes.taxCredit
                                )
                                  .toFixed(2)
                                  .replace('.', ',')}{' '}
                                EUR
                              </div>
                            </Grid.Row>
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >
                                {(
                                  (data.taxes.btwPercent / 100) *
                                  (data.gridConnection.transportationCosts +
                                    data.electricityDeliveryTotalCosts +
                                    data.taxes.electricityEnergyTax +
                                    data.taxes.electricitySustainabilityTax +
                                    data.taxes.taxCredit)
                                )
                                  .toFixed(2)
                                  .replace('.', ',')}{' '}
                                EUR
                              </div>
                            </Grid.Row>
                          </Grid.Column>
                        )
                      )
                    }
                  })}
                </>
              }
            </Grid>
            <Divider />
            {/* Electricity gird */}
            <Grid className="p-t-zero">
              <>
                <Grid.Column width="four">
                  <strong className="heading big">Totaal elektriciteit</strong>
                </Grid.Column>
                {reportingData &&
                reportingData.results &&
                reportingData.results.map((data: any, i: any) => {
                  if (data.contracts.length !== 0) {
                    return (
                      <Grid.Column key={i} width={data.gasContracts.length > data.contracts.length
                        ? data.gasContracts.length * 3 : data.gasContracts.length <
                        data.contracts.length ? data.contracts.length * 3
                          : data.contracts.length * 3}
                      textAlign="center">
                        <Grid.Row>
                          <div
                            style={{
                              color: '#4d4d4d',
                              fontSize: '16px',
                              fontWeight: 'bold'
                            }}
                            className={
                              data.contracts.length === 3 || data.gasContracts.length === 3
                                ? 'report-content align-euro-content-column2'
                                : 'report-content align-euro-content'
                            }
                          >
                            {`${(
                              data.gridConnection.transportationCosts +
                              data.electricityDeliveryTotalCosts +
                              data.taxes.electricityEnergyTax +
                              data.taxes.electricitySustainabilityTax +
                              data.taxes.taxCredit +
                              (data.taxes.btwPercent / 100) *
                                (data.gridConnection.transportationCosts +
                                  data.electricityDeliveryTotalCosts +
                                  data.taxes.electricityEnergyTax +
                                  data.taxes.electricitySustainabilityTax +
                                  data.taxes.taxCredit)
                            )
                              .toFixed(2)
                              .replace('.', ',')} EUR`}
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                    )
                  }
                })}
              </>
            </Grid>
            <Grid style={{ marginTop: '10rem' }}>
              {/* Years header */}
              <Grid.Row>
                <Grid.Column width="4"></Grid.Column>
                {reportingData &&
                reportingData.results &&
                reportingData.results.map((data: any, i: any) => {
                  if (data.gasContracts.length !== 0) {
                    return (
                      <Grid.Column
                        key={i}
                        width={data.gasContracts.length > data.contracts.length
                          ? data.gasContracts.length * 3 : data.gasContracts.length <
                          data.contracts.length ? data.contracts.length * 3
                            : data.gasContracts.length * 3}
                        style={{ justifyContent: 'center' }}
                      >

                        <strong className="heading medium">
                          {data.year}
                        </strong>

                      </Grid.Column>
                    )
                  }
                })}
              </Grid.Row>
            </Grid>
            <Divider />
            <Grid>
              {/* Gas data */}
              {
                <>
                  <Grid.Column width="four" className="p-b-zero">
                    <Grid.Row>
                      <strong className="heading big">Gas</strong>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content m-l-small">
                      Vaste vergoeding
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content m-l-small">
                      Gaslevering
                      </div>
                    </Grid.Row>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    if (data.gasContracts.length !== 0) {
                      return data.gasContracts.map((contract: any, i: any) => {
                        if (contract.days > 0) {
                          return (
                            <Grid.Column
                              key={i}
                              width={data.gasContracts.length > data.contracts.length
                                ? data.gasContracts.length * 3 : data.gasContracts.length <
                                data.contracts.length ? data.contracts.length * 3
                                  : data.gasContracts.length * 3}
                              className="p-b-zero"
                              textAlign="center"
                            >
                              <Grid.Row>
                                <strong className="heading small">
                                  {contract.retailer !== '' ? contract.retailer : 'Lyv standaard'}
                                </strong>
                              </Grid.Row>
                              <Grid.Row>
                                <div
                                  className={
                                    data.contracts.length === 3 || data.gasContracts.length === 3
                                      ? 'report-content align-euro-content-column2'
                                      : 'report-content align-euro-content'
                                  }
                                >{`${contract.fixedFee.toFixed(2).replace('.', ',')} EUR`}</div>
                              </Grid.Row>
                              <Grid.Row>
                                <div
                                  className={
                                    data.contracts.length === 3 || data.gasContracts.length === 3
                                      ? 'report-content align-euro-content-column2'
                                      : 'report-content align-euro-content'
                                  }
                                >{`${contract.deliveryFee.toFixed(2).replace('.', ',')} EUR`}</div>
                              </Grid.Row>
                            </Grid.Column>
                          )
                        }
                      })
                    }
                  })}
                </>
              }
            </Grid>
            {/* Gas delivery Total */}
            <Grid>
              {
                <>
                  <Grid.Column width="four" className="p-t-zero">
                    <strong className="heading small">Levering totaal</strong>
                  </Grid.Column>
                  {reportingData &&
                reportingData.results &&
                reportingData.results.map((data: any, i: any) => {
                  if (data.contracts.length !== 0) {
                    return (
                      <Grid.Column
                        key={i}
                        width={data.gasContracts.length > data.contracts.length
                          ? data.gasContracts.length * 3 : data.gasContracts.length <
                          data.contracts.length ? data.contracts.length * 3
                            : data.gasContracts.length * 3}
                        textAlign="center"
                        className="p-t-zero"
                      >
                        <Grid.Row>
                          <div
                            style={{
                              color: '#4d4d4d',
                              fontSize: '14px',
                              fontWeight: 'bold'
                            }}
                            className={
                              data.contracts.length === 3 || data.gasContracts.length === 3
                                ? 'report-content align-euro-content-column2'
                                : 'report-content align-euro-content'
                            }
                          >
                            {`${data.gasDeliveryTotalCosts.toFixed(2).replace('.', ',')} EUR`}
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                    )
                  }
                })}
                </>
              }
            </Grid>
            <Grid>
              {
              /* Gas network data */
                <>
                  <Grid.Column width="four" className="p-b-zero">
                    <strong className="heading big">Aansluitgegevens</strong>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    return (
                      data.gasConnection && (
                        <Grid.Column
                          key={i}
                          width={data.gasContracts.length > data.contracts.length
                            ? data.gasContracts.length * 3 : data.gasContracts.length <
                            data.contracts.length ? data.contracts.length * 3
                              : data.gasContracts.length * 3}
                          className="p-b-zero"
                          textAlign="center"
                        >
                          <div className="report-content">{`${data.gasConnection.dso}${''} ${
                            data.gasConnection.connectionType
                          }`}</div>
                        </Grid.Column>
                      )
                    )
                  })}
                </>
              }
            </Grid>
            {/* <Grid> */}
            <Grid>
              {
                <>
                  <Grid.Column width="four" className="p-t-zero">
                    <div className="report-content">Netwerkkosten</div>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    return (
                      data.gasConnection && (
                        <Grid.Column
                          key={i}
                          width={data.gasContracts.length > data.contracts.length
                            ? data.gasContracts.length * 3 : data.gasContracts.length <
                            data.contracts.length ? data.contracts.length * 3
                              : data.gasContracts.length * 3}
                          textAlign="center"
                          className="p-t-zero"
                        >
                          <Grid.Row>
                            <div className={
                              data.contracts.length === 3 || data.gasContracts.length === 3
                                ? 'report-content align-euro-content-column2'
                                : 'report-content align-euro-content'
                            }>{`${data.gasConnection.transportationCosts
                              .toFixed(2)
                              .replace('.', ',')} EUR`}</div>
                          </Grid.Row>
                        </Grid.Column>
                      )
                    )
                  })}
                </>
              }
            </Grid>
            {/* </Grid> */}
            <Grid>
              <Grid.Row>
                <Grid.Column width="four">
                  <div className="flex1-row">
                    {' '}
                    <strong className="heading big">Belasting</strong>
                  </div>
                </Grid.Column>
                <Grid.Column width="four"></Grid.Column>
                <Grid.Column width="four"></Grid.Column>
                <Grid.Column width="four"></Grid.Column>
              </Grid.Row>
              {
                <>
                  <Grid.Column width="four" className="p-t-zero">
                    <Grid.Row>
                      <div className="report-content m-l-small">
                      Energiebelastingen
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content m-l-small">
                      ODE belastingen
                      </div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content">Subtotaal</div>
                    </Grid.Row>
                    <Grid.Row>
                      <div className="report-content">BTW(21%)</div>
                    </Grid.Row>
                  </Grid.Column>
                  {reportingData &&
                  reportingData.results &&
                  reportingData.results.map((data: any, i: any) => {
                    if (data.contracts.length !== 0) {
                      return (
                        data.taxes && (
                          <Grid.Column
                            key={i}
                            width={data.gasContracts.length > data.contracts.length
                              ? data.gasContracts.length * 3 : data.gasContracts.length <
                              data.contracts.length ? data.contracts.length * 3
                                : data.gasContracts.length * 3}
                            className="p-t-zero"
                            textAlign="center"
                          >
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >{`${data.taxes.gasEnergyTax.toFixed(2).replace('.', ',')} EUR`}</div>
                            </Grid.Row>
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >{`${data.taxes.gasSustainabilityTax.toFixed(2).replace('.', ',')} EUR`}</div>
                            </Grid.Row>
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >
                                {(
                                  data.gasTotals.subtotal
                                )
                                  .toFixed(2)
                                  .replace('.', ',')}{' '}
                                EUR
                              </div>
                            </Grid.Row>
                            <Grid.Row>
                              <div
                                className={
                                  data.contracts.length === 3 || data.gasContracts.length === 3
                                    ? 'report-content align-euro-content-column2'
                                    : 'report-content align-euro-content'
                                }
                              >
                                {(
                                  (data.gasTotals.subtotal * 21) / 100
                                )
                                  .toFixed(2)
                                  .replace('.', ',')}{' '}
                                EUR
                              </div>
                            </Grid.Row>
                          </Grid.Column>
                        )
                      )
                    }
                  })}
                </>
              }
            </Grid>
            <Divider />
            <Grid>
              <>
                <Grid.Column width="four">
                  <strong className="heading big">Totaal gas</strong>
                </Grid.Column>
                {reportingData &&
                reportingData.results &&
                reportingData.results.map((data: any, i: any) => {
                  if (data.contracts.length !== 0) {
                    return (
                      <Grid.Column
                        key={i}
                        width={data.gasContracts.length > data.contracts.length
                          ? data.gasContracts.length * 3 : data.gasContracts.length <
                          data.contracts.length ? data.contracts.length * 3
                            : data.gasContracts.length * 3}
                        textAlign="center"
                      >
                        <Grid.Row>
                          <div
                            style={{
                              color: '#4d4d4d',
                              fontSize: '16px',
                              fontWeight: 'bold'
                            }}
                            className={
                              data.contracts.length === 3 || data.gasContracts.length === 3 ? 'align-euro-content-column2' : 'align-euro-content'
                            }
                          >
                            {`${(
                             data.gasTotals.total
                            )
                              .toFixed(2)
                              .replace('.', ',')} EUR`}
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                    )
                  }
                })}
              </>
            </Grid>
            <Divider style={{ marginTop: '5rem' }} />
            <Grid>
              <>
                <Grid.Column width="four">
                  <strong className="heading big">Eind totaal</strong>
                </Grid.Column>
                {reportingData &&
                reportingData.results &&
                reportingData.results.map((data: any, i: any) => {
                  if (data.gasContracts.length !== 0) {
                    return (
                      <Grid.Column
                        key={i}
                        width={data.gasContracts.length > data.contracts.length
                          ? data.gasContracts.length * 3 : data.gasContracts.length <
                          data.contracts.length ? data.contracts.length * 3
                            : data.gasContracts.length * 3}
                        textAlign="center"
                      >
                        <Grid.Row>
                          <div
                            style={{
                              color: '#4d4d4d',
                              fontSize: '16px',
                              fontWeight: 'bold'
                            }}
                            className={
                              data.contracts.length === 3 || data.gasContracts.length === 3 ? 'align-euro-content-column2' : 'align-euro-content'
                            }
                          >
                            {`${(
                              data.grandTotal
                            )
                              .toFixed(2)
                              .replace('.', ',')} EUR`}
                          </div>
                        </Grid.Row>
                      </Grid.Column>
                    )
                  }
                })}
              </>
            </Grid>
          </div>
        </Container>
      }
    </>
  )
}
export default FinancialReport
