import * as React from 'react'
import { Image } from 'semantic-ui-react'
import { RouteComponentProps, withRouter } from 'react-router'
import * as PropTypes from 'prop-types'
import * as loading from '../../assets/loading.png'
import Page from '../../pages/Page'
import OnboardService from '../../services/onboard'
import AuthContext from '../../contexts/auth'
import routes from '../../pages/Routes'

interface IMailVerifiedProps extends RouteComponentProps {
  token: string
}

// Component to display message that your email has been verified
const MailVerified: React.FC<IMailVerifiedProps> = ({ history, token }) => {
  const onboardService = new OnboardService()
  const authContext = React.useContext(AuthContext)
  const path = () => {
    return location.pathname === routes.CREATE_ACCOUNT_COMMUNITY_MANAGER
  }
  const verifyUserAccount = async () => {
    const result = await onboardService.verifyAccount(token)
    if (!result) {
      // TODO proper error display
      alert('Unable to verify account')
      return
    }
    const { access, refresh } = result
    authContext?.setUserToken({ accessToken: access, refreshToken: refresh })
    setTimeout(() => {
      path() ? history.push('/community/home') : history.push('/gateway/2')
    }, 5000)
  }

  React.useEffect(() => {
    verifyUserAccount()
  }, [])

  return (
    <>
      <Page>
        <div className="mail-verified-container flex1-column flex1-start  m-y-top">
          <div className="flex1-column flex1-center">
            <strong className="font-medium">Uw account is bevestigd</strong>
            <div className="m-top">U wordt nu automatisch omgeleid naar uw account,</div>
            <div>even geduld a.u.b.</div>
            <div className="m-y-top">
              <Image src={loading.default} className="rotate" />
            </div>
          </div>
        </div>
      </Page>
    </>
  )
}
MailVerified.propTypes = {
  history: PropTypes.any.isRequired
}
export default withRouter(MailVerified)
