import * as React from 'react'
import cn from 'classnames'
import { Image, Button, Grid, Form, Input, Checkbox, Divider } from 'semantic-ui-react'
import * as boiler from '../../../assets/boiler.svg'
import { useState, useContext, useEffect } from 'react'
import * as blueUpArrow from '../../../assets/blue_up_arrow.png'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import * as deviceService from '~/services/device'
import { SmartControlllerDetailContainer } from '../..'
import { SmartControllerApplicaiton } from '~/enums'
import { IUserDevice } from '~/interfaces/device'
import * as constants from '~/constants'
import AuthContext from '~/contexts/auth'
import UserContext from '~/contexts/user'
import * as helper from '../../../util/helper'
import DataContext from '~/contexts/data'
const moment = require('moment')

interface BoilerProps {
  device: IUserDevice
}

const Boiler = (props: BoilerProps) => {
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)
  const dataContext = useContext(DataContext)

  const [expand, setExpand] = useState(false)
  const [tileExpanded, setTileExpanded] = React.useState('')
  const [activePower, setActivePower] = React.useState(0)
  const [consumption, setConsumption] = React.useState(0)

  const detailsExpand = () => {
    setExpand(!expand)
  }

  const toggleUserDeviceActiveState = async () => {
    try {
      await dataContext?.device.switchSmartControllerDevice(props.device.deviceId, !props.device.lastSetState)
    } catch (error) {
    }
  }

  const onReset = async () => {
    await dataContext?.device.resetSmartControllerDeviceReading(props.device.deviceId)
  }

  useEffect(() => {
    const deviceAggregate = dataContext?.aggregate?.smartControllerDevicesAggregate!.find((item) => item.deviceId === props.device.deviceId)
    if (deviceAggregate) {
      setConsumption(deviceAggregate.consumption)
      setActivePower(deviceAggregate.activePower)
    }
  }, [dataContext?.aggregate.smartControllerDevicesAggregate])

  return (
    <>
      <div className="boiler-container">
        <div className="asset-container">
          <strong className="title">
            {props.device.applianceName
              ? helper.capitalize(props.device.applianceName)
              : helper.deviceDescriptionAssetApplicationMapping.boiler.boiler_dutch}
          </strong>
          <hr />
          <div className="flex1-row m-y-t">
            <Image src={boiler.default} className="boiler-size" />
            <div className="flex1-column m-x-r2 m-x-l">
              <div className="asset-details vertical-gap">Vermogen</div>
              <div className="asset-details vertical-gap">Verbruik</div>
              <div className="asset-details vertical-gap">Start</div>
            </div>
            <div className="flex1-column m-x-l">
              <div className="reading-info vertical-gap ">{activePower * 1000} W</div>
              <div className="reading-info vertical-gap">{consumption} kWh</div>
              <div className="flex1-row vertical-gap flex-center-vertical">
                <div className="reading-info ">{moment(props.device.lastReset).format('DD-MM-YYYY')}</div>
                <Button id="reset" type="button" onClick={onReset}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
          <div className="fle1-row flex-center-vertical" style={{ marginTop: '30px' }}>
            <div className="on-off">On\Off</div>
            <label className="switch">
              <input
                type="checkbox"
                checked={!!props.device.lastSetState}
                onChange={toggleUserDeviceActiveState}
              />
              <span className={cn('round', { slider: props.device.lastKnownState === props.device.lastSetState, 'slider-pending-status': props.device.lastKnownState != props.device.lastSetState })} />
            </label>
          </div>
        </div>
        <div className="flex1-row m-y-t flex1-end ">
          <div className="fle1-row flex-center-vertical" onClick={detailsExpand}>
            <strong className="detail-txt">Details</strong>
            {expand && <Image src={blueUpArrow.default} style={{ marginBottom: '2px' }} />}
            {!expand && <Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
          </div>
        </div>
        <hr />
        {expand && (
          <SmartControlllerDetailContainer
            library={props.device}
            addConfiguration={SmartControllerApplicaiton.BOILER}
            expand={expand}
            setExpand={setExpand}
          />
        )}
      </div>
    </>
  )
}
export default Boiler
