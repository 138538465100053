import * as React from 'react'
import { Image, Button } from 'semantic-ui-react'
import { useState } from 'react'
import { GatewayPairing } from '..'
import './register.scss'
import * as SlimmeMeter from '../../assets/Gateway_Slimme.png'
import * as one from '../../assets/one.png'
import * as two from '../../assets/two.png'
import * as three from '../../assets/three.png'
import * as four from '../../assets/four.png'

// Component which dispalys all the instructions need for the gateway aansluiten
const GatewayConnected: React.FC = () => {
  const [gatewayConnected, setGatewayConnected] = useState<boolean>(false)
  const gatewayConnectedComplete = () => {
    setGatewayConnected(true)
  }
  return (
    <>
      {gatewayConnected ? (
        <GatewayPairing />
      ) : (
        <>
          <div className="flex1-center flex1-column m-y-top gateway-container">
            <strong className="flex1-row flex1-center m-left font-medium">Gateway aansluiten</strong>
            <div className="flex1-row flex-center-vertical">
              <div className="slimme-meter-container ">
                <Image src={SlimmeMeter.default} />
              </div>
              <div className="flex1-column gateway-aansluiten-info">
                <div className="flex1-row flex-center-vertical">
                  <Image src={one.default} />
                  <div className="px-left flex1-row flex-center-vertical">
                    <div>Sluit de Gateway met een </div>
                    <pre> </pre>
                    <h3>Ethernetkabel</h3>
                    <pre> </pre>
                    <div>aan op het internet.</div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical">
                  <Image src={two.default} />
                  <div className="px-left flex-center-vertical">
                    <div>Sluit de </div>
                    <pre> </pre>
                    <h3>P1 kabel</h3>
                    <pre> </pre>
                    <div>aan op de gateway en slimme meter.</div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical">
                  <Image src={three.default} />
                  <div className="px-left flex-center-vertical">
                    <div>Sluit de adapter van de gateway aan en steek de </div>
                    <pre> </pre>
                    <h3>stekker in het stopcontact.</h3>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical">
                  <Image src={four.default} />
                  <div className="px-left flex-center-vertical">
                    <div>Neem verpakking met </div> <pre> </pre>
                    <h3>gateway Serial No.</h3> <pre> </pre>
                    <div>mee naar uw laptop of PC.</div>
                  </div>
                </div>
                <div className="flex1-column flex1-start gateway-m-top">
                  <div className="gateway-m-top">Indien u deze stappen hebt doorlopen, druk dan op OK.</div>

                  <Button className="btn-green m-y-top" onClick={gatewayConnectedComplete}>
                    OK
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default GatewayConnected
