import * as React from 'react'
import { Table, Input } from 'semantic-ui-react'

interface IGasProp {
  handleRefChange: Function
  handleRefClick: Function
  gasPriceRefs: any[]
  editPriceRefs: any
  inEditMode: any
}

const Gas: React.FC<IGasProp> = (props: IGasProp) => {
  const handleClickEvent = (id: string, field: string) => {
    props.handleRefClick(id, field)
  }

  const handleGasRefChange = (id: number, field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleRefChange(id, field, event)
  }

  return (
    <div className="source-container">
      <h3>Gas (ex BTW)</h3>
      <hr />
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="4">Name</Table.HeaderCell>
            {props.gasPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return <Table.HeaderCell key={i} width="3">{ref.year}</Table.HeaderCell>
              })}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Fixed fee gas</Table.Cell>
            {props.gasPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return (
                  <Table.Cell key={i} className={
                    props.inEditMode.ids.includes(ref.id) &&
                    'fixedFeePerYear' in props.editPriceRefs[ref.id] ? 'dirty' : ''
                  }>
                    {
                      props.inEditMode.status &&
                      props.inEditMode.ids.includes(ref.id) &&
                      'fixedFeePerYear' in props.editPriceRefs[ref.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={props.editPriceRefs[ref.id].fixedFeePerYear}
                            onChange={handleGasRefChange.bind(this, ref.id, 'fixedFeePerYear')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, ref.id, 'fixedFeePerYear')}
                          >
                            {ref.fixedFeePerYear}
                          </p>
                        )
                    }
                  </Table.Cell>
                )
              })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Commodity gas</Table.Cell>
            {props.gasPriceRefs
              .sort((a, b) => a.year - b.year)
              .map((ref, i) => {
                return (
                  <Table.Cell key={i} className={
                    props.inEditMode.ids.includes(ref.id) &&
                    'tariffRetailPerM3' in props.editPriceRefs[ref.id] ? 'dirty' : ''
                  }>
                    {
                      props.inEditMode.status &&
                      props.inEditMode.ids.includes(ref.id) &&
                      'tariffRetailPerM3' in props.editPriceRefs[ref.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={props.editPriceRefs[ref.id].tariffRetailPerM3}
                            onChange={handleGasRefChange.bind(this, ref.id, 'tariffRetailPerM3')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(this, ref.id, 'tariffRetailPerM3')}
                          >
                            {ref.tariffRetailPerM3}
                          </p>
                        )
                    }
                  </Table.Cell>)
              })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Region(component)</Table.Cell>
            {props.gasPriceRefs.map((ref, i) => {
              return (
                <Table.Cell key={i} className={
                  props.inEditMode.ids.includes(ref.id) &&
                  'tariffRegionComponent' in props.editPriceRefs[ref.id] ? 'dirty' : ''
                }>
                  {
                    props.inEditMode.status &&
                    props.inEditMode.ids.includes(ref.id) &&
                    'tariffRegionComponent' in props.editPriceRefs[ref.id] ? (
                        <Input
                          transparent
                          className="tableInput"
                          type="number"
                          value={props.editPriceRefs[ref.id].tariffRegionComponent}
                          onChange={handleGasRefChange.bind(this, ref.id, 'tariffRegionComponent')}
                        />
                      ) : (
                        <p
                          className="editableElement"
                          onClick={handleClickEvent.bind(this, ref.id, 'tariffRegionComponent')}
                        >
                          {ref.tariffRegionComponent}
                        </p>
                      )
                  }
                </Table.Cell>)
            })}
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

export default Gas
