import * as React from 'react'
import { Table, Input, Dropdown } from 'semantic-ui-react'
import { useContext, useState, useEffect } from 'react'
import AuthContext from '../../../../contexts/auth'
import DsoService from '../../../../services/dso'

interface IElectricityProp {
  gridTariffs: any[]
  years: number[]
  dsos: any[]
  editGridTariffs: any
  inEditMode: any
  handleTariffsChange: Function
  handleTariffsClick: Function
}

const Electricity: React.FC<IElectricityProp> = (props: IElectricityProp) => {
  const dsoService = new DsoService()
  const authContext = useContext(AuthContext)

  const [connTypes, setConnTypes] = useState<any[]>([])

  const fetchConnTypes = async () => {
    const accessToken = authContext?.accessToken!
    const result = await dsoService.getGridConnectionTypes(accessToken)
    setConnTypes(result)
  }

  const handleClickEvent = (ids: number[], field: string, value: number) => {
    props.handleTariffsClick(ids, field, value)
  }

  const handleTariffChange = (ids: number[], field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleTariffsChange(ids, field, event.target.value)
  }

  const handleSelectChange = (ids: number, field: string, _event: any, data: any) => {
    props.handleTariffsChange(ids, field, data.value)
  }

  useEffect(() => {
    fetchConnTypes()
  }, [])

  return (
    <div className="dso-container">
      {props.gridTariffs.length > 0 && (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="3">Name</Table.HeaderCell>
              <Table.HeaderCell width="2">Size</Table.HeaderCell>
              {props.years.map((year, i) => {
                return <Table.HeaderCell key={i} width="2">{year}</Table.HeaderCell>
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.gridTariffs.map((row: any, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell className={
                    row.size.ids.every((id: number) => {
                      return props.inEditMode.ids.includes(id)
                    }) &&
                      row.size.ids.every((id: number) => {
                        return 'dsoId' in props.editGridTariffs[id]
                      }) ? 'dirty' : ''
                  }>
                    {
                      props.inEditMode.status &&
                      Object.keys(props.editGridTariffs).length > 0 &&
                      row.size.ids.every((id: number) => {
                        return props.inEditMode.ids.includes(id) &&
                          id in props.editGridTariffs &&
                          'dsoId' in props.editGridTariffs[id]
                      }) ? (
                          <Dropdown
                            selection
                            options={props.dsos}
                            className="tableInput"
                            value={props.editGridTariffs[row.size.ids[0]].dsoId}
                            onChange={handleSelectChange.bind(this, row.size.ids, 'dsoId')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(
                              this,
                              row.size.ids,
                              'dsoId',
                              row.name.id
                            )}
                          >{row.name.name}</p>
                        )
                    }
                  </Table.Cell>
                  <Table.Cell className={
                    row.size.ids.every((id: number) => {
                      return props.inEditMode.ids.includes(id)
                    }) &&
                      row.size.ids.every((id: number) => {
                        return id in props.editGridTariffs &&
                          'gridConnectionTypeId' in props.editGridTariffs[id]
                      }) ? 'dirty' : ''
                  }>
                    {
                      props.inEditMode.status &&
                      Object.keys(props.editGridTariffs).length > 0 &&
                      row.size.ids.every((id: number) => {
                        return props.inEditMode.ids.includes(id) &&
                          id in props.editGridTariffs &&
                          'gridConnectionTypeId' in props.editGridTariffs[id]
                      }) ? (
                          <Dropdown
                            selection
                            options={connTypes}
                            className="tableInput"
                            value={props.editGridTariffs[row.size.ids[0]].gridConnectionTypeId}
                            onChange={handleSelectChange.bind(this, row.size.ids, 'gridConnectionTypeId')}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(
                              this,
                              row.size.ids,
                              'gridConnectionTypeId',
                              row.size.id
                            )}
                          >{row.size.name}</p>
                        )
                    }
                  </Table.Cell>
                  {props.years.map((year: number, i) => {
                    return (
                      <Table.Cell key={i} className={
                        row.years[year] !== undefined &&
                        row.years[year].ids.every((id: number) => {
                          return props.inEditMode.ids.includes(id)
                        }) &&
                          row.years[year].ids.every((id: number) => {
                            return id in props.editGridTariffs &&
                              'fixedTariffPerYear' in props.editGridTariffs[id]
                          }) ? 'dirty' : ''
                      }>
                        {year in row.years ? (
                          props.inEditMode.status &&
                            props.inEditMode.ids.includes(row.years[year].ids[0]) &&
                            'fixedTariffPerYear' in props.editGridTariffs[row.years[year].ids[0]] ? (
                              <Input
                                transparent
                                className="tableInput"
                                type="number"
                                value={props.editGridTariffs[row.years[year].ids[0]].fixedTariffPerYear}
                                onChange={handleTariffChange.bind(this, row.years[year].ids, 'fixedTariffPerYear')}
                              />
                            ) : (
                              <p
                                className="editableElement"
                                onClick={
                                  handleClickEvent.bind(
                                    this,
                                    row.years[year].ids,
                                    'fixedTariffPerYear',
                                    row.years[year].value
                                  )}
                              >
                                {parseFloat(row.years[year].value.toFixed(4))}
                              </p>
                            )
                        ) : (
                          <p></p>
                        )}
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      )}
    </div>
  )
}

export default Electricity
