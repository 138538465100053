import * as React from 'react'
import { useState, useEffect, useContext } from 'react'
import * as deleteassets from '../../../assets/delete.png'
import { Confirm, Input, Divider, Dimmer, Image, Message } from 'semantic-ui-react'
import DataContext from '../../../contexts/data'
import * as helper from '../../../util/helper'
import { IHybridInverter, IMainMeter, IUserDevice } from '../../../interfaces/device'
import * as searchIcon from '../../../assets/search.png'
import * as loadingSymbol from '../../../assets/loading.png'
import * as constants from '../../../constants'
import * as deviceService from '~/services/device'
import UserContext from '../../../contexts/user'
import AuthContext from '~/contexts/auth'

const ActiveDevice: React.FC = () => {
  const dataContext = useContext(DataContext)
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext)

  const [open, setOpen] = useState(false)
  const [userDeviceToDelete, setUserDeviceToDelete] = useState<IUserDevice | null>(null)
  const [searchValue, setSearchValue] = useState('')
  const [allUserDevices, setAllUserDevices] = useState<IUserDevice[]>([])

  const [isRemovingDevice, setIsRemovingDevice] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  setTimeout(() => {
    setErrorMessage('')
  }, 5000)

  const openModal = (userDevice: IUserDevice) => {
    setUserDeviceToDelete(userDevice)
    setOpen(true)
  }

  const close = () => setOpen(false)

  const handleRemoveItem = async () => {
    try {
      if (userDeviceToDelete) {
        setIsRemovingDevice(true)
        switch (userDeviceToDelete.deviceModel.groupLabel) {
          case constants.SmartMeterDeviceGroupLabel:
            await deviceService.removeMainMeterDevice(authContext?.accessToken!, userDeviceToDelete.deviceId)
            break
          case constants.HybridInverterDeviceGroupLabel:
            await deviceService.removeHybridInverterDevice(authContext?.accessToken!, userDeviceToDelete.deviceId)
            break
          case constants.SmartControllerDeviceGroupLabel:
            await deviceService.removeSmartControllerDevice(authContext?.accessToken!, userDeviceToDelete.deviceId)
            break
          default:
            break
        }
      }
      close()
    } catch (err) {
      setIsRemovingDevice(false)
      setErrorMessage('Unable to remove device') // TODO useful error message
    }
  }

  useEffect(() => {
    setAllUserDevices([
      ...dataContext?.device.userMainMeterDevices!,
      ...dataContext?.device.userHybridInverterDevices!,
      ...dataContext?.device.userSmartControllerDevices!
    ])
  }, [
    dataContext?.device.userMainMeterDevices,
    dataContext?.device.userHybridInverterDevices,
    dataContext?.device.userSmartControllerDevices
  ])

  useEffect(() => {
    userContext?.socket?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      const eventType = data.eventType
      if (eventType === constants.RemoveDeviceResponse || eventType === constants.RemoveSmartControllerDeviceResponse) {
        if (data.status === 'success') {
          setUserDeviceToDelete(null)
          setIsRemovingDevice(false)
        } else {
          console.log('data.message delete', data.message)
          setErrorMessage(data.message)
          setIsRemovingDevice(false)
        }
      }
    })
  }, [])

  /** Get device from the user device */
  const getDeviceForUserDevice = (userDevice: IUserDevice): IMainMeter | IHybridInverter => {
    let device: IMainMeter | IHybridInverter | undefined
    if (userDevice.deviceModel.groupLabel === constants.SmartMeterDeviceGroupLabel) {
      device = dataContext?.device.mainMeters.find((d) => d.label === userDevice.deviceModel.label)
    } else if (userDevice.deviceModel.groupLabel === constants.HybridInverterDeviceGroupLabel) {
      device = dataContext?.device.hybridInverters.find((d) => d.label === userDevice.deviceModel.label)
    } else if (userDevice.deviceModel.groupLabel === constants.SmartControllerDeviceGroupLabel) {
      device = dataContext?.device.smartControllers.find((d) => d.label === userDevice.deviceModel.label)
    }
    return device!
  }

  const toggleUserDeviceActiveState = async (userDevice: IUserDevice) => {
    try {
      if (userDevice.deviceModel.groupLabel === constants.HybridInverterDeviceGroupLabel) {
        if (userDevice.isActive) {
          await dataContext?.device.deactivateHybridInverterDevice(userDevice.deviceId)
        } else {
          await dataContext?.device.activateHybridInverterDevice(userDevice.deviceId)
        }
      }
      if (userDevice.deviceModel.groupLabel === constants.SmartControllerDeviceGroupLabel) {
        await dataContext?.device.toggleSmartControllerDevice(userDevice.deviceId)
      }
    } catch (err) {
      // TODO display error
    }

    // await dataContext?.device.toggleUserDevice(userDevice.deviceId)
  }
  const onChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  return (
    <Dimmer.Dimmable blurring dimmed={isRemovingDevice}>
      <Dimmer active={isRemovingDevice}>
        <Image src={loadingSymbol.default} className="rotate" />
        apparaat toevoegen even geduld...
      </Dimmer>
      <div className="device-gird-size device-m-x-l">
        <div className="flex1-row space-between flex-center-vertical ">
          <strong className="title">Mijn apparaten</strong>
        </div>
        <Divider />
        <div className="flex1-row flex-center-vertical space-between">
          <div className="search-box-container">
            <Input placeholder="Zoek apparaat" value={searchValue} onChange={onChange} id="search-input" fluid></Input>
          </div>
          <img src={searchIcon.default} className="search-box"></img>
        </div>
        <div className="flex1-row space-between">
          <div className="column1"></div>
          <div className="column2"></div>
          <div className="column3">Actief/Inactief</div>
          <div className="column4">Verwijder</div>
        </div>
        {allUserDevices?.map((userDevice) => {
          if (
            userDevice.deviceLibrary.application === helper.deviceDescriptionAssetApplicationMapping.smart_meter.smart_meter_english
          ) {
            return (
              <div key={userDevice.deviceModel.label} className="flex1-column">
                <div className="flex1-row space-between flex-center-vertical">

                  <div className="flex1-row flex-center-vertical column1">
                    <img src={helper.deviceDescriptionAssetApplicationMapping.smart_meter.smart_meter_image} className="device-icon-size"></img>
                  </div>
                  <div className="flex1-column column2">
                    <strong className="description flex1-row flex1-start">
                      {helper.deviceDescriptionAssetApplicationMapping.smart_meter.smart_meter_dutch}
                    </strong>
                    {userDevice.deviceLibrary.make && !userDevice.deviceModel.model &&
                    <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                      {userDevice.deviceModel.make}
                    </div>
                    }
                    {userDevice.deviceModel.make && userDevice.deviceModel.model &&
                    <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                      {userDevice.deviceModel.make}&nbsp;{userDevice.deviceModel.model}
                    </div>
                    }
                  </div>
                  <div className="flex1-row column3 flex1-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDevice.isActive}
                        onChange={() => toggleUserDeviceActiveState(userDevice)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {device?.groupLabel !== constants.SmartMeterDeviceGroupLabel ? (
                    <div className="column4" onClick={() => openModal(userDevice)}>
                      <img className="delete-device" src={deleteassets.default}></img>
                    </div>
                  ) : (
                    <div className="column4">
                      <img className="delete-device deleteunavailable" src={deleteassets.default}></img>
                    </div>
                  )}
                  {open && <Confirm open={open} onCancel={close} onConfirm={handleRemoveItem} />}
                </div>
                <Divider />
              </div>
            )
          } else if (
            userDevice.deviceLibrary.application === helper.deviceDescriptionAssetApplicationMapping.boiler.boiler_english
          ) {
            return (
              <div key={userDevice.deviceModel.label} className="flex1-column">
                <div className="flex1-row space-between flex-center-vertical">

                  <div className="flex1-row flex-center-vertical column1">
                    <img src={helper.deviceDescriptionAssetApplicationMapping.boiler.boiler_image} className="device-icon-size"></img>
                  </div>
                  <div className="flex1-column column2">
                    <strong className="description flex1-row flex1-start">
                      {userDevice.applianceName ? helper.capitalize(userDevice.applianceName) : helper.deviceDescriptionAssetApplicationMapping.boiler.boiler_dutch}
                    </strong>
                    {userDevice.deviceLibrary.make && !userDevice.deviceModel.model &&
                          <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                            {userDevice.deviceModel.make}
                          </div>
                    }
                    {userDevice.deviceModel.make && userDevice.deviceModel.model &&
                          <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                            {userDevice.deviceModel.make}&nbsp;{userDevice.deviceModel.model}
                          </div>
                    }
                  </div>
                  <div className="flex1-row column3 flex1-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDevice.isActive}
                        onChange={() => toggleUserDeviceActiveState(userDevice)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {device?.groupLabel !== constants.SmartMeterDeviceGroupLabel ? (
                    <div className="column4" onClick={() => openModal(userDevice)}>
                      <img className="delete-device" src={deleteassets.default}></img>
                    </div>
                  ) : (
                    <div className="column4">
                      <img className="delete-device deleteunavailable" src={deleteassets.default}></img>
                    </div>
                  )}
                  {open && <Confirm open={open} onCancel={close} onConfirm={handleRemoveItem} />}
                </div>
                <Divider />
              </div>
            )
          } else if (
            userDevice.deviceLibrary.application === helper.deviceDescriptionAssetApplicationMapping.electricity_meter_small_consumer
              .electricity_meter_small_consumer_english
          ) {
            return (
              <div key={userDevice.deviceModel.label} className="flex1-column">
                <div className="flex1-row space-between flex-center-vertical">

                  <div className="flex1-row flex-center-vertical column1">
                    <img src={ helper.deviceDescriptionAssetApplicationMapping.electricity_meter_small_consumer
                      .electricity_meter_small_consumer_image} className="device-icon-size"></img>
                  </div>
                  <div className="flex1-column column2">
                    <strong className="description flex1-row flex1-start">
                      { helper.deviceDescriptionAssetApplicationMapping.electricity_meter_small_consumer
                        .electricity_meter_small_consumer_dutch}
                    </strong>
                    {userDevice.deviceLibrary.make && !userDevice.deviceModel.model &&
                                <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                  {userDevice.deviceModel.make}
                                </div>
                    }
                    {userDevice.deviceModel.make && userDevice.deviceModel.model &&
                                <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                  {userDevice.deviceModel.make}&nbsp;{userDevice.deviceModel.model}
                                </div>
                    }
                  </div>
                  <div className="flex1-row column3 flex1-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDevice.isActive}
                        onChange={() => toggleUserDeviceActiveState(userDevice)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {device?.groupLabel !== constants.SmartMeterDeviceGroupLabel ? (
                    <div className="column4" onClick={() => openModal(userDevice)}>
                      <img className="delete-device" src={deleteassets.default}></img>
                    </div>
                  ) : (
                    <div className="column4">
                      <img className="delete-device deleteunavailable" src={deleteassets.default}></img>
                    </div>
                  )}
                  {open && <Confirm open={open} onCancel={close} onConfirm={handleRemoveItem} />}
                </div>
                <Divider />
              </div>
            )
          } else if (
            userDevice.deviceLibrary.application === helper.deviceDescriptionAssetApplicationMapping.electricity_meter_large_consumer
              .electricity_meter_large_consumer_english
          ) {
            return (
              <div key={userDevice.deviceModel.label} className="flex1-column">
                <div className="flex1-row space-between flex-center-vertical">

                  <div className="flex1-row flex-center-vertical column1">
                    <img src={helper.deviceDescriptionAssetApplicationMapping.electricity_meter_large_consumer
                      .electricity_meter_large_consumer_image} className="device-icon-size"></img>
                  </div>
                  <div className="flex1-column column2">
                    <strong className="description flex1-row flex1-start">
                      {helper.deviceDescriptionAssetApplicationMapping.electricity_meter_large_consumer
                        .electricity_meter_large_consumer_dutch}
                    </strong>
                    {userDevice.deviceLibrary.make && !userDevice.deviceModel.model &&
                                      <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                        {userDevice.deviceModel.make}
                                      </div>
                    }
                    {userDevice.deviceModel.make && userDevice.deviceModel.model &&
                                      <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                        {userDevice.deviceModel.make}&nbsp;{userDevice.deviceModel.model}
                                      </div>
                    }
                  </div>
                  <div className="flex1-row column3 flex1-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDevice.isActive}
                        onChange={() => toggleUserDeviceActiveState(userDevice)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {device?.groupLabel !== constants.SmartMeterDeviceGroupLabel ? (
                    <div className="column4" onClick={() => openModal(userDevice)}>
                      <img className="delete-device" src={deleteassets.default}></img>
                    </div>
                  ) : (
                    <div className="column4">
                      <img className="delete-device deleteunavailable" src={deleteassets.default}></img>
                    </div>
                  )}
                  {open && <Confirm open={open} onCancel={close} onConfirm={handleRemoveItem} />}
                </div>
                <Divider />
              </div>
            )
          } else if (
            userDevice.deviceLibrary.application === helper.deviceDescriptionAssetApplicationMapping.smart_gas_meter_consumer
              .smart_gas_meter_consumer_english
          ) {
            return (
              <div key={userDevice.deviceModel.label} className="flex1-column">
                <div className="flex1-row space-between flex-center-vertical">

                  <div className="flex1-row flex-center-vertical column1">
                    <img src={helper.deviceDescriptionAssetApplicationMapping.smart_gas_meter_consumer
                      .smart_gas_meter_consumer_image} className="device-icon-size"></img>
                  </div>
                  <div className="flex1-column column2">
                    <strong className="description flex1-row flex1-start">
                      { helper.deviceDescriptionAssetApplicationMapping.smart_gas_meter_consumer
                        .smart_gas_meter_consumer_dutch}
                    </strong>
                    {userDevice.deviceLibrary.make && !userDevice.deviceModel.model &&
                                            <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                              {userDevice.deviceModel.make}
                                            </div>
                    }
                    {userDevice.deviceModel.make && userDevice.deviceModel.model &&
                                            <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                              {userDevice.deviceModel.make}&nbsp;{userDevice.deviceModel.model}
                                            </div>
                    }
                  </div>
                  <div className="flex1-row column3 flex1-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDevice.isActive}
                        onChange={() => toggleUserDeviceActiveState(userDevice)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {device?.groupLabel !== constants.SmartMeterDeviceGroupLabel ? (
                    <div className="column4" onClick={() => openModal(userDevice)}>
                      <img className="delete-device" src={deleteassets.default}></img>
                    </div>
                  ) : (
                    <div className="column4">
                      <img className="delete-device deleteunavailable" src={deleteassets.default}></img>
                    </div>
                  )}
                  {open && <Confirm open={open} onCancel={close} onConfirm={handleRemoveItem} />}
                </div>
                <Divider />
              </div>
            )
          } else if (
            userDevice.deviceLibrary.application === helper.deviceDescriptionAssetApplicationMapping.space_heater.space_heater_english
          ) {
            return (
              <div key={userDevice.deviceModel.label} className="flex1-column">
                <div className="flex1-row space-between flex-center-vertical">

                  <div className="flex1-row flex-center-vertical column1">
                    <img src={helper.deviceDescriptionAssetApplicationMapping.space_heater.space_heater_image} className="device-icon-size"></img>
                  </div>
                  <div className="flex1-column column2">
                    <strong className="description flex1-row flex1-start">
                      {userDevice.applianceName ? helper.capitalize(userDevice.applianceName) : helper.deviceDescriptionAssetApplicationMapping.space_heater.space_heater_dutch}
                    </strong>
                    {userDevice.deviceLibrary.make && !userDevice.deviceModel.model &&
                                                  <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                                    {userDevice.deviceModel.make}
                                                  </div>
                    }
                    {userDevice.deviceModel.make && userDevice.deviceModel.model &&
                                                  <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                                    {userDevice.deviceModel.make}&nbsp;{userDevice.deviceModel.model}
                                                  </div>
                    }
                  </div>
                  <div className="flex1-row column3 flex1-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDevice.isActive}
                        onChange={() => toggleUserDeviceActiveState(userDevice)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {device?.groupLabel !== constants.SmartMeterDeviceGroupLabel ? (
                    <div className="column4" onClick={() => openModal(userDevice)}>
                      <img className="delete-device" src={deleteassets.default}></img>
                    </div>
                  ) : (
                    <div className="column4">
                      <img className="delete-device deleteunavailable" src={deleteassets.default}></img>
                    </div>
                  )}
                  {open && <Confirm open={open} onCancel={close} onConfirm={handleRemoveItem} />}
                </div>
                <Divider />
              </div>
            )
          } else if (
            userDevice.deviceLibrary.application === helper.deviceDescriptionAssetApplicationMapping.generic.generic_english
          ) {
            return (
              <div key={userDevice.deviceModel.label} className="flex1-column">
                <div className="flex1-row space-between flex-center-vertical">

                  <div className="flex1-row flex-center-vertical column1">
                    <img src={helper.deviceDescriptionAssetApplicationMapping.generic.generic_image} className="device-icon-size"></img>
                  </div>
                  <div className="flex1-column column2">
                    <strong className="description flex1-row flex1-start">
                      {userDevice.applianceName ? helper.capitalize(userDevice.applianceName) : helper.deviceDescriptionAssetApplicationMapping.generic.generic_dutch}
                    </strong>
                    {userDevice.deviceLibrary.make && !userDevice.deviceModel.model &&
                                        <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                          {userDevice.deviceModel.make}
                                        </div>
                    }
                    {userDevice.deviceModel.make && userDevice.deviceModel.model &&
                                        <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                          {userDevice.deviceModel.make}&nbsp;{userDevice.deviceModel.model}
                                        </div>
                    }
                  </div>
                  <div className="flex1-row column3 flex1-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDevice.isActive}
                        onChange={() => toggleUserDeviceActiveState(userDevice)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {device?.groupLabel !== constants.SmartMeterDeviceGroupLabel ? (
                    <div className="column4" onClick={() => openModal(userDevice)}>
                      <img className="delete-device" src={deleteassets.default}></img>
                    </div>
                  ) : (
                    <div className="column4">
                      <img className="delete-device deleteunavailable" src={deleteassets.default}></img>
                    </div>
                  )}
                  {open && <Confirm open={open} onCancel={close} onConfirm={handleRemoveItem} />}
                </div>
                <Divider />
              </div>
            )
          } else if (
            userDevice.deviceLibrary.application === helper.deviceDescriptionAssetApplicationMapping.hybrid_inverter.hybrid_inverter_english
          ) {
            return (
              <div key={userDevice.deviceModel.label} className="flex1-column">
                <div className="flex1-row space-between flex-center-vertical">

                  <div className="flex1-row flex-center-vertical column1">
                    <img src={helper.deviceDescriptionAssetApplicationMapping.hybrid_inverter.hybrid_inverter_image} className="device-icon-size"></img>
                  </div>
                  <div className="flex1-column column2">
                    <strong className="description flex1-row flex1-start">
                      {helper.deviceDescriptionAssetApplicationMapping.hybrid_inverter.hybrid_inverter_dutch}
                    </strong>
                    {userDevice.deviceLibrary.make && !userDevice.deviceModel.model &&
                                              <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                                {userDevice.deviceModel.make}
                                              </div>
                    }
                    {userDevice.deviceModel.make && userDevice.deviceModel.model &&
                                              <div className="flex1-row flex1-start" style={{ fontSize: '14px' }}>
                                                {userDevice.deviceModel.make}&nbsp;{userDevice.deviceModel.model}
                                              </div>
                    }
                  </div>
                  <div className="flex1-row column3 flex1-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={userDevice.isActive}
                        onChange={() => toggleUserDeviceActiveState(userDevice)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  {device?.groupLabel !== constants.SmartMeterDeviceGroupLabel ? (
                    <div className="column4" onClick={() => openModal(userDevice)}>
                      <img className="delete-device" src={deleteassets.default}></img>
                    </div>
                  ) : (
                    <div className="column4">
                      <img className="delete-device deleteunavailable" src={deleteassets.default}></img>
                    </div>
                  )}
                  {open && <Confirm open={open} onCancel={close} onConfirm={handleRemoveItem} />}
                </div>
                <Divider />
              </div>
            )
          }
        })}
        {errorMessage && errorMessage !== '' && <Message color="red">{errorMessage}</Message>}
      </div>
    </Dimmer.Dimmable>
  )
}
export default ActiveDevice
