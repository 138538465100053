import * as React from 'react'
import { Grid, Table, Container, Form, Button, Divider, Dimmer, Image, Message } from 'semantic-ui-react'
import AuthContext from '../../contexts/auth'
import {
  ReportingService,
  AggregatedDateReportData,
  electricityConsumptionSum,
  AggregatedDateReportTimeSeriesData
} from '../../services/reporting'
import { ReportConnectionRow, ReportHeader } from './generic'
import * as loadingSymbol from '../../assets/loading.png'
import { TimeSeriesGraph } from './graphs'
import UserContext from '../../contexts/user'
import { useContext } from 'react'
import {
  electricityImportColor,
  electricityExportPowerColor,
  gasImportColor,
  sumElectricityImportColor,
  batteryChargeColor,
  solarProductionColor,
  totalEnergyConsumption,
  graph2
} from '../../util/graph_data'
const moment = require('moment')

const DayReport: React.FC = () => {
  const [reportingData, setReportingData] = React.useState<AggregatedDateReportData | undefined>()
  const [isPrintMode, setIsPrintMode] = React.useState<boolean>(false)
  const reportElement = React.useRef<HTMLDivElement>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [defaultStartDate, setDefaultStartDate] = React.useState('')
  const [defaultEndDate, setDefaultEndDate] = React.useState('')
  const [inValidDate, setInvalidDate] = React.useState(false)
  const reportingService = new ReportingService()
  const userContext = useContext(UserContext)
  const authContext = React.useContext(AuthContext)
  const [daysRequested, setDaysRequested] = React.useState<number | undefined>()
  const date1 = new Date(defaultStartDate)
  const date2 = new Date(defaultEndDate)
  const diffTime: number = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  const fetchData = async () => {
    const token = authContext?.accessToken!
    if (diffDays > 6) {
      setInvalidDate(true)
    }
    const result = await reportingService.getAggregatedReportData(token, 'day', defaultStartDate, defaultEndDate)
    setReportingData(result)
  }
  React.useEffect(() => {
    fetchData()
  }, [])
  const handleStartDate = (e: any) => {
    setDefaultStartDate(e.target.value)
  }

  const handleEndDate = (e: any) => {
    setDefaultEndDate(e.target.value)
  }
  React.useEffect(() => {
    if (diffDays < 3) {
      setDaysRequested(diffDays)
    }
  })

  const onBeforePrint = () => {
    setIsPrintMode(true)
  }

  const onAfterPrint = () => {
    setIsPrintMode(false)
  }

  React.useEffect(() => {
    if (!defaultStartDate && !defaultEndDate && reportingData && reportingData.totals) {
      for (let i = 0; i < reportingData.totals.length; i++) {
        if (i === 0) {
          setDefaultStartDate(moment(reportingData.totals[i].date).format('YYYY-MM-DD'))
        }
        if (i === reportingData.totals.length - 1) {
          setDefaultEndDate(moment(reportingData.totals[i].date).format('YYYY-MM-DD'))
        }
      }
    }
  }, [reportingData])
  React.useEffect(() => {
    if (reportingData?.totals && userContext?.profile) {
      setLoading(false)
    }
  })
  setTimeout(() => {
    setInvalidDate(false)
  }, 9000)
  return (
    <>
      <div className="Loader" style={{ display: loading ? 'block' : 'none' }}>
        <Dimmer active>
          <Image src={loadingSymbol.default} className="rotate" />
        </Dimmer>
      </div>
      <Container className="report-workspace" id="day-report-container" style={{ display: loading ? 'none' : 'block' }}>
        <div className="report-container pdf-page" ref={reportElement}>
          <ReportHeader
            title="DAG"
            elementRef={reportElement.current}
            onBeforePrint={onBeforePrint}
            onAfterPrint={onAfterPrint}
          />
          <ReportConnectionRow></ReportConnectionRow>
          {inValidDate && <Message color="red">Selecteer de datum binnen zeven dagen.</Message>}
          <Grid>
            {/* Date selection */}
            <Grid.Row>
              <Grid.Column style={{ paddingLeft: '10px' }} width="3">
                <div className="flex1-column">
                  <strong className="date-selection"> Startdatum </strong>
                  <input type="date" className="startDate" onChange={handleStartDate} defaultValue={defaultStartDate} />
                </div>
              </Grid.Column>
              <Grid.Column width="3">
                <div className="flex1-column">
                  <strong className="date-selection">Einddatum</strong>
                  <input type="date" className="endDate" onChange={handleEndDate} defaultValue={defaultEndDate} />
                </div>
              </Grid.Column>
              {!isPrintMode && (
                <div className="flex1-row flex-center-vertical m-y-medium">
                  <Button className="generate-report" content="Genereer rapport" onClick={fetchData} />
                </div>
              )}
            </Grid.Row>
            {/* Data table */}
            <Grid.Row style={{ paddingTop: '10px' }}>
              <Grid.Column style={{ paddingLeft: '10px' }}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                      <Table.HeaderCell>Elektriciteit consumptie uit het net</Table.HeaderCell>
                      {/* TODO: Check translation. */}
                      <Table.HeaderCell>Elektriciteit teruglevering</Table.HeaderCell>
                      <Table.HeaderCell>Opbrengst zonnepanelen</Table.HeaderCell>
                      <Table.HeaderCell>Totaal elektriciteit consumptie</Table.HeaderCell>
                      <Table.HeaderCell>Piek consumptie</Table.HeaderCell>
                      <Table.HeaderCell>Piek teruglevering</Table.HeaderCell>
                      <Table.HeaderCell>Gas consumptie</Table.HeaderCell>
                      <Table.HeaderCell>Totale energie consumptie</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {reportingData &&
                      reportingData.totals &&
                      reportingData.totals.map(function (item, index) {
                        return (
                          <Table.Row key={index}>
                            <Table.Cell className="report-content" width="2">
                              {moment(item.date).format('DD-MM-YYYY')}
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {item.electricityImport.toFixed(2).replace('.', ',')} kWh
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {item.electricityExport.toFixed(2).replace('.', ',')} kWh
                            </Table.Cell>
                            <Table.Cell className="report-content" width="2" textAlign="center">
                              {item.solarGeneration.toFixed(2).replace('.', ',')} kWh
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {electricityConsumptionSum(item).toFixed(2).replace('.', ',')} kWh
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {item.peakElectricityImport.toFixed(2).replace('.', ',')} kW
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {item.peakElectricityExport.toFixed(2).replace('.', ',')} kW
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {item.gasImport?.toFixed(2).replace('.', ',') || '0,0'} m³
                            </Table.Cell>
                            <Table.Cell className="report-content" textAlign="center">
                              {(electricityConsumptionSum(item) + (item.gasImport || 0) * 9.769)
                                .toFixed(2)
                                .replace('.', ',')}{' '}
                              kWh
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            {reportingData && reportingData.electricityImportTimeseries.length > 0 && (
              <Grid.Row className="page-together" style={{ marginTop: '10px' }}>
                <Grid.Column className="graph-container">
                  <Grid.Row style={{ paddingTop: '10px' }}>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                       Elektriciteitsconsumptie uit het net (kWh per 15 minuten)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesGraph
                        timeSeriesData={reportingData.electricityImportTimeseries}
                        fillColor={electricityExportPowerColor}
                        title={'kW'}
                        daysRequested={daysRequested}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
            {reportingData && reportingData.electricityExportTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                        Teruglevering aan het elektriciteitsnet (kWh per 15 minuten)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesGraph
                        timeSeriesData={reportingData.electricityExportTimeseries}
                        title={'kW'}
                        fillColor={graph2}
                        daysRequested={daysRequested}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <Grid className="page-together">
            {reportingData && reportingData.solarGenerationTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                        Opbrengst zonnepanelen (kWh per 15 minuten)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesGraph
                        timeSeriesData={reportingData.solarGenerationTimeseries}
                        title={'kW'}
                        fillColor={solarProductionColor}
                        daysRequested={daysRequested}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
            {reportingData && reportingData.electricityConsumptionTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                        Totaal elektriciteit consumptie (kWh per 15 minuten)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesGraph
                        timeSeriesData={reportingData.electricityConsumptionTimeseries}
                        fillColor={sumElectricityImportColor}
                        title={'kW'}
                        daysRequested={daysRequested}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
            {reportingData && reportingData.batteryChargeTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Batterijverbruik (%)</strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesGraph
                        timeSeriesData={reportingData.batteryChargeTimeseries}
                        title={'%'}
                        fillColor={batteryChargeColor}
                        daysRequested={daysRequested}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <Grid className="page-together">
            {reportingData && reportingData.gasImportTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>Gasconsumptie (m³ per uur)</strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesGraph
                        timeSeriesData={reportingData.gasImportTimeseries}
                        fillColor={gasImportColor}
                        title={'m³ per uur'}
                        daysRequested={daysRequested}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Divider />
                </Grid.Column>
              </Grid.Row>
            )}
            {reportingData && reportingData.energyConsumptionTimeseries.length > 0 && (
              <Grid.Row>
                <Grid.Column className="graph-container">
                  <Grid.Row>
                    <Grid.Column>
                      <strong style={{ color: '#4d4d4d', fontSize: '14px' }}>
                        Totaal energieverbruik (elektriciteit + gas)
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <TimeSeriesGraph
                        timeSeriesData={reportingData.energyConsumptionTimeseries}
                        title={'kW'}
                        fillColor={totalEnergyConsumption}
                        daysRequested={daysRequested}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
          <Divider></Divider>
          <div className="flex1-row flex1-end m-y-t">©2021 Lyv B.V. Onder voorbehoud van fouten.</div>
        </div>
      </Container>
    </>
  )
}
export default DayReport
