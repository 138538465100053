import * as React from 'react'
import { Tab } from 'semantic-ui-react'
import { Day, Month, Year, Financial, Date } from '..'
import './report.scss'

const Reports: React.FC = () => {
  const panes = [
    {
      menuItem: 'Dag',
      render: () => (
        <Tab.Pane>
          <Day />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Maand',
      render: () => (
        <Tab.Pane>
          <Month />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Jaar',
      render: () => (
        <Tab.Pane>
          <Year />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Financieel',
      render: () => (
        <Tab.Pane>
          <Financial />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        id: 'Date',
        content: <Date />,
        position: 'right'
      }
    }
  ]
  return (
    <>
      <Tab panes={panes} className="home-menu-container" />
    </>
  )
}
export default Reports
