import * as React from 'react'
import { Image } from 'semantic-ui-react'
import { RouteComponentProps, withRouter } from 'react-router'
import * as PropTypes from 'prop-types'
import * as loading from '../../assets/loading.png'
import './register.scss'

type SomeComponentProps = RouteComponentProps
/* Component to display message that customer details has been filled
and they will redirected to Home - Monitor page */
const CustomerFormComplete: React.FC<SomeComponentProps> = ({ history }) => {
  setTimeout(() => {
    history.push('/home')
  }, 5000)

  return (
    <>
      <div className="flex1-center flex1-column py-top">
        <strong className="font-medium m-bottom">Uw adresgegevens zijn ingevoerd</strong>
        <div className="flex1-column flex1-start">U wordt nu doorgeleid naar uw persoonlijke monitoring pagina.</div>
        <div className="m-y-top">
          <Image src={loading.default} className="rotate" />
        </div>
      </div>
    </>
  )
}
CustomerFormComplete.propTypes = {
  history: PropTypes.any.isRequired
}
export default withRouter(CustomerFormComplete)
