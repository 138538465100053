import * as React from 'react'
import { Tab } from 'semantic-ui-react'
import UsersList from '../../shared/user/UsersList'
import { Date } from '../..'
import NewUser from '../../shared/user/NewUser'
import Page from '../../../pages/Page'

const UsersComponent: React.FC = () => {
  const panes = [
    {
      menuItem: {
        content: 'New User',
        id: 'addNewUser'
      },
      render: () => (
        <Tab.Pane>
          <NewUser community={() => {}} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        content: 'All Users',
        id: 'allUser'
      },
      render: () => (
        <Tab.Pane>
          <UsersList community={() => {}} />
        </Tab.Pane>
      )
    },
    {
      menuItem: {
        content: <Date />,
        id: 'Date',
        position: 'right'
      },
      render: () => (
        <Tab.Pane>
          <NewUser community={() => {}} />
        </Tab.Pane>
      )
    }
  ]
  return (
    <>
      <Page>
        <Tab panes={panes} className="home-menu-container" />
      </Page>
    </>
  )
}

export default UsersComponent
