import * as React from 'react'
import { Image } from 'semantic-ui-react'
import * as loading from '../../../assets/loading.png'

interface ILoadingProps{
  title?: string
  subtitle?: string
  content?: string
}

const Loader = (props: ILoadingProps) => (
  <div className="mail-verified-container flex1-column flex1-start  m-y-top">
    <div className="flex1-column flex1-center">
      <h5>{props.title}</h5>
      <div>{props.subtitle}</div>
      <div>{props.content}</div>
      <div>
        <Image src={loading.default} className="rotate" />
      </div>
    </div>
  </div>
)

export default Loader
