import * as React from 'react'
import { Button, Menu } from 'semantic-ui-react'

const Reference: React.FC = () => {
  return (
    <>
      <div className="admin-container">
        <div>
          <Menu secondary size="small">
            <Menu.Item position="right">
              <Button className="btn-green">Modify</Button>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </>
  )
}

export default Reference
