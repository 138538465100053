import './new-device-group.scss'
import './device-form.scss'
import * as React from 'react'
import { useState, useEffect, useContext } from 'react'
import { InlineTextInputField } from '../../Generic/InlineField'
import { Form, Button, Message } from 'semantic-ui-react'
import {
  IMainMeter,
  IHybridInverter,
  ISerialProtocol,
  IModbusRTUOverTCPProtocol,
  IModbusRTUProtocol,
  IModbusTCPProtocol,
  IZigbeeProtocol
} from '../../../interfaces/device'
import DataContext from '../../../contexts/data'
import { IBatteryModel } from '../../../interfaces/battery'
import AddBatteryModal from './AddBatteryModal'
import { IDropdownOption } from '~/interfaces/general'
import * as optionService from '../../../services/option'
import AuthContext from '../../../contexts/auth'
import ListWithOther from '../../Generic/ListWithOther'
import LibraryInput from './components/LibraryInput'
import * as constants from '../../../constants'
import * as options from '~/util/options'
import { LibraryInputValueItem } from '~/interfaces/library'
import Dropdown from '~/components/Generic/Dropdown'

export interface IDeviceFormProps {
  device?: IMainMeter | IHybridInverter
}

interface IDeviceTypeBaseFormData {
  label: string
  make: string
  model: string
  additions: string
  connection: string
  physicalInterface: string
  communicationPath: string
  deviceIntegrationStatus: string
  platformIntegrationStatus: string
  developmentTestingStatus: string
  acceptanceTestingStatus: string
  productionStatus: string
  communicationProtocol: string
  serialProtocol?: ISerialProtocol
  modbusRtuOverTcpProtocol?: IModbusRTUOverTCPProtocol
  modbusRtuProtocol?: IModbusRTUProtocol
  modbusTcpProtocol?: IModbusTCPProtocol
  zigbeeProtocol?: IZigbeeProtocol
}

interface IMainMeterFormData extends IDeviceTypeBaseFormData { }
interface IHybridInverterFormData extends IDeviceTypeBaseFormData {
  phaseType: string
  powerRating: number
  noOfPvStrings: number
}

const initialSerialProtocolData = {
  port: '',
  baudrate: 0,
  dataBits: 0,
  parity: 0,
  stopBits: 0,
  flowControl: 0,
  timeout: 0
}

const initailModbusRtuOverTcpProtocolData = {
  host: '',
  port: 0,
  slaveId: 0,
  isIpScannable: false
}

const initialZigbeeProtocolData = {}

const initialModbusRtuProtocolData = {
  port: '',
  baudrate: 0,
  dataBits: 0,
  parity: 0,
  stopBits: 0,
  flowControl: 0,
  timeout: 0,
  slaveId: 0
}

const initialModbusTcpProtocolData = {
  host: '',
  port: 0,
  slaveId: 0,
  isIpScannable: false
}

const initialNewDeviceTypeData: IMainMeterFormData | IHybridInverterFormData = {
  label: '',
  make: '',
  model: '',
  additions: '',
  connection: '',
  physicalInterface: '',
  communicationPath: '',
  deviceIntegrationStatus: '',
  platformIntegrationStatus: '',
  developmentTestingStatus: '',
  acceptanceTestingStatus: '',
  productionStatus: '',
  communicationProtocol: '',
  phaseType: '',
  powerRating: 0,
  noOfPvStrings: 0,
  serialProtocol: initialSerialProtocolData,
  modbusRtuOverTcpProtocol: initailModbusRtuOverTcpProtocolData,
  modbusRtuProtocol: initialModbusRtuProtocolData,
  modbusTcpProtocol: initialModbusTcpProtocolData,
  zigbeeProtocol: initialZigbeeProtocolData
}

const DeviceForm = (props: IDeviceFormProps) => {
  const dataContext = useContext(DataContext)
  const authContext = useContext(AuthContext)


  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')

  const [deviceData, setDeviceData] = useState<any>(props.device || initialNewDeviceTypeData)
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<string>(constants.SmartMeterDeviceGroupLabel)
  const [selectedCommunicationProtocol, setSelectedCommunicationProtocol] = useState<string>(props.device ? props.device.communicationProtocol : 'Serial')
  const [communicationProtocolKey, setCommunicationProtocolKey] = useState<string>('')
  const [communicationProtocolValue, setCommunicationProtocolValue] = useState<any>({})
  const [batteryModelOptions, setBatteryModelOptions] = useState<any>([])
  const [selectedBatteryModels, setSelectedBatteryModels] = useState<IBatteryModel[]>([])
  const [devicePhysicalInterfaceOptions, setDevicePhysicalInterfaceOptions] = useState<IDropdownOption[]>([])
  const [deviceCommunicationPathOptions, setDeviceCommunicationPathOptions] = useState<IDropdownOption[]>([])
  const [deviceConnectionOptions, setDeviceConnectionOptions] = useState<IDropdownOption[]>([])
  const [libraryMake, setLibraryMake] = useState<string>('')
  const [libraryModel, setLibraryModel] = useState<string>('')
  const [applicationLibraryValue, setApplicationLibraryValue] = useState<LibraryInputValueItem[]>([{ application: '', grouping: '' }])
  const [applicationOptions, setApplicationOptions] = useState<any>([]);

  /** Create a new device or update a selected device */
  const createOrUpdateDevice = async (e: any) => {
    e.preventDefault()
    const payload = getPayload()
    try {
      if (props.device) {
        if (selectedDeviceGroup === constants.SmartMeterDeviceGroupLabel) {
          await dataContext?.device.updateMainMeter(payload)
        } else if (selectedDeviceGroup === constants.HybridInverterDeviceGroupLabel) {
          await dataContext?.device.updateHybridInverter(payload)
        }
        else if (selectedDeviceGroup === constants.SmartControllerDeviceGroupLabel) {
          await dataContext?.device.updateSmartController(payload)
        }
        setSuccessMessage('Device updated successfully')
      } else {
        if (selectedDeviceGroup === constants.SmartMeterDeviceGroupLabel) {
          await dataContext?.device.createMainMeter(payload)
        } else if (selectedDeviceGroup === constants.HybridInverterDeviceGroupLabel) {
          await dataContext?.device.createHybridInverter(payload)
        }
        else if (selectedDeviceGroup === constants.SmartControllerDeviceGroupLabel) {
          await dataContext?.device.createSmartController(payload)
        }
        setSuccessMessage('Device created successfully')
      }
    } catch (err) {
      setErrorMessage(err.errors[0])
    }
  }

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value
    setDeviceData({ ...deviceData, [name]: value })
  }

  const handleProtocolParameterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.value
    setCommunicationProtocolValue({ ...communicationProtocolValue, [name]: value })
  }

  const onDropdownChange = (name: string, value: any) => {
    setDeviceData({ ...deviceData, [name]: value })
  }

  const communicationProtocolListWithOtherSelected = (name: string, value: any) => {
    setCommunicationProtocolValue({ ...communicationProtocolValue, [name]: value })
  }

  const fetchDevicePhysicalInterfaceOptions = async () => {
    const result = await optionService.fetchPhysicalInterfaceOptions(authContext?.accessToken!)
    const resultOptions = result.map((r: any) => { return { key: r, text: r, value: r } })
    setDevicePhysicalInterfaceOptions(resultOptions)
  }

  const fetchDeviceConnectionOptions = async () => {
    const result = await optionService.fetchConnectionOptions(authContext?.accessToken!)
    const resultOptions = result.map((r: any) => { return { key: r, text: r, value: r } })
    setDeviceConnectionOptions(resultOptions)
  }

  const fetchDeviceCommunicationPathOptions = async () => {
    const result = await optionService.fetchCommunicationPathOptions(authContext?.accessToken!)
    const resultOptions = result.map((r: any) => { return { key: r, text: r, value: r } })
    setDeviceCommunicationPathOptions(resultOptions)
  }

  const onLibraryInputAddClick = async (row: number) => {
    const new_value = [...applicationLibraryValue]
    new_value.splice(row + 1, 0, { application: '', grouping: '' })
    setApplicationLibraryValue(new_value)
  }

  const onLibraryInputRemoveClick = async (row: number) => {
    if (applicationLibraryValue.length > 1) {
      const new_value = [...applicationLibraryValue]
      new_value.splice(row, 1)
      setApplicationLibraryValue(new_value)
    }
  }

  const onLibraryApplicationChange = async (row: number, value: string) => {
    const new_value = [...applicationLibraryValue]
    new_value[row].application = value
    setApplicationLibraryValue(new_value)
  }

  const onLibraryGroupingChange = async (row: number, value: string) => {
    const new_value = [...applicationLibraryValue]
    new_value[row].grouping = value
    setApplicationLibraryValue(new_value)
  }

  const getFormDataFromDevice = (device: any) => {
    const data = { ...device }
    delete data['applications'];
    delete data['category'];
    delete data['controllableCategory']
    delete data['dataInterval']
    delete data['groupLabel']
    delete data['groupName']
    delete data['isActive']
    delete data['onOffControllable']
    delete data['powerControllable']
    delete data['services']
    return data;
  }

  /** Get request payload */
  const getPayload = (): any => {
    const formData = props.device ? getFormDataFromDevice(deviceData) : deviceData;
    const libraries = [];
    for (const val of applicationLibraryValue) {
      const libraryValue = { ...val }
      if (libraryMake && libraryMake.length) { libraryValue.make = libraryMake }
      if (libraryModel && libraryModel.length) { libraryValue.model = libraryModel }
      libraries.push(libraryValue);
    }
    const data = {
      ...formData,
      libraries,
      batteryModelIds: selectedBatteryModels.map((value) => value.id),
      communicationProtocol: selectedCommunicationProtocol,
      serialProtocol: null,
      modbusRtuOverTcpProtocol: null,
      modbusRtuProtocol: null,
      modbusTcpProtocol: null,
      zigbeeProtocol: null
    }
    data[communicationProtocolKey] = communicationProtocolValue
    if (props.device) {
      delete data.lastUpdatedOn
      delete data.createdOn
      delete data.batteryModels
      data.id = props.device.id
    }
    if (selectedDeviceGroup === constants.SmartMeterDeviceGroupLabel) {
      delete data.powerRating
      delete data.noOfPvStrings
      delete data.batteryModelIds
      delete data.mounting
      delete data.safemodeSupport
    }
    if (selectedDeviceGroup === constants.SmartControllerDeviceGroupLabel) {
      delete data.noOfPvStrings
      delete data.batteryModelIds
    }
    return data
  }

  setTimeout(() => {
    setErrorMessage('')
    setSuccessMessage('')
  }, 5000)

  React.useEffect(() => {
    if (!props.device) { setApplicationLibraryValue([{ application: '', grouping: '' }]) }
    switch (selectedDeviceGroup) {
      case constants.SmartMeterDeviceGroupLabel:
        setApplicationOptions(options.mainMeterApplicationOptions);
        break;
      case constants.SmartControllerDeviceGroupLabel:
        setApplicationOptions(options.smartControllerApplicationOptions)
        break;
      case constants.HybridInverterDeviceGroupLabel:
        setApplicationOptions(options.HybridInverterApplicationOptions)
        break;
      default:
        setApplicationOptions([]);
        break;
    }
  }, [selectedDeviceGroup])

  React.useEffect(() => {
    switch (selectedCommunicationProtocol) {
      case 'Serial':
        setCommunicationProtocolKey('serialProtocol')
        setCommunicationProtocolValue(deviceData.serialProtocol || initialSerialProtocolData)
        break
      case 'ModBusRTUOverTCP':
        setCommunicationProtocolKey('modbusRtuOverTcpProtocol')
        setCommunicationProtocolValue(deviceData.modbusRtuOverTcpProtocol || initailModbusRtuOverTcpProtocolData)
        break
      case 'Zigbee':
        setCommunicationProtocolKey('zigbeeProtocol')
        setCommunicationProtocolValue(deviceData.zigbeeProtocol || initialZigbeeProtocolData)
        break
      case 'ModBusRTU':
        setCommunicationProtocolKey('modbusRtuProtocol')
        setCommunicationProtocolValue(deviceData.modbusRtuProtocol || initialModbusRtuProtocolData)
        break
      case 'ModBusTCP':
        setCommunicationProtocolKey('modbusTcpProtocol')
        setCommunicationProtocolValue(deviceData.modbusTcpProtocol || initialModbusTcpProtocolData)
        break
    }
  }, [selectedCommunicationProtocol])

  React.useEffect(() => {
    const options = []
    for (const model of dataContext?.battery?.batteryModels!) {
      options.push({
        key: model.id,
        text: `${model.make} ${model.model}`,
        value: model
      })
    }
    setBatteryModelOptions(options)
  }, [dataContext?.battery?.batteryModels])

  React.useEffect(() => {
    if (props.device) {
      const currentLibraries = [...props.device.libraries]
      if (currentLibraries.length == 0) {
        currentLibraries.push({ application: "", grouping: "", make: "", model: "" })
      }
      let libraryMake = "";
      let libraryModel = "";
      const librariesValue = [];
      for (const library of currentLibraries) {
        libraryMake = library.make;
        libraryModel = library.model;
        librariesValue.push({ application: library.application, grouping: library.grouping })
      }
      setLibraryMake(libraryMake)
      setLibraryModel(libraryModel)
      setApplicationLibraryValue(librariesValue)
      setSelectedDeviceGroup(props.device.groupLabel)
      if (props.device.groupLabel === constants.HybridInverterDeviceGroupLabel) {
        const hybridInverter = props.device as IHybridInverter
        const selectedBatteryModels = []
        for (const hybridInverterBatteryModel of hybridInverter.batteryModels) {
          const batteryModel = dataContext?.battery?.batteryModels.find((model) => model.id === hybridInverterBatteryModel.id)
          selectedBatteryModels.push(batteryModel)
        }
        setSelectedBatteryModels(selectedBatteryModels)
      }
    } else {
      setSelectedDeviceGroup(constants.SmartMeterDeviceGroupLabel)
    }
  }, [props.device])

  React.useEffect(() => {
    fetchDevicePhysicalInterfaceOptions()
    fetchDeviceConnectionOptions()
    fetchDeviceCommunicationPathOptions()
  }, [])

  return (
    <Form onSubmit={createOrUpdateDevice}>
      <div className="form-section">Device Info</div>
      <Dropdown name="deviceGroup" placeholder="Device Group" label="Device Group" options={options.deviceGroupOptions} selected={selectedDeviceGroup} setSelected={(name, group) => setSelectedDeviceGroup(group)} />
      <InlineTextInputField label="Device Label" placeholder="" value={deviceData.label} name="label" onChange={handleTextInputChange} />
      <InlineTextInputField label="Make" placeholder="" value={deviceData.make} name="make" onChange={handleTextInputChange} />
      <InlineTextInputField label="Model" placeholder="" value={deviceData.model} name="model" onChange={handleTextInputChange} />
      <InlineTextInputField label="Additions" placeholder="" value={deviceData.additions} name="additions" onChange={handleTextInputChange} />
      <ListWithOther options={devicePhysicalInterfaceOptions} selected={deviceData.physicalInterface} label="Physical Interface" name="physicalInterface" hasOther placeholder="Select Physical Interface" setSelected={onDropdownChange} />
      <Dropdown options={options.phaseTypeOptions} selected={deviceData.phaseType} label="Phase Type" name="phaseType" placeholder="Select Phase Type" setSelected={onDropdownChange} />
      {(selectedDeviceGroup === constants.HybridInverterDeviceGroupLabel || selectedDeviceGroup === constants.SmartControllerDeviceGroupLabel) && <InlineTextInputField label="Power Rating" placeholder="" value={deviceData.powerRating} name="powerRating" onChange={handleTextInputChange} />}
      {selectedDeviceGroup === constants.HybridInverterDeviceGroupLabel && <InlineTextInputField label="Number of Pv Strings" placeholder="" value={deviceData.noOfPvStrings} name="noOfPvStrings" onChange={handleTextInputChange} />}
      {selectedDeviceGroup === constants.HybridInverterDeviceGroupLabel && (
        <Dropdown
          placeholder="Battery Models"
          allowMultipleSelection
          setSelected={(name, value) => setSelectedBatteryModels(value)}
          selected={selectedBatteryModels}
          options={batteryModelOptions}
          name="batteryModels"
          label="Battery Models"
        />)}
      {selectedDeviceGroup === constants.HybridInverterDeviceGroupLabel && <AddBatteryModal />}
      {selectedDeviceGroup === constants.SmartControllerDeviceGroupLabel && <Dropdown options={options.booleanOptions} selected={deviceData.safemodeSupport} label="Safemode Support" name="safemodeSupport" placeholder="Safemode" setSelected={onDropdownChange} />}
      {selectedDeviceGroup === constants.SmartControllerDeviceGroupLabel && <Dropdown options={options.mountingOptions} selected={deviceData.mounting} label="Mounting" name="mounting" placeholder="Mounting" setSelected={onDropdownChange} />}
      <div className="form-section">Communication</div>
      <ListWithOther options={deviceConnectionOptions} selected={deviceData.connection} label="Connection" name="connection" hasOther placeholder="Select Connection" setSelected={onDropdownChange} />
      <ListWithOther options={deviceCommunicationPathOptions} selected={deviceData.communicationPath} label="Communication Path" name="communicationPath" hasOther placeholder="Select Communication Path" setSelected={onDropdownChange} />
      <div className="form-section">Protocol Parameters</div>
      <Dropdown
        label="Communication Protocol"
        name="communicationProtocol"
        placeholder="Communication Protocol"
        setSelected={(name, selected) => setSelectedCommunicationProtocol(selected)}
        selected={selectedCommunicationProtocol}
        options={options.communicationProtocolOptions} />
      {Object.keys(communicationProtocolValue).map((dataKey, index) => {
        return dataKey === 'isIpScannable'
          ? <ListWithOther
            options={options.booleanOptions}
            selected={communicationProtocolValue[dataKey]}
            label="Is IP Scannable"
            name={dataKey}
            placeholder="Is IP Scannable?"
            setSelected={communicationProtocolListWithOtherSelected} /> : (
            <InlineTextInputField
              key={index}
              label={dataKey}
              placeholder=""
              value={communicationProtocolValue[dataKey]}
              name={dataKey}
              onChange={handleProtocolParameterChange} />
          )
      })}
      <div className="form-section">Integration</div>
      <ListWithOther options={options.deviceStatusOptions} selected={deviceData.deviceIntegrationStatus} label="Device Integration Status" name="deviceIntegrationStatus" placeholder="Select Device Integration Status" setSelected={onDropdownChange} />
      <ListWithOther options={options.deviceStatusOptions} selected={deviceData.platformIntegrationStatus} label="Platform Integration Status" name="platformIntegrationStatus" placeholder="Select Platform Integration Status" setSelected={onDropdownChange} />
      <ListWithOther options={options.deviceStatusOptions} selected={deviceData.developmentTestingStatus} label="Development Testing Status" name="developmentTestingStatus" placeholder="Select Development Testing Status" setSelected={onDropdownChange} />
      <ListWithOther options={options.deviceStatusOptions} selected={deviceData.acceptanceTestingStatus} label="Acceptance Testing Status" name="acceptanceTestingStatus" placeholder="Select Acceptance Testing Status" setSelected={onDropdownChange} />
      <ListWithOther options={options.deviceStatusOptions} selected={deviceData.productionStatus} label="Production Status" name="productionStatus" placeholder="Select Production Status" setSelected={onDropdownChange} />
      <div className="form-section">Library</div>
      <InlineTextInputField label="Make" placeholder="" value={libraryMake} name="libraryMake" onChange={(e) => setLibraryMake(e.target.value)} />
      <InlineTextInputField label="Model" placeholder="" value={libraryModel} name="libraryModel" onChange={(e) => setLibraryModel(e.target.value)} />
      <LibraryInput className='form-library_input' onAddClick={onLibraryInputAddClick} onRemoveClick={onLibraryInputRemoveClick} onApplicationChange={onLibraryApplicationChange} onGroupingChange={onLibraryGroupingChange} applicationOptions={applicationOptions} value={applicationLibraryValue} />
      <Button type='submit'>Add Device</Button>
      {errorMessage && errorMessage !== '' && <Message color="red">{errorMessage}</Message>}
      {successMessage && successMessage !== '' && <Message color="green">{successMessage}</Message>}
    </Form>
  )
}

export default DeviceForm
