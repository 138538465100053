import { HybridInverterApplication, MainMeterApplicaiton } from '~/enums'

export const MainMeterApplicationEnumDisplayMapping = {
    [MainMeterApplicaiton.SMART_METER]: "Slimme meter (Elektra en gas)",
    [MainMeterApplicaiton.ELECTRICITY_METER_SMALL_CONSUMER]: "Elektrameter kleinverbruik ",
    [MainMeterApplicaiton.SMART_GAS_METER_SMALL_CONSUMER]: "Slimme gasmeter kleinverbruik ",
    [MainMeterApplicaiton.ELECTRICITY_METER_LARGE_CONSUMER]: "Elektrameter grootverbruik "
}

export const HybridInverterApplicaitonEnumDisplayMapping = {
    [HybridInverterApplication.HYBRID_INVERTER]: 'Zonnepanelen met batterij'
}
