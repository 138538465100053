import * as React from 'react'
import * as headerLogo from '../../assets/logo_rgb100x100.png'
import routes from '../../pages/Routes'
import OnboardService from '../../services/onboard'
import { isCommunityMode } from '../../util/helper'
import './header.scss'

// Simple header with just logo
const Header: React.FC = () => {
  const [activeCommunityName, setActiveCommunityName] = React.useState<string>('')
  const onboardService = new OnboardService()
  const tokenFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    let urlToken = urlParams.get('token')
    urlToken = urlToken || ''
    return urlToken
  }
  const parseCommunityName = (name: string) => {
    if (name == null) {
      return
    }
    const re = /community$/i
    let trimmed = name.trim()
    if (trimmed.match(re)) {
      trimmed = trimmed.replace(re, 'community')
      return trimmed + ' portal'
    } else {
      return trimmed + ' community portal'
    }
  }
  const fetchComunityName = async () => {
    const urlToken: string = tokenFromUrl()
    const result = await onboardService.validateToken(urlToken)
    setActiveCommunityName(result && result.communityName)
  }
  console.log('activeCommunityName', activeCommunityName)
  React.useEffect(() => {
    fetchComunityName()
  }, [])
  return (
    <>
      <div className="header-container flex-center-vertical">
        <div className={isCommunityMode() ? 'flex1-row flex1-center width-small' : 'flex1-row flex1-center'}>
          <img src={headerLogo.default} className="header-logo-size header-logo-positioning" alt="Header logo" />
          {isCommunityMode() && <div className="admin-portal">{parseCommunityName(activeCommunityName)}</div>}
        </div>
        <div
          className={
            isCommunityMode()
              ? 'registration-container flex1-column flex1-end width-medium'
              : 'registration-container flex1-column flex1-end'
          }
        >
          <strong className="registration flex1-column-end">REGISTRATIE</strong>
        </div>
        {isCommunityMode() && <div className="flex1-column flex1-end width-small"></div>}
      </div>
    </>
  )
}
export default Header
