import * as boilerSwitch from '../assets/active_device_boiler.png'
import * as smartMeter from '../assets/active_device_smartmeter.png'
import * as smartPlug from '../assets/active_device_smartplug.png'
import * as solarPlant from '../assets/solar_plant.png'
import * as solarPanelBattery from '../assets/solar_plant_battery.png'
import * as chargingPoleEv from '../assets/charging_pole_ev.png'
import * as battery from '../assets/battery.png'
import { IDropdownOption } from '~/interfaces/general'
import {
  HybridInverterApplication,
  MainMeterApplicaiton,
  Mounting,
  SmartControllerApplianceType,
  SmartControllerApplicaiton,
  SmartControllerApplianceTypeDutch
} from '~/enums'
import * as constants from '~/constants'
import * as mappings from '~/mappings'

export const adminOptions: any = [{ key: 1, text: 'Log Uit', value: 1 }]
export const communityAdminOptions: any[] = [{ key: 1, text: 'Log Uit', value: 1 }]
export const options: any = [
  { key: 1, text: 'Ondersteuning', value: 1 },
  { key: 3, text: 'Log Uit', value: 3 }
]
export const homeOptions: any = [
  { key: 1, text: 'Ondersteuning', value: 1 },
  { key: 2, text: 'Gebruikers', value: 2 },
  { key: 3, text: 'Log Uit', value: 3 }
]
export const moreOptions: any = [
  { key: 1, text: 'Klantgegevens', value: 1 },
  { key: 2, text: 'Ondersteuning', value: 2 },
  { key: 3, text: 'Log Uit', value: 3 }
]
export const energyLabels = ['A', 'B', 'C', 'D', 'E']
export const heatingMediums = ['Gas CV', 'Electric Boiler']
export const langauges = ['Nederlands', 'English']
export const notifications = ['Ja', 'Nee']
export const contractOptions = [
  {
    key: 'Standaard',
    text: 'Standaard',
    value: 'Standaard'
  },
  {
    key: 'Werkelijk',
    text: 'Werkelijk',
    value: 'Werkelijk'
  }
]
export const typeTariff = [
  {
    key: 'Enkeltarief',
    text: 'Enkeltarief',
    value: 'Enkeltarief'
  },
  {
    key: 'Dubbeltarief',
    text: 'Dubbeltarief',
    value: 'Dubbeltarief'
  }
]
export const typeTariffSelected = [
  {
    key: 'Enkeltarief',
    text: 'Enkeltarief',
    value: 'Enkeltarief'
  }
]
export const consumerType = [
  {
    key: 'Kleinverbruiker',
    text: 'Kleinverbruiker',
    value: 'Kleinverbruiker'
  }
]
export const confirmationOptions = [
  {
    key: 'Ja',
    text: 'Ja',
    value: true
  },
  {
    key: 'Nee',
    text: 'Nee',
    value: false
  }
]
export const useContactPersonOptions = [
  {
    key: 'Yes',
    text: 'Yes',
    value: 'Yes'
  },
  {
    key: 'No',
    text: 'No',
    value: 'No'
  }
]
export const activeDevices: any = [
  { key: 1, pic: smartMeter, name: 'Slimme meter', description: 'Lyv' },
  { key: 2, pic: smartPlug, name: 'Slimme stekker', description: 'Develco' },
  {
    key: 3,
    pic: boilerSwitch,
    name: 'Boiler schakelaar',
    description: ' Develco'
  },
  {
    key: 4,
    pic: solarPanelBattery,
    name: 'Zonnepanelen met batterij',
    description: 'Solar 5K-SU'
  },
  { key: 5, pic: chargingPoleEv, name: 'Laadpaal EV', description: '-' },
  { key: 6, pic: solarPlant, name: 'Zonnepanelen omvormer', description: 'SMA' },
  { key: 7, pic: battery, name: 'Batterij', description: 'Solar X-1' }
]
export const privacyStatement = 'https://getlyv.com/wp-content/uploads/2020/03/Lyv-Privacyverklaring-Maart-2020.pdf'
export const eancodeBoek = ' https://www.eancodeboek.nl/'
export const netBeheerNederland = 'https://www.netbeheernederland.nl/consumenteninformatie/wie-is-mijn-netbeheerder'

export const booleanOptions: IDropdownOption[] = [
  { key: 'yes', text: 'Yes', value: true },
  { key: 'no', text: 'No', value: false }
]

export const deviceGroupOptions: IDropdownOption[] = [
  {
    key: constants.SmartMeterDeviceGroupLabel,
    text: constants.SmartMeterDeviceGroupLabel,
    value: constants.SmartMeterDeviceGroupLabel
  },
  {
    key: constants.HybridInverterDeviceGroupLabel,
    text: constants.HybridInverterDeviceGroupLabel,
    value: constants.HybridInverterDeviceGroupLabel
  },
  {
    key: constants.SmartControllerDeviceGroupLabel,
    text: constants.SmartControllerDeviceGroupLabel,
    value: constants.SmartControllerDeviceGroupLabel
  }
]

export const communicationProtocolOptions: IDropdownOption[] = [
  { key: 'serial', text: 'Serial', value: 'Serial' },
  { key: 'modbus_rtu_over_tcp', text: 'Modbus RTU Over TCP', value: 'ModBusRTUOverTCP' },
  { key: 'modbus_rtu', text: 'Modbus RTU', value: 'ModBusRTU' },
  { key: 'modbus_tcp', text: 'Modbus TCP', value: 'ModBusTCP' },
  { key: 'zigbee', text: 'Zigbee', value: 'Zigbee' }
]

export const phaseTypeOptions: IDropdownOption[] = [
  { key: 'one_phase', text: '1 Phase', value: '1P' },
  { key: 'three_phase', text: '3 Phase', value: '3P' }
]

export const deviceStatusOptions: IDropdownOption[] = [
  { key: 'not_started', text: 'Not Started', value: 'not_started' },
  { key: 'in_progress', text: 'In Progress', value: 'in_progress' },
  { key: 'completed', text: 'Completed', value: 'completed' }
]

export const mainMeterApplicationOptions: IDropdownOption[] = [
  {
    key: MainMeterApplicaiton.SMART_METER,
    text: mappings.MainMeterApplicationEnumDisplayMapping.SMART_METER,
    value: MainMeterApplicaiton.SMART_METER
  },
  {
    key: MainMeterApplicaiton.ELECTRICITY_METER_SMALL_CONSUMER,
    text: mappings.MainMeterApplicationEnumDisplayMapping.ELECTRICITY_METER_SMALL_CONSUMER,
    value: MainMeterApplicaiton.ELECTRICITY_METER_SMALL_CONSUMER
  },
  {
    key: MainMeterApplicaiton.SMART_GAS_METER_SMALL_CONSUMER,
    text: mappings.MainMeterApplicationEnumDisplayMapping.SMART_GAS_METER_SMALL_CONSUMER,
    value: MainMeterApplicaiton.SMART_GAS_METER_SMALL_CONSUMER
  },
  {
    key: MainMeterApplicaiton.ELECTRICITY_METER_LARGE_CONSUMER,
    text: mappings.MainMeterApplicationEnumDisplayMapping.ELECTRICITY_METER_LARGE_CONSUMER,
    value: MainMeterApplicaiton.ELECTRICITY_METER_LARGE_CONSUMER
  }
]

export const smartControllerApplicationOptions: IDropdownOption[] = [
  {
    key: SmartControllerApplicaiton.GENERIC,
    text: SmartControllerApplicaiton.GENERIC,
    value: SmartControllerApplicaiton.GENERIC
  },
  {
    key: SmartControllerApplicaiton.BOILER,
    text: SmartControllerApplicaiton.BOILER,
    value: SmartControllerApplicaiton.BOILER
  },
  {
    key: SmartControllerApplicaiton.SPACE_HEATER,
    text: SmartControllerApplicaiton.SPACE_HEATER,
    value: SmartControllerApplicaiton.SPACE_HEATER
  }
]

export const mountingOptions: IDropdownOption[] = [
  {
    key: Mounting.DIN_RAIL,
    text: Mounting.DIN_RAIL,
    value: Mounting.DIN_RAIL
  },
  {
    key: Mounting.SOCKET,
    text: Mounting.SOCKET,
    value: Mounting.SOCKET
  }
]

export const SmartControllerApplianceTypeOptions: IDropdownOption[] = [
  {
    key: SmartControllerApplianceType.LAMP,
    text: SmartControllerApplianceTypeDutch.LAMP,
    value: SmartControllerApplianceType.LAMP
  },
  {
    key: SmartControllerApplianceType.COOKING_STOVE,
    text: SmartControllerApplianceTypeDutch.COOKING_STOVE,
    value: SmartControllerApplianceType.COOKING_STOVE
  },
  {
    key: SmartControllerApplianceType.DRYER,
    text: SmartControllerApplianceTypeDutch.DRYER,
    value: SmartControllerApplianceType.DRYER
  },
  {
    key: SmartControllerApplianceType.HEATER,
    text: SmartControllerApplianceTypeDutch.HEATER,
    value: SmartControllerApplianceType.HEATER
  },
  {
    key: SmartControllerApplianceType.WASHING_MACHINE,
    text: SmartControllerApplianceTypeDutch.WASHING_MACHINE,
    value: SmartControllerApplianceType.WASHING_MACHINE
  },
  {
    key: SmartControllerApplianceType.OTHERS,
    text: SmartControllerApplianceTypeDutch.OTHERS,
    value: SmartControllerApplianceType.OTHERS
  }
]
export const HybridInverterApplicationOptions: IDropdownOption[] = [
  {
    key: HybridInverterApplication.HYBRID_INVERTER,
    text: HybridInverterApplication.HYBRID_INVERTER,
    value: HybridInverterApplication.HYBRID_INVERTER
  }
]
export const industryTypeOptions = [
  { key: 'none', text: 'Not Selected', value: null },
  { key: 'Metalektro en mkb-metaal', text: 'Metalektro en mkb-metaal', value: 'Metal and electronics and SME metal' },
  { key: 'Autoschadeherstelbedrijven', text: 'Autoschadeherstelbedrijven', value: 'Body repair shops' },
  {
    key: 'Gezondheids- en welzijnszorginstellingen',
    text: 'Gezondheids- en welzijnszorginstellingen',
    value: 'Health and welfare institutions'
  },
  { key: 'Kantoren', text: 'Kantoren', value: 'Offices' },
  { key: 'Onderwijsinstellingen', text: 'Onderwijsinstellingen', value: 'Educational institutions' },
  { key: 'Commerciële datacentra', text: 'Commerciële datacentra', value: 'Commercial data centers' },
  {
    key: 'Rubber - en kunststofindustrie',
    text: 'Rubber - en kunststofindustrie',
    value: 'Rubber and plastics industry'
  },
  { key: 'Levensmiddelenindustrie', text: 'Levensmiddelenindustrie', value: 'Food industry' },
  { key: 'Agrarische sector', text: 'Agrarische sector', value: 'Agricultural sector' },
  { key: 'Mobiliteitsbranche', text: 'Mobiliteitsbranche', value: 'Mobility industry' },
  { key: 'Sport en recreatie', text: 'Sport en recreatie', value: 'Sports and recreation' },
  { key: 'Hotels en restaurants', text: 'Hotels en restaurants', value: 'Hotels and restaurants' },
  {
    key: 'Drukkerijen, papier en karton',
    text: 'Drukkerijen, papier en karton',
    value: 'Printing, paper and cardboard'
  },
  { key: 'Bouwmaterialen', text: 'Bouwmaterialen', value: 'Construction materials' },
  { key: 'Verf en drukinkt', text: 'Verf en drukinkt', value: 'Paint and printing ink' },
  {
    key: 'Tankstations en autowasinrichtingen',
    text: 'Tankstations en autowasinrichtingen',
    value: 'Petrol stations and car wash facilities'
  },
  { key: 'Meubels en hout', text: 'Meubels en hout', value: 'Furniture and wood' },
  { key: 'Bedrijfshallen', text: 'Bedrijfshallen', value: 'Business halls' },
  { key: 'Detailhandel', text: 'Detailhandel', value: 'Retail' }
]
export const houseTypeOptions = [
  { key: 'none', text: 'Not Selected', value: null },
  { key: 'Apartment', text: 'Appartement', value: 'Appartment' }
]
