import * as React from 'react'
import * as headerLogo from '../../assets/logo_rgb100x100.png'
import * as userLogo from '../../assets/User_Icon.svg'
import { Menu, Dropdown, Image } from 'semantic-ui-react'
import { IconContext } from 'react-icons'
import { AiOutlineBell } from 'react-icons/ai'
import { RouteComponentProps, withRouter } from 'react-router'
import routes from '../../pages/Routes'
import * as headerVariable from '../../util/options'
import * as PropTypes from 'prop-types'
import UserContext from '../../contexts/user'
import AuthContext from '../../contexts/auth'

// Header with Menu , bell icon , user icon on the right
type SomeComponentProps = RouteComponentProps
const HeaderMenu: React.FC<SomeComponentProps> = ({ location, history }) => {
  const path = location.pathname
  const userContext = React.useContext(UserContext)
  const authContext = React.useContext(AuthContext)

  const onChange = (e: any, data: any) => {
    if (data.value === 2) {
      history.push('/companyform')
    } else if (data.value === 3) {
      authContext?.logout()
    }
  }

  return (
    <>
      <div className="header-container flex-center-vertical flex1-row space-between">
        <Image src={headerLogo.default} className="header-logo-size header-logo-positioning" alt="Header logo" />
        <Menu borderless stackable fluid>
          <Menu.Menu position="right" fitted>
            <Menu.Item>
              <div className="flex1-row">
                <IconContext.Provider
                  value={{
                    size: '37px',
                    style: {
                      verticalAlign: 'middle',
                      fill: '#E6F3F8',
                      strokeWidth: '1em'
                    }
                  }}
                >
                  <div>
                    <AiOutlineBell className="bell-logo" />
                  </div>
                </IconContext.Provider>
                <Image src={userLogo.default} className="user-logo" />
                {/* Menu options differs for different pages */}
                {path === routes.COMPANYFORM ? (
                  <Dropdown
                    className="user-name"
                    text={userContext?.profile?.lastName}
                    options={headerVariable.moreOptions}
                    simple
                    item
                  />
                ) : (
                  <Dropdown
                    className="user-name"
                    text={userContext?.profile?.lastName}
                    options={headerVariable.options}
                    simple
                    item
                    onChange={onChange}
                  />
                )}
              </div>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    </>
  )
}
HeaderMenu.propTypes = {
  location: PropTypes.any.isRequired
}
export default withRouter(HeaderMenu)
