import * as React from 'react'
import { useState, useContext, useEffect } from 'react'
import { MenuItemProps, Button, Menu, Segment, Confirm } from 'semantic-ui-react'
import Electricity from './Electricity'
import Gas from './Gas'
import List from './List'
import AuthContext from '../../../../contexts/auth'
import RetailerService from '../../../../services/retailer'

const Retailer: React.FC = () => {
  const retailerService = new RetailerService()
  const authContext = useContext(AuthContext)

  const [elecPriceRefs, setElecPriceRefs] = useState<any[]>([])
  const [gasPriceRefs, setGasPriceRefs] = useState<any[]>([])
  const [retailers, setRetailers] = useState<any[]>([])

  const [activeItem, setActiveItem] = useState<string>('Electricity')

  const [inEditModeElec, setInEditModeElec] = useState<any>({
    status: false,
    ids: []
  })
  const [editElecPriceRefs, setEditElecPriceRefs] = useState<any>({})
  const [inEditModeGas, setInEditModeGas] = useState<any>({
    status: false,
    ids: []
  })
  const [editGasPriceRefs, setEditGasPriceRefs] = useState<any>({})
  const [inEditModeRetailers, setInEditModeRetailers] = useState<any>({
    status: false,
    ids: []
  })
  const [editRetailers, setEditRetailers] = useState<any>({})

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  const openConfirm = () => {
    setConfirmOpen(true)
  }

  const closeConfirm = () => {
    setConfirmOpen(false)
  }

  const fetchElecPriceRefs = async () => {
    const accessToken = authContext?.accessToken!
    const result = await retailerService.getElecPriceRefs(accessToken)
    setElecPriceRefs(result)
  }

  const updateElecPriceRefs = async () => {
    const accessToken = authContext?.accessToken!
    const result = await retailerService.updateElecPriceRefs(accessToken, Object.values(editElecPriceRefs))
    setElecPriceRefs(result)
    setInEditModeElec({
      status: false,
      ids: []
    })
    setEditElecPriceRefs({})
  }

  const fetchGasPriceRefs = async () => {
    const accessToken = authContext?.accessToken!
    const result = await retailerService.getGasPriceRefs(accessToken)
    setGasPriceRefs(result)
  }

  const updateGasPriceRefs = async () => {
    const accessToken = authContext?.accessToken!
    const result = await retailerService.updateGasPriceRefs(accessToken, Object.values(editGasPriceRefs))
    setGasPriceRefs(result)
    setInEditModeGas({
      status: false,
      ids: []
    })
    setEditGasPriceRefs({})
  }

  const fetchRetailers = async () => {
    const accessToken = authContext?.accessToken!
    const result = await retailerService.listRetailers(accessToken)
    result.sort()
    setRetailers(result)
  }

  const updateRetailers = async () => {
    const accessToken = authContext?.accessToken!
    const result = await retailerService.updateRetailers(accessToken, Object.values(editRetailers))
    setRetailers(result)
    setInEditModeRetailers({
      status: false,
      ids: []
    })
    setEditRetailers({})
  }

  const handleItemClick = (_event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    setActiveItem(data.name as string)
  }

  const handleButtonClick = () => {
    if (Object.keys(editElecPriceRefs).length > 0) {
      updateElecPriceRefs()
    }
    if (Object.keys(editGasPriceRefs).length > 0) {
      updateGasPriceRefs()
    }
    if (Object.keys(editRetailers).length > 0) {
      updateRetailers()
    }
    closeConfirm()
  }

  const addElecRefToEdits = (id: number, field: string) => {
    setInEditModeElec({ status: true, ids: inEditModeElec.ids.concat(id) })
    const ref = JSON.parse(JSON.stringify(elecPriceRefs.filter((item) => item.id === id)[0]))
    if (!(id in editElecPriceRefs)) {
      editElecPriceRefs[id] = { id: ref.id }
    }
    editElecPriceRefs[id][field] = ref[field]
    setEditElecPriceRefs(editElecPriceRefs)
  }

  const addGasRefToEdits = (id: number, field: string) => {
    setInEditModeGas({ status: true, ids: inEditModeGas.ids.concat(id) })
    const ref = JSON.parse(JSON.stringify(gasPriceRefs.filter((item) => item.id === id)[0]))
    if (!(id in editGasPriceRefs)) {
      editGasPriceRefs[id] = { id: ref.id }
    }
    editGasPriceRefs[id][field] = ref[field]
    setEditGasPriceRefs(editGasPriceRefs)
  }

  const addRetailerToEdits = (id: number, field: string) => {
    setInEditModeRetailers({ status: true, ids: inEditModeRetailers.ids.concat(id) })
    const retailer = JSON.parse(JSON.stringify(retailers.filter((item) => item.id === id)[0]))
    if (!(id in editRetailers)) {
      editRetailers[id] = { id: retailer.id }
    }
    editRetailers[id][field] = retailer[field]
    setEditRetailers(editRetailers)
  }

  const handleElecRefChange = (id: number, field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    editElecPriceRefs[id][field] = value
    setEditElecPriceRefs(JSON.parse(JSON.stringify(editElecPriceRefs)))
  }

  const handleGasRefChange = (id: number, field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    editGasPriceRefs[id][field] = value
    setEditGasPriceRefs(JSON.parse(JSON.stringify(editGasPriceRefs)))
  }

  const handleRetailerChange = (id: number, field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    editRetailers[id][field] = value
    setEditRetailers(JSON.parse(JSON.stringify(editRetailers)))
  }

  useEffect(() => {
    fetchElecPriceRefs()
    fetchGasPriceRefs()
    fetchRetailers()
  }, [])

  return (
    <>
      <div className="source-container">
        <div>
          <Menu secondary size="small">
            <Menu.Item name="Electricity" active={activeItem === 'Electricity'} onClick={handleItemClick} />
            <Menu.Item name="Gas" active={activeItem === 'Gas'} onClick={handleItemClick} />
            <Menu.Item name="List" active={activeItem === 'List'} onClick={handleItemClick} />
            <Menu.Item position="right">
              <Button className="btn-green" size="medium"
                onClick={openConfirm}
                disabled={!(inEditModeElec.status || inEditModeGas.status || inEditModeRetailers.status)}
              >
                Modify
              </Button>
              <Confirm
                open={confirmOpen}
                onCancel={closeConfirm}
                onConfirm={handleButtonClick}
                content="Are you sure you want to modify the values?"
                cancelButton="No"
                confirmButton="Yes"
              />
            </Menu.Item>
          </Menu>
        </div>
        {activeItem === 'Electricity' && (
          <Segment attached="bottom">
            <Electricity
              handleRefChange={handleElecRefChange}
              handleRefClick={addElecRefToEdits}
              editPriceRefs={editElecPriceRefs}
              inEditMode={inEditModeElec}
              elecPriceRefs={elecPriceRefs}
            />
          </Segment>
        )}
        {activeItem === 'Gas' && (
          <Segment attached="bottom">
            <Gas
              handleRefChange={handleGasRefChange}
              handleRefClick={addGasRefToEdits}
              gasPriceRefs={gasPriceRefs}
              inEditMode={inEditModeGas}
              editPriceRefs={editGasPriceRefs}
            />
          </Segment>
        )}
        {activeItem === 'List' && (
          <Segment attached="bottom">
            <List
              retailers={retailers}
              editRetailers={editRetailers}
              inEditMode={inEditModeRetailers}
              handleRetailerChange={handleRetailerChange}
              handleRetailerClick={addRetailerToEdits}
            />
          </Segment>
        )}
      </div>
    </>
  )
}

export default Retailer
