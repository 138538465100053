import { createContext } from 'react'

export interface IToken {
  accessToken: string
  refreshToken: string
}

export interface IAuthContextProps {
  accessToken: string | null
  setUserToken: (token: IToken | null) => any
  isLoggedIn: () => boolean
  isAdminLoggedIn: () => boolean
  isCommunityAdminLoggedIn: () => boolean
  isRegularUserLoggedIn: () => boolean
  logout: () => any
  logoutCommunity: () => any
}

const AuthContext = createContext<IAuthContextProps | undefined>(undefined)

export default AuthContext
