import * as React from 'react'
import './user_details.scss'
import { Button, Form, Grid } from 'semantic-ui-react'
import AuthContext from '../../../contexts/auth'
import UserContext from '../../../contexts/user'
import UserService from '../../../services/user'
import { useHistory } from 'react-router'
import { normalizeCase } from '../../../util/strings'
import { IUserSubscription } from '../../../interfaces/user'

interface IUserDetailsProp {
  user: any
  community: any
}

const initialSubscriptionDetail: IUserSubscription = {
  userId: 0,
  subscriptionStart: '',
  subscriptionEnd: '',
  communityName: '',
  gatewayNumber: ''
}

const UserDetails: React.FC<IUserDetailsProp> = ({ user, community }) => {
  const userService = new UserService()
  const [userData, setUserData] = React.useState<any>(user)
  const history = useHistory()
  const [subscriptionDetail, setUserSubscriptionDetail] = React.useState<IUserSubscription>(initialSubscriptionDetail)
  const [abonement, setAbonement] = React.useState<any>(initialSubscriptionDetail)

  const userIsSuspended = user.status === 'inactive'
  const suspendButtonText = userIsSuspended ? 'Unsuspend' : 'Suspend'

  const authContext = React.useContext(AuthContext)
  const userContext = React.useContext(UserContext)

  const onSubmitUser = async (e: any) => {
    const token = authContext?.accessToken!
    e.preventDefault()
    // try catch display success or error
    await userService.updateUser(token, user.id, userData)
  }
  const onSubmitAbonement = () => { }

  const onSuspend = async () => {
    // try catch display success or error
    const token = authContext?.accessToken!
    if (userIsSuspended) {
      await userService.unSuspendUser(token, user.id)
    } else {
      await userService.suspendUser(token, user.id)
    }
  }

  const onResendInvite = async () => {
    // try catch display success or error
    const token = authContext?.accessToken!
    await userService.resendUserInvite(token, user.email)
  }

  const ToUser = async () => {
    console.log("Obtaining user token and impersonating user")
    const token = authContext?.accessToken!
    const result = await userService.getUserToken(token, user.id)
    const { access, refresh } = result
    const userProfile = await userService.getUserProfile(access)
    userContext?.setProfile(userProfile)
    authContext?.setUserToken({ accessToken: access, refreshToken: refresh })
    history.push('/home')
  }

  const onDeleteUser = async () => {
    // try catch display success or error
    const token = authContext?.accessToken!
    await userService.deleteUser(token, user.id)
  }

  const getAndSetSubscriptionDetail = async () => {
    const token = authContext?.accessToken!
    const result = await userService.getUserSubscriptionDetail(token, user.id)
    setUserSubscriptionDetail(result)
  }

  React.useEffect(() => {
    getAndSetSubscriptionDetail()
  }, [])
  React.useEffect(() => {
    setAbonement(subscriptionDetail)
  }, [subscriptionDetail])

  return (
    <>
      <div
        style={{
          justifyContent: 'flex-end',
          display: 'flex'
        }}
      >
        {!('id' in community) && (
          <Button onClick={ToUser} className="lyv-blue-btn">
            To User
          </Button>
        )}
        {!('id' in community) && (
          <Button className="lyv-blue-btn" onClick={onResendInvite}>
            Resend Invite
          </Button>
        )}
        {!('id' in community) && (
          <Button className="delete-btn" onClick={onSuspend}>
            {suspendButtonText}
          </Button>
        )}
        {!('id' in community) && (
          <Button className="delete-btn" onClick={onDeleteUser}>
            Delete
          </Button>
        )}
      </div>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <div className="form-title">User</div>
            <Form className=" flex1-column flex1-center" id="userUpdateForm" onSubmit={onSubmitUser}>
              <div className="form-inner-item flex1-column">
                <div className="login-form-inputdetails">
                  {userData.customerType !== 'private' && (
                    <div className="flex1-row">
                      <p className="customer-detail-names required-field">Company name</p>
                      <Form.Field>
                        <input
                          placeholder="Bedrijfsnaam"
                          className="form1-item"
                          onChange={(e) => setUserData({ ...userData, userName: e.target.value })}
                          value={normalizeCase(userData.userName)}
                        />
                      </Form.Field>
                    </div>
                  )}
                  <div className="flex1-row">
                    <p className="customer-detail-names required-field">First name</p>
                    <Form.Field>
                      <input
                        placeholder="Voornaam"
                        required
                        className="form1-item"
                        onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                        value={normalizeCase(userData.firstName)}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names required-field">Last name</p>
                    <Form.Field>
                      <input
                        placeholder="Achternaam"
                        required
                        className="form1-item"
                        onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                        value={normalizeCase(userData.lastName)}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row hide-overflow">
                    <p className="customer-detail-names required-field">Street + number</p>
                    <div className="flex1-row field">
                      <Form.Field className="larger">
                        <input
                          placeholder="Straat"
                          required
                          className="form1-item"
                          onChange={(e) => setUserData({ ...userData, street: e.target.value })}
                          value={normalizeCase(userData.street)}
                        />
                      </Form.Field>
                      <Form.Field className="tiny">
                        <input
                          placeholder="Straat Nummer"
                          required
                          className="form1-item ml05"
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              streetNumber: e.target.value
                            })
                          }
                          value={userData.streetNumber}
                        />
                      </Form.Field>
                    </div>
                  </div>
                  <div className="flex1-row hide-overflow">
                    <p className="customer-detail-names required-field">Postcode + city</p>
                    <div className="flex1-row field">
                      <Form.Field className="tiny">
                        <input
                          placeholder="Postcode"
                          required
                          className="form1-item"
                          onChange={(e) => setUserData({ ...userData, postcode: e.target.value })}
                          value={userData.postcode}
                        />
                      </Form.Field>
                      <Form.Field className="larger">
                        <input
                          placeholder="Platsnaam"
                          className="form1-item ml05"
                          required
                          onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                          value={normalizeCase(userData.city)}
                        />
                      </Form.Field>
                    </div>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">Email</p>
                    <Form.Field>
                      <input
                        placeholder="E-mail"
                        className="form1-item"
                        required
                        disabled
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        value={userData.email}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">Client type</p>
                    <Form.Field>
                      <input
                        placeholder="Klanttype"
                        className="form1-item"
                        required
                        disabled
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            customerType: e.target.value
                          })
                        }
                        value={normalizeCase(userData.customerType)}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row flex1-end">
                    <Button size="small" className="submit-btn">
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <div className="form-title">Subscription</div>
            <Form className=" flex1-column flex1-center" id="subscription" onSubmit={onSubmitAbonement}>
              <div className="form-inner-item flex1-column">
                <div className="login-form-inputdetails">
                  <div className="flex1-row">
                    <p className="customer-detail-names">User Id</p>
                    <Form.Field>
                      <input
                        placeholder="User-id"
                        className="form1-item"
                        required
                        disabled
                        defaultValue={subscriptionDetail.userId}
                        onChange={(e) =>
                          setAbonement({
                            ...abonement,
                            userId: e.target.value
                          })
                        }
                        value={abonement.userId}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">Start date</p>
                    <Form.Field>
                      <input
                        placeholder="Startdatum"
                        className="form1-item"
                        required
                        disabled
                        defaultValue={subscriptionDetail.subscriptionStart}
                        onChange={(e) =>
                          setAbonement({
                            ...abonement,
                            subscriptionStart: e.target.value
                          })
                        }
                        value={abonement.subscriptionStart}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">End date</p>
                    <Form.Field>
                      <input
                        placeholder="Einddatum"
                        className="form1-item"
                        defaultValue={subscriptionDetail.subscriptionEnd}
                        required
                        disabled
                        onChange={(e) => setAbonement({ ...abonement, subscriptionEnd: e.target.value })}
                        value={abonement.subscriptionEnd}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">Community</p>
                    <Form.Field>
                      <input
                        placeholder="Community"
                        className="form1-item"
                        required
                        disabled
                        defaultValue={subscriptionDetail.communityName}
                        onChange={(e) =>
                          setAbonement({
                            ...abonement,
                            communityName: e.target.value
                          })
                        }
                        value={normalizeCase(abonement.communityName)}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row">
                    <p className="customer-detail-names">Gateway No.</p>
                    <Form.Field>
                      <input
                        placeholder="Gateway number"
                        className="form1-item"
                        required
                        disabled
                        defaultValue={subscriptionDetail.gatewayNumber}
                        onChange={(e) =>
                          setAbonement({
                            ...abonement,
                            gatewayNumber: e.target.value
                          })
                        }
                        value={abonement.gatewayNumber}
                      />
                    </Form.Field>
                  </div>
                  <div className="flex1-row flex1-end">
                    <Button
                      disabled
                      type="submit"
                      className={userData.customerType === 'private' ? 'update-btn' : 'submit-btn'}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default UserDetails
