import './library-input.scss'
import * as React from 'react'
import cn from 'classnames'
import { Dropdown, Button } from 'semantic-ui-react'
import IconButton from '~/components/IconButton'
import { ApplicationGrouping } from '~/enums'
import { IDropdownOption } from '~/interfaces/general'
import { LibraryInputValueItem } from '~/interfaces/library'

interface LibraryInputItemProps {
    applicationOptions: IDropdownOption[]
    application: string,
    grouping: string
    className?: string
    onApplicationChange: (value: string) => any
    onGroupingChange: (value: string) => any
    onAddClick: () => any
    onRemoveClick: () => any
}

interface LibraryInputProps {
    value: LibraryInputValueItem[]
    applicationOptions: IDropdownOption[]
    className?: string
    onAddClick: (row: number) => any
    onRemoveClick: (row: number) => any
    onApplicationChange: (row: number, value: any) => any
    onGroupingChange: (row: number, value: any) => any
}


const ApplicationGroupingOptions: IDropdownOption[] = [
    { key: ApplicationGrouping.GROUP_LEVEL, text: ApplicationGrouping.GROUP_LEVEL, value: ApplicationGrouping.GROUP_LEVEL },
    { key: ApplicationGrouping.MAKE_LEVEL, text: ApplicationGrouping.MAKE_LEVEL, value: ApplicationGrouping.MAKE_LEVEL },
    { key: ApplicationGrouping.MODEL_LEVEL, text: ApplicationGrouping.MODEL_LEVEL, value: ApplicationGrouping.MODEL_LEVEL },
]


const LibraryInputItem = (props: LibraryInputItemProps) => {
    return (
        <div className={cn('library_input_item', props.className)}>
            <Dropdown
                selection
                className="library_input_item-dropdown"
                placeholder="Select Application"
                value={props.application}
                options={props.applicationOptions}
                onChange={(e, data) => props.onApplicationChange(data.value as string)} />
            <Dropdown
                selection
                className="library_input_item-dropdown"
                placeholder="Select Grouping"
                value={props.grouping}
                options={ApplicationGroupingOptions}
                onChange={(e, data) => props.onGroupingChange(data.value as string)} />
            <IconButton
                className="library_input_item-button"
                iconName="plus"
                iconColor='blue'
                onClick={props.onAddClick} />
            <IconButton
                className="library_input_item-button"
                iconName="minus"
                iconColor='red'
                onClick={props.onRemoveClick} />
        </div>
    );
}

const LibraryInput = (props: LibraryInputProps) => (
    <div className={cn('library_input', props.className)}>
        {props.value.map((val, row) => {
            return (
                <LibraryInputItem
                    application={val.application}
                    grouping={val.grouping}
                    applicationOptions={props.applicationOptions}
                    onAddClick={() => props.onAddClick(row)}
                    onRemoveClick={() => props.onRemoveClick(row)}
                    onApplicationChange={(value) => props.onApplicationChange(row, value)}
                    onGroupingChange={(value) => props.onGroupingChange(row, value)} />
            );
        })}
    </div>
)

export default LibraryInput;