import * as React from 'react'
import { useContext } from 'react'
import * as sunSymbol from '../../../assets/sun.png'
import * as upArrow from '../../../assets/up_arrow.png'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryStack, VictoryLabel, VictoryGroup, VictoryPie } from 'victory'
import { Image } from 'semantic-ui-react'
import './electricity.scss'
import { lightGreyColorScale, yellowColorScale, darkGreyColorScale } from '../../../util/graph_data'
import * as blueUpArrow from '../../../assets/blue_up_arrow.png'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import * as arrowIcon from '../../../assets/Arrow.svg'
import AuthContext from '../../../contexts/auth'
import { tickCount } from '../../../util/helper'
import UsageService from '../../../services/usage'
import DataContext from '../../../contexts/data'
import { ISolarAggregate } from '../../../interfaces/aggregate'
const moment = require('moment')

interface IProps {
  setTileExpanded: (value: string) => void
  tileExpanded: string
}
const SolarPanel: React.FC<IProps> = (props: IProps) => {
  const [expand, setExpand] = React.useState(false)
  const [value, setValue] = React.useState<string | undefined>(moment(new Date()).format('YYYY-MM-DD'))
  const [arrowUp, setArrowUp] = React.useState(false)
  const [usageData, setUsageData] = React.useState([])
  const [solarTickCount, setSolarTickcount] = React.useState<number>()
  const dataContext = useContext(DataContext)
  const [solar, setSolar] = React.useState<ISolarAggregate | undefined>(dataContext?.aggregate?.solarAggregate)
  const authContext = React.useContext(AuthContext)
  const usageService = new UsageService()
  const detailsExpand = () => {
    setExpand(!expand)
  }
  const closeGraph = () => {
    setArrowUp(true)
    props.setTileExpanded('')
  }
  const handleDate = (e: any) => {
    setValue(e.target.value)
    setArrowUp(false)
  }
  const rightArrowClicked = async (e: any) => {
    props.setTileExpanded('solar')
  }
  const fetchData = async () => {
    const token = authContext?.accessToken!
    const results = await usageService.getSolarUsage(token, value)
    setUsageData(results)
  }

  React.useEffect(() => {
    fetchData()
  }, [value])

  const startAngle = 180
  let actualEndAngle = 180
  let predictedEndAngle = 180
  if (Math.abs(solar?.actualGenerated!) >= Math.abs(solar?.predictedGenerated!)) {
    actualEndAngle = solar?.actualGenerated! > 0 ? 495 : -135
    const percentageOfPredictedGenerated =
      (100 * Math.abs(solar?.predictedGenerated!)) / Math.abs(solar?.actualGenerated!)
    predictedEndAngle = (percentageOfPredictedGenerated / 100) * actualEndAngle + 180
  } else {
    predictedEndAngle = solar?.predictedGenerated! > 0 ? 495 : -135
    const percentageOfActualGenerated = (100 * Math.abs(solar?.actualGenerated!)) / Math.abs(solar?.predictedGenerated!)
    actualEndAngle = (percentageOfActualGenerated / 100) * predictedEndAngle + 180
  }
  React.useEffect(() => {
    setSolarTickcount(tickCount(usageData))
  }, [usageData])

  React.useEffect(() => { setSolar(dataContext?.aggregate?.solarAggregate) }, [dataContext?.aggregate?.solarAggregate])

  const isAllZero: boolean = usageData.every((item: any) => item.value === 0)
  return (
    <>
      <div className="grid-container">
        <div className="flex1-row space-between">
          <strong className="title">Zonnepanelen</strong>
          <strong className="detail-txt">Huidig vermogen</strong>
        </div>
        <hr />
        <div className="tile-content">
          <div className="flex1-row flex1-end bold-font">
            {solar && solar.totalPower.toFixed(2).replace('.', ',')} W
          </div>
          <div className="flex1-column">
            <div className="electricity-graph-positioning">
              <div className={props.tileExpanded ? 'expanded-tile' : 'normal-tile'}>
                <VictoryGroup height={180}>
                  <VictoryPie
                    startAngle={startAngle}
                    endAngle={predictedEndAngle}
                    colorScale={lightGreyColorScale}
                    standalone={false}
                    labels={() => null}
                    data={[{ y: 1 }]}
                    radius={70}
                    innerRadius={60}
                    style={{ labels: { fontSize: 16, fill: 'white' } }}
                  />
                  <VictoryPie
                    startAngle={startAngle}
                    endAngle={actualEndAngle}
                    colorScale={yellowColorScale}
                    standalone={false}
                    labels={() => null}
                    data={[{ y: 1 }]}
                    radius={90}
                    innerRadius={73}
                    style={{ labels: { fontSize: 16, fill: 'white' } }}
                  />
                  <VictoryLabel
                    style={{ fill: 'rgba(251,187,33)', fontSize: '14px', textAlign: 'center' }}
                    x={solar?.actualGenerated === 0 ? 205 : 195}
                    y={85}
                    text={
                      solar?.actualGenerated !== 0
                        ? `${solar?.actualGenerated.toFixed(2).toString().replace('.', ',')} kWh`
                        : `${solar?.actualGenerated.toString().replace('.', ',')} kWh`
                    }
                    direction="ltr"
                  />
                  <VictoryLabel
                    style={{ fill: 'rgba(204,204,204)', fontSize: '14px', textAlign: 'center' }}
                    x={solar?.predictedGenerated === 0 ? 205 : 195}
                    y={105}
                    text={
                      solar?.predictedGenerated !== 0
                        ? `${solar?.predictedGenerated.toFixed(2).toString().replace('.', ',')} kWh`
                        : `${solar?.predictedGenerated.toString().replace('.', ',')} kWh`
                    }
                    direction="ltr"
                  />
                </VictoryGroup>
                <Image src={sunSymbol.default} className={props.tileExpanded ? 'sun-symbol modified' : 'sun-symbol'} />
              </div>
            </div>
            <div className="flex1-row space-between flex-center-vertical " style={{ width: '100%' }}>
              <div className="flex1-row flex1-center" style={{ width: '95%' }}>
                <div
                  className={
                    props.tileExpanded
                      ? 'actual flex1-row flex1-end modified-tile'
                      : 'actual flex1-row flex1-end normal-tile'
                  }
                >
                  Actueel
                </div>
                <div
                  className={
                    props.tileExpanded
                      ? 'expected flex1-row flex1-start modified-tile'
                      : 'expected flex1-row flex1-start normal-tile'
                  }
                  style={{ marginLeft: '15px' }}
                >
                  Voorspeld
                </div>
              </div>
              {props.tileExpanded && <div className="flex1-row flex1-end" style={{ width: '5%' }}></div>}
              {!props.tileExpanded && (
                <div className="flex1-row flex1-end">
                  <Image src={arrowIcon.default} className="right-arrow-icon" onClick={rightArrowClicked} />
                </div>
              )}
            </div>

            {props.tileExpanded && (
              <div>
                <div className="flex1-row space-between" style={{ marginTop: '10px' }}>
                  <strong>Zonnepanelen historie</strong>
                  <div className="fle1-row flex-center-vertical" onClick={detailsExpand}>
                    <strong className="detail-txt select-period">Selecteer periode</strong>
                    <input type="date" className="input-date" style={{ opacity: '0' }} onChange={handleDate}></input>
                    {expand && <Image src={blueUpArrow.default} style={{ marginBottom: '2px' }} />}
                    {!expand && <Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
                  </div>
                </div>
                <hr />
                <div>
                  {value && (
                    <div style={{ marginTop: '10px' }}>
                      <div style={{ fontSize: '14px' }}> {value && moment(value).format('DD MMMM YYYY')}</div>
                      <div className="monitor-graph-container m-t-medium">
                        <VictoryChart
                          height={80}
                          scale={{ x: 'time' }}
                          padding={{ top: 5, bottom: 20, right: 10, left: 26 }}
                        >
                          {usageData && isAllZero ? (
                            <VictoryAxis dependentAxis={true} tickFormat={() => ''} />
                          ) : (
                            <VictoryAxis
                              dependentAxis={true}
                              style={{
                                tickLabels: { fontSize: '5px', padding: 3 }
                              }}
                              crossAxis={false}
                              tickFormat={(t) => t}
                            />
                          )}
                          {usageData && isAllZero ? (
                            <VictoryAxis tickFormat={() => ''} />
                          ) : (
                            <VictoryAxis
                              style={{
                                axis: { stroke: 'black' },
                                grid: { stroke: darkGreyColorScale },
                                tickLabels: { fontSize: '5px', padding: -62 }
                              }}
                              tickFormat={(time) => {
                                return moment(time).format('HH:mm')
                              }}
                              tickLabelComponent={<VictoryLabel dx={6} />}
                              tickCount={solarTickCount && solarTickCount}
                              // tickCount={6}
                            />
                          )}
                          <VictoryLabel
                            textAnchor="end"
                            style={{ fontSize: 8, fill: '#FBBB21' }}
                            x={170}
                            y={75}
                            text="Actueel"
                          />
                          <VictoryLabel
                            textAnchor="end"
                            style={{ fontSize: 8, fill: darkGreyColorScale }}
                            x={solar?.actualGenerated === 0 ? 200 : 220}
                            y={75}
                            text={`${solar?.actualGenerated} kWh`}
                          />
                          <VictoryLabel
                            textAnchor="end"
                            style={{ fontSize: 8, fill: lightGreyColorScale }}
                            x={270}
                            y={75}
                            text="Voorspeld"
                          />
                          <VictoryLabel
                            textAnchor="end"
                            style={{ fontSize: 8, fill: lightGreyColorScale }}
                            x={solar?.actualGenerated === 0 ? 300 : 330}
                            y={75}
                            text={`${solar?.predictedGenerated} kWh`}
                          />
                          <VictoryStack>
                            {usageData.length > 1 && (
                              <VictoryArea
                                interpolation="cardinal"
                                style={{ data: { fill: '#FBBB21', opacity: '0.6' } }}
                                data={usageData.map((data: any) => {
                                  return {
                                    x: data.time,
                                    y: data.value
                                  }
                                })}
                              />
                            )}
                          </VictoryStack>
                        </VictoryChart>
                      </div>
                      <div className="flex1-row flex1-end">
                        <Image src={upArrow.default} onClick={closeGraph} className="monitor-arrow-up" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default SolarPanel
