import api from '../api'
import { getConfig } from './base'
import {
  IDso,
  IElectricityContract,
  IElectricityContractCreate,
  IElectricityGridContract,
  IElectricityGridContractLarge,
  IElectricityGridContractLargeUpdate,
  IElectricityGridContractUpdate,
  IEnergyRetailer,
  IGasConnectionContract,
  IGasConnectionContractUpdate,
  IGasConnectionType,
  IGasContract,
  IGasContractCreate,
  IGasTariff,
  IGridConnectionType,
  IGridConnectionTypeLarge,
  IGridTariff,
  IGridTransportTypeLarge
} from '../interfaces/contracts'

export const fetchEnergyRetailers = async (token: string): Promise<IEnergyRetailer[]> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/energy-retailers', config)
  return result.data
}

export const fetchTariffTypes = async (token: string): Promise<string[]> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/tariff-types', config)
  return result.data
}

export const fetchDsos = async (token: string): Promise<IDso[]> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/dsos', config)
  return result.data
}

export const fetchGridConnectionTypes = async (token: string): Promise<IGridConnectionType[]> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/electricity/connection-types', config)
  return result.data
}

export const fetchGasConnectionTypes = async (token: string): Promise<IGasConnectionType[]> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/gas/connection-types', config)
  return result.data
}

export const fetchConsumerTypes = async (token: string): Promise<string[]> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/consumer-types', config)
  return result.data
}

export const getGridTariff = async (token: string, dsoId: number|null = null, gridConnectionTypeId: number|null = null): Promise<IGridTariff> => {
  const config = getConfig(token)
  let url = '/contracts/electricity/tariff'
  if (dsoId !== null) {
    url = `${url}?dso_id=${dsoId}`
  }
  if (gridConnectionTypeId !== null) {
    url = dsoId != null ? `${url}&grid_connection_type_id=${gridConnectionTypeId}` : `${url}?grid_connection_type_id=${gridConnectionTypeId}`
  }
  const result = await api.get(url, config)
  return result.data
}

export const getGasTariff = async (token: string, dsoId: number|null = null, gasConnectionTypeId: number|null = null): Promise<IGasTariff> => {
  const config = getConfig(token)
  let url = '/contracts/gas/tariff'
  if (dsoId !== null) {
    url = `${url}?dso_id=${dsoId}`
  }
  if (gasConnectionTypeId !== null) {
    url = dsoId !== null ? `${url}&gas_connection_type_id=${gasConnectionTypeId}` : `${url}?gas_connection_type_id=${gasConnectionTypeId}`
  }
  const result = await api.get(url, config)
  return result.data
}

export const getElectricityContract = async (token: string): Promise<IElectricityContract> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/electricity/retail', config)
  return result.data
}

export const getDefaultElectricityContract = async (token: string): Promise<IElectricityContract> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/electricity/retail/default', config)
  return result.data
}

export const getElectricityGridContract = async (token: string): Promise<IElectricityGridContract> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/electricity/network', config)
  return result.data
}

export const getGasContract = async (token: string): Promise<IGasContract> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/gas/retail', config)
  return result.data
}

export const getDefaultGasContract = async (token: string): Promise<IGasContract> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/gas/retail/default', config)
  return result.data
}

export const getGasConnectionContract = async (token: string): Promise<IGasConnectionContract> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/gas/network', config)
  return result.data
}

export const createElectricityContract = async (token: string, data: IElectricityContractCreate): Promise<IElectricityContract> => {
  const config = getConfig(token)
  const result = await api.post('/contracts/electricity/retail', data, config)
  return result.data
}

export const updateElectricityGridContract = async (token: string, data: IElectricityGridContractUpdate): Promise<IElectricityGridContract> => {
  const config = getConfig(token)
  const result = await api.put('/contracts/electricity/network', data, config)
  return result.data
}

export const createGasContract = async (token: string, data: IGasContractCreate): Promise<IGasContract> => {
  const config = getConfig(token)
  const result = await api.post('/contracts/gas/retail', data, config)
  return result.data
}

export const updateGasConnectionContract = async (token: string, data: IGasConnectionContractUpdate): Promise<IGasConnectionContract> => {
  const config = getConfig(token)
  const result = await api.put('/contracts/gas/network', data, config)
  return result.data
}

export const updateElectricityGridCotractLarge = async (token: string, data: IElectricityGridContractLargeUpdate): Promise<IElectricityGridContractLarge> => {
  const config = getConfig(token)
  const result = await api.put('/contracts/electricity/network/large', data, config)
  return result.data
}

export const getElectricityGridContractLarge = async (token: string): Promise<IElectricityGridContractLarge|null> => {
  const config = getConfig(token)
  const result = await api.get('/contracts/electricity/network/large', config)
  return result.data
}

export const fetchLargeConnectionTypes = async (token: string, dsoId: string|number): Promise<IGridConnectionTypeLarge[]> => {
  const config = getConfig(token)
  const result = await api.get(`/contracts/connection-types/large?dso_id=${dsoId}`, config)
  return result.data
}

export const fetchLargeTransportTypes = async (token: string, dsoId: string|number): Promise<IGridTransportTypeLarge[]> => {
  const config = getConfig(token)
  const result = await api.get(`/contracts/transport-types/large?dso_id=${dsoId}`, config)
  return result.data
}
