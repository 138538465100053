import * as React from 'react'
import '../Monitor/battery.scss'
import { Button, Image, Divider, Dropdown } from 'semantic-ui-react'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { useState, useContext, useEffect } from 'react'
import * as blueUpArrow from '../../../assets/blue_up_arrow.png'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import { SolarEnergy } from '../..'
import './house_battery.scss'
import './configuration_details.scss'
import { IBatteryModel } from '../../../interfaces/battery'
import DataContext from '../../../contexts/data'
import { IHybridInverter, ISolarModel, IUserHybridInverterDeviceCreate } from '../../../interfaces/device'
import { fetchHybridInverterBatteryModels } from '../../../services/device'
import AuthContext from '../../../contexts/auth'
import { IDropdownOption } from '../../../interfaces/general'
import { DutchBatteryState, EnBatteryState } from '~/enums'
import { batteryLevel } from '../../../util/helper'
import { IBatteryAggregate } from '../../../interfaces/aggregate'
import * as batteryEmpty from '../../../assets/full_battery.svg'
import * as batteryFull from '../../../assets/empty_battery.svg'

const initialSolarData: ISolarModel = {
  powerRating: 0,
  panelCount: 0,
  panelArea: 1.6,
  panelOrientation: 180,
  panelTilt: 30
}

const HybridInverter: React.FC = () => {
  const phaseTypeMapping = { one_phase: '1 Phase', three_phase: '3 Phase' }
  const dataContext = useContext(DataContext)
  const authContext = useContext(AuthContext)

  const [expand, setExpand] = useState(false)

  const [batteryModels, setBatteryModels] = useState<IBatteryModel[]>([])
  const [batteryModelModelOptions, setBatteryModelModelOptions] = useState<IDropdownOption[]>([])
  const [batteryModelMakeOptions, setBatteryModelMakeOptions] = useState<any[]>([])
  const [selectedBatteryModelMake, setSelectedBatteryModelMake] = useState<any>()
  const [selectedBatteryModelModel, setSelectedBatteryModelModel] = useState<any>()
  const [hybridInverter, setHybridInverter] = useState<IHybridInverter | null>(null)
  const [selectedBatteryModel, setSelectedBatteryModel] = useState<IBatteryModel | null>(null)
  const [noOfRoofSide, setNoOfRoofSide] = useState<number>(0)
  const [pv1, setPv1] = useState<any>(null)
  const [pv2, setPv2] = useState<any>(null)
  const [batteryCount, setBatteryCount] = useState<number>(1)
  const [ipAddress, setIpAddress] = useState<string>('')
  const [port, setPort] = useState<string>('')
  const [battery, setBattery] = React.useState<IBatteryAggregate | undefined>(dataContext?.aggregate?.batteryAggregate)

  const detailsExpand = () => {
    setExpand(!expand)
  }

  const onSolarInputChange = (index: number) => (e: any) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'powerRating') {
      const panelCount = value > 0 ? Math.ceil(value / 0.24) : 0
      if (index === 1) {
        setPv1({ ...pv1, [name]: value, panelCount })
      }
      if (index === 2) {
        setPv2({ ...pv2, [name]: value, panelCount })
      }
    } else {
      if (index === 1) {
        setPv1({ ...pv1, [name]: value })
      }
      if (index === 2) {
        setPv2({ ...pv2, [name]: value })
      }
    }
  }

  const onSolarDropDownChange = (index: number) => (name: string, value: number) => {
    if (index === 1) {
      setPv1({ ...pv1, [name]: value })
    }
    if (index === 2) {
      setPv2({ ...pv2, [name]: value })
    }
  }

  const getDeviceProtocolParameterKey = (device: any) => {
    const keys = [
      'serialProtocolParameter',
      'zigbeeProtocolParameter',
      'modbusRtuOverTcpProtocolParameter',
      'modbusRtuProtocolParameter',
      'modbusTcpProtocolParameter'
    ]
    for (const key of keys) {
      if (device[key]) {
        return key
      }
    }
    return null
  }

  const getDeviceProtocolParameterValue = (device: any) => {
    const parameterKey = getDeviceProtocolParameterKey(device)
    return parameterKey ? device[parameterKey] : null
  }

  const fetchBatteryModels = async (hybridInverterId: number) => {
    const result = await fetchHybridInverterBatteryModels(authContext?.accessToken!, hybridInverterId)
    setBatteryModels(result)
  }

  const onBatteryMakeSelected = (e: any, data: any) => {
    setSelectedBatteryModelMake(data.value)
  }

  const onBatteryModelSelected = (e: any, data: any) => {
    setSelectedBatteryModelModel(data.value)
  }

  const getHybridInverterPayload = (): IUserHybridInverterDeviceCreate => {
    let payload = {
      batteryCount,
      batteryModelId: selectedBatteryModel?.id!,
      pvString1: pv1,
      modbusTcpProtocolParameter: undefined,
      modbusRtuOverTcpProtocolParameter: undefined,
      modbusRtuProtocolParameter: undefined,
      zigbeeProtocolParameter: undefined,
      serialProtocolParameter: undefined,
      pvString2: noOfRoofSide > 1 ? pv2 : undefined
    }
    const protocolParameterKey = getDeviceProtocolParameterKey(dataContext?.device?.activeUserHybridInverterDevice!)
    console.log({ protocolParameterKey })
    const protocolParameter = getDeviceProtocolParameterValue(dataContext?.device?.activeUserHybridInverterDevice!)
    console.log({ protocolParameter })
    payload = { ...payload, [protocolParameterKey!]: { ...protocolParameter, host: ipAddress, port } }
    return payload
  }

  const updateDevice = async () => {
    const payload: IUserHybridInverterDeviceCreate = getHybridInverterPayload()
    const device = dataContext?.device.activeUserHybridInverterDevice
    await dataContext?.device.updateHybridInverterDevice(device!.deviceId, payload)
  }

  useEffect(() => {
    if (dataContext?.device.activeUserHybridInverterDevice && batteryModels.length > 0) {
      let makes: any[] = batteryModels.map((batteryModel) => batteryModel.make)
      makes = makes.filter((v, i, a) => a.indexOf(v) === i) // filter out duplicates
      makes = makes.map((v) => ({ key: v, value: v, text: v }))
      setBatteryModelMakeOptions(makes)
      const device = dataContext?.device.activeUserHybridInverterDevice
      setSelectedBatteryModelMake(device.batteryModel.make)
      setSelectedBatteryModelModel(device.batteryModel.model)
    }
  }, [batteryModels])

  const state = () => {
    if (battery && battery.state === EnBatteryState.Idle) {
      return DutchBatteryState.Inactief
    } else if (battery && battery.state === EnBatteryState.Charging) {
      return DutchBatteryState.Opladen
    } else if (battery && battery.state === EnBatteryState.Discharging) {
      return DutchBatteryState.Ontladen
    }
  }

  useEffect(() => {
    if (selectedBatteryModelMake) {
      let models: any[] = batteryModels.map((batteryModel) => {
        if (batteryModel.make === selectedBatteryModelMake) {
          return batteryModel.model
        } else {
          return null
        }
      })
      models = models.filter((v) => v !== null) // filter out models not under make
      models = models.filter((v, i, a) => a.indexOf(v) === i) // filter out duplicates
      models = models.map((v) => ({ key: v, value: v, text: v }))
      setBatteryModelModelOptions(models)
    }
  }, [selectedBatteryModelMake])

  useEffect(() => {
    if (selectedBatteryModelMake && selectedBatteryModelModel) {
      const batteryModel = batteryModels.find(
        (b) => b.make === selectedBatteryModelMake && b.model === selectedBatteryModelModel
      )
      setSelectedBatteryModel(batteryModel!)
    } else {
      setSelectedBatteryModel(null)
    }
  }, [selectedBatteryModelMake, selectedBatteryModelModel])

  useEffect(() => {
    if (dataContext?.device.activeUserHybridInverterDevice) {
      const device = dataContext?.device.activeUserHybridInverterDevice
      const hybridInverter = dataContext?.device.hybridInverters.find((h) => h.id === device.deviceModel.id)
      const protocolParameter = getDeviceProtocolParameterValue(device)
      let noOfRoofSide = 0
      if (device.pvString1) {
        noOfRoofSide++
      }
      if (device.pvString2) {
        noOfRoofSide++
      }
      fetchBatteryModels(device!.deviceModel.id)
      setHybridInverter(hybridInverter!)
      setPort(protocolParameter.port)
      setIpAddress(protocolParameter.host)
      setBatteryCount(device!.batteryCount)
      setNoOfRoofSide(noOfRoofSide)
      setPv1(device!.pvString1 || initialSolarData)
      setPv2(device!.pvString2 || initialSolarData)
    }
  }, [dataContext?.device.activeUserHybridInverterDevice])

  useEffect(() => {
    setBattery(dataContext?.aggregate?.batteryAggregate)
  }, [dataContext?.aggregate?.batteryAggregate])

  return (
    <>
      <div>
        <div className="asset-container">
          <strong className="title">Zonnepanelen met batterij</strong>
          <hr />
          <div className="flex1-row m-t-medium">
            <div className="battery-outer" style={{ justifyContent: 'center' }}>
              <img src={batteryFull.default} className="battery-size" />
              <div className="battery-inner" style={{ width: `${batteryLevel(battery)}px` }}>
                <img src={batteryEmpty.default} className="battery-size" />
              </div>
            </div>

            <div className="flex1-column m-y-t" style={{ marginLeft: '40px' }}>
              <strong className="discharged m-b-medium">{state()}</strong>
              <strong className="discharged m-b-medium" style={{ fontSize: '18px' }}>
                {battery && `${battery.percentage}%`}
              </strong>
            </div>
          </div>
          <div className="fle1-row flex-center-vertical m-t-medium">
            <Button attached="right" disabled>
              Manual
            </Button>
            <Button
              attached="left"
              data-tooltip="Automatische bediening zonder invloed van buitenaf."
              data-position="bottom center"
            >
              Auto
            </Button>
            <Button attached="right" disabled>
              Auto extern
            </Button>
          </div>
        </div>
        <div className="flex1-row m-y-t flex1-end">
          <div className="fle1-row flex-center-vertical" onClick={detailsExpand}>
            <strong className="detail-txt">Details</strong>
            {expand && <Image src={blueUpArrow.default} style={{ marginBottom: '2px' }} />}
            {!expand && <Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
          </div>
        </div>
        <hr />
        {expand && (
          <div className="detail-container">
            <div className="configuration-details flex1-column">
              <strong className="flex1-row flex1-start m-t-medium ">Configuratie gegevens</strong>
              <Divider />
              <div className="flex1-column">
                <div className="flex1-row flex1-start">
                  <strong>Omvormer</strong>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium ">
                  <div className="configuration-column1">Merk</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input disabled className="obligation-input" defaultValue={hybridInverter!.make} />
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium ">
                  <div className="configuration-column1">Model</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input disabled className="obligation-input" defaultValue={hybridInverter!.model} />
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium ">
                  <div className="configuration-column1">Vermogen</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input disabled className="obligation-input" defaultValue={hybridInverter!.powerRating} />
                      <span style={{ marginLeft: '-30px', paddingTop: '8px', opacity: '0.9', fontSize: '14px' }}>
                        kW
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium ">
                  <div className="configuration-column1">Fase type</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input
                        defaultValue={phaseTypeMapping[hybridInverter!.phaseType]}
                        className="obligation-input"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium ">
                  <div className="configuration-column1">Communicatie</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input
                        defaultValue={hybridInverter!.communicationProtocol}
                        className="obligation-input"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium ">
                  <div className="configuration-column1">IP-adres</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input
                        placeholder="IP-adres"
                        name="ipAddress"
                        value={ipAddress}
                        onChange={(e) => setIpAddress(e.target.value)}
                      />
                      <IoMdInformationCircleOutline />
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium">
                  <div className="configuration-column1">Poort</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input placeholder="Poort" name="port" value={port} onChange={(e) => setPort(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex1-column m-t-medium">
                <div className="flex1-row space-between flex-center-vertical m-t-medium">
                  <strong className="configuration-column1">Batterij</strong>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2"></div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium">
                  <div className="configuration-column1">Merk</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <Dropdown
                        placeholder="Merk"
                        selection
                        fluid
                        options={batteryModelMakeOptions}
                        value={selectedBatteryModelMake}
                        onChange={onBatteryMakeSelected}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium">
                  <div className="configuration-column1">Model</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <Dropdown
                        placeholder="Model"
                        selection
                        fluid
                        options={batteryModelModelOptions}
                        value={selectedBatteryModelModel}
                        onChange={onBatteryModelSelected}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium">
                  <div className="configuration-column1">Aantal batterijen</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input
                        placeholder="Aantal batterijen"
                        value={batteryCount}
                        onChange={(e) => {
                          setBatteryCount(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium">
                  <div className="configuration-column1">Vermogen</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input
                        defaultValue={selectedBatteryModel?.powerRating}
                        className="obligation-input"
                        disabled
                      ></input>
                      <span style={{ marginLeft: '-30px', paddingTop: '8px', opacity: '0.9', fontSize: '14px' }}>
                        kW
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium">
                  <div className="configuration-column1">Capaciteit</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input
                        defaultValue={selectedBatteryModel?.capacity}
                        disabled
                        className="obligation-input"
                      ></input>
                      <span style={{ marginLeft: '-37px', paddingTop: '8px', opacity: '0.9', fontSize: '14px' }}>
                        kWh
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex1-row flex-center-vertical m-t-medium ">
                  <div className="configuration-column1">Type batterij</div>
                  <div className="flex1-column">
                    <div className="flex1-row configuration-column2">
                      <input defaultValue={selectedBatteryModel?.type} disabled className="obligation-input"></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex1-column m-y-t">
                <SolarEnergy
                  noOfRoofSide={noOfRoofSide}
                  pv1={pv1}
                  pv2={pv2}
                  setNoOfRoofSide={setNoOfRoofSide}
                  onDropdownChange={onSolarDropDownChange}
                  onInputChange={onSolarInputChange}
                />
              </div>
            </div>
          </div>
        )}
        <div className="flex1-row flex1-end">
          <button className="btn-blue" onClick={updateDevice}>
            Pas toe
          </button>
        </div>
      </div>
    </>
  )
}
export default HybridInverter
