import api from '../api'

export default class RetailerServce {
  listRetailers = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get('/retailers/', config)
    return response.data
  }

  updateRetailers = async (accessToken: string, retailers: any[]) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.put('/retailers/', retailers, config)
    return response.data
  }

  getElecPriceRefs = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get('/retailers/elec-price-refs', config)
    return response.data
  }

  updateElecPriceRefs = async (accessToken: string, priceRefs: any[]) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.put('/retailers/elec-price-refs', priceRefs, config)
    return response.data
  }

  getGasPriceRefs = async (accessToken: string) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.get('/retailers/gas-price-refs', config)
    return response.data
  }

  updateGasPriceRefs = async (accessToken: string, priceRefs: any[]) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await api.put('/retailers/gas-price-refs', priceRefs, config)
    return response.data
  }
}
