import * as React from 'react'
import './login.scss'
import { Button, Checkbox, Form, Image, Message, Modal, Input, Header } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useState, useContext } from 'react'
import * as logoWhite from '../../assets/rsz_logo_rgb_3-09-2019_3.jpg'
import AuthService from '../../services/auth'
import PersistenceService from '../../services/persistence'
import AuthContext from '../../contexts/auth'
import Page from '../../pages/Page'

// Defining the type for the loginform data
type Login = {
  userName: string
  password: string
}
// Login Ui Contents
const LoginForm: React.FC = () => {
  const persistenceService = new PersistenceService()

  // const [access, setAcess] = useState('')
  // const [refresh, setRefresh] = useState('')
  const [open, setOpen] = React.useState(false)
  const history = useHistory()

  // @ts-ignore
  // const [rememberMe, setRememberMe] = useState(false)
  const [failureMessage, setFailureMessage] = useState<string | null>(null)
  const [showUserName, setShowUserName] = useState(false)
  const [forgetPwdUserName, setForgetPwdUserName] = useState<string>('')
  const [forgetPwd, setForgetPwd] = useState<string>('')
  const [forgetConfirmPwd, setForgetConfirmPwd] = useState<string>('')
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [codeSentSuccess, setCodeSentSuccess] = React.useState<boolean>(false)
  const [codeSentFailure, setCodeSentFailure] = React.useState<boolean>(false)
  const [pwdResetSuccess, setPwdResetSuccess] = React.useState<boolean>(false)
  const [pwdResetFailure, setPwdResetFailure] = React.useState<boolean>(false)
  const authService = new AuthService()
  const authContext = useContext(AuthContext)

  // @ts-ignore
  const { from } = { from: { pathname: '/home' } }

  const { register, handleSubmit, errors } = useForm<Login>()

  const onSubmit = async (data: Login) => {
    try {
      const result = await authService.login(data.userName, data.password)
      const { access, refresh } = result
      authContext?.setUserToken({
        accessToken: access,
        refreshToken: refresh
      })
      history.replace(from)
    } catch (err) {
      setFailureMessage('Invalid login credentials')
    }
  }

  const resetPwdIsMatch = () => {
    return forgetPwd === forgetConfirmPwd
  }

  const onClickSendCode = async () => {
    if (forgetPwdUserName === '') {
      setShowUserName(true)
    } else {
      try {
        await authService.forgotPassword(forgetPwdUserName)
        setCodeSentSuccess(true)
      } catch (err) {
        setCodeSentFailure(true)
      }
    }
  }

  const onClickResetPwd = async () => {
    if (forgetPwdUserName === '') {
      setShowUserName(true)
    } else {
      try {
        await authService.resetPassword(forgetPwdUserName, verificationCode, forgetPwd)
        setPwdResetSuccess(true)
        setForgetPwdUserName('')
        setForgetPwd('')
        setForgetConfirmPwd('')
        setVerificationCode('')
      } catch (err) {
        setPwdResetFailure(true)
      }
    }
  }

  setTimeout(() => {
    setCodeSentSuccess(false)
    setCodeSentFailure(false)
    setPwdResetSuccess(false)
    setPwdResetFailure(false)
  }, 2000)

  setTimeout(() => {
    setFailureMessage(null)
  }, 2000)

  const onChangeCheckbox = (e: any, data: any) => {
    persistenceService.store('keepMeLoggedIn', data.checked.toString())
  }

  return (
    <Page>
      <div className="login-container flex1-column-start">
        <div className="form-container flex-row flex1-center login-margin-top">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-inner-item flex1-column">
              <div className="flex1-center flex1-column">
                <Image src={logoWhite.default} className="logo-size login-form-inner-item"></Image>
                <strong className="login-font-blue login-form-inner-item text-center">Smart Energy Platform</strong>
              </div>
              <div className="login-form-inputdetails space-between">
                <Form.Field>
                  <input placeholder="Gebruikersnaam" name="userName" ref={register({ required: true })} />
                  {errors.userName && errors.userName.type === 'required' && (
                    <div className="error-msg">Voer hier uw gebruikersnaam in.</div>
                  )}
                </Form.Field>
                <Form.Field>
                  <input placeholder="Wachtwoord" name="password" ref={register({ required: true })} type="password" />
                  {errors.password && errors.password.type === 'required' && (
                    <div className="error-msg">Voer hier uw wachwoord in.</div>
                  )}
                </Form.Field>
                <Form.Field className="flex1-row onhoud-contianer">
                  <Checkbox label="" onChange={onChangeCheckbox} />
                  <div className="login-onthoudmetxt">Houd me ingelogd</div>
                </Form.Field>
                <Button type="submit" className="btn-green">
                  Log in
                </Button>
              </div>
              <Modal
                closeIcon
                open={open}
                trigger={<a className="login-wachtword-verget-link">Wachtwoord vergeten ?</a>}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
              >
                <Header content="Forgot your password ?" />
                <Modal.Content>
                  <div className="flex1-row flex1-center forgetpassword-inputform">
                    <div className="flex1-column forgetpassword-container">
                      {showUserName && <Message color="red">Enter username.</Message>}
                      <Input
                        placeholder="User Name/E-mail"
                        onChange={(e) => {
                          setForgetPwdUserName(e.target.value)
                          if (e.target.value !== '') {
                            setShowUserName(false)
                          }
                        }}
                        value={forgetPwdUserName}
                        focus={showUserName}
                      ></Input>
                      <Button color="green" onClick={onClickSendCode}>
                        Send Code
                      </Button>
                      {codeSentSuccess && <Message color="green">Code has been sent successfully</Message>}
                      {codeSentFailure && <Message color="red">Code has not been sent successfully</Message>}
                      <Input
                        placeholder="Verification code"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        value={verificationCode}
                      ></Input>
                      <Input
                        placeholder="Password"
                        type="password"
                        onChange={(e) => setForgetPwd(e.target.value)}
                        value={forgetPwd}
                      ></Input>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        onChange={(e) => setForgetConfirmPwd(e.target.value)}
                        value={forgetConfirmPwd}
                      ></Input>
                    </div>
                  </div>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    color="blue"
                    disabled={!resetPwdIsMatch()}
                    onClick={() => {
                      onClickResetPwd()
                    }}
                  >
                    Submit
                  </Button>
                  {pwdResetSuccess && <Message color="green">Password has been reset please goto login page</Message>}
                  {pwdResetFailure && <Message color="red">Password has not been reset</Message>}
                </Modal.Actions>
              </Modal>
              {failureMessage && <Message color="red">{failureMessage}</Message>}
            </div>
          </Form>
        </div>
      </div>
    </Page>
  )
}
export default LoginForm
