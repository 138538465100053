import * as React from 'react'
import { Input, Table } from 'semantic-ui-react'

interface IListProp {
  retailers: any[]
  editRetailers: any
  inEditMode: any
  handleRetailerChange: Function
  handleRetailerClick: Function
}

const List: React.FC<IListProp> = (props: IListProp) => {
  const handleClickEvent = (id: string, field: string) => {
    props.handleRetailerClick(id, field)
  }

  const handleRetailerChange = (id: number, field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleRetailerChange(id, field, event)
  }

  return (
    <>
      <div className="source-component">
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {props.retailers.map((retailer, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell
                    className={
                      props.inEditMode.ids.includes(retailer.id) && 'name' in props.editRetailers[retailer.id]
                        ? 'dirty'
                        : ''
                    }
                  >
                    {
                      props.inEditMode.status &&
                      props.inEditMode.ids.includes(retailer.id) &&
                      'name' in props.editRetailers[retailer.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="text"
                            value={props.editRetailers[retailer.id].name}
                            onChange={handleRetailerChange.bind(this, retailer.id, 'name')}
                          />
                        ) : (
                          <p className="editableElement" onClick={handleClickEvent.bind(this, retailer.id, 'name')}>
                            {retailer.name}
                          </p>
                        )
                    }
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}

export default List
