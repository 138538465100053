import * as React from 'react'
import { useState, useEffect } from 'react'
import * as headerLogo from '../../assets/logo_rgb100x100.png'
import './community.scss'
import { Menu, Image, MenuItemProps, Dropdown, Segment } from 'semantic-ui-react'
import { Users as CommunityUsersComponent } from './users'
import { Community } from './community/index'
import { Dashboard } from './dashboard'
import { privacyStatement } from '../../util/options'
import UserContext from '../../contexts/user'
import AuthContext from '../../contexts/auth'
import * as options from '../../util/options'
import CommAdminService from '../../services/commAdminService'

const CommunityHomeMenu: React.FC = () => {
  const authContext = React.useContext(AuthContext)
  const userContext = React.useContext(UserContext)
  const commAdminService = new CommAdminService()

  const [activeItem, setActiveItem] = useState<string>('DASHBOARD')
  const [activeCommunity, setActiveCommunity] = useState<any>({})

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    setActiveItem(data.name as string)
  }

  const onChange = (e: any, data: any) => {
    if (data.value === 1) {
      authContext?.logoutCommunity()
    }
  }

  const parseCommunityName = (name: string) => {
    if (name == null) {
      return
    }
    const re = /community$/i
    let trimmed = name.trim()
    if (trimmed.match(re)) {
      trimmed = trimmed.replace(re, 'community')
      return trimmed + ' portal'
    } else {
      return trimmed + ' community portal'
    }
  }

  const fetchComunity = async () => {
    const accessToken = authContext?.accessToken!
    const result = await commAdminService.getCommunity(accessToken)
    setActiveCommunity(result)
  }

  useEffect(() => {
    fetchComunity()
  }, [])

  return (
    <div id="root-container">
      <div className="community-container">
        <div className="flex1-row header-container">
          <Menu attached="top" tabular className="flex1-row">
            <div className="header-column1 flex1-row flex-center-vertical flex1-start">
              <Image src={headerLogo.default} className="header-logo-size header-logo-positioning" alt="Header logo" />
              <pre className="community-portal">{parseCommunityName(activeCommunity.name)}</pre>
            </div>
            <div className="header-column2 flex1-row flex1-center">
              <Menu.Item
                name="DASHBOARD"
                active={activeItem === 'DASHBOARD'}
                onClick={handleItemClick}
                id="dashboard-active"
              >
                <h4>DASHBOARD</h4>
              </Menu.Item>
              <Menu.Item name="USERS" active={activeItem === 'USERS'} onClick={handleItemClick} id="users-active">
                <h4>USERS</h4>
              </Menu.Item>
              <Menu.Item
                name="COMMUNITY"
                active={activeItem === 'COMMUNITY'}
                onClick={handleItemClick}
                id="community-active"
              >
                <h4>COMMUNITY</h4>
              </Menu.Item>
            </div>
            <div className="header-column3 user-name flex-center-vertical flex1-row flex1-end">
              <Dropdown
                text={userContext?.profile?.lastName}
                options={options.communityAdminOptions}
                onChange={onChange}
                simple
                item
              />
            </div>
          </Menu>
        </div>
        {activeItem === 'DASHBOARD' && 'id' in activeCommunity && (
          <Segment attached="bottom">
            <Dashboard community={activeCommunity} />
          </Segment>
        )}
        {activeItem === 'USERS' && (
          <Segment attached="bottom">
            <CommunityUsersComponent community={activeCommunity} />
          </Segment>
        )}
        {activeItem === 'COMMUNITY' && (
          <Segment attached="bottom">
            <Community community={activeCommunity} />
          </Segment>
        )}
      </div>
      <div className="footer-contianer flex-center-vertical">
        <a className="footer-policy-txt" href={privacyStatement} target="_blank" rel="noreferrer">
          Privacybeleid
        </a>
      </div>
    </div>
  )
}

export default CommunityHomeMenu
