import * as React from 'react'
import cn from 'classnames'
import { Button, Icon, SemanticICONS } from 'semantic-ui-react'

interface IconButtonProps {
  iconName: string
  iconColor?: string
  className?: string
  onClick?: () => any
}

const IconButton = (props: IconButtonProps) => (
  <Button onClick={props.onClick} className={cn(props.className)} type="button">
    <Button.Content>
      <Icon name={props.iconName} color={props.iconColor} />
    </Button.Content>
  </Button>
)

export default IconButton
