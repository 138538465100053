import * as React from 'react'
import './new-device-group.scss'
import { IDevice } from '../../../interfaces/device'
import DeviceForm from './DeviceForm'

interface IDeviceProps{
  device: IDevice
}

const DeviceDetailComponent = (props: IDeviceProps) => {
  return (
    <div className='container'>
      <div className='container__inner'>
        <h3 className="form-header">Update Device</h3>
        <hr />
        <DeviceForm device={props.device}/>
      </div>
    </div>

  )
}

export default DeviceDetailComponent
