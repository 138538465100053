import * as React from 'react'
// import CommunityRow from './CommunityRow'
import CommunityService from '../../../services/community'
import AuthContext from '../../../contexts/auth'
import { DataTable } from '../../Generic/Table'
import { CommunityDetails } from '../../shared/community'

const CommunitiesList: React.FC = () => {
  const communityService = new CommunityService()
  const authContext = React.useContext(AuthContext)

  const [communities, setCommunities] = React.useState([])

  const fetchCommunities = async () => {
    const accessToken = authContext?.accessToken!
    const result = await communityService.listCommunities(accessToken)
    setCommunities(result)
  }

  const communityDetailsComponent = (community: any) => {
    return <CommunityDetails community={community} />
  }

  const getTableValueForKey = (key: string, user: any) => {
    const mapping: any = {
      'Community ID': 'id',
      Name: 'name',
      'Active Users': 'activeUsers',
      Online: 'lostLt24',
      Offline: 'lostGt24',
      Pending: 'pending'
    }
    return user[mapping[key]]
  }

  React.useEffect(() => {
    fetchCommunities()
  }, [])

  return (
    <DataTable
      displayKeys={['Community ID', 'Name', 'Active Users', 'Online', 'Offline', 'Pending']}
      records={communities}
      detailsComponent={communityDetailsComponent}
      valueForkey={getTableValueForKey}
    />
  )
}

export default CommunitiesList
