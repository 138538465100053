import * as React from 'react'
import { Segment } from 'semantic-ui-react'
import { Date } from '../..'
import { CommunityDetails } from '../../shared/community'

import './community.scss'

interface ICommunityProps {
  community: any
}

const Community: React.FC<ICommunityProps> = (props) => {
  return (
    <div className="home-menu-container">
      <div className="flex1-row flex1-end">
        <div className="mr2">
          <Date />
        </div>
      </div>
      <div className="community-container" id="contain">
        <Segment attached="bottom" className="contain">
          <CommunityDetails community={props.community} />
        </Segment>
      </div>
    </div>
  )
}

export default Community
