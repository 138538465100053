import * as React from 'react'
import { useState, useEffect, useContext } from 'react'
import DataContext, { IDataContext } from '../../contexts/data'
import * as constants from '../../constants'
import * as dataService from '../../services/data'
import * as contractService from '../../services/contract'
import * as batteryService from '../../services/battery'
import * as deviceService from '../../services/device'
import { useInterval } from '../../hooks'
import AuthContext from '../../contexts/auth'
import {
  IElectricityAggregate,
  IGasAggregate,
  ISolarAggregate,
  IBatteryAggregate,
  ISummaryAggregate
} from '../../interfaces/aggregate'
import {
  IHybridInverter,
  IHybridInverterCreate,
  IHybridInverterUpdate,
  IMainMeter,
  IMainMeterCreate,
  IMainMeterUpdate,
  IUserDevice,
  IUserHybridInverterDevice,
  IUserHybridInverterDeviceCreate,
  IUserMainMeterDevice
  // ILargeConsumerMainmeter
} from '../../interfaces/device'
import {
  IElectricityContract,
  IEnergyRetailer,
  IGasContract,
  IGridConnectionType,
  IDso,
  IGasConnectionType,
  IGridTariff,
  IGasTariff,
  IGasContractCreate,
  IElectricityContractCreate,
  IElectricityGridContract,
  IGasConnectionContract,
  IGasConnectionContractUpdate,
  IElectricityGridContractUpdate,
  IElectricityGridContractLarge,
  IElectricityGridContractLargeUpdate
} from '../../interfaces/contracts'
import UserContext from '../../contexts/user'
import { IBatteryModelCreate, IBatteryModel } from '../../interfaces/battery'
import { executeInParallel } from '../../utils'
import { SmartControllerApplicaiton } from '~/enums'

const initialElectricityAggregate: IElectricityAggregate = {
  actualCost: 0,
  actualEnergy: 0,
  predictedCost: 0,
  predictedEnergy: 0,
  instantPower: 0,
  exportTariff: 0,
  importTariff: 0,
  powerGenerated: 0,
  powerUsage: 0
}

const initialGasAggregate: IGasAggregate = {
  actualCost: 0,
  actualVolume: 0,
  predictedCost: 0,
  predictedVolume: 0
}

const initialSolarAggregate: ISolarAggregate = {
  actualGenerated: 0,
  predictedGenerated: 0,
  totalPower: 0
}

const initialSummaryAggregate: ISummaryAggregate = {
  totalConsumption: 0,
  fromGrid: 0,
  fromSolar: 0,
  fromBattery: 0
}

const initialBatteryAggregate: IBatteryAggregate = {
  state: '',
  percentage: 0
}

const initialElectricityContract: IElectricityContract = {
  id: 0,
  startDateTime: '',
  endDateTime: '',
  tariffType: '',
  fixedFeePerYear: 0,
  tariffHighPerKwh: 0,
  tariffLowPerKwh: 0,
  tariffFlatPerKwh: 0,
  feedInTariffPerKwh: 0,
  isDefaultContract: true
}

const initialElectricityGridContract: IElectricityGridContract = {
  startDateTime: '',
  endDateTime: '',
  dso: {
    id: 0,
    name: ''
  },
  gridConnectionType: {
    id: 0,
    connectionName: '',
    consumerType: ''
  },
  contractedPower: 0
}

const initialGasContract: IGasContract = {
  id: 0,
  startDateTime: '',
  endDateTime: '',
  tariffType: '',
  fixedFeePerYear: 0,
  tariffRetailPerM3: 0,
  tariffRegionComponent: 0,
  isDefaultContract: true
}

const initialGasConnectionContract: IGasConnectionContract = {
  startDateTime: '',
  endDateTime: '',
  dso: {
    id: 0,
    name: ''
  },
  gasConnectionType: {
    id: 0,
    connectionName: '',
    consumerType: ''
  }
}

const initialGridTariff: IGridTariff = {
  fixedTariffPerYear: 0,
  year: ''
}

const initialGasTariff: IGasTariff = {
  fixedTariffPerYear: 0,
  year: ''
}

const DataProvider: React.FC = ({ children }) => {
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)

  const [electricityAggregate, setElectricityAggregate] = useState<IElectricityAggregate>(initialElectricityAggregate)
  const [summaryAggregate, setSummaryAggregate] = useState<ISummaryAggregate>(initialSummaryAggregate)
  const [gasAggregate, setGasAggregate] = useState<IGasAggregate>(initialGasAggregate)
  const [solarAggregate, setSolarAggregate] = useState<ISolarAggregate>(initialSolarAggregate)
  const [batteryAggregate, setBatteryAggregate] = useState<IBatteryAggregate>(initialBatteryAggregate)
  const [smartControllerDevicesAggregate, setSmartControllerDevicesAggregate] = useState<any[]>([])
  const [batteryModels, setBatteryModels] = useState<IBatteryModel[]>([])
  const [activeUserMainMeterDevice, setActiveUserMainMeterDevice] = useState<IUserMainMeterDevice | null>(null)
  const [
    activeUserHybridInverterDevice,
    setActiveUserHybridInverterDevice
  ] = useState<IUserHybridInverterDevice | null>(null)
  const [activeBoilerDevice, setActiveBoilerDevice] = useState<IUserDevice | null>(null)
  const [activeSpaceHeaterDevice, setActiveSpaceHeaterDevice] = useState<IUserDevice | null>(null)
  const [activeSmartPlugDevice, setActiveSmartPlugDevice] = useState<IUserDevice | null>(null)
  const [mainMeters, setMainMeters] = useState<IMainMeter[]>([])
  const [hybridInverters, setHybridInverters] = useState<IHybridInverter[]>([])
  const [smartControllers, setSmartControllers] = useState<any[]>([])
  const [userMainMeterDevices, setUserMainMeterDevices] = useState<IUserMainMeterDevice[]>([])
  const [userHybridInverterDevices, setUserHybridInverterDevices] = useState<IUserHybridInverterDevice[]>([])
  const [userSmartControllerDevices, setUserSmartControllerDevices] = useState<any[]>([])
  const [electricityContract, setElectricityContract] = useState<IElectricityContract>(initialElectricityContract)
  const [defaultElectricityContract, setDefaultElectricityContract] = useState<IElectricityContract>(
    initialElectricityContract
  )
  const [electricityGridContract, setElectricityGridContract] = useState<IElectricityGridContract>(
    initialElectricityGridContract
  )
  const [
    electricityGridContractLarge,
    setElectricityGridContractLarge
  ] = useState<IElectricityGridContractLarge | null>(null)
  const [gasContract, setGasContract] = useState<IGasContract>(initialGasContract)
  const [defaultGasContract, setDefaultGasContract] = useState<IGasContract>(initialGasContract)
  const [gasConnectionContract, setGasConnectionContract] = useState<IGasConnectionContract>(
    initialGasConnectionContract
  )
  const [gridTariff, setGridTariff] = useState<IGridTariff>(initialGridTariff)
  const [gasTariff, setGasTariff] = useState<IGasTariff>(initialGasTariff)
  const [energyRetailers, setEnergyRetailers] = useState<IEnergyRetailer[]>([])
  const [tariffTypes, setTariffTypes] = useState<string[]>([])
  const [dsos, setDsos] = useState<IDso[]>([])
  const [gridConnectionTypes, setGridConnectionTypes] = useState<IGridConnectionType[]>([])
  const [gasConnectionTypes, setGasConnectionTypes] = useState<IGasConnectionType[]>([])
  const [consumerTypes, setConsumerTypes] = useState<string[]>([])

  const fetchBatteryModels = async () => {
    const result = await batteryService.fetchBatteryModels(authContext?.accessToken!)
    setBatteryModels(result)
  }

  const createBatteryModel = async (data: IBatteryModelCreate) => {
    const result = await batteryService.createBatteryModel(authContext?.accessToken!, data)
    setBatteryModels([...batteryModels, result])
  }

  const fetchDevices = async () => {
    const mainMeters = await deviceService.fetchMainMeterDevices(authContext?.accessToken!)
    const hybridInverters = await deviceService.fetchHybridInverterDeviceTypes(authContext?.accessToken!)
    const smartControllers = await deviceService.fetchSmartControllerDeviceTypes(authContext?.accessToken!)
    setMainMeters(mainMeters)
    setHybridInverters(hybridInverters)
    setSmartControllers(smartControllers)
  }

  const fetchUserDevices = async () => {
    const mainMeterDevices = await deviceService.fetchUserMainMeterDevices(authContext?.accessToken!)
    const hybridInverterDevices = await deviceService.fetchUserHybridInverterDevices(authContext?.accessToken!)
    const smartControlerDevices = await deviceService.fetchUserSmartControllerDevices(authContext?.accessToken!)
    setUserMainMeterDevices(mainMeterDevices)
    setUserHybridInverterDevices(hybridInverterDevices)
    setUserSmartControllerDevices(smartControlerDevices)
  }

  const fetchElectricityContract = async () => {
    const result = await contractService.getElectricityContract(authContext?.accessToken!)
    setElectricityContract(result)
  }

  const fetchDefaultElectricityContract = async () => {
    const result = await contractService.getDefaultElectricityContract(authContext?.accessToken!)
    setDefaultElectricityContract(result)
  }

  const fetchElectricityGridContract = async () => {
    const result = await contractService.getElectricityGridContract(authContext?.accessToken!)
    setElectricityGridContract(result)
  }

  const getElectricityGridContractLarge = async () => {
    const result = await contractService.getElectricityGridContractLarge(authContext?.accessToken!)
    setElectricityGridContractLarge(result)
  }

  const fetchGasContract = async () => {
    const result = await contractService.getGasContract(authContext?.accessToken!)
    setGasContract(result)
  }

  const fetchDefaultGasContract = async () => {
    const result = await contractService.getDefaultGasContract(authContext?.accessToken!)
    setDefaultGasContract(result)
  }

  const fetchGasConnectionContract = async () => {
    const result = await contractService.getGasConnectionContract(authContext?.accessToken!)
    setGasConnectionContract(result)
  }

  const fetchEnergyRetailers = async () => {
    const result = await contractService.fetchEnergyRetailers(authContext?.accessToken!)
    setEnergyRetailers(result)
  }

  const fetchTariffTypes = async () => {
    const result = await contractService.fetchTariffTypes(authContext?.accessToken!)
    setTariffTypes(result)
  }

  const fetchDsos = async () => {
    const result = await contractService.fetchDsos(authContext?.accessToken!)
    setDsos(result)
  }

  const fetchGridConnectionTypes = async () => {
    const result = await contractService.fetchGridConnectionTypes(authContext?.accessToken!)
    setGridConnectionTypes(result)
  }

  const fetchGasConnectionTypes = async () => {
    const result = await contractService.fetchGasConnectionTypes(authContext?.accessToken!)
    setGasConnectionTypes(result)
  }

  const fetchConsumerTypes = async () => {
    const result = await contractService.fetchConsumerTypes(authContext?.accessToken!)
    setConsumerTypes(result)
  }

  const fetchGridTariff = async (dsoId: number | null, gridConnectionTypeId: number | null) => {
    const result = await contractService.getGridTariff(authContext?.accessToken!, dsoId, gridConnectionTypeId)
    setGridTariff(result)
  }

  const fetchGasTariff = async (dsoId: number | null, gasConnecionTypeId: number | null) => {
    const result = await contractService.getGasTariff(authContext?.accessToken!, dsoId, gasConnecionTypeId)
    setGasTariff(result)
  }

  const createMainMeter = async (data: IMainMeterCreate) => {
    const result = await deviceService.createOrUpdateMainMeterDevice(authContext?.accessToken!, data)
    setMainMeters([...mainMeters, result])
  }

  const createHybridInverter = async (data: IHybridInverterCreate) => {
    const result = await deviceService.createOrUpdateHybridInverterDeviceType(authContext?.accessToken!, data)
    setHybridInverters([...hybridInverters, result])
  }

  const createSmartController = async (data: any) => {
    const result = await deviceService.createOrUpdateSmartControllerDeviceType(authContext?.accessToken!, data)
    setSmartControllers([...smartControllers, result])
  }

  const updateMainMeter = async (data: IMainMeterUpdate) => {
    const result = await deviceService.createOrUpdateMainMeterDevice(authContext?.accessToken!, data)
    const newMainMeters = [...mainMeters]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newMainMeters.length; i++) {
      const mainMeter = newMainMeters[i]
      if (mainMeter.id === result.id) {
        newMainMeters[i] = result
        break
      }
    }
    setMainMeters(newMainMeters)
  }

  const updateHybridInverter = async (data: IHybridInverterUpdate) => {
    const result = await deviceService.createOrUpdateHybridInverterDeviceType(authContext?.accessToken!, data)
    const newHybridInverters = [...hybridInverters]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newHybridInverters.length; i++) {
      const hybridInverter = newHybridInverters[i]
      if (hybridInverter.id === result.id) {
        newHybridInverters[i] = result
        break
      }
    }
    setMainMeters(newHybridInverters)
  }

  const updateSmartController = async (data: any) => {
    const result = await deviceService.createOrUpdateSmartControllerDeviceType(authContext?.accessToken!, data)
    const newSmartControllers = [...smartControllers]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newSmartControllers.length; i++) {
      const smartController = newSmartControllers[i]
      if (smartController.id === result.id) {
        newSmartControllers[i] = result
        break
      }
    }
    setSmartControllers(newSmartControllers)
  }

  const updateMainMeterDeviceGasConnected = async (value: boolean) => {
    const result = await deviceService.updateIsGasConnected(authContext?.accessToken!, value)
    const newUserMainMeterDevices = [...userMainMeterDevices]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newUserMainMeterDevices.length; i++) {
      const mainMeterDevice = newUserMainMeterDevices[i]
      if (mainMeterDevice.deviceId === result.deviceId) {
        newUserMainMeterDevices[i] = result
        break
      }
    }
    setUserMainMeterDevices(newUserMainMeterDevices)
  }

  const getElectricityAggregate = async () => {
    const result = await dataService.getElectricityAggregate(authContext?.accessToken!)
    setElectricityAggregate(result)
  }

  // Needed later when we Inzicht summary clock is connected
  const getSummaryAggregate = async () => {
    const result = await dataService.getSummaryAggregate(authContext?.accessToken!)
    setSummaryAggregate(result)
  }

  const getSolarAggregate = async () => {
    const result = await dataService.getSolarAggregate(authContext?.accessToken!)
    setSolarAggregate(result)
  }

  const getGasAggregate = async () => {
    const result = await dataService.getGasAggregate(authContext?.accessToken!)
    setGasAggregate(result)
  }

  const getBatteryAggregate = async () => {
    const result = await dataService.getBatteryAggregate(authContext?.accessToken!)
    setBatteryAggregate(result)
  }

  const getSmartControllerDevicesAggregate = async () => {
    const result = await dataService.getSmartControllerDevicesAggregate(authContext?.accessToken!)
    setSmartControllerDevicesAggregate(result)
  }

  const addMainMeterDevice = async (mainMeterLibraryId: number) => {
    const result = await deviceService.addMainMeterDevice(authContext?.accessToken!, mainMeterLibraryId)
    setUserMainMeterDevices([...userMainMeterDevices, result])
  }

  const addHybridInverterDevice = async (hybridInverterLibraryId: number, payload: IUserHybridInverterDeviceCreate) => {
    await deviceService.addHybridInverterDevice(authContext?.accessToken!, hybridInverterLibraryId, payload)
  }

  const updateHybridInverterDevice = async (
    deviceId: string,
    payload: IUserHybridInverterDeviceCreate
  ) => {
    const result = await deviceService.updateHybridInverterDevice(
      authContext?.accessToken!,
      deviceId,
      payload
    )
    const newUserHybridInverterDevices = [...userHybridInverterDevices]
    for (let i = 0; i < newUserHybridInverterDevices.length; i++) {
      const device = newUserHybridInverterDevices[i]
      if (device.deviceId === result.deviceId) {
        newUserHybridInverterDevices[i] = result
        break
      }
    }
    setUserHybridInverterDevices(newUserHybridInverterDevices)
  }

  const removeMainMeterDevice = async (userMainMeterDevice: IUserMainMeterDevice) => {
    const result = await deviceService.removeMainMeterDevice(authContext?.accessToken!, userMainMeterDevice.deviceId)
    let newUserMainMeterDevices = [...userMainMeterDevices]
    newUserMainMeterDevices = newUserMainMeterDevices.filter((device) => device.deviceId !== result.deviceId)
    setUserMainMeterDevices(newUserMainMeterDevices)
  }

  const removeHybridInverterDevice = async (userHybridInverterDevice: IUserHybridInverterDevice) => {
    await deviceService.removeHybridInverterDevice(authContext?.accessToken!, userHybridInverterDevice.deviceId)
  }

  const activateMainMeterDevice = async () => {
    // TODO
  }

  const activateHybridInverterDevice = async (deviceId: string) => {
    const result = await deviceService.activateHybridInverterDevice(authContext?.accessToken!, deviceId)
    const newUserHybridInverterDevices = [...userHybridInverterDevices]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newUserHybridInverterDevices.length; i++) {
      const hybridInverterDevice = newUserHybridInverterDevices[i]
      if (hybridInverterDevice.deviceId === result.deviceId) {
        newUserHybridInverterDevices[i] = result
        break
      }
    }
    setUserHybridInverterDevices(newUserHybridInverterDevices)
  }

  const deactivateMainMeterDevice = async () => {
    // TODO
  }

  const deactivateHybridInverterDevice = async (deviceId: string) => {
    const result = await deviceService.deactivateHybridInverterDevice(authContext?.accessToken!, deviceId)
    const newUserHybridInverterDevices = [...userHybridInverterDevices]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newUserHybridInverterDevices.length; i++) {
      const hybridInverterDevice = newUserHybridInverterDevices[i]
      if (hybridInverterDevice.deviceId === result.deviceId) {
        newUserHybridInverterDevices[i] = result
        break
      }
    }
    setUserHybridInverterDevices(newUserHybridInverterDevices)
  }

  const toggleSmartControllerDevice = async (deviceId: string) => {
    const result = await deviceService.toggleSmartControllerDevice(authContext?.accessToken!, deviceId)
    const newSmartControllerDevices = [...userSmartControllerDevices]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newSmartControllerDevices.length; i++) {
      const smartControllerDevice = newSmartControllerDevices[i]
      if (smartControllerDevice.deviceId === result.deviceId) {
        newSmartControllerDevices[i] = result
        break
      }
    }
    setUserSmartControllerDevices(newSmartControllerDevices)
  }

  const resetSmartControllerDeviceReading = async (deviceId: string) => {
    const result = await deviceService.resetSmartControllerDeviceReading(authContext?.accessToken!, deviceId)
    const newSmartControllerDevices = [...userSmartControllerDevices]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newSmartControllerDevices.length; i++) {
      const smartControllerDevice = newSmartControllerDevices[i]
      if (smartControllerDevice.deviceId === result.deviceId) {
        newSmartControllerDevices[i] = result
        break
      }
    }
    setUserSmartControllerDevices(newSmartControllerDevices)
  }

  const switchSmartControllerDevice = async (deviceId: string, on: boolean) => {
    const result = await deviceService.switchSmartControllerDevice(
      authContext?.accessToken!,
      deviceId,
      on
    )
    const newSmartControllerDevices = [...userSmartControllerDevices]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newSmartControllerDevices.length; i++) {
      const smartControllerDevice = newSmartControllerDevices[i]
      if (smartControllerDevice.deviceId === result.deviceId) {
        newSmartControllerDevices[i] = result
        break
      }
    }
    setUserSmartControllerDevices(newSmartControllerDevices)
  }

  const updateSmartControllerDevice = async (
    deviceId: string,
    payload: any
  ) => {
    const result = await deviceService.updateSmartControllerDevice(
      authContext?.accessToken!,
      deviceId,
      payload
    )
    const newSmartControllerDevices = [...userSmartControllerDevices]
    // replace device in devices list with updated device returned from api call
    for (let i = 0; i < newSmartControllerDevices.length; i++) {
      const smartControllerDevice = newSmartControllerDevices[i]
      if (smartControllerDevice.deviceId === result.deviceId) {
        newSmartControllerDevices[i] = result
        break
      }
    }
    setUserSmartControllerDevices(newSmartControllerDevices)
  }

  const userHas = (deviceType: string) => {
    switch (deviceType) {
      case constants.SmartMeterDeviceGroupLabel:
        return userMainMeterDevices.length > 0
      case constants.HybridInverterDeviceGroupLabel:
        return userHybridInverterDevices.length > 0
      default:
        return false
    }
  }

  const createGasContract = async (data: IGasContractCreate) => {
    await contractService.createGasContract(authContext?.accessToken!, data)
    await fetchGasContract()
  }

  const updateGasConnectionContract = async (data: IGasConnectionContractUpdate) => {
    const result = await contractService.updateGasConnectionContract(authContext?.accessToken!, data)
    setGasConnectionContract(result)
    await fetchGasTariff(null, null)
  }

  const createElectricityContract = async (data: IElectricityContractCreate) => {
    await contractService.createElectricityContract(authContext?.accessToken!, data)
    await fetchElectricityContract()
  }

  const updateElectricityGridContract = async (data: IElectricityGridContractUpdate) => {
    const result = await contractService.updateElectricityGridContract(authContext?.accessToken!, data)
    setElectricityGridContract(result)
    await fetchGridTariff(null, null)
  }

  const updateElectricityGridContractLarge = async (data: IElectricityGridContractLargeUpdate) => {
    const result = await contractService.updateElectricityGridCotractLarge(authContext?.accessToken!, data)
    setElectricityGridContractLarge(result)
  }

  const refreshData = async () => {
    if (!authContext?.accessToken!) {
      return
    }
    await fetchDevices()

    if (authContext?.isAdminLoggedIn()) {
      await fetchBatteryModels()
    }

    if (authContext?.isRegularUserLoggedIn()) {
      await executeInParallel([
        getElectricityAggregate(),
        getGasAggregate(),
        getSolarAggregate(),
        getBatteryAggregate(),
        getSummaryAggregate(),
        getSmartControllerDevicesAggregate(),
        fetchUserDevices(),
        fetchElectricityContract(),
        fetchDefaultElectricityContract(),
        fetchElectricityGridContract(),
        getElectricityGridContractLarge(),
        fetchGasContract(),
        fetchDefaultGasContract(),
        fetchGasConnectionContract(),
        fetchTariffTypes(),
        fetchEnergyRetailers(),
        fetchDsos(),
        fetchGridConnectionTypes(),
        fetchGasConnectionTypes(),
        fetchConsumerTypes(),
        fetchGridTariff(null, null),
        fetchGasTariff(null, null)
      ])
    }

    if (authContext?.isCommunityAdminLoggedIn()) {
    }
  }

  const dataContext: IDataContext = {
    aggregate: {
      electricityAggregate,
      gasAggregate,
      batteryAggregate,
      solarAggregate,
      summaryAggregate,
      smartControllerDevicesAggregate
    },
    device: {
      mainMeters,
      hybridInverters,
      smartControllers,
      userMainMeterDevices,
      userHybridInverterDevices,
      userSmartControllerDevices,
      activeUserMainMeterDevice,
      activeUserHybridInverterDevice,
      activeBoilerDevice,
      activeSmartPlugDevice,
      activeSpaceHeaterDevice,
      userHas,
      createMainMeter,
      createHybridInverter,
      createSmartController,
      updateMainMeter,
      updateHybridInverter,
      updateSmartController,
      updateHybridInverterDevice,
      addMainMeterDevice,
      addHybridInverterDevice,
      removeMainMeterDevice,
      removeHybridInverterDevice,
      activateMainMeterDevice,
      activateHybridInverterDevice,
      deactivateMainMeterDevice,
      deactivateHybridInverterDevice,
      toggleSmartControllerDevice,
      resetSmartControllerDeviceReading,
      switchSmartControllerDevice,
      updateMainMeterDeviceGasConnected,
      updateSmartControllerDevice
    },
    contract: {
      electricity: electricityContract,
      defaultElectricity: defaultElectricityContract,
      electricityGrid: electricityGridContract,
      electricityGridLarge: electricityGridContractLarge,
      gas: gasContract,
      defaultGas: defaultGasContract,
      gasConnection: gasConnectionContract,
      gridTariff,
      gasTariff,
      energyRetailers,
      tariffTypes,
      dsos,
      gridConnectionTypes,
      gasConnectionTypes,
      consumerTypes,
      fetchGridTariff,
      fetchGasConnectionTariff: fetchGasTariff,
      createElectricityContract,
      createGasContract,
      updateElectricityGridContract,
      updateElectricityGridContractLarge,
      updateGasConnectionContract
    },
    battery: {
      batteryModels,
      createBatteryModel
    }
  }

  useEffect(() => {
    refreshData()
  }, [authContext?.accessToken])

  useEffect(() => {
    if (userContext?.profile?.onboardingCompletedOn) {
      refreshData()
    }
  }, [userContext?.profile?.onboardingCompletedOn])

  useEffect(() => {
    const activeDevice = userHybridInverterDevices.find((d) => d.isActive)
    setActiveUserHybridInverterDevice(activeDevice || null)
  }, [userHybridInverterDevices])

  useEffect(() => {
    const activeDevice = userMainMeterDevices.find((d) => d.isActive)
    setActiveUserMainMeterDevice(activeDevice || null)
  }, [userMainMeterDevices])

  useEffect(() => {
    const activeBoiler: IUserDevice[] = userSmartControllerDevices.filter(
      (d) => d.deviceLibrary.application === SmartControllerApplicaiton.BOILER && d.isActive
    )
    const sorterActiveBoiler: IUserDevice[] = activeBoiler.sort((a: any, b: any) => {
      return a.isActive > b.isActive ? 1 : -1
    })
    const activeHeater: IUserDevice[] = userSmartControllerDevices.filter(
      (d) => d.deviceLibrary.application === SmartControllerApplicaiton.SPACE_HEATER && d.isActive
    )
    const sorterActiveHeater: IUserDevice[] = activeHeater.sort((a: any, b: any) => {
      return a.isActive > b.isActive ? 1 : -1
    })
    const activeSmartPlug: IUserDevice[] = userSmartControllerDevices.filter(
      (d) => d.deviceLibrary.application === SmartControllerApplicaiton.GENERIC && d.isActive
    )
    const sorterActiveSmartPlug: IUserDevice[] = activeSmartPlug.sort((a: any, b: any) => {
      return a.isActive > b.isActive ? 1 : -1
    })
    setActiveBoilerDevice(sorterActiveBoiler || null)
    setActiveSpaceHeaterDevice(sorterActiveHeater || null)
    setActiveSmartPlugDevice(sorterActiveSmartPlug || null)
  }, [userSmartControllerDevices])

  useEffect(() => {
    if (userContext?.socket) {
      userContext?.socket?.addEventListener('message', (event) => {
        const data = JSON.parse(event.data)
        const eventType = data.eventType
        if (
          eventType === constants.AddDeviceResponse ||
          eventType === constants.RemoveDeviceResponse ||
          eventType === constants.AddSmartControllerDeviceResponse ||
          eventType === constants.RemoveSmartControllerDeviceResponse ||
          eventType === constants.SwitchSmartControllerDeviceResponse
        ) {
          if (data.status === 'success') {
            fetchUserDevices()
          } else {
          }
        }
      })
    }
  }, [userContext?.socket])

  useInterval(async () => {
    if (authContext?.isRegularUserLoggedIn()) {
      await executeInParallel([
        getElectricityAggregate(),
        getGasAggregate(),
        getSolarAggregate(),
        getBatteryAggregate(),
        getSummaryAggregate(),
      ])
    }
  }, 60000)

  useInterval(async () => {
    if (authContext?.isRegularUserLoggedIn()) {
      await executeInParallel([
        getSmartControllerDevicesAggregate()
      ])
    }
  }, 10000)

  return <DataContext.Provider value={dataContext}>{children}</DataContext.Provider>
}

export default DataProvider
