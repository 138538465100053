import './new-device-group.scss'
import * as React from 'react'
import DeviceForm from './DeviceForm'

const NewDeviceComponent = () => {
  return (
    <div className='container'>
      <div className='container__inner'>
        <h3 className="form-header">New Device</h3>
        <hr />
        <DeviceForm/>
      </div>
    </div>

  )
}

export default NewDeviceComponent
