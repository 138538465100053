import * as React from 'react'
import { Form, Confirm, Dropdown, Message } from 'semantic-ui-react'
import { useState } from 'react'
import DataContext from '../../../contexts/data'
import { IGasContract, IGasContractCreate } from '../../../interfaces/contracts'
import { contractOptions } from '../../../util/options'
import { clientType, toolTipText } from '~/enums'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import NumberFormat from 'react-number-format'
import UserContext from '../../../contexts/user'
const moment = require('moment')

type SubscriptionForm = {
  supplier: string
  rate: string
  standingRight: string
  startContract: string
  eindeContract: string
}
const EnergySupplyGas: React.FC = () => {
  const dataContext = React.useContext(DataContext)
  const userContext = React.useContext(UserContext)
  const [formData, setFormData] = useState<any>({})
  const [currentContract, setCurrentContract] = useState<IGasContract>(dataContext?.contract?.gas!)
  const [obligationInput, setObligationInput] = useState('obligation-input')
  const [selectedContractType, setSelectedContractType] = useState<any>(
    currentContract.isDefaultContract ? 'Standaard' : 'Werkelijk'
  )
  const [change, setChange] = useState<boolean>(false)
  const [disabledForm, setDisabledForm] = useState<boolean>(true)
  const [energyRetailerOptions, setEnergyRetailerOptions] = useState<any[]>([])
  const [selectedRetailer, setSelectedRetailer] = useState(dataContext?.contract.electricity.retailer?.id)
  const [failureMessage, setFailureMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const [openPopUp, setOpenPopUp] = useState<boolean>(false)

  const close = () => {
    setOpenPopUp(false)
  }
  const onContractChange = (e: any, data: any) => {
    if (data.value === 'Werkelijk') {
      setChange(true)
      setDisabledForm(false)
      setObligationInput('')
    } else {
      setDisabledForm(true)
      setObligationInput('obligation-input')
      setChange(false)
    }
    setSelectedContractType(data.value)
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const payload: IGasContractCreate = {
      ...formData,
      retailerId: selectedRetailer
    }
    setDisabledForm(true)
    setObligationInput('obligation-input')
    setChange(false)
    try {
      await dataContext?.contract?.createGasContract(payload)
      setSuccessMessage('De gasgegevens zijn succesvol verwerkt.')
    } catch (err) {
      setFailureMessage('Klant heeft reeds actieve, niet standaard, gas en elektriciteitsgegevens.')
    }
  }
  const onChangeConfirm = () => {
    setChange(true)
    setDisabledForm(false)
    setObligationInput('')
  }

  const onEnergyRetailerSelected = (e: any, data: any) => {
    setSelectedRetailer(data.value)
  }

  const handleInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.replace(',', '.') })
  }

  setTimeout(() => {
    setSuccessMessage(null)
    setFailureMessage(null)
  }, 6000)
  const inclusiveOrExclusive = () => {
    if (userContext?.profile?.customerType === clientType.Business) {
      return toolTipText.Exclusive
    } else if (userContext?.profile?.customerType === clientType.Private) {
      return toolTipText.Inclusive
    }
  }
  React.useEffect(() => {
    const result = dataContext?.contract.energyRetailers.map((value) => {
      return { key: value.id, text: value.displayName, value: value.id }
    })
    setEnergyRetailerOptions(result || [])
  }, [dataContext?.contract.energyRetailers])

  React.useEffect(() => {
    if (selectedContractType === 'Standaard') {
      setCurrentContract(dataContext?.contract?.defaultGas!)
    }
  }, [selectedContractType])

  React.useEffect(() => {
    setFormData({
      tariffRetailPerM3: currentContract.tariffRetailPerM3.toFixed(2),
      fixedFeePerYear: currentContract.fixedFeePerYear.toFixed(2),
      startDateTime: moment(currentContract.startDateTime).format('DD-MM-YYYY'),
      endDateTime: moment(currentContract.endDateTime).format('DD-MM-YYYY')
    })
    const contractType = currentContract.isDefaultContract ? 'Standaard' : 'Werkelijk'
    setSelectedContractType(contractType)
  }, [currentContract])

  React.useEffect(() => {
    if (dataContext?.contract.gas) {
      setCurrentContract(dataContext.contract.gas)
    }
  }, [dataContext?.contract.gas])

  return (
    <div className="energy-supply-container">
      <div className="energy-form-container">
        <hr style={{ marginTop: '5px' }} />
        <Form onSubmit={onSubmit} className="form">
          <div className="energy-supply-details flex1-column">
            {' '}
            <div className="flex1-row">
              <p className="energy-supply-detail-names">Contract gegevens</p>
              <Dropdown
                name="contract"
                placeholder="Standaard"
                onChange={onContractChange}
                options={contractOptions}
                value={selectedContractType}
                selection
                fluid
                style={{ marginLeft: '6px', marginRight: '2px' }}
              />
              <div className="tooltip">
                <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                <div className="tooltiptext">
                  Werkerlijk: selecteeren als u de werkelijke waarde van uw <br />
                  energiecontact wilt gebruiken. Dit geeft meest nauwkeurige <br />
                  resultaat. <br />
                  Standaard: selecteerd u als u niet weet wat u voor stroom en gas <br />
                  betaald. Het syteem rekend dan met standaard waarde. Let op! <br />
                  De overzichten komen dan niet overeen met wat u <br />
                  werkelijk betaald
                </div>
              </div>
            </div>
            <div className="flex1-row" style={{ marginTop: '10px' }}>
              <p className="energy-supply-detail-names">Leverancier</p>
              <Dropdown
                name="supplier"
                placeholder="Details"
                onChange={onEnergyRetailerSelected}
                value={selectedRetailer}
                options={energyRetailerOptions}
                disabled={disabledForm}
                className={obligationInput}
                selection
                fluid
                style={{ marginLeft: '4px', marginRight: '32px' }}
              />
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Tarief</p>
              </div>
              <NumberFormat
                value={formData.tariffRetailPerM3}
                disabled={disabledForm}
                onChange={handleInputChange}
                name="tariffRetailPerM3"
                className={`form-item ${obligationInput} number-format`}
                style={{ marginRight: '0px' }}
                decimalSeparator={','}
                allowedDecimalSeparators={[',']}
                isNumericString={true}
              />
              <span style={{ marginLeft: '-34px', paddingTop: '4px', opacity: '0.9' }}>€/m³</span>
              <div className="tooltip"></div>
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Vastrecht</p>
              </div>
              <NumberFormat
                value={formData.fixedFeePerYear}
                disabled={disabledForm}
                onChange={handleInputChange}
                name="fixedFeePerYear"
                className={`form-item ${obligationInput} number-format`}
                decimalSeparator={','}
                allowedDecimalSeparators={[',']}
                isNumericString={true}
              />
              <span style={{ marginLeft: '-40px', paddingTop: '4px', opacity: '0.9' }}>€/jaar</span>
              <div className="tooltip">
                <IoMdInformationCircleOutline className="info" style={{ fontSize: '18px', marginLeft: '0px' }} />
                <div className="tooltiptext" style={{ height: '50px', bottom: '-100%' }}>
                  {inclusiveOrExclusive()}
                </div>
              </div>
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Start contract</p>
              </div>
              <input
                value={formData.startDateTime}
                disabled={disabledForm}
                onChange={handleInputChange}
                name="startDateTime"
                className={`form-item ${obligationInput}`}
                style={{ marginLeft: '0px', marginRight: '30px' }}
              />
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names">
                <p>Einde contract</p>
              </div>
              <input
                value={formData.endDateTime}
                disabled={disabledForm}
                onChange={handleInputChange}
                name="endDateTime"
                className={`form-item ${obligationInput}`}
                style={{ marginLeft: '0px', marginRight: '30px' }}
              />
            </div>
            <div className="flex1-row">
              <div className="energy-supply-detail-names"></div>
              {failureMessage && <Message color="red">{failureMessage}</Message>}
              {successMessage && <Message color="green">{successMessage}</Message>}
            </div>
            <div className="flex1-row flex1-end" style={{ marginRight: '30px' }}>
              {selectedContractType === 'Werkelijk' && !change && (
                <button className="btn-blue" onClick={onChangeConfirm}>
                  Wijzig
                </button>
              )}
              {change && (
                <button className="btn-blue" type="submit">
                  Pas toe
                </button>
              )}
              <Confirm
                content="
                Wil je de data pastoe?"
                cancelButton="Nee"
                confirmButton="Ja"
                open={openPopUp}
                onCancel={close}
                onConfirm={close}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default EnergySupplyGas
