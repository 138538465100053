import * as React from 'react'
import { Table, Input } from 'semantic-ui-react'

interface ITaxTypeProps {
  taxRates: any
  taxType: string
  years: number[]
  commodity: string
  inEditMode: any
  editTaxRates: any
  handleTaxRatesClick: Function
  handleTaxRatesChange: Function
}

const TaxType: React.FC<ITaxTypeProps> = (props: ITaxTypeProps) => {
  const handleClickEvent = (ids: number[], field: string, value: number) => {
    props.handleTaxRatesClick(ids, field, value)
  }

  const handleTaxRatesChange = (ids: number[], field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleTaxRatesChange(ids, field, event.target.value)
  }
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>Tax</Table.HeaderCell>
          <Table.HeaderCell width={2}>Commodity</Table.HeaderCell>
          <Table.HeaderCell width={3}>Range</Table.HeaderCell>
          {'energy' in props.taxRates &&
            props.years.map((key) => {
              return (
                <Table.HeaderCell key={key} width={2}>
                  {key}
                </Table.HeaderCell>
              )
            })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {'energy' in props.taxRates &&
          Object.keys(props.taxRates[props.taxType][props.commodity]).map((limit: any, i) => {
            return (
              <Table.Row key={limit}>
                <Table.Cell>{props.taxType === 'energy' ? 'Energy tax' : 'Sustainability tax'}</Table.Cell>
                <Table.Cell>{props.commodity === 'electricity' ? 'ELECTRICITY' : 'GAS'}</Table.Cell>
                <Table.Cell>
                  {(props.taxRates[props.taxType][props.commodity][limit].upperLimit === 0
                    ? '>' + limit
                    : limit + '-' + props.taxRates[props.taxType][props.commodity][limit].upperLimit) +
                    (props.commodity === 'electricity' ? ' kWh' : ' m3')}
                </Table.Cell>
                {props.years.map((year: any) =>
                  Object.keys(props.taxRates[props.taxType][props.commodity][limit]).includes(year) ? (
                    <Table.Cell
                      key={year}
                      className={
                        props.inEditMode.ids.includes(props.taxRates[props.taxType][props.commodity][limit][year].id) &&
                        props.taxRates[props.taxType][props.commodity][limit][year].id in props.editTaxRates &&
                        ('taxEuroPerKwh' in
                          props.editTaxRates[props.taxRates[props.taxType][props.commodity][limit][year].id] ||
                          'taxEuroPerM3' in
                            props.editTaxRates[props.taxRates[props.taxType][props.commodity][limit][year].id])
                          ? 'dirty'
                          : ''
                      }
                    >
                      {props.inEditMode.status &&
                      Object.keys(props.editTaxRates).length > 0 &&
                      props.taxRates[props.taxType][props.commodity][limit][year].id in props.editTaxRates &&
                      ('taxEuroPerKwh' in
                        props.editTaxRates[props.taxRates[props.taxType][props.commodity][limit][year].id] ||
                        'taxEuroPerM3' in
                          props.editTaxRates[props.taxRates[props.taxType][props.commodity][limit][year].id]) ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={
                              props.editTaxRates[props.taxRates[props.taxType][props.commodity][limit][year].id][
                                props.taxRates[props.taxType][props.commodity][limit][year].field
                              ]
                            }
                            onChange={handleTaxRatesChange.bind(
                              this,
                              [props.taxRates[props.taxType][props.commodity][limit][year].id],
                              props.taxRates[props.taxType][props.commodity][limit][year].field
                            )}
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={handleClickEvent.bind(
                              this,
                              [props.taxRates[props.taxType][props.commodity][limit][year].id],
                              props.taxRates[props.taxType][props.commodity][limit][year].field,
                              props.taxRates[props.taxType][props.commodity][limit][year].value
                            )}
                          >
                            {props.taxRates[props.taxType][props.commodity][limit][year].value}
                          </p>
                        )}
                    </Table.Cell>
                  ) : (
                    <Table.Cell key={year}>
                      <p></p>
                    </Table.Cell>
                  )
                )}
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  )
}

export default TaxType
