import api from '../api'

export const inviteCommunityManager = async (accessToken: string, data: any) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = '/community-managers/'
  const response = await api.post(url, data, config)
  return response.data
}

export const resendInviteCommunityManager = async (accessToken: string, communityManagerId: number) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = `/community-managers/${communityManagerId}`
  const response = await api.post(url, null, config)
  return response.data
}

export const suspendCommunityManager = async (accessToken: string, communityManagerId: number) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = `/community-managers/${communityManagerId}/suspend`
  const response = await api.post(url, null, config)
  return response.data
}

export const unsuspendCommunityManager = async (accessToken: string, communityManagerId: number) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = `/community-managers/${communityManagerId}/unsuspend`
  const response = await api.post(url, null, config)
  return response.data
}

export const deleteCommunityManager = async (accessToken: string, communityManagerId: number) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = `/community-managers/${communityManagerId}`
  const response = await api.delete(url, config)
  return response.data
}

export const fetchCommunityManagers = async (accessToken: String, communityId: number) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = `/community-managers/?community_id=${communityId}`
  const response = await api.get(url, config)
  return response.data
}

export const getTokenCommunityManager = async (accessToken: string, communityManagerId: number) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = `/community-managers/${communityManagerId}/token`
  const response = await api.delete(url, config)
  return response.data
}

export const getInvitationUrl = async (accessToken: string, communityManagerId: number): Promise<string> => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = `/community-managers/${communityManagerId}/token/invitation`
  const response = await api.get(url, config)
  return response.data.url
}

export const getVerificationUrl = async (accessToken: string, communityManagerId: number): Promise<string> => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const url = `/community-managers/${communityManagerId}/token/verification`
  const response = await api.get(url, config)
  return response.data.url
}
