import * as React from 'react'
import AuthContext, { IAuthContextProps, IToken } from '../../contexts/auth'
import UserContext, { IUserContextProps, IUserProfileProps } from '../../contexts/user'
import UserService from '../../services/user'
import PersistenceService from '../../services/persistence'
import AuthService from '../../services/auth'
import OnboardingService from '../../services/onboard'
import api from '../../api'
import {
  IUserBuildingDetail,
  IUserBuildingDetailsUpdate,
  IUserCustomerDetail,
  IUserCustomerDetailsUpdate,
  IUserSettings,
  IUserSettingsUpdate,
  IUserSubscription
} from '../../interfaces/user'
import { AxiosError, AxiosResponse } from 'axios'

const UNAUTHORIZED_RESPONSE = [
  'Could not validate credentials',
  'Not authenticated',
  'Signature has expired',
  'Signature verification failed'
]

const WEBSOCKET_ERROR_RESPONSE = "user websocket not connected"

const defaultCustomerDetail: IUserCustomerDetail = { email: '', customerType: '' }
const defaultBuildingDetail: IUserBuildingDetail = {}
const defaultSettings: IUserSettings = {}
const defaultSubscriptionDetail: IUserSubscription = {}

const AuthUserProvider: React.FC = ({ children }) => {
  const userService = new UserService()
  const onboardingService = new OnboardingService()
  const persistenceService = new PersistenceService()
  const authService = new AuthService()

  const _tokenFromStore = persistenceService.get('token')
  const _token: IToken | null = _tokenFromStore ? JSON.parse(_tokenFromStore) : null

  const [token, setToken] = React.useState<IToken | null>(_token)
  const [profile, setProfile] = React.useState<IUserProfileProps | null>(null)
  const [hasOnboarded, setHasOnboarded] = React.useState(false)
  const [socket, setSocket] = React.useState<WebSocket | null>(null)
  const [customerDetail, setCustomerDetail] = React.useState<IUserCustomerDetail>(defaultCustomerDetail)
  const [buildingDetail, setBuildingDetail] = React.useState<IUserBuildingDetail>(defaultBuildingDetail)
  const [settingsDetail, setSettingsDetail] = React.useState<IUserSettings>(defaultSettings)
  const [subscriptionDetail, setSubscriptionDetail] = React.useState<IUserSubscription>(defaultSubscriptionDetail)

  const getRoleFromToken = (jwtToken: string): string | null => {
    const jwt = JSON.parse(atob(jwtToken.split('.')[1]))
    return (jwt && jwt.role) || null
  }

  const setUserToken = (token: IToken | null) => {
    if (token) {
      persistenceService.store('token', JSON.stringify(token))
    } else {
      persistenceService.remove('token')
    }
    setToken(token)
  }

  const isLoggedIn = () => {
    return !!token
  }
  const isAdminToken = (token: IToken): boolean => {
    const role = getRoleFromToken(token.accessToken)
    return (role && role === 'admin') || false
  }

  const isAdminLoggedIn = () => {
    return (token && isAdminToken(token)) || false
  }

  const isCommunityAdminToken = (token: IToken): boolean => {
    const role = getRoleFromToken(token.accessToken)
    return (role && role === 'communityAdmin') || false
  }

  const isCommunityAdminLoggedIn = () => {
    return (token && isCommunityAdminToken(token)) || false
  }

  const isRegularUserToken = (token: IToken): boolean => {
    const role = getRoleFromToken(token.accessToken)
    return (role && role === 'regular') || false
  }

  const isRegularUserLoggedIn = () => {
    return (token && isRegularUserToken(token)) || false
  }

  const logout = () => {
    setUserToken(null)
  }
  const logoutCommunity = () => {
    setUserToken(null)
  }
  const completeOnboarding = async () => {
    const result = await onboardingService.setOnboardingComplete(token!.accessToken)
    setProfile(result)
  }

  const updateCustomerDetails = async (data: IUserCustomerDetailsUpdate) => {
    const { accessToken } = token!
    if (accessToken) {
      const result = await userService.updateCustomerDetails(accessToken, data)
      setCustomerDetail(result)
      setProfile({ ...profile, ...result })
    }
  }

  const updateBuildingDetails = async (data: IUserBuildingDetailsUpdate) => {
    const { accessToken } = token!
    if (accessToken) {
      const result = await userService.updateBuildingDetails(accessToken, data)
      setBuildingDetail(result)
    }
  }
  const updateSettings = async (data: IUserSettingsUpdate) => {
    const { accessToken } = token!
    if (accessToken) {
      const result = await userService.updateSettingsDetails(accessToken, data)
      setSettingsDetail(result)
    }
  }

  const authContextData: IAuthContextProps = {
    accessToken: token?.accessToken!,
    setUserToken,
    isLoggedIn,
    isAdminLoggedIn,
    isCommunityAdminLoggedIn,
    isRegularUserLoggedIn,
    logout,
    logoutCommunity
  }

  const userContextData: IUserContextProps = {
    profile,
    customerDetail,
    buildingDetail,
    settingsDetail,
    subscriptionDetail,
    socket,
    hasOnboarded,
    setProfile,
    completeOnboarding,
    updateCustomerDetails,
    updateBuildingDetails,
    updateSettings
  }

  const fetchAndSetProfile = async () => {
    try {
      const { accessToken } = token!
      if (!accessToken) {
        return
      }
      const result = await userService.getUserProfile(accessToken)
      setProfile(result)
    } catch (err) {
      setUserToken(null)
    }
  }

  const fetchAndSetCustomerDetail = async () => {
    const { accessToken } = token!
    if (accessToken) {
      const result = await userService.getUserCustomerDetails(accessToken)
      setCustomerDetail(result)
    }
  }

  const fetchAndSetBuildingDetail = async () => {
    const { accessToken } = token!
    if (accessToken) {
      const result = await userService.getUserBuildingDetails(accessToken)
      setBuildingDetail(result)
    }
  }

  const fetchAndSetSettings = async () => {
    const { accessToken } = token!
    if (accessToken) {
      const result = await userService.getUserSettings(accessToken)
      setSettingsDetail(result)
    }
  }

  const fetchAndSetSubscription = async () => {
    const { accessToken } = token!
    if (accessToken) {
      const result = await userService.getUserSubscription(accessToken)
      setSubscriptionDetail(result)
    }
  }

  const validateTokenAndReconnectWebsocket = async () => {
    try {
      await userService.getUserProfile(token?.accessToken!)

      setupWebsocket(token?.accessToken!)
    } catch (err) { }
  }

  const setupWebsocket = async (accessToken: string) => {
    const setupSocketHandlers = (socketUrl: string) => {
      const socket = new WebSocket(socketUrl)
      socket.onopen = () => {
        setSocket(socket)
      }
      socket.onclose = async () => {
        setSocket(null)
      }
    }
    const websocketUrl = `${process.env.WEBSOCKET_HOST}/api/wsroom/ws/${accessToken}`
    setupSocketHandlers(websocketUrl)
  }

  const refreshAuthToken = async (refreshToken: string) => {
    const result = await authService.refreshToken(refreshToken)
    const { access } = result
    const newToken = { accessToken: access, refreshToken: refreshToken }
    setUserToken(newToken)
  }

  const shouldRefreshToken = () => {
    const keepMeLoggedIn = persistenceService.get('keepMeLoggedIn') || ''
    return keepMeLoggedIn === 'true'
  }

  const handleInvalidToken = async () => {
    if (token) {
      const refreshToken = token.refreshToken
      if (shouldRefreshToken()) {
        await refreshAuthToken(refreshToken)
      } else {
        logout()
      }
    }
  }

  const configureApi = async () => {
    const repeatWebSocketRequest = async (config: any) => {
      setupWebsocket(token?.accessToken!)
      await api.request(config)
    }

    const responseHandler = async (response: AxiosResponse<any>): Promise<any> => {
      return Promise.resolve(response)
    }

    const errorHandler = async (error: AxiosError) => {
      const modifiedError = { ...error }
      if (error.isAxiosError) {
        if (UNAUTHORIZED_RESPONSE.includes(error?.response?.data?.detail)) {
          await handleInvalidToken()
        }
        if (error?.response?.data?.detail === WEBSOCKET_ERROR_RESPONSE) {
          return await repeatWebSocketRequest(error.response.config)
        }
      }
      return Promise.reject(modifiedError)
    }

    api.interceptors.response.use(responseHandler, errorHandler)
  }

  React.useEffect(() => {
    configureApi()
    if (token) { setupWebsocket(token.accessToken!) }
    if (isLoggedIn()) {
      fetchAndSetProfile()
    }
    if (isRegularUserLoggedIn()) {
      fetchAndSetCustomerDetail()
      fetchAndSetBuildingDetail()
      fetchAndSetSettings()
      fetchAndSetSubscription()
    }
  }, [token])

  React.useEffect(() => {
    if (profile != null) {
      setHasOnboarded(!!profile?.onboardingCompletedOn)
    }
  }, [profile])


  React.useEffect(() => {
    if (!!token && socket == null) {
      validateTokenAndReconnectWebsocket()
    }
  }, [socket])

  React.useEffect(() => {
    window.addEventListener('storage', async function (e: StorageEvent) {
      if (e.key === 'token') {
        const newToken = e.newValue ? JSON.parse(e.newValue) : null
        if (!newToken && token != null) {
          const refreshToken = token!.refreshToken
          const shouldRefresh = shouldRefreshToken()
          if (shouldRefresh) {
            await refreshAuthToken(refreshToken)
          } else {
            alert('Your session has been logged out')
            logout()
          }
        }
      }
    })
  }, [])

  return (
    <AuthContext.Provider value={authContextData}>
      <UserContext.Provider value={userContextData}>{children}</UserContext.Provider>
    </AuthContext.Provider>
  )
}

export default AuthUserProvider
