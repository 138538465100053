import * as React from 'react'
import * as carGauge from '../../../assets/smart_meter.png'
import { Image } from 'semantic-ui-react'
import DataContext from '../../../contexts/data'

interface Iprops {
  title: string
}
const SmartMeterGeneric = (props: Iprops) => {
  const dataContext = React.useContext(DataContext)

  const getNetworkName = () => {
    switch (dataContext?.device?.activeUserMainMeterDevice?.consumerType) {
      case 'small':
        return dataContext?.contract?.electricityGrid?.dso?.name
      case 'large':
        return dataContext?.contract?.electricityGridLarge?.dso?.name
      default:
        return 'N/A'
    }
  }

  return (
    <>
      <div className="asset-container">
        <strong className="title">{props && props.title}</strong>
        <hr />
        <div className="flex1-row flex-center-vertical">
          <Image src={carGauge.default} className="smart-meter" />
          <div className="flex1-row">
            <div className="flex1-column m-x-l m-y-t smart-column1">
              <div className="asset-details">Levering</div>
              <div className="asset-details">Teruglevering</div>
            </div>
            <div className="flex1-column m-y-t smart-column2" style={{ marginLeft: '40px' }}>
              <div className="reading-info">
                {dataContext?.aggregate?.electricityAggregate?.importTariff.toString().replace('.', ',')} kWh
              </div>
              <div className="reading-info">
                {dataContext?.aggregate?.electricityAggregate?.exportTariff.toString().replace('.', ',')} kWh
              </div>
            </div>
          </div>
        </div>
        <div className="flex1-row">
          <div className="flex1-column m-y-t smart-column1">
            <div className="asset-details">Leverancier</div>
            <div className="asset-details">Netwerkbeheerder</div>
          </div>
          <div className="flex1-column m-y-t smart-column2">
            <div className="reading-info">{dataContext?.contract?.electricity?.retailer?.name || 'N/A'}</div>
            <div className="reading-info">{getNetworkName()}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmartMeterGeneric
