import * as React from 'react'
import './charts.scss'

const LegendPoint = (props: any) => {
  return (
    <>
      <svg x={props.x - 8} y={props.y - 1}
        id="svg8"
      >
        <g
          id="layer1"
          transform="scale(0.05) translate(-56.696419,-62.240181)">
          <rect
            id="rect10"
            width="67.956833"
            height="65.959854"
            x="56.696419"
            y="62.240181" />
          <ellipse
            id="path841"
            cx="90.538162"
            cy="95.256332"
            rx="33.88393"
            ry="32.681667" />
          <rect
            style={{ fill: props.style.fill }}
            id="rect867"
            width="160"
            height="37.814739"
            x="143.22412"
            y="74.432411" />
        </g>
      </svg>
    </>
  )
}

export default LegendPoint
