import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  IDevice,
  IHybridInverter,
  IUserDevice,
  ISolarModel,
  IUserHybridInverterDeviceCreate,
  IModbusRTUOverTCPProtocol,
  IModbusRTUProtocol,
  IModbusTCPProtocol,
  IZigbeeProtocol,
  ISerialProtocol
} from '../../../interfaces/device'
import * as searchIcon from '../../../assets/search.png'
import { Checkbox, Input, Divider, Dimmer, Image, Message, Button, Item } from 'semantic-ui-react'
import DataContext from '../../../contexts/data'
import * as helper from '../../../util/helper'
import { DeviceLibraryGeneric, SmartControllerConfigurationDetails, ConfigurationDetails } from '../../'
import * as deviceService from '../../../services/device'
import { IBatteryModel } from '../../../interfaces/battery'
import { fetchHybridInverterBatteryModels } from '../../../services/device'
import AuthContext from '../../../contexts/auth'
import * as constants from '../../../constants'
import UserContext from '../../../contexts/user'
import * as loadingSymbol from '../../../assets/loading.png'
import './device_list.scss'
import { ApplicationGrouping, SmartControllerApplicaiton } from '~/enums'

const initialSolarData: ISolarModel = {
  powerRating: 0,
  panelCount: 0,
  panelArea: 1.6,
  panelOrientation: 180,
  panelTilt: 30
}
const DeviceList: React.FC = () => {
  const dataContext = React.useContext(DataContext)
  const authContext = React.useContext(AuthContext)
  const userContext = React.useContext(UserContext)
  const [addConfiguration, setAddConfiguration] = React.useState<SmartControllerApplicaiton | null>(null)
  const [filteredDevices, setFilteredDevices] = useState<IDevice[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null)
  const [selectedHybridInverterBatteryModels, setSelectedHybridInverterBatteryModels] = useState<IBatteryModel[]>([])
  const [batteryModelModelOptions, setBatteryModelModelOptions] = useState<any[]>([])
  const [batteryModelMakeOptions, setBatteryModelMakeOptions] = useState<any[]>([])
  const [selectedBatteryModelMake, setSelectedBatteryModelMake] = useState<any>()
  const [selectedBatteryModelModel, setSelectedBatteryModelModel] = useState<any>()
  const [noOfRoofSide, setNoOfRoofSide] = useState<number>(1)
  const [pv1, setPv1] = useState<any>(initialSolarData)
  const [pv2, setPv2] = useState<any>(initialSolarData)
  const [batteryCount, setBatteryCount] = useState<number>(1)
  const [ipAddress, setIpAddress] = useState<string>('')
  const [isIpScanning, setIsIpScanning] = useState<boolean>(false)
  const [isAddingDevice, setIsAddingDevice] = useState<boolean>(false)
  const [modifiedList, setModifiedList] = useState<IDevice[]>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [deviceAddedSuccessfully, setDeviceAddedSuccessfully] = useState<string>('')

  setTimeout(() => {
    setErrorMessage('')
  }, 5000)
  useEffect(() => {
    const allDevices: IDevice[] = [
      ...dataContext?.device.mainMeters!,
      ...dataContext?.device.hybridInverters!,
      ...dataContext?.device.smartControllers!
    ]
    const filteredDevices = allDevices.filter(
      (device: IDevice) =>
        device.groupLabel.toLowerCase().includes(searchValue.toLowerCase()) ||
        device.make.toLowerCase().includes(searchValue.toLowerCase()) ||
        device.model.toLowerCase().includes(searchValue.toLowerCase())
    )
    setFilteredDevices(filteredDevices!)
  }, [
    dataContext?.device.mainMeters,
    dataContext?.device.hybridInverters,
    dataContext?.device.smartControllers,
    searchValue
  ])

  useEffect(() => {
    if (selectedDevice?.groupLabel === constants.SmartMeterDeviceGroupLabel) {
      addDevice()
    } else if (selectedDevice?.groupLabel === constants.HybridInverterDeviceGroupLabel) {
      fetchBatteryModels()
    } else {
      setSelectedHybridInverterBatteryModels([])
      setSelectedBatteryModelMake('')
      setSelectedBatteryModelModel('')
      setBatteryModelMakeOptions([])
      setSelectedBatteryModelModel([])
    }
  }, [selectedDevice])

  useEffect(() => {
    let makes: any[] = selectedHybridInverterBatteryModels.map((batteryModel) => batteryModel.make)
    makes = makes.filter((v, i, a) => a.indexOf(v) === i)
    makes = makes.map((v) => ({ key: v, value: v, text: v }))
    setBatteryModelMakeOptions(makes)
  }, [selectedHybridInverterBatteryModels])

  useEffect(() => {
    if (selectedBatteryModelMake) {
      let models: any[] = selectedHybridInverterBatteryModels.map((batteryModel) => {
        if (batteryModel.make === selectedBatteryModelMake) {
          return batteryModel.model
        } else {
          return null
        }
      })
      models = models.filter((v) => v !== null)
      models = models.filter((v, i, a) => a.indexOf(v) === i)
      models = models.map((v) => ({ key: v, value: v, text: v }))
      setBatteryModelModelOptions(models)
    }
  }, [selectedBatteryModelMake])

  useEffect(() => {
    userContext?.socket?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      const eventType = data.eventType
      if (eventType === constants.AddDeviceResponse) {
        setIsAddingDevice(false)
        if (data.status === 'success') {
          setSelectedDevice(null)
        } else {
          setErrorMessage(data.message)
        }
      }
    })
  }, [])

  const onChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  const scanHybridInverterIpAddresses = async () => {
  
    userContext?.socket?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)
      const eventType = data.eventType
      if (eventType === constants.ScanIpResponse) {
        if (data.status === 'success') {
          const ipList = data.data
          console.log('ipList', ipList)
          if (ipList.length) {
            setIsIpScanning(false)
            setIpAddress(ipList[0].ip)
          } else {
            setIsIpScanning(false)
            setErrorMessage('No ip found, try again or enter manually')
          }
        } else {
          console.log('ipList in else part')
          // setIsIpScanning(false)
          setErrorMessage(data.message)
        }
      }
    })
    setIsIpScanning(true)
    await deviceService.scanIp(authContext?.accessToken!, selectedDevice!.libraryId)
  }

  const onSolarInputChange = (index: number) => (e: any) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'powerRating') {
      const panelCount = value > 0 ? Math.ceil(value / 0.24) : 0
      if (index === 1) {
        setPv1({ ...pv1, [name]: value, panelCount })
      }
      if (index === 2) {
        setPv2({ ...pv2, [name]: value, panelCount })
      }
    } else {
      if (index === 1) {
        setPv1({ ...pv1, [name]: value })
      }
      if (index === 2) {
        setPv2({ ...pv2, [name]: value })
      }
    }
  }

  const onSolarDropDownChange = (index: number) => (name: string, value: number) => {
    if (index === 1) {
      setPv1({ ...pv1, [name]: value })
    }
    if (index === 2) {
      setPv2({ ...pv2, [name]: value })
    }
  }

  const onBatteryMakeOptionsChange = (e: any, data: any) => {
    setSelectedBatteryModelMake(data.value)
  }

  const onBatteryModelOptionsChange = (e: any, data: any) => {
    setSelectedBatteryModelModel(data.value)
  }

  const getSelectedBatteryModel = (): IBatteryModel | undefined => {
    return selectedHybridInverterBatteryModels.find(
      (value) => value.model === selectedBatteryModelModel && value.make === selectedBatteryModelMake
    )
  }

  const getHybridInverterPayload = (): IUserHybridInverterDeviceCreate => {
    const selectedBatteryModel = getSelectedBatteryModel()
    let modbusRtuOverTcpProtocolParameter: IModbusRTUOverTCPProtocol
    let modbusRtuProtocolParameter: IModbusRTUProtocol
    let modbusTcpProtocolParameter: IModbusTCPProtocol
    let zigbeeProtocolParameter: IZigbeeProtocol
    let serialProtocolParameter: ISerialProtocol
    if (selectedDevice?.protocol.serialProtocol) {
      serialProtocolParameter = {}
    }
    if (selectedDevice?.protocol.modbusRtuOverTcpProtocol) {
      modbusRtuOverTcpProtocolParameter = { host: ipAddress }
    }
    if (selectedDevice?.protocol.modbusRtuProtocol) {
      modbusRtuProtocolParameter = {}
    }
    if (selectedDevice?.protocol.modbusTcpProtocol) {
      modbusTcpProtocolParameter = { host: ipAddress }
    }
    if (selectedDevice?.protocol.zigbeeProtocol) {
      zigbeeProtocolParameter = {}
    }
    return {
      batteryCount,
      batteryModelId: selectedBatteryModel?.id!,
      modbusTcpProtocolParameter,
      modbusRtuOverTcpProtocolParameter,
      modbusRtuProtocolParameter,
      zigbeeProtocolParameter,
      serialProtocolParameter,
      pvString1: pv1,
      pvString2: noOfRoofSide > 1 ? pv2 : undefined
    }
  }

  const selectDevice = (device: IDevice) => {
    const allUserDevices: IUserDevice[] = [
      ...dataContext?.device.userMainMeterDevices!,
      ...dataContext?.device.userHybridInverterDevices!
    ]
    const foundUserDevice = allUserDevices?.find((d) => d.deviceModel.label === device.label)
    if (foundUserDevice) {
      return
    }
    if (selectedDevice?.label !== device.label) {
      setSelectedDevice(device)
    } else if (selectedDevice?.label === device.label) {
      setSelectedDevice(null)
    }
  }

  const addDevice = async () => {
    try {
      if (selectedDevice && selectedDevice.groupLabel === constants.SmartMeterDeviceGroupLabel) {
        await dataContext?.device.addMainMeterDevice(selectedDevice.libraryId)
        setSelectedDevice(null)
      }
      if (selectedDevice && selectedDevice.groupLabel === constants.HybridInverterDeviceGroupLabel) {
        const payload: IUserHybridInverterDeviceCreate = getHybridInverterPayload()
        await dataContext?.device.addHybridInverterDevice(selectedDevice.libraryId, payload)
        setIsAddingDevice(true)
      }
    } catch (err) {
      setIsAddingDevice(false)
      setErrorMessage('Error adding device') // TODO display useful error
    }
  }

  const fetchBatteryModels = async () => {
    setSelectedHybridInverterBatteryModels([])
    setSelectedBatteryModelMake('')
    setSelectedBatteryModelModel('')
    setBatteryModelMakeOptions([])
    setSelectedBatteryModelModel([])
    const result = await fetchHybridInverterBatteryModels(authContext?.accessToken!, selectedDevice!.id)
    setSelectedHybridInverterBatteryModels(result)
  }

  /** Returns true if a device should be checked */
  const deviceIsChecked = (item: any): boolean => {
    const allUserDevices: IUserDevice[] = [
      ...dataContext?.device.userMainMeterDevices!,
      ...dataContext?.device.userHybridInverterDevices!,
      ...dataContext?.device.userSmartControllerDevices!
    ]
    // if (selectedDevice !== null && selectedDevice.label === device.label) {
    //   return true
    // }
    const foundDeviceInUserDevice = allUserDevices.find((ud) => {
      return (
        ud.deviceModel.id === item.id &&
        ud.deviceLibrary.id === item.libraryId &&
        ud.deviceLibrary?.application === item.application
      )
    })
    return !!foundDeviceInUserDevice
  }

  useEffect(() => {
    const genericMakeLevelModelInfo: any[] = []
    const spaceHeaterMakeLevelModelInfo: any[] = []
    const boilerMakeLevelModelInfo: any[] = []
    const genericGroupLevelModelInfo: any[] = []
    const spaceHeaterGroupLevelModelInfo: any[] = []
    const boilerGroupLevelModelInfo: any[] = []
    const genericGroupLevelMakeInfo: any[] = []
    const spaceHeaterGroupLevelMakeInfo: any[] = []
    const boilerGroupLevelMakeInfo: any[] = []
    const newArray: any[] = filteredDevices?.map((device: IDevice) => {
      return device?.libraries?.map((item: any) => {
        if (item.grouping === ApplicationGrouping.MAKE_LEVEL) {
          if (item.application === 'generic') {
            genericMakeLevelModelInfo.push(item.model)
          }
          if (item.application === 'space_heater') {
            spaceHeaterMakeLevelModelInfo.push(item.model)
          }
          if (item.application === 'boiler') {
            boilerMakeLevelModelInfo.push(item.model)
          }
          return {
            id: device.id,
            libraryId: item.id,
            createdOn: item.createdOn,
            lastUpdatedOn: item.lastUpdatedOn,
            application: item.application,
            make: item.make || device.make,
            model: null,
            groupLabel: device.groupLabel,
            grouping: item.grouping,
            label: device.label,
            protocol: {
              serialProtocol: device.serialProtocol,
              zigbeeProtocol: device.zigbeeProtocol,
              modbusRtuOverTcpProtocol: device.modbusRtuOverTcpProtocol,
              modbusRtuProtocol: device.modbusRtuProtocol,
              modbusTcpProtocol: device.modbusTcpProtocol
            },
            configDetails: {
              genericMakeLevelModelInfo: genericMakeLevelModelInfo,
              spaceHeaterMakeLevelModelInfo: spaceHeaterMakeLevelModelInfo,
              boilerMakeLevelModelInfo: boilerMakeLevelModelInfo,
              genericGroupLevelModelInfo: genericGroupLevelModelInfo,
              spaceHeaterGroupLevelModelInfo: spaceHeaterGroupLevelModelInfo,
              boilerGroupLevelModelInfo: boilerGroupLevelModelInfo,
              genericGroupLevelMakeInfo: genericGroupLevelMakeInfo,
              spaceHeaterGroupLevelMakeInfo: spaceHeaterGroupLevelMakeInfo,
              boilerGroupLevelMakeInfo: boilerGroupLevelMakeInfo
            }
          }
        } else if (item.grouping === ApplicationGrouping.MODEL_LEVEL) {
          return {
            id: device.id,
            libraryId: item.id,
            createdOn: item.createdOn,
            lastUpdatedOn: item.lastUpdatedOn,
            application: item.application,
            make: item.make || device.make,
            model: item.model || device.model,
            groupLabel: device.groupLabel,
            grouping: item.grouping,
            label: device.label,
            protocol: {
              serialProtocol: device.serialProtocol,
              zigbeeProtocol: device.zigbeeProtocol,
              modbusRtuOverTcpProtocol: device.modbusRtuOverTcpProtocol,
              modbusRtuProtocol: device.modbusRtuProtocol,
              modbusTcpProtocol: device.modbusTcpProtocol
            },
            configDetails: {
              genericMakeLevelModelInfo: genericMakeLevelModelInfo,
              spaceHeaterMakeLevelModelInfo: spaceHeaterMakeLevelModelInfo,
              boilerMakeLevelModelInfo: boilerMakeLevelModelInfo,
              genericGroupLevelModelInfo: genericGroupLevelModelInfo,
              spaceHeaterGroupLevelModelInfo: spaceHeaterGroupLevelModelInfo,
              boilerGroupLevelModelInfo: boilerGroupLevelModelInfo,
              genericGroupLevelMakeInfo: genericGroupLevelMakeInfo,
              spaceHeaterGroupLevelMakeInfo: spaceHeaterGroupLevelMakeInfo,
              boilerGroupLevelMakeInfo: boilerGroupLevelMakeInfo
            }
          }
        } else if (item.grouping === ApplicationGrouping.GROUP_LEVEL) {
          if (item.application === 'generic') {
            genericGroupLevelModelInfo.push(item.model)
            genericGroupLevelMakeInfo.push(item.model)
          }
          if (item.application === 'space_heater') {
            spaceHeaterGroupLevelModelInfo.push(item.model)
            spaceHeaterGroupLevelMakeInfo.push(item.model)
          }
          if (item.application === 'boiler') {
            boilerGroupLevelModelInfo.push(item.model)
            boilerGroupLevelMakeInfo.push(item.model)
          }
          return {
            id: device.id,
            libraryId: item.id,
            createdOn: item.createdOn,
            lastUpdatedOn: item.lastUpdatedOn,
            application: item.application,
            make: null,
            model: null,
            groupLabel: device.groupLabel,
            grouping: item.grouping,
            label: device.label,
            conFigMake: item.make || device.make,
            conFigModel: item.model || device.model,
            protocol: {
              serialProtocol: device.serialProtocol,
              zigbeeProtocol: device.zigbeeProtocol,
              modbusRtuOverTcpProtocol: device.modbusRtuOverTcpProtocol,
              modbusRtuProtocol: device.modbusRtuProtocol,
              modbusTcpProtocol: device.modbusTcpProtocol
            },
            configDetails: {
              genericMakeLevelModelInfo: genericMakeLevelModelInfo,
              spaceHeaterMakeLevelModelInfo: spaceHeaterMakeLevelModelInfo,
              boilerMakeLevelModelInfo: boilerMakeLevelModelInfo,
              genericGroupLevelModelInfo: genericGroupLevelModelInfo,
              spaceHeaterGroupLevelModelInfo: spaceHeaterGroupLevelModelInfo,
              boilerGroupLevelModelInfo: boilerGroupLevelModelInfo,
              genericGroupLevelMakeInfo: genericGroupLevelMakeInfo,
              spaceHeaterGroupLevelMakeInfo: spaceHeaterGroupLevelMakeInfo,
              boilerGroupLevelMakeInfo: boilerGroupLevelMakeInfo
            }
          }
        }
      })
    })

    const flatArray = newArray.flat().filter((item: any) => !!item)
    const sortedArray = flatArray.sort((a: any, b: any) => (a.application > b.application ? 1 : -1))
    let itemPrev = sortedArray[0]
    const result: any[] = []
    let itemCount = 0
    sortedArray.map((item: any) => {
      itemCount++

      if (item.application !== itemPrev.application || item.make !== itemPrev.make || item.model !== itemPrev.model) {
        result.push(itemPrev)
      }
      itemPrev = item
      if (itemCount === sortedArray.length) {
        result.push(item)
      }
    })
    const finalResult = result.sort((a: any, b: any) => (a.groupLabel > b.groupLabel ? 1 : -1))
    setModifiedList(finalResult)
  }, [filteredDevices])
  return (
    <Dimmer.Dimmable blurring dimmed={isAddingDevice}>
      <Dimmer active={isAddingDevice}>
        <Image src={loadingSymbol.default} className="rotate" />
        apparaat toevoegen even geduld...
      </Dimmer>
      <div className="device-gird-size device-m-x-l flex1-column">
        <div className="flex1-row space-between flex-center-vertical ">
          <strong className="title">Apparaat toevoegen</strong>
        </div>
        <Divider />
        <div className="flex1-row flex-center-vertical space-between">
          <div className="search-box-container">
            <Input placeholder="Zoek apparaat" value={searchValue} onChange={onChange} id="search-input" fluid></Input>
          </div>
          <img src={searchIcon.default} className="search-box"></img>
        </div>
        <div className="flex1-column m-t-medium">
          {modifiedList &&
            modifiedList?.map((item: any) => {
              if (
                item.application === helper.deviceDescriptionAssetApplicationMapping.smart_meter.smart_meter_english
              ) {
                return (
                  <div>
                    <div className="flex1-row flex-center-vertical">
                      <img
                        src={helper.deviceDescriptionAssetApplicationMapping.smart_meter.smart_meter_image}
                        className="device-icon-size"
                      ></img>
                      <div className="flex1-column column2 flex1-start m-l-medium">
                        <strong className="description flex1-row flex1-start">
                          {helper.deviceDescriptionAssetApplicationMapping.smart_meter.smart_meter_dutch}
                        </strong>
                        <div className="flex1-row flex1-start">
                          <div>{item.make}</div>
                          <div className={item.make && 'm-l-medium'}>{item.model}</div>
                        </div>
                      </div>
                      <Button content="Toevoegen" className="add-device-btn"></Button>
                      <Checkbox className="m-l-medium" checked={deviceIsChecked(item)} />
                    </div>
                    <Divider />
                  </div>
                )
              } else if (
                item.application ===
                helper.deviceDescriptionAssetApplicationMapping.electricity_meter_small_consumer
                  .electricity_meter_small_consumer_english
              ) {
                return (
                  <div>
                    <div className="flex1-row flex-center-vertical">
                      <img
                        src={
                          helper.deviceDescriptionAssetApplicationMapping.electricity_meter_small_consumer
                            .electricity_meter_small_consumer_image
                        }
                        className="device-icon-size"
                      ></img>
                      <div className="flex1-column column2 flex1-start m-l-medium">
                        <strong className="description flex1-row flex1-start">
                          {
                            helper.deviceDescriptionAssetApplicationMapping.electricity_meter_small_consumer
                              .electricity_meter_small_consumer_dutch
                          }
                        </strong>
                        <div className="flex1-row flex1-start">
                          <div>{item.make}</div>
                          <div className={item.make && 'm-l-medium'}>{item.model}</div>
                        </div>
                      </div>
                      <Button content="Toevoegen" className="add-device-btn"></Button>
                      <Checkbox className="m-l-medium" checked={deviceIsChecked(item)} />
                    </div>
                    <Divider />
                  </div>
                )
              } else if (
                item.application ===
                helper.deviceDescriptionAssetApplicationMapping.electricity_meter_large_consumer
                  .electricity_meter_large_consumer_english
              ) {
                return (
                  <div>
                    <div className="flex1-row flex-center-vertical">
                      <img
                        src={
                          helper.deviceDescriptionAssetApplicationMapping.electricity_meter_large_consumer
                            .electricity_meter_large_consumer_image
                        }
                        className="device-icon-size"
                      ></img>
                      <div className="flex1-column column2 flex1-start m-l-medium">
                        <strong className="description flex1-row flex1-start">
                          {
                            helper.deviceDescriptionAssetApplicationMapping.electricity_meter_large_consumer
                              .electricity_meter_large_consumer_dutch
                          }
                        </strong>
                        <div className="flex1-row flex1-start">
                          <div>{item.make}</div>
                          <div className={item.make && 'm-l-medium'}>{item.model}</div>
                        </div>
                      </div>
                      <Button content="Toevoegen" className="add-device-btn"></Button>
                      <Checkbox className="m-l-medium" checked={deviceIsChecked(item)} />
                    </div>
                    <Divider />
                  </div>
                )
              } else if (
                item.application ===
                helper.deviceDescriptionAssetApplicationMapping.smart_gas_meter_consumer
                  .smart_gas_meter_consumer_english
              ) {
                return (
                  <div>
                    <div className="flex1-row flex-center-vertical">
                      <img
                        src={
                          helper.deviceDescriptionAssetApplicationMapping.smart_gas_meter_consumer
                            .smart_gas_meter_consumer_image
                        }
                        className="device-icon-size"
                      ></img>
                      <div className="flex1-column column2 m-l-medium">
                        <strong className="description flex1-start">
                          {
                            helper.deviceDescriptionAssetApplicationMapping.smart_gas_meter_consumer
                              .smart_gas_meter_consumer_dutch
                          }
                        </strong>
                        <div className="flex1-row flex1-start">
                          <div>{item.make}</div>
                          <div className={item.make && 'm-l-medium'}>{item.model}</div>
                        </div>
                      </div>
                      <Button content="Toevoegen" className="add-device-btn"></Button>
                      <Checkbox className="m-l-medium" checked={deviceIsChecked(item)} />
                    </div>
                    <Divider />
                  </div>
                )
              } else if (item.application === helper.deviceDescriptionAssetApplicationMapping.boiler.boiler_english) {
                return (
                  <div className="flex1-column">
                    <div className="flex1-row flex-center-vertical">
                      <img
                        src={helper.deviceDescriptionAssetApplicationMapping.boiler.boiler_image}
                        className="device-icon-size"
                      ></img>
                      <div className="flex1-column column2 m-l-medium">
                        <strong className="description flex1-row flex1-start">
                          {helper.deviceDescriptionAssetApplicationMapping.boiler.boiler_dutch}
                        </strong>
                        <div className="flex1-row flex1-start">
                          <div>{item.make}</div>
                          <div className={item.make && 'm-l-medium'}>{item.model}</div>
                        </div>
                      </div>
                      <Button
                        content="Toevoegen"
                        className="add-device-btn"
                        enabled={deviceIsChecked(item)}
                        onClick={() => {
                          selectDevice(item)
                          setAddConfiguration(SmartControllerApplicaiton.BOILER)
                        }}
                      ></Button>
                      <Checkbox className="m-l-medium" checked={deviceIsChecked(item)} />
                    </div>
                    {selectedDevice &&
                      addConfiguration === SmartControllerApplicaiton.BOILER &&
                      selectedDevice.label === item.label &&
                      item.groupLabel === constants.SmartControllerDeviceGroupLabel && (
                        <div key={item.label} className="device-selected-detail flex1-column m-t-medium">
                          <SmartControllerConfigurationDetails
                            library={item}
                            addConfiguration={addConfiguration}
                            selectedDevice={selectedDevice}
                            setSelectedDevice={setSelectedDevice}
                            deviceAddedSuccessfully={deviceAddedSuccessfully}
                            setDeviceAddedSuccessfully={setDeviceAddedSuccessfully}
                          />
                        </div>
                      )}
                    <Divider />
                  </div>
                )
              } else if (
                item.application === helper.deviceDescriptionAssetApplicationMapping.space_heater.space_heater_english
              ) {
                return (
                  <div className="flex1-column">
                    <div className="flex1-row flex-center-vertical">
                      <img
                        src={helper.deviceDescriptionAssetApplicationMapping.space_heater.space_heater_image}
                        className="device-icon-size"
                      ></img>
                      <div className="flex1-column column2 m-l-medium">
                        <strong className="description flex1-row flex1-start">
                          {helper.deviceDescriptionAssetApplicationMapping.space_heater.space_heater_dutch}
                        </strong>
                        <div className="flex1-row flex1-start">
                          <div>{item.make}</div>
                          <div className={item.make && 'm-l-medium'}>{item.model}</div>
                        </div>
                      </div>
                      <Button
                        content="Toevoegen"
                        className="add-device-btn"
                        enabled={deviceIsChecked(item)}
                        onClick={() => {
                          selectDevice(item)
                          setAddConfiguration(SmartControllerApplicaiton.SPACE_HEATER)
                        }}
                      ></Button>
                      <Checkbox className="m-l-medium" checked={deviceIsChecked(item)} />
                    </div>
                    {selectedDevice &&
                      addConfiguration === SmartControllerApplicaiton.SPACE_HEATER &&
                      selectedDevice.label === item.label &&
                      item.groupLabel === constants.SmartControllerDeviceGroupLabel && (
                        <div key={item.label} className="device-selected-detail flex1-column m-t-medium">
                          <SmartControllerConfigurationDetails
                            library={item}
                            addConfiguration={addConfiguration}
                            selectedDevice={selectedDevice}
                            setSelectedDevice={setSelectedDevice}
                            deviceAddedSuccessfully={deviceAddedSuccessfully}
                            setDeviceAddedSuccessfully={setDeviceAddedSuccessfully}
                          />
                        </div>
                      )}
                    <Divider />
                  </div>
                )
              } else if (item.application === helper.deviceDescriptionAssetApplicationMapping.generic.generic_english) {
                return (
                  <div>
                    <div className="flex1-row flex-center-vertical">
                      <img
                        src={helper.deviceDescriptionAssetApplicationMapping.generic.generic_image}
                        className="device-icon-size"
                      ></img>
                      <div className="flex1-column column2 m-l-medium">
                        <strong className="description flex1-row flex1-start">
                          {helper.deviceDescriptionAssetApplicationMapping.generic.generic_dutch}
                        </strong>
                        <div className="flex1-row flex1-start">
                          <div>{item.make}</div>
                          <div className={item.make && 'm-l-medium'}>{item.model}</div>
                        </div>
                      </div>
                      <Button
                        content="Toevoegen"
                        className="add-device-btn"
                        enabled={deviceIsChecked(item)}
                        onClick={() => {
                          selectDevice(item)
                          setAddConfiguration(SmartControllerApplicaiton.GENERIC)
                        }}
                      ></Button>
                      <Checkbox className="m-l-medium" checked={deviceIsChecked(item)} />
                    </div>
                    {selectedDevice &&
                      addConfiguration === SmartControllerApplicaiton.GENERIC &&
                      selectedDevice.label === item.label &&
                      item.groupLabel === constants.SmartControllerDeviceGroupLabel && (
                        <div key={item.label} className="device-selected-detail flex1-column m-t-medium">
                          <SmartControllerConfigurationDetails
                            library={item}
                            addConfiguration={addConfiguration}
                            selectedDevice={selectedDevice}
                            setSelectedDevice={setSelectedDevice}
                            deviceAddedSuccessfully={deviceAddedSuccessfully}
                            setDeviceAddedSuccessfully={setDeviceAddedSuccessfully}
                          />
                        </div>
                      )}
                    <Divider />
                  </div>
                )
              } else if (
                item.application ===
                helper.deviceDescriptionAssetApplicationMapping.hybrid_inverter.hybrid_inverter_english
              ) {
                return (
                  <div>
                    <div className="flex1-row flex-center-vertical">
                      <img
                        src={helper.deviceDescriptionAssetApplicationMapping.hybrid_inverter.hybrid_inverter_image}
                        className="device-icon-size"
                      ></img>
                      <div className="flex1-column column2 m-l-medium">
                        <strong className="description flex1-row flex1-start">
                          {helper.deviceDescriptionAssetApplicationMapping.hybrid_inverter.hybrid_inverter_dutch}
                        </strong>
                        <div className="flex1-row flex1-start">
                          <div>{item.make}</div>
                          <div className={item.make && 'm-l-medium'}>{item.model}</div>
                        </div>
                      </div>
                      <Button
                        content="Toevoegen"
                        className="add-device-btn"
                        enabled={deviceIsChecked(item)}
                        onClick={() => {
                          selectDevice(item)
                        }}
                      ></Button>
                      <Checkbox className="m-l-medium" checked={deviceIsChecked(item)} />
                    </div>
                    {selectedDevice &&
                      selectedDevice.libraryId === item.libraryId &&
                      selectedDevice.label === item.label &&
                      item.groupLabel === constants.HybridInverterDeviceGroupLabel && (
                        <div key={item.label} className="flex1-column">
                          <ConfigurationDetails
                            pv1={pv1}
                            pv2={pv2}
                            isIpScanning={isIpScanning}
                            ipAddress={ipAddress}
                            noOfRoofSide={noOfRoofSide}
                            batteryCount={batteryCount}
                            setNoOfRoofSide={setNoOfRoofSide}
                            onSolarInputDataChange={onSolarInputChange}
                            makeOptions={batteryModelMakeOptions}
                            modelOptions={batteryModelModelOptions}
                            make={selectedBatteryModelMake}
                            model={selectedBatteryModelModel}
                            onScanClick={scanHybridInverterIpAddresses}
                            onAdd={addDevice}
                            onDropdownChange={onSolarDropDownChange}
                            setIpAddress={setIpAddress}
                            setBatteryCount={setBatteryCount}
                            onMakeChange={onBatteryMakeOptionsChange}
                            onModelChange={onBatteryModelOptionsChange}
                          />
                        </div>
                      )}
                    <Divider />
                  </div>
                )
              }
            })}
          <DeviceLibraryGeneric
            imageLink={helper.deviceGroupAssetMapping.gas_meter}
            description={helper.deviceDescriptionAssetMapping.gas_meter}
            groupLabel=""
          />
          <DeviceLibraryGeneric
            imageLink={helper.deviceGroupAssetMapping.charging_pole_ev}
            description={helper.deviceDescriptionAssetMapping.charging_pole_ev}
            groupLabel=""
          />
          <DeviceLibraryGeneric
            imageLink={helper.deviceGroupAssetMapping.battery}
            description={helper.deviceDescriptionAssetMapping.battery}
            groupLabel="Solax X-1"
          />
          <DeviceLibraryGeneric
            imageLink={helper.deviceGroupAssetMapping.battery}
            description={helper.deviceDescriptionAssetMapping.battery}
            groupLabel="Solax X-3"
          />
          <DeviceLibraryGeneric
            imageLink={helper.deviceGroupAssetMapping.battery}
            description={helper.deviceDescriptionAssetMapping.battery}
            groupLabel="Lyv WS laag voltage 1-fase"
          />
          <DeviceLibraryGeneric
            imageLink={helper.deviceGroupAssetMapping.battery}
            description={helper.deviceDescriptionAssetMapping.battery}
            groupLabel="Lyv WS hoog voltage 3-fase"
          />
          <DeviceLibraryGeneric
            imageLink={helper.deviceGroupAssetMapping.light_warm_pump}
            description={helper.deviceDescriptionAssetMapping.light_warm_pump}
            groupLabel="Nefit"
          />
        </div>
      </div>
    </Dimmer.Dimmable>
  )
}
export default DeviceList
