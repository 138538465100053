import './table.scss'
import * as React from 'react'
import { ListRow } from '../Row'
import { contractOptions } from '../../../util/options'

interface ITableProps {
  records: any[]
  displayKeys: string[]
  valueForkey: (key: string, record: any) => any
  detailsComponent: (record: any) => any
}

const TableComponent = (props: ITableProps) => {
  const mutableRowData = [...props.records].sort((a: any, b: any) => {
    return a.id - b.id
  })
  const getRowDataFromRecord = (record: any) => {
    const result: any = {}
    for (const key of props.displayKeys) {
      result[key] = props.valueForkey(key, record)
    }
    return result
  }
  return (
    <div className="data-table">
      <div className="data-table__inner">
        <table className="table">
          <thead>
            <tr>
              {props.displayKeys.map((val, index) => {
                const textAlign =
                  val === 'Name' || val === 'Status' || val === 'Community' || val === 'User'
                    ? 'textAlignLeft'
                    : 'textAlignCenter'
                return (
                  <th key={index} className={textAlign}>
                    {val}
                  </th>
                )
              })}
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {mutableRowData.map((record, index) => {
              return (
                <ListRow key={index} rowData={getRowDataFromRecord(record)}>
                  <div>{props.detailsComponent(record)}</div>
                </ListRow>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableComponent
