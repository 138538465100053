import * as React from 'react'
import { AiOutlineSync } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { VictoryPie, VictoryLabel, VictoryGroup, VictoryArea, VictoryAxis, VictoryChart, VictoryStack } from 'victory'
import * as upArrow from '../../../assets/up_arrow.png'
import { Image } from 'semantic-ui-react'
import * as gasSymbol from '../../../assets/Gas.svg'
import * as arrowIcon from '../../../assets/Arrow.svg'
import * as blueUpArrow from '../../../assets/blue_up_arrow.png'
import * as blueDownArrow from '../../../assets/blue_down_arrow.png'
import './gas.scss'
import { useContext } from 'react'
import { lightGreyColorScale, darkGreyColorScale } from '../../../util/graph_data'
import DataContext from '../../../contexts/data'
import AuthContext from '../../../contexts/auth'
import { tickCount } from '../../../util/helper'
import UsageService from '../../../services/usage'
import { IGasAggregate } from '../../../interfaces/aggregate'
const moment = require('moment')

interface IProps {
  setTileExpanded: (value: string) => void
  tileExpanded: string
}
const Gas: React.FC<IProps> = (props: IProps) => {
  const [expand, setExpand] = React.useState(false)
  const [value, setValue] = React.useState<string | undefined>(moment(new Date()).format('YYYY-MM-DD'))
  const [arrowUp, setArrowUp] = React.useState(false)
  const [gasCount, setGasCount] = React.useState<number>()
  const [usageData, setUsageData] = React.useState([])
  const [forecastData, setForecastData] = React.useState([])
  const authContext = React.useContext(AuthContext)
  const dataContext = useContext(DataContext)
  const [gas, setGas] = React.useState<IGasAggregate | undefined>(dataContext?.aggregate?.gasAggregate)
  const usageService = new UsageService()
  const detailsExpand = () => {
    setExpand(!expand)
  }
  const closeGraph = () => {
    setArrowUp(true)
    props.setTileExpanded('')
  }
  const handleDate = (e: any) => {
    setValue(e.target.value)
    setArrowUp(false)
  }
  const rightArrowClicked = (e: any) => {
    props.setTileExpanded('gas')
  }
  const fetchData = async () => {
    const token = authContext?.accessToken!
    const results = await usageService.getGasUsage(token, value)
    const forecast = await usageService.getGasUsage(token, value, true)
    setUsageData(results)
    setForecastData(results)
  }

  React.useEffect(() => {
    fetchData()
  }, [value])

  React.useEffect(() => {
    setGas(dataContext?.aggregate?.gasAggregate)
  }, [dataContext?.aggregate?.gasAggregate])

  const startAngle = 180
  let actualEndAngle = 180
  let predictedEndAngle = 180

  if (Math.abs(gas?.actualVolume!) >= Math.abs(gas?.predictedVolume!)) {
    actualEndAngle = gas?.actualVolume! > 0 ? 495 : -135
    const percentageOfPredictedVolume = (100 * Math.abs(gas?.predictedVolume!)) / Math.abs(gas?.actualVolume!)
    predictedEndAngle = (percentageOfPredictedVolume / 100) * actualEndAngle + 180
  } else {
    predictedEndAngle = gas?.predictedVolume! > 0 ? 495 : -135
    const percentageOfActualVolume = (100 * Math.abs(gas?.actualVolume!)) / Math.abs(gas?.predictedVolume!)
    actualEndAngle = (percentageOfActualVolume / 100) * predictedEndAngle + 180
  }
  React.useEffect(() => {
    setGasCount(tickCount(usageData))
  }, [usageData])

  const isAllZero: boolean = usageData.every((item: any) => item.value === 0)
  return (
    <>
      <div className="grid-container flex1-comlumn">
        <div className="flex1-row space-between">
          <strong className="title">Gas</strong>
          <div className="refresh-icon">
            <IconContext.Provider
              value={{
                style: { verticalAlign: 'middle', fill: 'rgba(0,183,213)' }
              }}
            >
              <div>
                <AiOutlineSync />
              </div>
            </IconContext.Provider>
          </div>
        </div>
        <hr />
        <div className="flex1-column">
          {!gas?.actualVolume && !gas?.predictedVolume && (
            <div className="flex-center-h-v">
              <div>Geen data beschikbaar</div>
            </div>
          )}
          <div className="gas-graph-positioning">
            {(!!gas?.actualVolume || !!gas?.predictedVolume) && (
              <div className="m-top-my ">
                <div className={props.tileExpanded ? 'expanded-tile' : 'normal-tile'}>
                  <VictoryGroup height={180}>
                    <VictoryPie
                      startAngle={startAngle}
                      endAngle={predictedEndAngle}
                      colorScale={lightGreyColorScale}
                      labels={() => null}
                      data={[{ y: 1 }]}
                      radius={70}
                      innerRadius={60}
                      style={{ labels: { fontSize: 16, fill: 'white' } }}
                    />
                    <VictoryPie
                      startAngle={startAngle}
                      endAngle={actualEndAngle}
                      colorScale={darkGreyColorScale}
                      data={[{ y: 1 }]}
                      radius={90}
                      innerRadius={73}
                      style={{ labels: { fontSize: 16, fill: 'white' } }}
                    />
                    <VictoryLabel
                      textAnchor="start"
                      style={{ fill: 'rgba(126,126,126)', fontSize: '14px' }}
                      x={gas?.actualVolume === 0 ? 195 : 176}
                      y={105}
                      text={
                        gas?.actualVolume !== 0
                          ? `${gas?.actualVolume.toFixed(2).toString().replace('.', ',')} m³`
                          : `${gas?.actualVolume.toString().replace('.', ',')} m³`
                      }
                      direction="ltr"
                    />
                    <VictoryLabel
                      textAnchor="start"
                      style={{ fill: 'rgba(126,126,126)', fontSize: '14px' }}
                      x={gas?.actualCost === 0 ? 195 : 176}
                      y={85}
                      text={
                        gas?.actualCost !== 0
                          ? `€ ${gas?.actualCost.toFixed(2).toString().replace('.', ',')}`
                          : `€ ${gas?.actualCost.toString().replace('.', ',')}`
                      }
                    />
                    <VictoryLabel
                      textAnchor="start"
                      style={{ fill: 'rgba(204,204,204)', fontSize: '14px' }}
                      x={gas?.predictedCost === 0 ? 238 : 250}
                      y={85}
                      text={
                        gas?.predictedCost !== 0
                          ? `€ ${gas?.predictedCost.toFixed(2).toString().replace('.', ',')}`
                          : `€ ${gas?.predictedCost.toString().replace('.', ',')}`
                      }
                      direction="ltr"
                    />
                    <VictoryLabel
                      textAnchor="start"
                      style={{ fill: 'rgba(204,204,204)', fontSize: '14px' }}
                      x={gas?.predictedVolume === 0 ? 238 : 270}
                      y={105}
                      text={
                        gas?.predictedVolume !== 0
                          ? `${gas?.predictedVolume.toFixed(2).toString().replace('.', ',')} m³`
                          : `${gas?.predictedVolume.toString().replace('.', ',')} m³`
                      }
                    />
                  </VictoryGroup>
                </div>
              </div>
            )}
            {(!!gas?.actualVolume || !!gas?.predictedVolume) && (
              <Image src={gasSymbol.default} className={props.tileExpanded ? 'gas-symbol modified' : 'gas-symbol'} />
            )}
          </div>
          <div className="flex1-row space-between flex-center-vertical">
            {(!!gas?.actualVolume || !!gas?.predictedVolume) && (
              <div className="flex1-row flex1-center full-width">
                <div className="flex1-row flex1-center" style={{ width: '95%' }}>
                  <div
                    className={
                      props.tileExpanded
                        ? 'actual flex1-row flex1-end modified-tile'
                        : 'actual flex1-row flex1-end normal-tile'
                    }
                    style={{ color: darkGreyColorScale }}
                  >
                    Actueel
                  </div>
                  <div
                    className={
                      props.tileExpanded
                        ? 'expected flex1-row flex1-start modified-tile'
                        : 'expected flex1-row flex1-start normal-tile'
                    }
                    style={{ marginLeft: '15px' }}
                  >
                    Voorspeld
                  </div>
                </div>
                {props.tileExpanded && <div className="flex1-row flex1-end" style={{ width: '5%' }}></div>}
                {!props.tileExpanded && (
                  <div className="flex1-row flex1-end">
                    <Image src={arrowIcon.default} className="right-arrow-icon" onClick={rightArrowClicked} />
                  </div>
                )}
              </div>
            )}
          </div>
          {props.tileExpanded && (
            <div>
              {(!!gas?.actualVolume || !!gas?.predictedVolume) && (
                <div className="flex1-row space-between" style={{ marginTop: '10px' }}>
                  <strong>Gas historie</strong>
                  <div className="fle1-row flex-center-vertical" onClick={detailsExpand}>
                    <strong className="detail-txt select-period">Selecteer periode</strong>
                    <input type="date" className="input-date" style={{ opacity: '0' }} onChange={handleDate}></input>
                    {expand && <Image src={blueUpArrow.default} style={{ marginBottom: '2px' }} />}
                    {!expand && <Image src={blueDownArrow.default} style={{ marginBottom: '2px' }} />}
                  </div>
                </div>
              )}
              <div>
                {value && (
                  <div className="m-top-s">
                    <div style={{ fontSize: '14px' }}> {value && moment(value).format('DD MMMM YYYY')}</div>
                    <div className="monitor-graph-container m-t-medium">
                      <VictoryChart
                        height={80}
                        scale={{ x: 'time' }}
                        padding={{ top: 5, bottom: 20, right: 10, left: 26 }}
                      >
                        {usageData && isAllZero ? (
                          <VictoryAxis
                            dependentAxis={true}
                            style={{
                              tickLabels: { fontSize: '5px', padding: 3 }
                            }}
                            tickFormat={() => ''}
                          />
                        ) : (
                          <VictoryAxis
                            dependentAxis={true}
                            crossAxis={false}
                            style={{
                              tickLabels: { fontSize: '5px', padding: 3 }
                            }}
                            tickFormat={(t) => t}
                          />
                        )}
                        {usageData && isAllZero ? (
                          <VictoryAxis tickFormat={() => ''} />
                        ) : (
                          <VictoryAxis
                            style={{
                              axis: { stroke: 'black' },
                              grid: { stroke: lightGreyColorScale, width: '2px' },
                              tickLabels: { fontSize: '5px', padding: -60 }
                            }}
                            tickFormat={(time) => {
                              return moment(time).format('HH:mm')
                            }}
                            tickLabelComponent={<VictoryLabel dx={6} />}
                            // tickCount={gasCount && gasCount}
                            tickCount={3}
                          />
                        )}
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: darkGreyColorScale }}
                          x={150}
                          y={75}
                          text="Actueel"
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: darkGreyColorScale }}
                          x={174}
                          y={75}
                          text={
                            gas?.actualCost !== 0
                              ? `€ ${gas?.actualCost.toFixed(2).toString().replace('.', ',')}`
                              : `€ ${gas?.actualCost.toString().replace('.', ',')}`
                          }
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: darkGreyColorScale }}
                          x={205}
                          y={75}
                          text={
                            gas?.actualVolume !== 0
                              ? `| ${gas?.actualVolume.toFixed(2).toString().replace('.', ',')} m³`
                              : `| ${gas?.actualVolume.toString().replace('.', ',')} m³`
                          }
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: lightGreyColorScale }}
                          x={255}
                          y={75}
                          text="Voorspeld"
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: lightGreyColorScale }}
                          x={270}
                          y={75}
                          text={
                            gas?.predictedCost !== 0
                              ? `€ ${gas?.predictedCost.toFixed(2).toString().replace('.', ',')}`
                              : `€ ${gas?.predictedCost.toString().replace('.', ',')}`
                          }
                        />
                        <VictoryLabel
                          textAnchor="end"
                          style={{ fontSize: 8, fill: lightGreyColorScale }}
                          x={295}
                          y={75}
                          text={
                            gas?.predictedVolume !== 0
                              ? `| ${gas?.predictedVolume.toFixed(2).toString().replace('.', ',')} m³`
                              : `| ${gas?.predictedVolume.toString().replace('.', ',')} m³`
                          }
                        />

                        <VictoryStack>
                          {usageData.length > 1 && (
                            <VictoryArea
                              interpolation="cardinal"
                              style={{ data: { fill: darkGreyColorScale, opacity: '0.6' } }}
                              data={
                                usageData &&
                                usageData.map((data: any) => {
                                  return {
                                    x: data.time,
                                    y: data.value
                                  }
                                })
                              }
                            />
                          )}
                        </VictoryStack>
                      </VictoryChart>
                    </div>
                    <div className="flex1-row flex1-end">
                      <Image src={upArrow.default} onClick={closeGraph} className="monitor-arrow-up" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default Gas
