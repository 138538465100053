import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Router } from 'react-router'
import history from './history'
import AuthUserProvider from './components/AuthUser/AuthUser'
import DataProvider from './components/Providers/Data'
import 'semantic-ui-css/semantic.min.css'
import App from './App'
import 'moment/locale/nl'

ReactDOM.render(
  <Router history={history}>
    <AuthUserProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </AuthUserProvider>
  </Router>,
  document.getElementById('root')
)
