import * as React from 'react'
import DataContext from '../../../contexts/data'
import { DataTable } from '../../Generic/Table'
import DeviceDetail from './DeviceDetail'

const DeviceListComponent = () => {
  const mapping: any = {
    'Device Group': 'groupName',
    Make: 'make',
    Model: 'model',
    Connection: 'connection',
    'Communication Path': 'communicationPath',
    'Communication Protocol': 'communicationProtocol',
    'On/OFF Controllable': 'onOffControllable',
    'Power Controllable': 'powerControllable'
  }

  const dataContext = React.useContext(DataContext)

  const fetchDevices = () => {
    return [...dataContext?.device?.mainMeters!, ...dataContext?.device?.hybridInverters!, ...dataContext?.device?.smartControllers!]
  }

  const deviceDetailsComponent = (device: any) => {
    return <DeviceDetail device={device} />
  }

  const getTableValueForKey = (key: string, user: any) => {
    return user[mapping[key]]
  }

  return (
    <DataTable
      displayKeys={Object.keys(mapping)}
      records={fetchDevices()!}
      detailsComponent={deviceDetailsComponent}
      valueForkey={getTableValueForKey}
    />
  )
}

export default DeviceListComponent
