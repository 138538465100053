import * as React from 'react'
import { Dropdown as Dd, Form } from 'semantic-ui-react'
import { IDropdownOption } from '../../../interfaces/general'

interface DropdownProps {
    options: IDropdownOption[]
    selected: any
    placeholder: string
    name: string
    label: string
    allowSearch?: boolean
    allowMultipleSelection?: boolean
    setSelected: (name: string, selected: any) => void
}

const Dropdown = (props: DropdownProps) => {
    const onChange = (e: any, data: any) => {
        props.setSelected(props.name, data.value)
    }

    return (
        <div className="flex1-row">
            <p className="customer-detail-names">{props.label}</p>
            <Form.Field className="flex1-row">
                <Dd
                    selection
                    search={props.allowSearch}
                    value={props.selected}
                    multiple={props.allowMultipleSelection}
                    placeholder={props.placeholder}
                    onChange={onChange}
                    options={props.options} />
            </Form.Field>
        </div>
    )
}

export default Dropdown
