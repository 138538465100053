import * as React from 'react'
import * as headerLogo from '../../assets/logo_rgb100x100.png'
import { useState } from 'react'
import { Segment, Menu, Image, MenuItemProps, Dropdown } from 'semantic-ui-react'
import './admin.scss'
import { Users as AdminUsersComponent } from '../shared/user'
import { Community } from './community'
import { Device } from './device'
import { Source } from './source'
import { Dashboard } from './dashboard'
import Zoho from './Zoho'
import CommunityService from '../../services/community'

import UserContext from '../../contexts/user'
import * as options from '../../util/options'
import AuthContext from '../../contexts/auth'

const AdminHomeMenu: React.FC = () => {
  const communityService = new CommunityService()

  const [activeItem, setActiveItem] = useState<string>('DASHBOARD')
  const authContext = React.useContext(AuthContext)
  const userContext = React.useContext(UserContext)
  const [communities, setCommunities] = React.useState<any[]>([])

  const fetchCommunities = async () => {
    const accessToken = authContext?.accessToken!
    const result = await communityService.listCommunities(accessToken)
    setCommunities(result)
  }

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement>, data: MenuItemProps) => {
    setActiveItem(data.name as string)
  }

  const onChange = (e: any, data: any) => {
    if (data.value === 1) {
      authContext?.logout()
    }
  }

  React.useEffect(() => {
    fetchCommunities()
  }, [])
  console.log('userContext?.profile?.lastName', userContext?.profile)
  return (
    <>
      <div className="admin-container">
        <div className="flex1-row header-container">
          <Menu attached="top" tabular className="flex1-row ">
            <div className="header-column1 flex1-row flex-center-vertical flex1-start">
              <Image src={headerLogo.default} className="header-logo-size header-logo-positioning" alt="Header logo" />
              <pre className="admin-portal">Admin portal</pre>
            </div>
            <div className="header-column2 flex1-row flex1-center">
              <Menu.Item
                name="DASHBOARD"
                active={activeItem === 'DASHBOARD'}
                onClick={handleItemClick}
                id="dashboard-active"
              >
                <h4>DASHBOARD</h4>
              </Menu.Item>
              <Menu.Item name="USERS" active={activeItem === 'USERS'} onClick={handleItemClick} id="users-active">
                <h4>USERS</h4>
              </Menu.Item>
              <Menu.Item
                name="COMMUNITY"
                active={activeItem === 'COMMUNITY'}
                onClick={handleItemClick}
                id="community-active"
              >
                <h4>COMMUNITY</h4>
              </Menu.Item>
              <Menu.Item name="DEVICES" active={activeItem === 'DEVICES'} onClick={handleItemClick} id="device-active">
                <h4>DEVICES</h4>
              </Menu.Item>
              <Menu.Item name="SOURCE" active={activeItem === 'SOURCE'} onClick={handleItemClick} id="source-active">
                <h4>SOURCE</h4>
              </Menu.Item>
              <Menu.Item name="ZOHO" active={activeItem === 'ZOHO'} onClick={handleItemClick} id="source-active">
                <h4>ZOHO</h4>
              </Menu.Item>
            </div>
            <div className="header-column3 user-name flex-center-vertical flex1-row flex1-end">
              <Dropdown
                text={userContext?.profile?.lastName}
                options={options.adminOptions}
                onChange={onChange}
                simple
                item
              />
            </div>
          </Menu>
        </div>
        {activeItem === 'DASHBOARD' && (
          <Segment attached="bottom">
            <Dashboard community={{}} communities={communities} />
          </Segment>
        )}
        {activeItem === 'USERS' && (
          <Segment attached="bottom">
            <AdminUsersComponent />
          </Segment>
        )}
        {activeItem === 'COMMUNITY' && (
          <Segment attached="bottom">
            <Community />
          </Segment>
        )}
        {activeItem === 'DEVICES' && (
          <Segment attached="bottom">
            <Device />
          </Segment>
        )}
        {activeItem === 'SOURCE' && (
          <Segment attached="bottom">
            <Source />
          </Segment>
        )}
        {activeItem === 'ZOHO' && (
          <Segment attached="bottom">
            <Zoho />
          </Segment>
        )}
      </div>
    </>
  )
}

export default AdminHomeMenu
