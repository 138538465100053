import * as React from 'react'
import { Table, Input } from 'semantic-ui-react'

interface ITaxReductionProps {
  taxReductions: any[]
  years: number[]
  editTaxReductions: any[]
  inEditMode: any
  handleReductionChange: Function
  handleReductionClick: Function
}

const TaxReductions = (props: ITaxReductionProps) => {
  const handleClickEvent = (id: string, field: string, value: number) => {
    props.handleReductionClick(id, field, value)
  }

  const handleTaxReductionChange = (id: number, field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleReductionChange(id, field, event.target.value)
  }

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Description</Table.HeaderCell>
            <Table.HeaderCell width={2}> </Table.HeaderCell>
            <Table.HeaderCell width={3}> </Table.HeaderCell>
            {props.years.map((year, i) => {
              return (
                <Table.HeaderCell key={i} width={2}>
                  {year}
                </Table.HeaderCell>
              )
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Heffingskorting</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            {props.years.map((year, i) => {
              const reduction: any = props.taxReductions.filter((reduction: any) => {
                return reduction.year === year
              })[0]
              return (
                <Table.Cell
                  key={i}
                  className={
                    reduction !== undefined &&
                      props.inEditMode.ids.includes(reduction.id) &&
                      reduction.id in props.editTaxReductions &&
                      'value' in props.editTaxReductions[reduction.id]
                      ? 'dirty'
                      : ''
                  }
                >
                  {reduction !== undefined
                    ? (
                      props.inEditMode.status &&
                      props.inEditMode.ids.includes(reduction.id) &&
                      reduction.id in props.editTaxReductions &&
                      'value' in props.editTaxReductions[reduction.id] ? (
                          <Input
                            transparent
                            className="tableInput"
                            type="number"
                            value={props.editTaxReductions[reduction.id].value}
                            onChange={
                              handleTaxReductionChange.bind(this, reduction.id, 'value')
                            }
                          />
                        ) : (
                          <p
                            className="editableElement"
                            onClick={
                              handleClickEvent.bind(this,
                                reduction.id,
                                'value',
                                reduction.value)
                            }>
                            {reduction.value}
                          </p>
                        )
                    ) : (
                      <p></p>
                    )}
                </Table.Cell>
              )
            })}
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  )
}

export default TaxReductions
