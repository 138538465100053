import * as React from 'react'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { Divider, Dropdown, Image } from 'semantic-ui-react'
import { SolarEnergy } from '../..'
import * as save from '../../../assets/save.png'
import * as unsave from '../../../assets/unsave.png'
import { ISolarModel } from '../../../interfaces/device'
import { IDropdownOption } from '../../../interfaces/general'
import * as loadingSymbol from '../../../assets/loading.png'
import './configuration_details.scss'

interface IConfigurationDetailsProps {
  batteryCount: number
  isIpScanning: boolean
  ipAddress: string
  makeOptions: IDropdownOption[]
  modelOptions: IDropdownOption[]
  make: string
  model: string
  noOfRoofSide: number
  pv1: ISolarModel
  pv2: ISolarModel
  onScanClick: () => void
  onAdd: () => void
  setIpAddress: (ip: string) => void
  setBatteryCount: (count: number) => void
  onMakeChange: (e: any, data: any) => void
  onModelChange: (e: any, data: any) => void
  onSolarInputDataChange: (index: number) => (e: any) => void
  onDropdownChange: (index: number) => (name: string, value: number) => void
  setNoOfRoofSide: (value: number) => void
}

const CofigurationDetails = (props: IConfigurationDetailsProps) => {
  return (
    <div className="configuration-details flex1-column">
      <strong className="flex1-row flex1-start m-t-medium">Configuratie gegevens</strong>
      <Divider />
      <div className="flex1-row flex-center-vertical">
        <div className="configuration-column1 required-field">IP-adres</div>
        <div className="flex1-column">
          <div className="flex1-row flex-center-vertical configuration-column2 flex1-start">
            <input
              placeholder="IP-adres"
              value={props.ipAddress}
              onChange={(e) => {
                props.setIpAddress(e.target.value)
                console.log('e.target.value', e.target.value)
              }}
              disabled={props.isIpScanning}
            />
            <IoMdInformationCircleOutline className="m-config-left" />
          </div>
        </div>
      </div>

      <div className="flex1-row flex1-end m-t-medium">
        {props.isIpScanning && (
          <div style={{ alignSelf: 'start' }} className="flex1-row flex1-start flex-center-vertical">
            <Image src={loadingSymbol.default} className="rotate scanner-size m-r-medium" />
            <div className="m-r-medium">Scannen naar IP-adres</div>
          </div>
        )}
        <button className="btn-blue scan-btn" onClick={props.onScanClick}>
          Scan
        </button>
      </div>
      <div className="flex1-column m-y-t">
        <div className="flex1-row space-between flex-center-vertical m-config-top">
          <strong className="configuration-column1">Batterij</strong>
          <div className="flex1-column">
            <div className="flex1-row configuration-column2"></div>
          </div>
        </div>
        <div className="flex1-row flex-center-vertical m-t-medium">
          <div className="configuration-column1 required-field">Merk</div>
          <div className="flex1-column">
            <div className="flex1-row configuration-column2">
              <Dropdown
                placeholder="Merk"
                selection
                fluid
                options={props.makeOptions}
                value={props.make}
                onChange={props.onMakeChange}
              />
            </div>
          </div>
        </div>
        <div className="flex1-row flex-center-vertical m-t-medium">
          <div className="configuration-column1 required-field">Model</div>
          <div className="flex1-column">
            <div className="flex1-row configuration-column2">
              <Dropdown
                placeholder="Model"
                selection
                fluid
                options={props.modelOptions}
                value={props.model}
                onChange={props.onModelChange}
              />
            </div>
          </div>
        </div>
        <div className="flex1-row flex-center-vertical m-t-medium">
          <div className="configuration-column1 required-field">Aantal batterijen</div>
          <div className="flex1-column">
            <div className="flex1-row configuration-column2">
              <input
                type="number"
                placeholder="Aantal batterijen"
                value={props.batteryCount}
                onChange={(e) => props.setBatteryCount(Number(e.target.value))}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex1-column m-y-t">
        <SolarEnergy
          onDropdownChange={props.onDropdownChange}
          pv1={props.pv1}
          pv2={props.pv2}
          noOfRoofSide={props.noOfRoofSide}
          setNoOfRoofSide={props.setNoOfRoofSide}
          onInputChange={props.onSolarInputDataChange}
        />
        <div className="flex1-row flex-center-vertical flex1-end m-config-top m-config-right">
          <img src={unsave.default}></img>
          <img src={save.default} onClick={props.onAdd}></img>
        </div>
      </div>
    </div>
  )
}

export default CofigurationDetails
