import * as React from 'react'
import { Divider, Input, Grid, Form, Dropdown } from 'semantic-ui-react'
import UnitInput from './unit_input'
import GenericSmartController from './generic_smart_controller'
import { useState, useEffect } from 'react'
import { SmartControllerApplianceTypeOptions } from '~/util/options'
import * as tick from '../../../assets/tick.png'
import { SmartControllerApplianceType, SmartControllerApplicaiton } from '~/enums'
import { CustomizedButton } from '../..'
import { IDevice } from '~/interfaces/device'
import styled from 'styled-components'
import { IDropdownOption } from '~/interfaces/general'
const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown > .text {
    color: #4d4d4d !important;
  }
  &.ui.selection.dropdown {
    border-color: rgba(34, 36, 38, 0.35);
    padding-left: 0.5rem;
  }
  &.ui.selection.dropdown > .dropdown.icon {
    top: 0.5em !important;
  }
`
interface IProps {
  library: any
  addConfiguration: SmartControllerApplicaiton
  setSelectedDevice: (value: IDevice | null) => void
  selectedDevice: IDevice | null
  setDeviceAddedSuccessfully: (value: string) => void
  deviceAddedSuccessfully: string
}
const SmartControllerConfigurationDetails = (props: IProps) => {
  const [formData, setFormData] = useState<any>({
    applianceType: '',
    applianceName: '',
    powerRating: '',
    boilerCapacity: ''
  })
  const [genericModelData, setGenericModelData] = useState('')
  const [boilerModelData, setBoilerModelData] = useState('')
  const [heaterModelData, setHeaterModelData] = useState('')
  const [heaterGroupModelData, setHeaterGroupModelData] = useState('')
  const [genericGroupModelData, setGenericGroupModelData] = useState('')
  const [boilerGroupModelData, setBoilerGroupModelData] = useState('')
  const [heaterGroupMakeData, setHeaterGroupMakeData] = useState('')
  const [genericGroupMakeData, setGenericGroupMakeData] = useState('')
  const [boilerGroupMakeData, setBoilerGroupMakeData] = useState('')

  const handleInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    switch (props.addConfiguration) {
      case SmartControllerApplicaiton.BOILER:
        setFormData({ ...formData, applianceType: SmartControllerApplianceType.ELECTRIC_BOILER })
        break
      case SmartControllerApplicaiton.SPACE_HEATER:
        setFormData({ ...formData, applianceType: SmartControllerApplianceType.HEATER })
        break
      default:
        setFormData({ ...formData, applianceType: '' })
    }
  }, [props.addConfiguration])

  const genericMakeLevelModelInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.genericMakeLevelModelInfo.length !== 0 &&
    props.library.configDetails.genericMakeLevelModelInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  const boilerMakeLevelModelInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.boilerMakeLevelModelInfo.length !== 0 &&
    props.library.configDetails.boilerMakeLevelModelInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  const heaterMakeLevelModelInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.spaceHeaterMakeLevelModelInfo.length !== 0 &&
    props.library.configDetails.spaceHeaterMakeLevelModelInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  const genericGroupLevelModelInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.genericGroupLevelModelInfo.length !== 0 &&
    props.library.configDetails.genericGroupLevelModelInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  const spaceHeaterGroupLevelModelInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.spaceHeaterGroupLevelModelInfo.length !== 0 &&
    props.library.configDetails.spaceHeaterGroupLevelModelInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  const boilerGroupLevelModelInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.boilerGroupLevelModelInfo.length !== 0 &&
    props.library.configDetails.boilerGroupLevelModelInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  const genericGroupLevelMakeInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.genericGroupLevelMakeInfo.length !== 0 &&
    props.library.configDetails.genericGroupLevelMakeInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  const spaceHeaterGroupLevelMakeInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.spaceHeaterGroupLevelMakeInfo.length !== 0 &&
    props.library.configDetails.spaceHeaterGroupLevelMakeInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  const boilerGroupLevelMakeInfo: IDropdownOption[] =
    props.library &&
    props.library.configDetails.boilerGroupLevelMakeInfo.length !== 0 &&
    props.library.configDetails.boilerGroupLevelMakeInfo.map((item: any) => {
      return {
        key: item,
        text: item,
        value: item
      }
    })
  useEffect(() => {
    setGenericModelData(genericMakeLevelModelInfo && genericMakeLevelModelInfo[0].value)
    setBoilerModelData(boilerMakeLevelModelInfo && boilerMakeLevelModelInfo[0].value)
    setHeaterModelData(heaterMakeLevelModelInfo && heaterMakeLevelModelInfo[0].value)
    setHeaterGroupModelData(spaceHeaterGroupLevelModelInfo && spaceHeaterGroupLevelModelInfo[0].value)
    setGenericGroupModelData(genericGroupLevelModelInfo && genericGroupLevelModelInfo[0].value)
    setBoilerGroupModelData(boilerGroupLevelModelInfo && boilerGroupLevelModelInfo[0].value)
    setHeaterGroupMakeData(spaceHeaterGroupLevelMakeInfo && spaceHeaterGroupLevelMakeInfo[0].value)
    setGenericGroupMakeData(genericGroupLevelMakeInfo && genericGroupLevelMakeInfo[0].value)
    setBoilerGroupMakeData(boilerGroupLevelMakeInfo && boilerGroupLevelMakeInfo[0].value)
  }, [])

  return (
    <>
      {!props.deviceAddedSuccessfully ? (
        <Form>
          <Grid>
            <Grid.Row style={{ paddingBottom: '0.2rem' }}>
              <Grid.Column width="6" textAlign="left">
                <strong className="heading medium">Benoem apparaat</strong>
              </Grid.Column>
            </Grid.Row>
            <Divider></Divider>
            {props.addConfiguration === SmartControllerApplicaiton.GENERIC &&
              props.library.configDetails.genericMakeLevelModelInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Model</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={genericMakeLevelModelInfo}
                    value={genericModelData}
                    onChange={(e, data) => setGenericModelData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {props.addConfiguration === SmartControllerApplicaiton.BOILER &&
              props.library.configDetails.boilerMakeLevelModelInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Model</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={boilerMakeLevelModelInfo}
                    value={boilerModelData}
                    onChange={(e, data) => setBoilerModelData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {props.addConfiguration === SmartControllerApplicaiton.SPACE_HEATER &&
              props.library.configDetails.spaceHeaterMakeLevelModelInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Model</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={heaterMakeLevelModelInfo}
                    value={heaterModelData}
                    onChange={(e, data) => setHeaterModelData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {/* add here */}
            {props.addConfiguration === SmartControllerApplicaiton.BOILER &&
              props.library.configDetails.boilerGroupLevelMakeInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Make</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={boilerGroupLevelMakeInfo}
                    value={boilerGroupMakeData}
                    onChange={(e, data) => setBoilerGroupMakeData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {props.addConfiguration === SmartControllerApplicaiton.SPACE_HEATER &&
              props.library.configDetails.spaceHeaterGroupLevelMakeInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Make</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={spaceHeaterGroupLevelMakeInfo}
                    value={heaterGroupMakeData}
                    onChange={(e, data) => setHeaterGroupMakeData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {props.addConfiguration === SmartControllerApplicaiton.GENERIC &&
              props.library.configDetails.genericGroupLevelMakeInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Make</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={genericGroupLevelMakeInfo}
                    value={genericGroupMakeData}
                    onChange={(e, data) => setGenericGroupMakeData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {props.addConfiguration === SmartControllerApplicaiton.GENERIC &&
              props.library.configDetails.genericGroupLevelModelInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Model</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={genericGroupLevelModelInfo}
                    value={genericGroupModelData}
                    onChange={(e, data) => setGenericGroupModelData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {props.addConfiguration === SmartControllerApplicaiton.BOILER &&
              props.library.configDetails.boilerGroupLevelModelInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Model</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={boilerGroupLevelModelInfo}
                    value={boilerGroupModelData}
                    onChange={(e, data) => setBoilerGroupModelData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {props.addConfiguration === SmartControllerApplicaiton.SPACE_HEATER &&
              props.library.configDetails.spaceHeaterGroupLevelModelInfo.length !== 0 && (
              <Grid.Row>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium">Model</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left" verticalAlign="middle">
                  <StyledDropdown
                    style={{ height: '36px', backgroundColor: 'white' }}
                    options={spaceHeaterGroupLevelModelInfo}
                    value={heaterGroupModelData}
                    onChange={(e, data) => setHeaterGroupModelData(data.value)}
                    className="full-width"
                    selection
                    name="model"
                    placeholder="Model"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}

            {props.addConfiguration === SmartControllerApplicaiton.GENERIC && (
              <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium required-field">Type</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <StyledDropdown
                    options={SmartControllerApplianceTypeOptions}
                    value={formData.applianceType}
                    onChange={(e, data) => setFormData({ ...formData, applianceType: data.value })}
                    className="full-width"
                    style={{ height: '36px', backgroundColor: 'white' }}
                    selection
                    name="applianceType"
                    placeholder="None"
                    required
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '1.5rem' }}>
              <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                <div className="heading medium ">Naam</div>
              </Grid.Column>
              <Grid.Column width="12" textAlign="left">
                <Form.Field>
                  <Input onChange={handleInputChange} name="applianceName" className="full-width" placeholder="Naam" />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            {props.addConfiguration === SmartControllerApplicaiton.BOILER && (
              <Grid>
                <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '0.7rem' }}>
                  <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                    <div className="heading medium required-field">Inhoud</div>
                  </Grid.Column>
                  <Grid.Column width="12" textAlign="left">
                    <UnitInput
                      value={formData.boilerCapacity}
                      onChange={(e) => setFormData({ ...formData, boilerCapacity: e.target.value })}
                      name="boilerCapacity"
                      unitLabel="Liter"
                      width="82%"
                      placeholder="Inhoud"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '1.5rem' }}>
                  <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                    <div className="heading medium required-field">Vermogen</div>
                  </Grid.Column>
                  <Grid.Column width="12" textAlign="left">
                    <UnitInput
                      value={formData.powerRating}
                      name="powerRating"
                      onChange={(e) => setFormData({ ...formData, powerRating: e.target.value })}
                      unitLabel="W"
                      width="86.5%"
                      placeholder="Vermogen"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            {props.addConfiguration === SmartControllerApplicaiton.SPACE_HEATER && (
              <Grid.Row style={{ paddingTop: '0.7rem', paddingBottom: '1.5rem' }}>
                <Grid.Column width="4" textAlign="left" verticalAlign="middle">
                  <div className="heading medium required-field">Vermogen</div>
                </Grid.Column>
                <Grid.Column width="12" textAlign="left">
                  <UnitInput
                    value={formData.powerRating}
                    name="powerRating"
                    onChange={(e) => setFormData({ ...formData, powerRating: e.target.value })}
                    unitLabel="W"
                    width="86.5%"
                    placeholder="Vermogen"
                    required
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <GenericSmartController
              library={props.library}
              data={formData}
              deviceAddedSuccessfully={props.deviceAddedSuccessfully}
              selectedDevice={props.selectedDevice}
              setSelectedDevice={props.setSelectedDevice}
              setDeviceAddedSuccessfully={props.setDeviceAddedSuccessfully}
            />
          </Grid>
        </Form>
      ) : (
        <Grid>
          <Grid.Row>
            <Grid.Column width="1"></Grid.Column>
            <Grid.Column width="12">
              <div className="full-width">Apparaat toegevoegd</div>
            </Grid.Column>
            <Grid.Column width="4">
              <img src={tick.default}></img>
            </Grid.Column>
            <Grid.Column width="1"></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="6"></Grid.Column>
            <Grid.Column width="4">
              <CustomizedButton
                content="Sluiten"
                width="100%"
                backgroundColor="#00b7d5"
                color="white"
                height="36px"
                onClick={() => props.setSelectedDevice(null)}
              />
            </Grid.Column>
            <Grid.Column width="6"></Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  )
}
export default SmartControllerConfigurationDetails
