import * as React from 'react'
import * as houseLogo from '../../assets/House.svg'
import { Image, Form, Dropdown, Message } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import './company_form.scss'
import UserContext from '../../contexts/user'

type UserSettingsForm = {
  language: string
  notification: string
  extraLogin: string
  nameUsage: string
  numberOfFte: string
  numberOfAdults: string
  numberOfChildren: string
}
// Component to get all the details of the customer
const UserSettings: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<UserSettingsForm>()

  const userContext = React.useContext(UserContext)

  const [formData, setFormData] = useState<any>({})
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
  const [selectedNotification, setSelectedNotification] = useState<boolean | null>(null)

  const languageOptions = [
    { key: 'none', text: 'Not Selected', value: null },
    { key: 'Nederlands', text: 'Nederlands', value: 'Dutch' }
  ]

  const notificationOptions = [
    { key: 'none', text: 'Not Selected', value: null },
    { key: 'Yes', text: 'Yes', value: true },
    { key: 'No', text: 'No', value: false }
  ]

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const data = { ...formData }
    delete data.createdOn
    delete data.lastUpdatedOn
    data.language = selectedLanguage
    data.notifications = selectedNotification
    try {
      await userContext?.updateSettings(data)
      setSuccessMessage('Gegevens succesvol bijgewerkt.')
    } catch (err) {
      setErrorMessage(err.errors[0])
    }
  }

  const onInputChange = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setFormData({ ...formData, [name]: value })
  }

  React.useEffect(() => {
    setFormData({ ...userContext?.settingsDetail })
    setSelectedLanguage(userContext?.settingsDetail.language || null)
    setSelectedNotification(userContext?.settingsDetail.notifications || null)
  }, [userContext?.settingsDetail])

  setTimeout(() => { setErrorMessage(''); setSuccessMessage('') }, 5000)
  return (
    <React.Fragment>
      <div className="customer-detail-container" id="company-container">
        <div className="customer-detail-form-container company-form">
          <div className="flex1-row">
            <Image src={houseLogo.default} className="customer-detail-logo-size"></Image>
            <strong className="customer-details-header" style={{ fontWeight: 'bold', fontSize: '22px' }}>
              Algemeen
            </strong>
          </div>
          <hr></hr>
          <Form onSubmit={onSubmit}>
            <div className="customer-details flex1-column">
              <div className="flex1-row">
                <p className="customer-detail-names">Taal</p>
                <Form.Field className="flex1-row">
                  <Dropdown
                    placeholder=""
                    selection
                    name="language"
                    onChange={(e, data) => setSelectedLanguage(data.value)}
                    value={selectedLanguage}
                    options={languageOptions}
                    className="form1-item"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names">Notificatie</p>
                <Form.Field className="flex1-row">
                  <Dropdown
                    placeholder=""
                    name="notifications"
                    selection
                    onChange={(e, data) => setSelectedNotification(data.value)}
                    value={selectedNotification}
                    options={notificationOptions}
                    disabled={userContext?.profile?.customerType === 'business'}
                    className="form1-item"
                  />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <p className="customer-detail-names">Extra Logins</p>
                <Form.Field>
                  <input value={formData.extraLogins ? formData.extraLogins : ''}
                    onChange={onInputChange} name="extraLogins" className="form1-item"
                    disabled={userContext?.profile?.customerType === 'business'} />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p>Naam gebruiker</p>
                </div>
                <Form.Field>
                  <input value={formData.user1} onChange={onInputChange} name="user1" className="form1-item" disabled={userContext?.profile?.customerType === 'business'} />
                </Form.Field>
              </div>
              <div className="flex1-row">
                <div className="customer-detail-names">
                  <p>Naam gebruiker</p>
                </div>
                <Form.Field>
                  <input value={formData.user2} onChange={onInputChange} name="user2" className="form1-item" disabled={userContext?.profile?.customerType === 'business'} />
                </Form.Field>
              </div>
              {userContext?.profile?.customerType === 'business' && <div className="flex1-row">
                <p className="customer-detail-names required-field ">Aantal FTE</p>
                <Form.Field>
                  <input name="totalFte" value={formData.totalFte ? formData.totalFte : ''} onChange={onInputChange} className="form1-item" ref={register({ required: true })} />
                  {errors.numberOfFte && errors.numberOfFte.type === 'required' && (
                    <div className="error-msg">U moet uw Aantal FTE invoeren.</div>
                  )}
                </Form.Field>
              </div>}
              {userContext?.profile?.customerType === 'private' && <div className="flex1-row">
                <p className="customer-detail-names required-field ">Aantal volwassenen</p>
                <Form.Field>
                  <input name="totalAdults" value={formData.totalAdults ? formData.totalAdults : ''} onChange={onInputChange} className="form1-item" ref={register({ required: true })} />
                  {errors.numberOfAdults && errors.numberOfAdults.type === 'required' && (
                    <div className="error-msg">U moet uw Aantal volwassenen invoeren.</div>
                  )}
                </Form.Field>
              </div>}
              {userContext?.profile?.customerType === 'private' && <div className="flex1-row">
                <p className="customer-detail-names required-field ">Aantal kinderen</p>
                <Form.Field>
                  <input name="totalChildren" value={formData.totalChildren ? formData.totalChildren : ''} onChange={onInputChange} className="form1-item" ref={register({ required: true })} />
                  {errors.numberOfChildren && errors.numberOfChildren.type === 'required' && (
                    <div className="error-msg">U moet uw Aantal kinderen invoeren.</div>
                  )}
                </Form.Field>
              </div>}
              <div className="flex1-row flex1-end m-py-bottom m-py-top-usersettings">
                <button className="btn-blue">Pas toe</button>
              </div>
              {errorMessage && errorMessage.length > 0 && <Message color="red">{errorMessage}</Message>}
              {successMessage && successMessage.length > 0 && <Message color="green">{successMessage}</Message>}
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  )
}
export default UserSettings
