import * as React from 'react'
import { Checkbox, Dropdown } from 'semantic-ui-react'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import './configuration_details.scss'
import { ISolarModel } from '../../../interfaces/device'
import { IDropdownOption } from '../../../interfaces/general'

interface ISolarEnergyProps {
  pv1: ISolarModel
  pv2: ISolarModel
  noOfRoofSide: number
  onInputChange: (index: number) => (e: any) => void
  onDropdownChange: (index: number) => (name: string, value: number) => void
  setNoOfRoofSide: (value: number) => void
}

const SolarEnergy = (props: ISolarEnergyProps) => {
  const orientationOptions: IDropdownOption[] = [
    { key: 'north', text: 'North', value: 0 },
    { key: 'north_east', text: 'North East', value: 45 },
    { key: 'east', text: 'East', value: 90 },
    { key: 'south_east', text: 'South East', value: 135 },
    { key: 'south', text: 'South', value: 180 },
    { key: 'south_west', text: 'South West', value: 225 },
    { key: 'west', text: 'West', value: 270 },
    { key: 'north_west', text: 'North West', value: 315 }
  ]

  const onCheckClick = (value: number) => () => {
    if (props.noOfRoofSide !== value) {
      props.setNoOfRoofSide(value)
    }
  }

  const onSolarDropDownChange = (index: number) => (e: any, data: any) => {
    props.onDropdownChange(index)('panelOrientation', data.value)
  }

  return (
    <>
      <div className="flex1-row space-between flex-center-vertical m-t-medium">
        <strong className="configuration-column1">Zonne-energie</strong>
        <div className="flex1-column">
          <div className="flex1-row configuration-column2"></div>
        </div>
      </div>
      <div className="flex1-row space-between flex-center-vertical m-t-medium">
        <div className="configuration-column1 required-field">Hoeveel zijden heeft uw dak?</div>
        <div className="flex1-row flex-center-vertical" style={{ width: '8.68vw' }}>
          <Checkbox checked={props.noOfRoofSide === 1} onClick={onCheckClick(1)} />
          <strong style={{ marginLeft: '5px', fontSize: '12px' }}>Een zijde</strong>
        </div>
        <div className="flex1-row flex-center-vertical" style={{ width: '8.86vw' }}>
          <Checkbox checked={props.noOfRoofSide === 2} onClick={onCheckClick(2)} />
          <strong style={{ marginLeft: '5px', fontSize: '12px' }}>Twee zijden</strong>
        </div>
      </div>
      {props.noOfRoofSide > 0 && (
        <>
          <div className="flex1-row space-between flex-center-vertical m-t-medium">
            <strong className="configuration-column1">Dakzijde 1</strong>
            <div className="flex1-column">
              <div className="flex1-row configuration-column2"></div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="configuration-column1 required-field">Vermogen zonnepanelen</div>
            <div className="flex1-column">
              <div className="flex1-row configuration-column2">
                <input
                  type="number"
                  placeholder="Vermogen"
                  name="powerRating"
                  value={props.pv1.powerRating}
                  onChange={props.onInputChange(1)}
                />
                <span style={{ marginLeft: '-37px', paddingTop: '8px', opacity: '0.9', fontSize: '14px' }}>kWp</span>
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="energy-supply-detail-names configuration-column1">Aantal panelen</div>
            <div className="flex1-column">
              <div className="flex1-row configuration-column2">
                <input
                  type="number"
                  placeholder="Aantal panelen"
                  name="panelCount"
                  value={props.pv1.panelCount}
                  onChange={props.onInputChange(1)} />
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="energy-supply-detail-names configuration-column1">Oppervlakte van elk paneel</div>
            <div className="flex1-column">
              <div className="flex1-row flex-center-vertical configuration-column2">
                <input
                  type="number"
                  placeholder="Oppervlakte"
                  name="panelArea"
                  value={props.pv1.panelArea}
                  onChange={props.onInputChange(1)}
                />
                <span style={{ marginLeft: '-30px', paddingTop: '2px', opacity: '0.9' }}>m²</span>
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="configuration-column1 required-field">Positionering van het zonnepaneel</div>
            <div className="flex1-column">
              <div className="flex1-row configuration-column2">
                <Dropdown
                  placeholder="Select Panel Orientation"
                  selection fluid
                  options={orientationOptions}
                  value={props.pv1.panelOrientation}
                  onChange={onSolarDropDownChange(1)} />
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="energy-supply-detail-names configuration-column1">
              Hoek van zonnepaneel t.o.v. horizontaal
            </div>
            <div className="flex1-column">
              <div className="flex1-row flex-center-vertical configuration-column2">
                <input
                  type="number"
                  placeholder="Hoek"
                  name="panelTilt"
                  value={props.pv1.panelTilt}
                  onChange={props.onInputChange(1)}
                />
                <IoMdInformationCircleOutline className="m-config-left" />
              </div>
            </div>
          </div>
        </>
      )}
      {props.noOfRoofSide > 1 && (
        <>
          <div className="flex1-row space-between flex-center-vertical m-t-medium">
            <strong className="configuration-column1">Dakzijde 2</strong>
            <div className="flex1-column">
              <div className="flex1-row configuration-column2"></div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="energy-supply-detail-names configuration-column1 required-field">Vermogen zonnepanelen</div>
            <div className="flex1-column">
              <div className="flex1-row configuration-column2">
                <input
                  type="number"
                  placeholder="Vermogen"
                  name="powerRating"
                  value={props.pv2.powerRating}
                  onChange={props.onInputChange(2)}
                />
                <span style={{ marginLeft: '-37px', paddingTop: '8px', opacity: '0.9', fontSize: '14px' }}>kWp</span>
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="energy-supply-detail-names configuration-column1">Aantal panelen</div>
            <div className="flex1-column">
              <div className="flex1-row configuration-column2">
                <input
                  type="number"
                  placeholder="Aantal panelen"
                  name="panelCount"
                  value={props.pv2.panelCount}
                  onChange={props.onInputChange(2)}
                />
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="energy-supply-detail-names configuration-column1">Oppervlakte van elk paneel</div>
            <div className="flex1-column">
              <div className="flex1-row flex-center-vertical configuration-column2">
                <input
                  type="number"
                  placeholder="Oppervlakte"
                  name="panelArea"
                  value={props.pv2.panelArea}
                  onChange={props.onInputChange(2)}
                />
                <span style={{ marginLeft: '-30px', paddingTop: '2px', opacity: '0.9' }}>m²</span>
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="configuration-column1 required-field">Positionering van het zonnepaneel</div>
            <div className="flex1-column">
              <div className="flex1-row configuration-column2">
                <Dropdown
                  placeholder="Select Panel Orientation"
                  selection fluid
                  options={orientationOptions}
                  value={props.pv2.panelOrientation}
                  onChange={onSolarDropDownChange(2)} />
              </div>
            </div>
          </div>
          <div className="flex1-row flex-center-vertical m-t-medium">
            <div className="energy-supply-detail-names configuration-column1">
              Hoek van zonnepaneel t.o.v. horizontaal
            </div>
            <div className="flex1-column">
              <div className="flex1-row flex-center-vertical configuration-column2">
                <input
                  type="number"
                  placeholder="Hoek"
                  name="panelTilt"
                  value={props.pv2.panelTilt}
                  onChange={props.onInputChange(2)}
                />
                <IoMdInformationCircleOutline className="m-config-left" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SolarEnergy
